import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProductsComponent } from './products/products.component';
import { SalesComponent } from './sales/sales.component';
import { SaleDetailComponent } from './sales-detail/sale-detail.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { RegistrationComponent } from './registration/registration.component';
import { ActivationComponent } from './activation/activation.component';
import { CompanySettingsComponent } from './company-settings/company-settings/company-settings.component';
import { TablesComponent } from './tables/tables.component';
import { StockListComponent } from './stock/stock-list/stock-list.component';
import { StockNoteBaseComponent } from './stock/stock-note-base/stock-note-base.component';
import { ContactListComponent } from './contacts/contact-list.component';
import { SkuListComponent } from './sku/sku-list/sku-list.component';
import { AdminComponent } from './admin-panel/admin.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserDetailComponent } from './users/user-detail/user-detail-tabs/user-detail/user-detail.component';
import { ContioStoreListComponent } from './contio-store/contio-store-list/contio-store-list.component';
import { DevicesComponent } from './devices/devices.component';
import { CameraListComponent } from './cameras/camera-list.component';
import { TelegramComponent } from './telegram/telegram.component';
import { LoyaltyCardListComponent } from './loyalty-cards/loyalty-card-list/loyalty-card-list.component';
import { LoyaltyCardDetailComponent } from './loyalty-cards/loyalty-card-detail/loyalty-card-detail.component';
import { ReturnListComponent } from './returns/return-list/return-list.component';
import { ReturnDetailComponent } from './returns/return-detail/return-detail.component';
import { OrdersComponent } from './orders/orders.component';
import { SettingsComponent } from './settings/settings.component';
import { CategoriesTabsComponent } from './categories/categories-tabs.component';
import { contioStoreDetailRoute } from './contio-store/route';
import { StockNoteInternalComponent } from './stock/stock-note-internal/stock-note-internal.component';
import { StockRoute } from './stock/stock-list/stock-route.enum';
import { ContioStoreSettingsComponent } from './contio-store/contio-store-settings/contio-store-settings.component';
import { TwilioListComponent } from './twilio/twilio-list.component';
import { TwilioRoute } from './twilio/twilio-route.enum';
import { SubaccountListComponent } from './subaccounts/subaccount-list/subaccount-list.component';
import { SubaccountDetailComponent } from './subaccounts/subaccount-detail/subaccount-detail.component';
import { SubaccountRoute } from './subaccounts/subaccount-route.enum';
import { ContioStoreActivityListComponent } from './contio-store/contio-store-activity-list/contio-store-activity-list.component';
import { CashRecordComponent } from './cash-record/cash-record.component';
import { ContioStoreScheduleComponent } from './contio-store/contio-store-schedule/contio-store-schedule.component';
import { ContioStoreAnalyticsComponent } from './contio-store/contio-store-analytics/contio-store-analytics.component';
import { UserDetailTabsComponent } from './users/user-detail/user-detail-tabs.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'sales/:value', component: SalesComponent },
  { path: 'sales/detail/:value', component: SaleDetailComponent },
  { path: 'user-settings', component: UserSettingsComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'activation/:value', component: ActivationComponent },
  { path: 'company-settings', component: CompanySettingsComponent },
  { path: 'tables', component: TablesComponent },
  { path: StockRoute.Stock, component: StockListComponent },
  { path: StockRoute.ReceiveNote + ':value', component: StockNoteBaseComponent },
  { path: StockRoute.WithdrawalNote + ':value', component: StockNoteBaseComponent },
  { path: StockRoute.InternalNote + ':value', component: StockNoteInternalComponent },
  { path: StockRoute.NewReceiveNote, component: StockNoteBaseComponent },
  { path: StockRoute.NewWithdrawalNote, component: StockNoteBaseComponent },
  { path: StockRoute.NewInternalNote, component: StockNoteInternalComponent },
  { path: 'contacts', component: ContactListComponent },
  { path: 'sku', component: SkuListComponent },
  { path: 'admin', component: AdminComponent },
  { path: SubaccountRoute.SubaccountList, component: SubaccountListComponent },
  { path: SubaccountRoute.SubaccountDetail + ':value', component: SubaccountDetailComponent },
  { path: 'users', component: UserListComponent },
  { path: 'user/detail/:value', component: UserDetailTabsComponent },
  { path: 'loyalty-cards', component: LoyaltyCardListComponent },
  { path: 'loyalty-card/detail/:value', component: LoyaltyCardDetailComponent },
  { path: 'returns', component: ReturnListComponent },
  { path: 'returns/detail/:value', component: ReturnDetailComponent },
  { path: 'contio-store', component: ContioStoreListComponent },
  contioStoreDetailRoute,
  { path: 'contio-store/detail/:value/settings', component: ContioStoreSettingsComponent },
  { path: 'contio-store/:value/activities', component: ContioStoreActivityListComponent },
  { path: 'contio-store/:value/schedule', component: ContioStoreScheduleComponent },
  { path: 'contio-store/analytics', component: ContioStoreAnalyticsComponent },
  { path: 'devices/:value', component: DevicesComponent },
  { path: 'cameras/:value', component: CameraListComponent },
  { path: 'telegram', component: TelegramComponent },
  { path: TwilioRoute.TwilioList, component: TwilioListComponent },
  { path: 'orders', component: OrdersComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'categories', component: CategoriesTabsComponent },
  { path: 'cashregister', component: CashRecordComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})

export class AppRoutingModule { }
