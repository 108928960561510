import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['../../../../../assets/scss/input.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputTextComponent<T extends string | number> implements OnInit {
  formControlName: string = 'name';
  floatLabelControl: FormControl = new FormControl('never');
  hideRequiredControl: FormControl = new FormControl(true);

  @Input() input?: T;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() errorMessage: string;
  @Input() type: string;
  @Input() min: string;
  @Input() step: string;
  @Input() required: boolean = false;
  @Input() submit: boolean = false;

  @Output() inputChange: EventEmitter<string | number> = new EventEmitter<string | number>();

  form: FormControl = new FormControl('', [Validators.required]);

  ngOnInit(): void {
    this.hideRequiredControl = new FormControl(!this.required);
  }

  onChange(input?: T): void {
      this.inputChange.emit(input);
  }
}
