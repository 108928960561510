<authentication-test></authentication-test>
<navigation selectedPage="subaccounts">
  <div class="initial-row w-100">
    <button
      class="normal-btn"
      mat-button
      (click)="onSetNewSubaccountClick(!areInputElementsVisible)"
    >
      <i *ngIf="areInputElementsVisible" class="fas fa-plus"></i>
      <i *ngIf="!areInputElementsVisible" class="fas fa-minus"></i>
      <span class="icon-name">{{ 'PRODUCTS.ADDPRODUCT' | translate }}</span>
    </button>
  </div>

  <div class="content mt-3" *ngIf="!areInputElementsVisible">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="100%" fxFlex.gt-md="50%">
        <app-input-text
          [(input)]="selectedSubaccount.Name"
          [submit]="isSubmit"
          type="text"
          label="{{ 'USERS.NAME' | translate }}"
          placeholder="{{ 'USERS.NAME' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
        ></app-input-text>
      </div>
      <div fxFlex="100%" fxFlex.gt-md="50%">
        <app-input-text
          [(input)]="selectedSubaccount.Surname"
          [submit]="isSubmit"
          type="text"
          label="{{ 'USERS.SURNAME' | translate }}"
          placeholder="{{ 'USERS.SURNAME' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
        ></app-input-text>
      </div>
      <div fxFlex="100%" fxFlex.gt-md="50%">
        <app-input-text
          [(input)]="selectedSubaccount.Email"
          [submit]="isSubmit"
          type="email"
          label="{{ 'USERS.EMAIL' | translate }}"
          placeholder="{{ 'USERS.EMAIL' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
        ></app-input-text>
      </div>
      <div fxFlex="100%" fxFlex.gt-md="50%">
        <app-input-text
          [(input)]="selectedSubaccount.Password"
          [submit]="isSubmit"
          type="text"
          label="{{ 'LOGIN.PASSWORD' | translate }}"
          placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
        ></app-input-text>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="20%">
        <app-dropdown
          *ngIf="true"
          [(selected)]="companyPrivilege"
          label="{{ 'NAVIGATION.STORES' | translate }}"
          itemKey="Value"
          [items]="priviligeType"
          [type]="dropdownType.Subaccount"
        ></app-dropdown>
      </div>
       <div fxFlex="100%" fxFlex.gt-lg="20%">
        <app-dropdown
          *ngIf="true"
          [(selected)]="contioPrivilege"
          label="{{ 'NAVIGATION.SHOP' | translate }}"
          itemKey="Value"
          [items]="priviligeType"
          [type]="dropdownType.Subaccount"
        ></app-dropdown>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="20%">
        <app-dropdown
          *ngIf="true"
          [(selected)]="loyaltyCardPrivilege"
          label="{{ 'NAVIGATION.LOYALTYCARDS' | translate }}"
          itemKey="Value"
          [items]="priviligeType"
          [type]="dropdownType.Subaccount"
          class="float-center"
        ></app-dropdown>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="20%" fxLayoutAlign.gt-lg="end">
        <app-dropdown
          *ngIf="true"
          [(selected)]="reportPrivilege"
          label="{{ 'SHOP.REPORTS' | translate }}"
          itemKey="Value"
          [items]="priviligeType"
          [type]="dropdownType.Subaccount"
        ></app-dropdown>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="20%" fxLayoutAlign.gt-lg="end">
        <app-dropdown
          *ngIf="true"
          [(selected)]="userPrivilege"
          label="{{ 'NAVIGATION.USERS' | translate }}"
          itemKey="Value"
          [items]="priviligeType"
          [type]="dropdownType.Subaccount"
        ></app-dropdown>
      </div>
      <div fxFlex="100%" fxLayoutAlign="end">
        <button
          class="save-btn"
          mat-button
          (click)="onSubaccountSaveClick()"
        >
          <i class="fas fa-save"></i>
          <span class="icon-name">{{
            'PRODUCTS.SAVE' | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row no-gutters w-100">
    <app-table
      *ngIf="entityList"
      [columns]="columns"
      [data]="entityList.items"
      [totalCount]="entityList.totalCount"
      [currentPage]="currentPage"
      [selectable]="true"
      (selectedRow)="onGoToAdministratorDetailCLick($event)"
      (pageChange)="onPageChange($event)"
    ></app-table>
  </div>
</navigation>
