import { Component, OnChanges, Input, ViewChild } from '@angular/core';
import { CashRegisterService } from '../cash-register.service';
import { ValidatorService } from '../../../core/services/validator/validator.service';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { SaleService } from '../../../core/services/sale/sale.service';
import { finalize } from 'rxjs/operators';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { CashRegister } from '../models/cashregister.model';
import { PostResponse } from '../../../core/interfaces/post-response.model';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { SubaccountValue } from '../../subaccounts/subaccount-value.enum';

@Component({
  selector: 'app-company-settings-cash-register',
  templateUrl: './company-settings-cash-register.component.html',
  styleUrls: ['./company-settings-cash-register.component.scss'],
})
export class CompanySettingsCashRegisterComponent implements OnChanges {
  @ViewChild('tab') tab: any;

  privilegeValue: any = SubaccountValue;
  cashRegisterList: CashRegister[];
  selectedCashRegister: CashRegister;
  cashRegisterModalInput: string;
  selectedCashRegisterName: string;

  defaultInput: string = '';
  selectedNavTitle: string;
  isMobileWaiter: boolean = false;
  isCashRegisterLoaded: boolean = false;
  canShowCashRegisterValidation: boolean = false;

  receiptGetResponse: PostResponse;
  postedReceipts: boolean = false;

  cashRegisterServerResponse: CreateRecordResponse;

  error: string;

  @Input() storeId: string;
  @Input() companyPrivilege: string;

  constructor(
    private cashRegisterService: CashRegisterService,
    private validatorService: ValidatorService,
    private modalService: NgbModal,
    private saleService: SaleService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnChanges(): void {
    this.selectedCashRegister = null;
    this.loadCashRegisters();
    this.validatorService.cashRegisterValidator();
  }

  onDeleteCashRegisterModalOpenClick(cashRegisterName: string): void {
    this.modalService.open(cashRegisterName, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  onAddCashRegisterModalOpen(cashregAdd: string): void {
    this.modalService.open(cashregAdd, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  onEditCashRegisterModalOpenClick(cashregEdit: string): void {
    this.cashRegisterModalInput = this.selectedCashRegister.Name;
    this.isMobileWaiter = this.selectedCashRegister.IsWaiter;
    this.modalService.open(cashregEdit, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  onCashRegisterPostClick(cashregisterName: string): void {
    this.canShowCashRegisterValidation = true;

    if (this.validatorService.cashRegisterForm.invalid) {
      return;
    }

    this.cashRegisterService
      .post(
        cashregisterName,
        this.isMobileWaiter,
        this.storeId
      )
      .pipe(
        finalize(() => {
          this.cashRegisterServerResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.cashRegisterServerResponse = successResponse;
          if (successResponse.success) {
            this.selectedCashRegisterName = cashregisterName;

            this.loadCashRegisters();
            this.onResetFieldsClick();
            this.snackBarService.show('CASHREGISTERS.CASHREGISTERPOSTSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('CASHREGISTERS.CASHREGISTERPOSTFAIL', false);
        },
      });
  }

  onCashRegisterEditClick(cashRegisterName: string): void {
    if (!this.selectedCashRegister.Name) {
      this.canShowCashRegisterValidation = true;
      return;
    }

    this.cashRegisterService
      .put(
        this.selectedCashRegister.Id,
        cashRegisterName,
        this.isMobileWaiter,
        this.storeId
      )
      .pipe(
        finalize(() => {
          this.cashRegisterServerResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.cashRegisterServerResponse = successResponse;
          if (successResponse.success) {
            this.selectedCashRegisterName = cashRegisterName;

            this.loadCashRegisters();
            this.onResetFieldsClick();

            this.snackBarService.show('CASHREGISTERS.CASHREGISTEREDITSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('CASHREGISTERS.CASHREGISTEREDITFAIL', false);
        },
      });
  }

  onCashRegisterDeleteClick(): void {
    this.hasCashRegisterAssignedReceipts()
      .then(
        (isCashRegisterAssignedToReceipt) => {
          this.deleteCashRegister(isCashRegisterAssignedToReceipt);
        });
  }

  onTabChange(navTab: NgbNavChangeEvent): void {
    this.canShowCashRegisterValidation = false;
    this.postedReceipts = false;

    this.selectedCashRegister = this.cashRegisterList.find(
      (cashregister) => cashregister.Name === navTab.nextId
    );
  }

  onResetFieldsClick(): void {
    this.canShowCashRegisterValidation = false;
    this.isMobileWaiter = false;
    this.defaultInput = '';
  }

  private loadCashRegisters(canEmptySelectedCashRegisterAfterLoad?: boolean): void {
    this.canShowCashRegisterValidation = false;

    this.cashRegisterService
      .get(this.storeId)
      .subscribe({
        next: (cashRegisterList) => {
          this.cashRegisterList = cashRegisterList;
          if (canEmptySelectedCashRegisterAfterLoad) {
            this.selectedCashRegister = null;
          }
          this.setCashRegister();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setCashRegister(): void {
    if (this.cashRegisterList.length === 0) {
      this.isCashRegisterLoaded = false;
      return;
    }

    if (this.selectedCashRegister) {
      this.selectCashRegisterByName(this.selectedCashRegisterName);
      this.selectedNavTitle = this.selectedCashRegisterName;
    } else {
      this.selectedCashRegister = this.cashRegisterList[0];
      this.isCashRegisterLoaded = true;
      this.selectedNavTitle = this.selectedCashRegister.Name;
    }
  }

  private selectCashRegisterByName(cashRegisterName: string): void {
    this.selectedCashRegister = this.cashRegisterList.find(
      (cashregister) => cashregister.Name === cashRegisterName
      );
  }

  private hasCashRegisterAssignedReceipts(): Promise<boolean> {
    let receiptsExist = false;

    return new Promise((resolve) => {
      this.saleService
        .getReceiptsByCashRegisterId(this.selectedCashRegister.Id)
        .subscribe({
          next: (getResponse) => {
            this.receiptGetResponse = getResponse;
            if (getResponse.success) {
              receiptsExist = true;
            }
            resolve(receiptsExist);
          },
          error: (error) => {
            this.error = error;
          },
        });
    });
  }

  private deleteCashRegister(isCashRegisterAssignedToReceipt: boolean): void {
    if (isCashRegisterAssignedToReceipt) {
      this.postedReceipts = isCashRegisterAssignedToReceipt;

      this.snackBarService.show('CASHREGISTERS.RECEIPTEXIST', false);

      return;
    }

    this.cashRegisterService
      .delete(
        this.storeId,
        this.selectedCashRegister.Id
      )
      .pipe(
        finalize(() => {
          this.cashRegisterServerResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.cashRegisterServerResponse = successResponse;
          if (successResponse.success) {
            this.canShowCashRegisterValidation = false;

            this.loadCashRegisters(true);
            this.onResetFieldsClick();

            this.snackBarService.show('CASHREGISTERS.CASHREGISTERDELETESUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('CASHREGISTERS.CASHREGISTERDELETEFAIL', false);
        },
      });
  }
}
