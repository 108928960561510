import { Component, OnInit } from '@angular/core';
import { TableColumn } from '../../../core/interfaces/shared/table-columns.model';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { SubaccountService } from '../subaccount.service';
import { TranslateService } from '@ngx-translate/core';
import { Subaccount } from '../models/subaccount.model';
import { Privilege } from '../models/privilege.model';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { SubaccountRoute } from '../subaccount-route.enum';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { SubaccountValue } from '../subaccount-value.enum';
import { SubaccountType } from '../subaccount-type.enum';
import { TwilioServerResponse } from '../../twilio/models/twilio-server-response.model';
import { ListResponse } from '../../../core/interfaces/shared/list-response.interface';
import { DropdownType } from '../../../shared/core-components/dropdown/dropdown-type.enum';

@Component({
  selector: 'app-subaccount-list',
  templateUrl: './subaccount-list.component.html',
})
export class SubaccountListComponent
  implements OnInit {
  dropdownType: any = DropdownType;
  userId: number;

  currentPage: number = 0;
  pageIndex: number;
  entityList: ListResponse<Subaccount>;

  columns: TableColumn[];
  getList$: Observable<ListResponse<Subaccount>>;

  areInputElementsVisible: boolean = true;
  isSubmit: boolean = false;
  selectedSubaccount: Subaccount;

  priviligeType: Privilege[];

  companyPrivilege: Privilege;
  contioPrivilege: Privilege;
  loyaltyCardPrivilege: Privilege;
  reportPrivilege: Privilege;
  userPrivilege: Privilege;

  postResponse: TwilioServerResponse;
  error: string;

  constructor(
    private router: Router,
    private subaccountService: SubaccountService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.columns = this.getAdministratorListColums();
    this.userId = this.localStorageService.getUserId();

    this.priviligeType = <Privilege[]>[
      {
        Value: SubaccountValue.NoAccess,
      },
      {
        Value: SubaccountValue.Read,
      },
      {
        Value: SubaccountValue.ReadWrite,
      },
    ];

    this.onPageChange(0);
  }

  onPageChange(pageIndex?: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.subaccountService.getList(
      this.userId,
      pageIndex,
      GridSetting.pageLimit
    );

    this.getList$.subscribe(
      (entityList) => { this.entityList = entityList; }
    );
  }

  onGoToAdministratorDetailCLick(administrator: Subaccount): void {
    this.router.navigate(['/' + SubaccountRoute.SubaccountDetail + administrator.Id]);
  }

  onSetNewSubaccountClick(areInputElementsVisible: boolean): void {
    this.selectedSubaccount = <Subaccount>{
      Name: '',
      Surname: '',
      Email: '',
      Password: '',
      IsEmailConfirmed: false,
      IsDeactivated: false,
    };

    this.companyPrivilege = <Privilege>{
      Value: SubaccountValue.NoAccess,
    };
    this.contioPrivilege = <Privilege>{
      Value: SubaccountValue.NoAccess,
    };
    this.loyaltyCardPrivilege = <Privilege>{
      Value: SubaccountValue.NoAccess,
    };
    this.reportPrivilege = <Privilege>{
      Value: SubaccountValue.NoAccess,
    };
    this.userPrivilege = <Privilege>{
      Value: SubaccountValue.NoAccess,
    };

    this.isSubmit = false;
    this.areInputElementsVisible = areInputElementsVisible;
  }

  onSubaccountSaveClick(): void {
    this.isSubmit = true;

    if (
      !this.selectedSubaccount.Name ||
      !this.selectedSubaccount.Surname ||
      !this.selectedSubaccount.Email ||
      !this.selectedSubaccount.Password) {
      return;
    }

    const priviligeList = <Privilege[]>[{
      Type: SubaccountType.Company,
      Value: this.companyPrivilege.Value,
    },
    {
      Type: SubaccountType.Contio,
      Value: this.contioPrivilege.Value,
    },
    {
      Type: SubaccountType.LoyaltyCard,
      Value: this.loyaltyCardPrivilege.Value,
    },
    {
      Type: SubaccountType.Reports,
      Value: this.reportPrivilege.Value,
    },
    {
      Type: SubaccountType.Users,
      Value: this.userPrivilege.Value,
    },
    ];

    this.subaccountService
      .post(
        this.selectedSubaccount,
        this.userId,
        priviligeList
      )
      .subscribe({
        next: (successResponse) => {
          this.postResponse = successResponse;

          this.onSetNewSubaccountClick(true);
          this.onPageChange(0);
          this.showSnackBar(true);
        },
        error: (error) => {
          this.error = error;

          this.showSnackBar(false);
        },
      });
  }

  private getAdministratorListColums(): TableColumn[] {
    return [
      {
        columnDef: 'Name',
        header: this.translateService.instant('USERS.NAME'),
        cell: (element: Subaccount) => `${element.Name}`,
      },
      {
        columnDef: 'Surname',
        header: this.translateService.instant('USERS.SURNAME'),
        cell: (element: Subaccount) => `${element.Surname}`,
      },
      {
        columnDef: 'Email',
        header: this.translateService.instant('USERS.EMAIL'),
        cell: (element: Subaccount) => `${element.Email}`,
      },
    ];
  }

  private showSnackBar(success: boolean): void {
    if (success) {
      this.snackBarService.show(this.translateService.instant('SHOP.ADDSUBACCOUNTSUCCESS'), true);
      return;
    }

    this.snackBarService.show(this.translateService.instant('SHOP.ADDSUBACCOUNTFAIL'), false);
  }
}
