<div class="row no-gutters my-row mt-3 w-100">
    <div class="col-sm">
      <h4 class="mt-2 reporting-name">
        {{ 'SKU.SKUREPORTINGNAME' | translate }} {{ _selectedSku.Name }}
      </h4>
    </div>

    <div class="col-xl-7 col-md-12 pl-xl-3">
      <div class="input-group-prepend float-xl-right float-left">
        <div class="input-group line-height date-input">
          <div class="input-group-prepend">
            <span class="input-group-text" for="from">{{
              'SALES.DATEFROM' | translate
            }}</span>
          </div>
          <input
            [minDate]="minDate"
            [maxDate]="maxDate"
            [placement]="ngbDatepickerPlacement"
            readonly="false"
            id="from"
            class="form-control"
            name="dp"
            value="{{ inceptionDate | dateFormat }}"
            [(ngModel)]="inceptionDate"
            ngbDatepicker
            #warehouseInceptionDate="ngbDatepicker"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="warehouseInceptionDate.toggle()"
              type="button"
            >
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>

        <div class="input-group ml-2 line-height date-input">
          <div class="input-group-prepend">
            <span class="input-group-text" for="to">{{
              'SALES.DATETO' | translate
            }}</span>
          </div>
          <input
            [minDate]="minDate"
            [maxDate]="maxDate"
            [placement]="ngbDatepickerPlacement"
            readonly="true"
            id="to"
            class="form-control"
            name="dp"
            value="{{ finalDate | dateFormat }}"
            [(ngModel)]="finalDate"
            ngbDatepicker
            #datePickerFinalDate="ngbDatepicker"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="datePickerFinalDate.toggle()"
              type="button"
            >
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-secondary search-btn ml-1"
          [class.active]="isButtonActive(1)"
          (click)="onSetDateFromFilterClick(inceptionDate, finalDate); onSetButtonActive(1)"
        >
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </div>

  <hr
    class="hr-line"
  />

  <div class="row no-gutters w-100 data-box">
    <app-table
              *ngIf="isDataTableLoaded"
              [columns]="columns"
              [data]="entityList"
    ></app-table>
    <div class="col-sm-12 my-row float-left mt-1">
      <button
        (click)="onDownloadSkuReportsClick()"
        triggers="mouseenter:mouseleave"
        class="btn btn-primary normal-btn"
      >
        <i class="fas fa-download mr-1"></i>
        &nbsp;&nbsp;{{ 'SALES.DOWNLOAD' | translate }}&nbsp;&nbsp;
      </button>
    </div>
  </div>
