<div
  class="nav-tabs mt-3"
  *ngIf="cashRegisterList && cashRegisterList.length > 0"
  style="border: none; overflow: hidden"
>
  <ng-container class="col-sm-12">
    <nav ngbNav #tab="ngbNav" [(activeId)]="selectedNavTitle" class="nav-tabs" (navChange)="onTabChange($event)">
      <ng-container
        *ngFor="let cashRegister of cashRegisterList"
        [ngbNavItem]="cashRegister.Name"
      >
        <a ngbNavLink>{{ cashRegister.Name }}</a>
        <ng-template ngbNavContent>
          <tr>
            <td>
              <p>ID: {{ cashRegister.Id }}</p>
              <p>
                {{ 'PRODUCTS.MOBILEWAITER' | translate }}:
                {{ cashRegister.IsWaiter ? '✔' : '❌' }}
              </p>
            </td>
          </tr>
        </ng-template>
      </ng-container>
    </nav>

    <div [ngbNavOutlet]="tab" class="mt-3 mb-1 ml-3"></div>
  </ng-container>
</div>

<div class="row no gutters">
  <div class="col-sm-12">
    <div class="btn-group" role="group" aria-label="Basic example">
      <button
        #addCashregister
        type="text"
        class="btn btn-primary normal-btn"
        id="addCashregister"
        style="width: auto"
        (click)="onAddCashRegisterModalOpen(cashregAdd)"
        [hidden]="companyPrivilege && companyPrivilege === privilegeValue.Read"
      >
        <i class="fas fa-plus" style="margin-right: 3px"></i>
        {{ 'CASHREGISTERS.CASHREGISTER' | translate }}
      </button>
      <button
        #editCashregister
        type="text"
        [hidden]="!selectedCashRegister || (companyPrivilege && companyPrivilege === privilegeValue.Read)"
        [disabled]="!selectedCashRegister"
        class="btn btn-primary normal-btn"
        id="addCashregisterEdit"
        (click)="onEditCashRegisterModalOpenClick(cashregEdit)"
      >
        {{ 'STORES.EDIT' | translate }}
      </button>
    </div>
  </div>
</div>

<!-- MODAL FOR ADDING CASHREGISTER -->
<ng-template #cashregAdd let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'CASHREGISTERS.ADDCASHREGISTER' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <div class="form-group" [formGroup]="validatorService.cashRegisterForm">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" for="add">{{
            'CASHREGISTERS.NEWCASHREGISTER' | translate
          }}</span>
        </div>
        <input
          #newCashregisterName
          [(ngModel)]="defaultInput"
          type="text"
          id="add"
          placeholder="{{ 'CASHREGISTERS.NAME' | translate }}"
          formControlName="cashregisterName"
          class="form-control"
          [ngClass]="{
            'is-invalid':
              canShowCashRegisterValidation &&
              validatorService.validateSubForm.cashregisterName.errors
          }"
        />
        <div
          *ngIf="
            canShowCashRegisterValidation &&
            validatorService.validateSubForm.cashregisterName.errors
          "
          class="invalid-feedback"
          style="text-align: end; margin-right: 1px"
        >
          <div class="input-group-append float-right">
            <div
              *ngIf="
                validatorService.validateSubForm.cashregisterName.errors
                  .required
              "
            >
              {{ 'VALIDATOR.VALIDATION' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="custom-control custom-checkbox checkbox-lg float-left">
        <input
          #newCashregisterIsWaiter
          type="checkbox"
          [(ngModel)]="isMobileWaiter"
          [ngModelOptions]="{ standalone: true }"
          class="custom-control-input"
          id="checkbox-3"
          checked="isMobileWaiter"
        />
        <label class="custom-control-label" for="checkbox-3">{{
          'PRODUCTS.MOBILEWAITER' | translate
        }}</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="c('Close click'); onResetFieldsClick()"
    >
      {{ 'PRODUCTS.CLOSE' | translate }}
    </button>

    <button
      class="btn btn-primary save-btn"
      type="button"
      (click)="onCashRegisterPostClick(newCashregisterName.value); cashRegisterName; c('Close click')"
    >
      <i class="fas fa-save" style="margin-right: 3px"></i>
      {{ 'CASHREGISTERS.NEW' | translate }}
    </button>
  </div>
</ng-template>

<!-- MODAL FOR EDITING CASHREGISTER -->
<ng-template #cashregEdit let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ selectedCashRegister.Name }}</h4>
    <button
      style="float: right"
      class="btn btn-danger"
      type="button"
      (click)="onDeleteCashRegisterModalOpenClick(cashregDelete); c('Close click')"
    >
      <i class="fas fa-trash-alt" style="margin-right: 3px"></i>
      {{ 'PRODUCTS.DELETEGROUP' | translate }}
    </button>
  </div>
  <div class="modal-body">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text" for="add">{{
          'CASHREGISTERS.EDITCASHREGISTER' | translate
        }}</span>
      </div>
      <input
        #cashRegisterNameEdit
        [(ngModel)]="cashRegisterModalInput"
        type="text"
        placeholder="{{ 'CASHREGISTERS.NAME' | translate }}"
        class="form-control"
        [ngClass]="{
          'is-invalid': !cashRegisterModalInput && canShowCashRegisterValidation
        }"
      />

      <div
        *ngIf="canShowCashRegisterValidation && !cashRegisterModalInput"
        class="invalid-feedback"
        style="text-align: end; margin-right: 1px"
      >
        <div class="input-group-append float-right">
          <div *ngIf="!selectedCashRegister.Name">
            {{ 'VALIDATOR.VALIDATION' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="custom-control custom-checkbox checkbox-lg float-left">
      <input
        #cashregisterIsWaiter
        type="checkbox"
        [(ngModel)]="isMobileWaiter"
        [ngModelOptions]="{ standalone: true }"
        class="custom-control-input"
        id="checkbox-3"
        checked="isMobileWaiter"
      />
      <label class="custom-control-label" for="checkbox-3">{{
        'PRODUCTS.MOBILEWAITER' | translate
      }}</label>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="c('Close click'); onResetFieldsClick()"
    >
      {{ 'PRODUCTS.CLOSE' | translate }}
    </button>

    <button
      class="btn btn-primary save-btn"
      type="button"
      (click)="onCashRegisterEditClick(cashRegisterNameEdit.value); selectedCashRegister.Name && c('Close click')"
    >
      <i class="fas fa-save" style="margin-right: 3px"></i>
      {{ 'STOCK.UPDATE' | translate }}
    </button>
  </div>
</ng-template>

<!-- MODAL WINDOW FOR DELETE CASHREGISTER -->
<ng-template #cashregDelete let-c="close" let-d="dismiss">
  <div style="padding-top: 3%">
    <div class="modal-header" style="text-align: center">
      <span style="font-size: large"
        >{{ 'VALIDATOR.SDIALOG' | translate }} '{{
          selectedCashRegister.Name
        }}'?</span
      >
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="onEditCashRegisterModalOpenClick(cashregEdit); c('Close click')"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        (click)="onCashRegisterDeleteClick(); c('Close click')"
      >
        <i class="fas fa-trash-alt" style="margin-right: 3px"></i>
        {{ 'PRODUCTS.DELETEGROUP' | translate }}
      </button>
    </div>
  </div>
</ng-template>
