import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationTestService } from '../../../core/authentication-test/authentication-test.service';
import { Router } from '@angular/router';
import { LocalStorageTags } from '../../../core/enums/local-storage-tags.enum';

@Component({
    selector: 'authentication-test',
    template: '',
})
export class AuthenticationTestComponent implements OnInit {
    authentication: any;

    @Output() success: EventEmitter<boolean> = new EventEmitter<boolean>();

    error: string;

    constructor(
        private authenticationService: AuthenticationTestService,
        private router: Router) { }

    ngOnInit(): void {
        this.authenticate();
    }

    authenticate() {
        const token = localStorage.getItem(LocalStorageTags.Token);
        this.authenticationService.authenticationTest(token)
            .subscribe(
                authentication => this.authentication = authentication,
                handleError => this.error = <any>handleError,
                () => this.emitResult()
            );
    }

    emitResult() {
        if (this.authentication.success === false) {
            localStorage.setItem(LocalStorageTags.Token, '');
            this.router.navigate(['/login']);
            this.success.emit(false);
            return;
        }

        this.success.emit(true);
    }
}
