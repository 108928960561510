import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { CashRegister } from './models/cashregister.model';
import { catchError } from 'rxjs/operators';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';

@Injectable()
export class CashRegisterService {
  private cashregisterUrl: string = Centrala.URL + '/api/cashregister';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService,
  ) { }

  get(storeId: string): Observable<CashRegister[]> {
    const params = new HttpParams()
      .set('storeId', storeId)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<CashRegister[]>(
        this.cashregisterUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  post(
    name: string,
    isWaiter: boolean,
    storeId: string
  ): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<CreateRecordResponse>(
        this.cashregisterUrl,
        {
          StoreId: storeId,
          Name: name,
          IsWaiter: isWaiter,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  put(
    id: number,
    name: string,
    isWaiter: boolean,
    storeId: string
  ): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<CreateRecordResponse>(
        this.cashregisterUrl,
        {
          StoreId: storeId,
          Id: id,
          Name: name,
          IsWaiter: isWaiter,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  delete(
    storeId: string,
    id: number
  ): Observable<CreateRecordResponse> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        StoreId: storeId,
        Id: id,
        token: localStorage.getItem(LocalStorageTags.Token),
      },
    };

    return this.httpClient
      .delete<CreateRecordResponse>(
        this.cashregisterUrl,
        options
      )
      .catch(this.httpHandlerService.handleError);
  }
}
