import { Component } from '@angular/core';
import { PasswordService } from '../../core/services/password/password.service';
import { Company } from '../company-settings/models/company.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaleService } from '../../core/services/sale/sale.service';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { ServerResponse } from '../../core/interfaces/shared/server-response.model';
import { Language } from '../../core/interfaces/language/language.model';
import { LanguageService } from '../../core/services/shared/language.service';
import { SettingsService } from '../../core/services/settings/settings.service';
import { SnackBarService } from '../../core/services/shared/snackbar.service';
import { Settings } from '../../core/interfaces/settings/settings.model';
import { ChangePasswordResponse } from '../../core/interfaces/password/change-password-response.model';

@Component({
  selector: 'user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: [
    './user-settings.component.scss',
  ],
})
export class UserSettingsComponent {
  //Password
  oldPassword: string = '';
  newPassword: string = '';
  newPasswordAgain: string = '';
  receiptDeletedData: ServerResponse = {
    action: '',
    success: null,
    message: '',
    code: null,
  };

  //Company
  selectedCompany: Company;
  companyData: Company[];

  changePassFormat: ChangePasswordResponse = {
    action: '',
    success: null,
    message: '',
    code: null,
    token: null,
  };

  languages: Language[];
  selectedLanguage: Language;

  constructor(
    private userSettingsService: PasswordService,
    private salesService: SaleService,
    private modalService: NgbModal,
    private languageService: LanguageService,
    private settingsService: SettingsService,
    private snackBarService: SnackBarService,
  ) {}

  openModalDanger(dangerModal: string) {
    this.modalService.open(dangerModal, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  errorMessage = null;

  translationCodeFormat = null;

  isAuthentication: any;

  ngOnInit(): void {
    this.companyData = JSON.parse(localStorage.getItem('companyData'));

    this.selectedCompany = this.getSelectedCompany(
      + localStorage.getItem(LocalStorageTags.CompanyId)
    );

    this.getGlobalLanguages();
  }

  /** Tab 1 **/

  sendPasswordChangeReq(
    currentPwd: string,
    newPwd: string,
    newPwdAgain: string
  ) {
    if (currentPwd === '' || newPwd === '' || newPwdAgain === '') {
      this.changePassFormat.code = 200;
      this.changePassFormat.success = false;
      this.translationCodeFormat = 'USERSETTINGS.CODE200';
      return;
    }

    if (newPwd.length < 6) {
      this.changePassFormat.code = 201;
      this.changePassFormat.success = false;
      this.translationCodeFormat = 'USERSETTINGS.CODE201';
      return;
    }

    if (newPwd != newPwdAgain) {
      this.changePassFormat.code = 102;
      this.changePassFormat.success = false;
      this.translationCodeFormat = 'USERSETTINGS.CODE102';
      return;
    }

    if (newPwd === currentPwd) {
      this.changePassFormat.code = 202;
      this.changePassFormat.success = false;
      this.translationCodeFormat = 'USERSETTINGS.CODE202';
      return;
    }

    this.userSettingsService
      .changePassword(
        localStorage.getItem('email'),
        currentPwd,
        newPwd,
        newPwdAgain
      )
      .subscribe(
        (extractData) => (this.changePassFormat = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          if (this.errorMessage) {
            this.changePassFormat.success = false;
            this.translationCodeFormat =
              'USERSETTINGS.CODE' + this.errorMessage.code;
          } else {
            this.translationCodeFormat =
              'USERSETTINGS.CODE' + this.changePassFormat.code;
            this.clearFields();
          }
        }
      );
  }

  clearFields() {
    this.oldPassword = '';
    this.newPassword = '';
    this.newPasswordAgain = '';
    setTimeout(() => {
      this.changePassFormat = {
        action: '',
        success: null,
        message: '',
        code: null,
        token: null,
      };
    }, 1000);
  }

  authenticationResult(event: object) {
    this.isAuthentication = event;
  }

  onDefaultLanguageSaveClick(): void {
    this.settingsService
    .post(
      this.selectedLanguage.Language,
    )
    .subscribe({
      next: (successResponse: ServerResponse) => {
        if (!successResponse) {
          return;
        }

        this.snackBarService.show('USERSETTINGS.DEFLANGPOSTSUCCESS', successResponse.success);
      },
      error: () => {
        this.snackBarService.show('USERSETTINGS.DEFLANGPOSTFAIL', false);
      },
    });
  }

  private getGlobalLanguages(): void {
    this.languages = this.languageService.getList();
    this.setDefaultLanguageFromSettings();
  }

  private setDefaultLanguageFromSettings(): void {
    this.settingsService.get().subscribe({
      next: (language: Settings[]) => {
        if (!language) {
          return;
        }
        let userDefaultLanguage = language[0].Language;
        this.selectedLanguage = this.languages.find(
          (lang) => lang.Language === userDefaultLanguage
        );
      },
    });
  }

  /** Tab 2 **/

  changeCompany(id: number) {
    this.selectedCompany = this.getSelectedCompany(id);
  }

  getSelectedCompany(selectedId: number) {
    for (var i = 0; i < this.companyData.length; i++) {
      if (this.companyData[i].Id === selectedId) {
        return this.companyData[i];
      }
    }
  }

  deleteCompanyReceipts(id) {
    if (!id) {
      return;
    }

    this.salesService.deleteReceipts(id).subscribe(
      (extractData) => (this.receiptDeletedData = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => {
        if (this.receiptDeletedData) {
          this.resetAlerts();
        }
      }
    );
  }

  resetAlerts() {
    setTimeout(() => {
      this.receiptDeletedData = {
        action: '',
        success: null,
        message: '',
        code: null,
      };
    }, 1000);
  }
}
