<mat-button-toggle-group appearance="legacy" aria-label="Font Style">
  <mat-button-toggle *ngIf="label" class="dropdown-label" disabled>
    {{ label }}
  </mat-button-toggle>
  <mat-button-toggle
    class="dropdown-row"
    [ngClass]="{ 'dropdown-corners': label }"
    [matMenuTriggerFor]="menu"
    (menuOpened)="onMenuOpen()"
    (menuClosed)="onMenuClose()"
    [style.border]="isDropdownInvalid ? '3px solid red !important' : ''"
  >
  <span *ngIf="type === dropdownType.Subaccount">
    {{ name | subaccountAccess: name}}
  </span>
  <span *ngIf="type === dropdownType.Analytics">
    {{ name | analyticsType: name}}
  </span>
  <span *ngIf="!type">
    {{ name }}
  </span>
    <i *ngIf="!isMenuOpen" class="fas fa-caret-down icon-name"></i>
    <i *ngIf="isMenuOpen" class="fas fa-caret-up icon-name"></i>
  </mat-button-toggle>
</mat-button-toggle-group>
<mat-menu
  #menu="matMenu"
  backdropClass="custom-dropdown"
  yPosition="below"
  xPosition="before"
>
  <button
    class="dropdown-row"
    mat-menu-item
    [ngClass]="{ 'selected-item': item[itemKey] === name }"
    *ngFor="let item of items"
    (click)="onSelectItem(item)"
  >
  <span *ngIf="type === dropdownType.Analytics">
    {{ item[itemKey] | analyticsType: item[itemKey]}}
  </span>
    <span *ngIf="type === dropdownType.Subaccount">
      {{ item[itemKey] | subaccountAccess: item[itemKey]}}
    </span>
    <span *ngIf="!type">
      {{ item[itemKey] }}
    </span>
  </button>
</mat-menu>
