import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';

@Directive({
  selector: '[appDragAndDrop]',
})
export class DragAndDropDirective {
  @Output() onFileDropped = new EventEmitter<File>();

  readonly allowedFormats: Array<string> = ['image/png', 'image/jpeg', 'image/jpg'];

  constructor(
    private translateService: TranslateService,
    private snackBarService: SnackBarService
  ) {}

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    if (files.length > 1) {
      this.snackBarService.show('PICTURE.ONLYONE', false);
      return;
    }

    const file = files[0];
    if (this.allowedFormats.indexOf(file.type) === -1) {
      this.snackBarService.show('PICTURE.WRONGTYPE', false);
      return;
    } else {
      this.onFileDropped.emit(file);
    }
  }
}
