export class Company {
  Bank: string;
  Bin: string;
  City: string;
  Email: string;
  Id: number;
  Logo: any;
  Name: string;
  Phone: string;
  State: string;
  Street: string;
  TaxPayer: boolean;
  Tin: string;
  UserId: number;
  Web: string;
  Zip: string;
}
