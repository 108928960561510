import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { Centrala } from '../../http/centrala';
import { Settings } from '../../interfaces/settings/settings.model';
import { HttpHandlerService } from '../shared/http-handler.service';
import { Language } from '../../interfaces/language/language.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languages: string[]; 
  private readonly url: string = Centrala.URL + '/api/languages';

  //TODO: Get jazyků je třeba vyčlenit z constructoru bude to probíhat samostatně při loginu, zatím nevíme jak přesně implementovat.
  
  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService,
    private translateService: TranslateService) {
      this.get().subscribe({
        next: (languages: string[]) => {
          this.languages = languages;
          this.translateService.addLangs(this.languages);
        },
      });
    }

  getList(): Language[] {
    let applicationLanguages: Language[] = [];
    let languages = this.translateService.getLangs();

    languages.forEach((language, index) => {
      applicationLanguages.push({ Id: index, Language: language });
    });

    return applicationLanguages;
  }

  private get(): Observable<string[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<string[]>(this.url, { params })
      .pipe(catchError(this.httpHandlerService.handleError));
  }
}
