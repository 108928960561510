<authentication-test></authentication-test>
<navigation selectedPage="returns">
  <div class="row no-gutters w-100 justify-content-center">
    <div class="col-sm-12">
      <div class="input-group-prepend float-right">
        <div class="row m-0">
          <div class="col-12 col-md-5 p-0">
        <div class="input-group date-input">
          <div class="input-group-prepend">
            <span class="input-group-text" for="from">{{
              'SALES.DATEFROM' | translate
            }}</span>
          </div>
          <input
            [minDate]="minDate"
            [maxDate]="maxDate"
            [placement]="placement"
            readonly="false"
            id="from"
            class="form-control"
            name="dp"
            value="{{ dateFrom | dateFormat }}"
            [(ngModel)]="dateFrom"
            ngbDatepicker
            #d1="ngbDatepicker"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="d1.toggle()"
              type="button"
            >
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-auto col-md-5 p-0 mt-2 mt-md-0">
        <div class="input-group date-input">
          <div class="input-group-prepend">
            <span class="input-group-text" for="to">{{
              'SALES.DATETO' | translate
            }}</span>
          </div>
          <input
            [minDate]="minDate"
            [maxDate]="maxDate"
            [placement]="placement"
            readonly="true"
            id="to"
            class="form-control"
            name="dp"
            value="{{ dateTo | dateFormat }}"
            [(ngModel)]="dateTo"
            ngbDatepicker
            #d2="ngbDatepicker"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="d2.toggle()"
              type="button"
            >
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>
    <div class="col-auto col-md-2 d-flex p-0 pt-md-0 pt-2 ml-2 ml-md-0">
        <button
          type="button"
          class="btn btn-secondary search-btn mr-1"
          [class.active]="isButtonActive(1)"
          (click)="onGetReportByDateFilterClick(dateFrom, dateTo); onSetButtonActiveClick(1)"
        >
          <i class="fas fa-search"></i>
        </button>

        <button
          type="button"
          class="btn btn-secondary search-btn mr-1"
          [class.active]="isButtonActive(2)"
          (click)="onGetAllReportClick(); onSetButtonActiveClick(2)"
        >
          {{ 'PRODUCTS.ALL' | translate }}
        </button>
      </div>
    </div>
    </div>
    </div>
  </div>

  <div class="row no-gutters w-100">
    <div class="col-sm my-col data-box">
      <ngx-datatable
        #datatable
        class="material ngx-datatable-cursor"
        [rows]="returnList"
        [limit]="10"
        [columns]="[
          { prop: 'Name', name: 'USERS.NAME' | translate },
          { prop: 'Surname', name: 'USERS.SURNAME' | translate },
          { prop: 'Date', name: 'Datum' },
          { prop: 'Description', name: 'Stav' },
          { prop: 'ReturnType', name: 'Identifikátor' }
        ]"
        [headerHeight]="50"
        [footerHeight]="50"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        [selectionType]="'single'"
        [limit]="pageLimit"
        [externalPaging]="true"
        [count]="returnsCount"
        [offset]="currentPage"
        [messages]="{
          emptyMessage: 'PRODUCTS.TABLENODATA' | translate,
          totalMessage: 'PRODUCTS.TABLETOTAL' | translate
        }"
        (select)="onGoToReturnDetailClick($event.selected[0])"
        (page)="setReturns($event)"
      >
      </ngx-datatable>
    </div>
  </div>
</navigation>
