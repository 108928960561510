<div class="row">
  <div class="col-sm">
    <div class="pl-2 dynamic-title">
      <span *ngIf="noteId && noteType === stockNoteTypes.Receive">
        {{ 'STOCK.EDITRECEIVE' | translate }}
      </span>
      <span *ngIf="!noteId && noteType === stockNoteTypes.Receive">
        {{ 'STOCK.NEWRECEIVE' | translate }}
      </span>
      <span *ngIf="noteId && noteType === stockNoteTypes.Withdrawal">
        {{ 'STOCK.EDITPICKING' | translate }}
      </span>
      <span *ngIf="!noteId && noteType === stockNoteTypes.Withdrawal">
        {{ 'STOCK.NEWPICKING' | translate }}
      </span>
      <button
        class="save-btn float-right"
        mat-button
        (click)="onSaveStockCardClick()"
        [disabled]="!selectedWarehouse"
      >
        <i class="fas fa-save"></i>
        <span class="icon-name">{{ 'PRODUCTS.SAVECHANGES' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<hr class="hr-line mb-4" />

<div class="row">
  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
    <form class="form-inline">
      <div class="form-group">
        <div class="input-group input-height">
          <div class="input-group-prepend">
            <span class="input-group-text" for="to">{{
              'SALES.TABLEDATE' | translate
            }}</span>
          </div>
          <div class="input-group-prepend"></div>
          <input
            class="form-control input-height"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [placement]="placement"
            [disabled]="isStockRowEdit"
            readonly="false"
            name="dp"
            value="{{ document.Date | dateFormat }}"
            [(ngModel)]="document.Date"
            ngbDatepicker
            #d="ngbDatepicker"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="d.toggle()"
              type="button"
              [disabled]="isStockRowEdit"
            >
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div
    class="col-xl-4 col-lg-6 col-md-6 col-sm-12 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3 form-group mb-3"
    [formGroup]="stockForm"
  >
    <div class="input-group">
      <div class="input-group-prepend input-height">
        <span class="input-group-text" id="spInvoiceNo">{{
          'STOCK.INVOICENO' | translate
        }}</span>
      </div>
      <input
        [(ngModel)]="document.InvoiceNo"
        type="text"
        class="form-control normal-input input-height"
        id="inpInvoiceNo"
        placeholder="0"
        formControlName="invoiceNumber"
        [ngClass]="{
          'is-invalid': isSubmitted && validateStockForm.invoiceNumber.errors
        }"
      />
      <div
        *ngIf="isSubmitted && validateStockForm.invoiceNumber.errors"
        class="invalid-feedback text-right div-right-margin"
      >
        <div *ngIf="validateStockForm.invoiceNumber.errors.required">
          {{ 'VALIDATOR.VALIDATION' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 float-left">
    <div class="input-group-prepend">
      <span class="input-group-text">{{ 'STOCK.SUPPLIER' | translate }}</span>

      <div
        class="ng-autocomplete w-100"
        [ngClass]="{
          'autocomplete-invalid': isSubmitted
        }"
      >
        <ng-autocomplete
          [data]="contactList"
          [searchKeyword]="keyword"
          [(ngModel)]="selectedContact"
          (selected)="selectEvent($event)"
          (inputChanged)="onContactChangeSearch($event)"
          (inputCleared)="onInputCleared()"
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate"
        >
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item[keyword]"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
    <span class="help-block invalid-name" *ngIf="isSubmitted">{{
      'VALIDATOR.VALIDATION' | translate
    }}</span>
  </div>
</div>

<div class="row" class="mb-1">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" class="mt-1">
    <app-dropdown
      *ngIf="selectedWarehouse"
      [(selected)]="selectedWarehouse"
      (selectedChange)="onWarehouseSelectChange()"
      label="{{ 'STORES.WAREHOUSE' | translate }}"
      itemKey="Name"
      [items]="warehouseList"
    ></app-dropdown>
    <div
      class="row no-gutters"
      *ngIf="isDropdownValidate && !selectedWarehouse"
    >
      <span class="help-block invalid-group w-auto text-right"
        >{{ 'VALIDATOR.WAREHOUSEVALID' | translate }}
        <span class="text-undeline">
          <a routerLink="/company-settings">{{
            'VALIDATOR.ROUTER' | translate
          }}</a> </span
        >.
      </span>
    </div>
  </div>
</div>

<div class="pb-4">
  <div class="row">
    <div class="col-sm">
      <hr class="hr-line" />
    </div>
  </div>
</div>

<div class="row mt-3" *ngIf="storageCardList">
  <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3 float-left">
    <div class="input-group-prepend">
      <span class="input-group-text" id="spInvoiceNo">{{
        'SKU.SKU' | translate
      }}</span>

      <div
        class="ng-autocomplete w-100 input-height"
        [ngClass]="{
          'autocomplete-invalid':
            isStockNoteRecordSubmitted && isAutocompleteRecordValidate
        }"
      >
        <ng-autocomplete
          *ngIf="storageCardList"
          [data]="storageCardList"
          [searchKeyword]="keyword"
          [(ngModel)]="selectedStorageCard"
          (selected)="selectEvent($event)"
          (inputChanged)="onChangeStorageCardSearch($event)"
          (inputCleared)="onInputCleared()"
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate"
        >
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.Name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
    <span
      class="help-block invalid-name"
      *ngIf="isStockNoteRecordSubmitted && isAutocompleteRecordValidate"
      >{{ 'VALIDATOR.VALIDATION' | translate }}</span
    >
  </div>

  <div
    class="col-xl-4 col-lg-4 col-md-4 col-sm-6 form-group mb-3"
    [formGroup]="stockNoteForm"
  >
    <div class="input-group">
      <div class="input-group-prepend input-height">
        <span class="input-group-text" id="nameInput">{{
          'STOCK.QUANTITY' | translate
        }}</span>
      </div>
      <input
        [(ngModel)]="quantity"
        type="number"
        class="form-control normal-input input-height"
        id="inpQuantity"
        placeholder="0"
        formControlName="stockNoteQuantity"
        [ngClass]="{
          'is-invalid':
            isStockNoteRecordSubmitted &&
            validateStockNoteForm.stockNoteQuantity.errors
        }"
      />
      <div
        *ngIf="
          isStockNoteRecordSubmitted &&
          validateStockNoteForm.stockNoteQuantity.errors
        "
        class="invalid-feedback text-right div-right-margin"
      >
        <div *ngIf="validateStockNoteForm.stockNoteQuantity.errors.required">
          {{ 'VALIDATOR.VALIDATION' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-xl-4 col-lg-4 col-md-4 col-sm-6 form-group mb-3"
    [formGroup]="stockNoteForm"
  >
    <div class="input-group">
      <div class="input-group-prepend input-height">
        <span class="input-group-text" id="nameInput">{{
          'STOCK.UNITPRICE' | translate
        }}</span>
      </div>
      <input
        [(ngModel)]="unitPrice"
        type="text"
        class="form-control normal-input input-height"
        id="inpUnitPrice"
        placeholder="0"
        formControlName="stockNoteUnitPrice"
        [ngClass]="{
          'is-invalid':
            isStockNoteRecordSubmitted &&
            validateStockNoteForm.stockNoteUnitPrice.errors
        }"
      />
      <div
        *ngIf="
          isStockNoteRecordSubmitted &&
          validateStockNoteForm.stockNoteUnitPrice.errors
        "
        class="invalid-feedback text-right div-right-margin"
      >
        <div *ngIf="validateStockNoteForm.stockNoteUnitPrice.errors.required">
          {{ 'VALIDATOR.VALIDATION' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="float-left" *ngIf="noteType === stockNoteTypes.Receive">
      <input
        #uploader
        type="file"
        name="file"
        id="file"
        class="form-control inputfile"
        accept=".xml,.isdoc"
        (change)="onChangeFileInput($event)"
      />
      <button
        class="btn btn-primary normal-btn"
        [disabled]="!selectedWarehouse"
        (click)="uploader.click()"
      >
        <i class="fas fa-upload mr-1"></i>
        {{ 'STOCK.IMPORT' | translate }}
      </button>
    </div>

    <button
      (click)="onDeleteNoteRecordClick()"
      class="btn btn-danger float-right"
      id="btnDeleteRecord"
      [hidden]="!isStockRowEdit"
      [disabled]="!selectedWarehouse"
    >
      <i class="fas fa-trash-alt mr-1"></i>
      {{ 'STOCK.DELETERECORD' | translate }}
    </button>
    <button
      (click)="onAddNoteRecordClicl()"
      class="btn btn-primary new-btn float-right mr-1"
      id="btnAddRecord"
      [hidden]="isStockRowEdit"
      [disabled]="!selectedWarehouse"
    >
      <i class="fas fa-plus mr-1"></i>
      {{ 'STOCK.ADDRECORD' | translate }}
    </button>
    <button
      (click)="onEditNoteRecordClick()"
      class="btn btn-primary save-btn float-right mr-1"
      id="btnSaveDocument"
      [hidden]="!isStockRowEdit"
      [disabled]="!selectedWarehouse"
    >
      <i class="fas fa-save mr-1"></i>
      {{ 'STOCK.UPDATE' | translate }}
    </button>
  </div>
</div>

<div class="row no-gutters my-row w-100">
  <app-table
    *ngIf="isDataTableLoaded"
    [columns]="columns"
    [data]="entityList"
    [totalCount]="recordsCount"
    [currentPage]="currentPage"
    [selectable]="true"
    (selectedRow)="onNoteRecordTableSelect($event)"
    (pageChange)="onPageChange($event)"
  ></app-table>
</div>
