import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarMessageType } from '../../enums/snackbar-message-type.enum';

@Injectable({
    providedIn: 'root',
})

export class SnackBarService {
    constructor(
        public snackBar: MatSnackBar,
        private translateService: TranslateService
    ) { }

    show(
        message: string,
        isSuccess: boolean,
        additionalMessage: string = '',
    ): void {
        let messageType: string;
        const snackBarMessage = `${this.translateService.instant(message)} ${additionalMessage}`;

        if (isSuccess) {
            messageType = SnackBarMessageType.SUCCESS;
        } else {
            messageType = SnackBarMessageType.ERROR;
        }

        this.snackBar.open(snackBarMessage, '', {
            duration: 3000,
            data: snackBarMessage,
            panelClass: messageType,
        });
    }
}
