<authentication-test></authentication-test>
<navigation selectedPage="cashRecord">
  <div class="row no-gutters w-100">
    <app-input-datetime class="mr-2" label="{{ 'CASHRECORD.DATEFROM' | translate }}" [(input)]="dateFrom"
      errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}" [useMaterial]="false"></app-input-datetime>
    <app-input-datetime class="mr-2" label="{{ 'CASHRECORD.DATETO' | translate }}" [(input)]="dateTo"
      errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}" [useMaterial]="false"></app-input-datetime>
    <button class="btn btn-secondary search-btn mr-2" (click)="onFilterByDateClick()">
      <i class="fas fa-search"></i>
    </button>
  </div>
  <div class="mb-2">
    <div class="row no-gutters mt-2">
      <app-dropdown [(selected)]="selectedCurrency" (selectedChange)="onCurrencyChange(selectedCurrency)"
        *ngIf="selectedCurrency" label="{{ 'CASHRECORD.CURRENCY' | translate }}" [items]="currencies" itemKey="Name">
      </app-dropdown>
    </div>
    <div class="row no-gutters mt-2">
      <app-dropdown [(selected)]="selectedStore" (selectedChange)="onStoreChange(selectedStore)" *ngIf="selectedStore"
        label="{{ 'CASHRECORD.STORE' | translate }}" itemKey="Name" [items]="storeList">
      </app-dropdown>
    </div>
    <div class="row no-gutters mt-2">
      <div class="col-6">
        <app-dropdown label="{{ 'CASHRECORD.CASHREGISTER' | translate }}" itemKey="Name"
          [(selected)]="selectedCashRegister" (selectedChange)="onCashRegisterChange(selectedCashRegister)"
          *ngIf="selectedCashRegister" [items]="cashRegisterList"></app-dropdown>
        <div *ngIf="!selectedCashRegister" class="alert alert-danger" role="alert">
          {{ 'CASHRECORD.NOCASHREGISTER' | translate }}
        </div>
      </div>
      <div class="col-6">
        <button (click)="onCreateCsvClick()" class="btn btn-primary normal-btn float-right">
          <i class="fas fa-download mr-2"></i>
          {{ 'CASHRECORD.EXPORTCSV' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="row no-gutters w-100">
    <app-table [columns]="columns" [data]="cashRecords" [totalCount]="cashRecordCount"
      (pageChange)="onPageChange($event)"></app-table>
  </div>
</navigation>