import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '../../core/interfaces/shared/table-columns.model';
import { CashRecord } from '../../core/interfaces/cash-record/cash-record.interface';
import { CashRecordService } from '../../core/services/cash-record/cash-record.service';
import { CashRegister } from '../company-settings/models/cashregister.model';
import { CashRegisterService } from '../company-settings/cash-register.service';
import { StoreService } from '../company-settings/store.service';
import { Store } from '../company-settings/models/store.model';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';
import { CurrencyService } from '../../core/services/currency/currency.service';
import { Currency } from '../../core/interfaces/currency/currency.interface';

@Component({
  selector: 'app-cashregister',
  templateUrl: './cash-record.component.html',
})
export class CashRecordComponent implements OnInit {
  cashRecords: CashRecord[] = [];
  columns: TableColumn[];
  cashRecordCount: number;

  page_count: number = 10;

  dateFrom: Date;
  dateTo: Date;

  currencies: Currency[] = [];
  selectedCurrency: Currency;

  selectedStore: Store;
  selectedStoreName: string;
  storeList: Store[];
  companyId: number;

  cashRegisterList: CashRegister[];
  selectedCashRegister: CashRegister;

  userId: number;

  constructor(
    private translateService: TranslateService,
    private cashRecordService: CashRecordService,
    private cashRegisterService: CashRegisterService,
    private storeService: StoreService,
    private localStorageService: LocalStorageService,
    private currencyService: CurrencyService
  ) { }

  ngOnInit(): void {
    this.loadCurrency();
    this.columns = this.getNoteRecordListColums();
    this.companyId = this.localStorageService.getCompanyId();
    this.userId = this.localStorageService.getUserId();
  }

  ngAfterViewInit(): void {
    this.setStores();
  }

  onPageChange(pageIndex?: number): void {
    this.cashRecordService.getCashRecord(this.selectedCashRegister.Id, this.selectedCurrency?.Id, this.userId, this.page_count, pageIndex, this.dateFrom, this.dateTo)
      .subscribe((cashRecords) => {
        this.cashRecords = cashRecords.items;
      });
  }

  onFilterByDateClick(): void {
    this.dateFrom = !this.dateFrom ? undefined : new Date(this.dateFrom.getFullYear(), this.dateFrom.getMonth(), this.dateFrom.getDate(), 0, 1, 1, 999);
    this.dateTo = !this.dateTo ? undefined : new Date(this.dateTo.getFullYear(), this.dateTo.getMonth(), this.dateTo.getDate(), 23, 59, 59, 999);

    if (!this.selectedCashRegister) {
      return;
    }

    this.cashRecordService.getCashRecord(this.selectedCashRegister.Id, this.selectedCurrency?.Id, this.userId, this.page_count, 0, this.dateFrom, this.dateTo)
      .subscribe((cashRecords) => {
        this.cashRecords = cashRecords.items;
        this.cashRecordCount = cashRecords.totalCount;
      });
  }

  onCurrencyChange(currency?: Currency): void {
    this.selectedCurrency = currency;
  }

  onStoreChange(store?: Store): void {
    this.getCashRegister(store.Id);
  }

  onCashRegisterChange(cashRegister?: CashRegister): void {
    this.selectedCashRegister = cashRegister;
  }

  onCreateCsvClick(): void {
    let allRecords = true;

    if (!this.selectedCashRegister) {
      return;
    }

    this.cashRecordService.getCashRecordCSV(this.selectedCashRegister.Id, this.selectedCurrency?.Id, this.userId, null, null, this.dateFrom, this.dateTo, allRecords);
  }

  private loadCurrency(): void {
    this.currencyService.getCurrencies().subscribe((currencie) => {
      this.currencies = currencie;
      this.selectedCurrency = this.currencies[0];
    });
  }

  private loadCashRecords(cashRegisterId: number): void {
    this.cashRecordService.getCashRecord(cashRegisterId, this.selectedCurrency?.Id, this.userId, this.page_count, 0).subscribe((cashRecords) => {
      this.cashRecords = cashRecords.items;
      this.cashRecordCount = cashRecords.totalCount;
    });
  }

  private getCashRegister(storeId): void {
    this.cashRegisterService.get(storeId).subscribe((cashRegister) => {
      if (cashRegister.length === 0) {
        this.cashRecordCount = 0;
        this.cashRecords = null;
        this.selectedCashRegister = null;
        return;
      }

      this.selectedCashRegister = cashRegister[0];
      this.cashRegisterList = cashRegister;

      this.loadCashRecords(cashRegister[0].Id);
    });
  }

  private setStores(): void {
    this.storeService.getList(this.companyId).subscribe((stores) => {
      this.storeList = stores;
      this.setSelectedStore()
    });
  }

  private setSelectedStore(): void {
    if (this.storeList.length === 0) {
      return;
    }

    if (this.selectedStore) {
      this.onStoreChange();
    } else {
      this.selectedStore = this.storeList[0];
    }
  }

  private getNoteRecordListColums(): TableColumn[] {
    return [
      {
        columnDef: 'Date',
        header: this.translateService.instant('CASHRECORD.DATE'),
        cell: (element: CashRecord) => new Date(`${element.Date}`).toLocaleString(),
      },
      {
        columnDef: 'Price',
        header: this.translateService.instant('CASHRECORD.PRICE'),
        cell: (element: CashRecord) => element.Income == 0 ? parseFloat(`${element.Spending * -1}`).toFixed(2) : parseFloat(`${element.Income}`).toFixed(2),
      },
      {
        columnDef: 'Text',
        header: this.translateService.instant('CASHRECORD.TEXT'),
        cell: (element: CashRecord) => `${element.Text}`,
      },
      {
        columnDef: 'Cashregister',
        header: this.translateService.instant('CASHRECORD.CASHREGISTERCOLUM'),
        cell: (element: CashRecord) => `${element.CashRegisterName}`,
      },
    ];
  }
}
