import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
  ) {}

  transform(value: boolean): any {
    switch (value) {
      case true:
        return this.translateService.instant('SHOP.YES');
      case false:
        return this.translateService.instant('SHOP.NO');
      default:
        return '';
    }
  }
}
