import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ContioStore } from '../models/contio-store.model';
import { ContioIntervalResponse } from '../models/contio-interval-response.model';
import { IntervalType } from '../../../core/enums/interval-type.enum';
import { StoreService } from '../../company-settings/store.service';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { ContioService } from '../contio.service';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { VerificationLevel } from './models/verification-level.model';
import { VerificationLevelService } from './verification-level.service';
import { Store } from '../../company-settings/models/store.model';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';

@Component({
  selector: 'app-contio-store-settings',
  templateUrl: './contio-store-settings.component.html',
})
export class ContioStoreSettingsComponent implements OnInit {
  storeId: number;
  store: Store;
  contioStore: ContioStore;

  setDoorIntervalSuccess: ContioIntervalResponse;
  doorInterval: string = IntervalType.door;
  lockInterval: string = IntervalType.lock;

  error: string;

  verificationLevels: VerificationLevel[] = [];
  selectedVerificationLevel: VerificationLevel = null;

  @ViewChild('imageUploadComponent', { static: false }) imageUploadComponent: ImageUploadComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private storeService: StoreService,
    private contioService: ContioService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
    private verificationLevelService: VerificationLevelService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.storeId = this.activatedRoute.snapshot.params.value;

    this.localStorageService.autenticateContioUser();

    this.setVerificationLevelList();
    this.getStore();
    this.getContioStore();
  }

  onEditContioDoorIntervalClick(
    interval: number,
    type: IntervalType
  ): void {
    this.editStore(interval, type);
  }

  onVerificationLevelChange(verificationLevel: VerificationLevel): void {
    this.setContioStoreVerificationlevelId(verificationLevel.Id);
  }

  onGeneralSettingsSaveClick(minimumAge: number, isManualRegistration: boolean, conditionsUrl: string, eshopUrl: string, isScanAndGoAllowed: boolean,
    latitude: number, longitude: number, goPayId: number, goPayClientId: number, goPayClientSecret: string): void {

    this.contioService.setGeneralSettings(minimumAge, isManualRegistration, conditionsUrl, eshopUrl, this.contioStore.Logo,
      isScanAndGoAllowed, latitude, longitude, goPayId, goPayClientId, goPayClientSecret, this.contioStore.Id).subscribe({
        next: (successResponse) => {
          this.snackBarService.show('ADMIN.SUCCESS', successResponse.success);
        },
        error: (error) => {
          this.error = error;
          this.snackBarService.show('ADMIN.FAIL', false);
        },
      });
  }

  onGoToContioShopDetailClick(): void {
    this.location.back();
  }

  onCheckChange(isChecked: boolean): void {
    this.contioStore.IsManualRegistration = isChecked;
  }

  onScanAndGoChange(isChecked: boolean): void {
    this.contioStore.IsScanAndGoAllowed = isChecked;
  }

  onImageSelect(logoBase64: string): void {
    this.contioStore.Logo = logoBase64;
  }

  onGeolocationChanged(): void {
    if (!this.contioStore.Longitude || !this.contioStore.Latitude) {
      this.contioStore.IsScanAndGoAllowed = false;
    }
  }

  private setContioStoreVerificationlevelId(verificationLevelId: number): void {
    this.contioService.setVerificationLevel(verificationLevelId, this.contioStore.Id).subscribe({
      next: (successResponse) => {
        this.setDoorIntervalSuccess = successResponse;

        this.snackBarService.show('ADMIN.SUCCESS', successResponse.success);
      },
      error: (error) => {
        this.error = error;

        this.snackBarService.show('ADMIN.FAIL', false);
      },
    });
  }

  private editStore(
    interval?: number,
    type?: IntervalType,
  ): void {
    const companyId = this.localStorageService.getCompanyId();
    this.storeService
      .put(
        this.storeId,
        companyId,
        this.contioStore.Name,
        interval,
        type
      )
      .subscribe({
        next: (successResponse) => {
          this.setDoorIntervalSuccess = successResponse;

          this.snackBarService.show('ADMIN.SUCCESS', successResponse.success);
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('ADMIN.FAIL', false);
        },
      });
  }

  private setVerificationLevelList(): void {
    this.verificationLevelService.getList().subscribe({
      next: (successResponse) => {
        this.verificationLevels = successResponse;
      },
      error: (error) => {
        this.error = error;
      },
    });
  }

  private getStore(): void {
    this.storeService
      .get(this.storeId)
      .subscribe({
        next: (store) => {
          this.store = store[0];
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private getContioStore(): void {
    this.contioService
      .getContioStoreByStoreId(this.storeId, true)
      .subscribe({
        next: (contioStore) => {
          this.contioStore = contioStore[0];
          this.selectedVerificationLevel = this.verificationLevels.find(vl => vl.Id === this.contioStore.VerificationLevelId);
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
