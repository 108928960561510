import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../shared/http-handler.service';
import { ProductGroup } from '../../interfaces/product-group/product-group.model';
import { catchError } from 'rxjs/operators';
import { TranslationLanguage } from '../../interfaces/language/language.model';
import { ProductGroupPageCount } from '../../interfaces/product-group/product-group-page-count.model';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupService {
  private groupUrl: string = Centrala.URL + '/api/batch';
  private groupCountUrl: string = Centrala.URL + '/api/batch/count';
  private groupChildUrl: string = Centrala.URL + '/api/batch-child';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  /**
   * Get groups for currently signed company ID
   */
  getList(
    currentPage: number,
    pageLimit: number,
    filter: string,
    isAutocompleteRequest?: boolean,
    isImageIncluded?: boolean,
    areAllTranslationsIncluded?: boolean,
  ): Observable<ProductGroup[]> {
    let params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('companyId', localStorage.getItem(LocalStorageTags.CompanyId))
      .set('pageIndex', currentPage.toString())
      .set('pageCount', pageLimit.toString())
      .set('filter', filter);

    if (isAutocompleteRequest != null) {
      params = params.set('isAutocompleteRequest', isAutocompleteRequest.toString());
    }

    if (isImageIncluded != null) {
      params = params.set('isImageIncluded', isImageIncluded.toString());
    }

    if (areAllTranslationsIncluded != null) {
      params = params.set('areAllTranslationsIncluded', areAllTranslationsIncluded.toString());
    }

    return this.httpClient
      .get<ProductGroup[]>(this.groupUrl, { params })
      .pipe(catchError(this.httpHandlerService.handleError));
  }

  /**
   * Get group item count
   */
  getPageCount(filter: string): Observable<ProductGroupPageCount> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('companyId', localStorage.getItem(LocalStorageTags.CompanyId))
      .set('filter', filter);

    return this.httpClient
      .get<ProductGroupPageCount>(
        this.groupCountUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError));
  }

  /**
   * Create new group
   * @param name {string} group name
   * @param companyId {string} company ID
   */
  post(
    name: string,
    companyId: string,
    hexColor?: string,
    parentBatchId?: number,
    image?: string,
    translation?: TranslationLanguage[],
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.groupUrl,
        {
          CompanyId: companyId,
          Name: name,
          HexColor: hexColor,
          ParentBatchId: parentBatchId,
          Image: image,
          Translation: translation,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Update group name
   * @param id {number} group ID
   * @param name {string} group name
   */
  put(
    id: number,
    name: string,
    hexColor?: string,
    imageBase64?: string,
    translation?: TranslationLanguage[],
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put(
        this.groupUrl,
        {
          Id: id,
          CompanyId: localStorage.getItem(LocalStorageTags.CompanyId),
          Name: name,
          HexColor: hexColor,
          Image: imageBase64,
          Translation: translation,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  postGroupChild(batchId: number, parentBatchId: number): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.groupChildUrl,
        {
          BatchId: batchId,
          ParentBatchId: parentBatchId,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  deleteGroupChild(batchId: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        BatchId: batchId,
        token: localStorage.getItem(LocalStorageTags.Token),
      },
    };

    return this.httpClient
      .delete(this.groupChildUrl, options)
      .pipe(catchError(this.httpHandlerService.handleError));
  }
}
