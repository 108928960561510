import { TranslationLanguage } from "../language/language.model";

export class ProductGroup {
  Id: number;
  CompanyId: number;
  Name: string;
  HexColor: string;
  Note: string;
  Image: string;
  ParentBatchId?: number;
  Translation?: TranslationLanguage[];
}
