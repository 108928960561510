import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class NgbDateService {
  today: number = Date.now();

  constructor() { }

  ngbDateFilter(input) {
    var date;
    date = new Date(parseInt(input) * 1000);
    date = new NgbDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
    return date;
  }

  ngbDateMax(): NgbDate {
    var max;
    max = this.today;
    max = new Date(parseInt(max));
    max = new NgbDate(
      max.getFullYear(),
      max.getMonth() + 1,
      max.getDate()
    );
    return max;
  }

  zuluTransactionFormatter(date: any) {
    for (let i = 0; i < date.length; i++) {
      var zuluToDate = new Date(date[i].TransactionTime).toLocaleString();
      date[i].TransactionTime = zuluToDate;
    }
  }

  zuluReturnsFormatter(date: any) {
    for (let i = 0; i < date.length; i++) {
      var zuluToDate = new Date(date[i].Date).toLocaleString();
      date[i].Date = zuluToDate;
    }
  }

  zuluDateToLocale(data) {
    data.BirthDate = new Date(data.BirthDate).toLocaleDateString();
    return data;
  }
}
