<div class="custom-table" (window:resize)="onResize($event)">
  <table mat-table [dataSource]="data">
    <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.columnDef">
      <mat-header-cell *cdkHeaderCellDef>{{ column.header }}</mat-header-cell>
      <mat-cell *cdkCellDef="let row">
        <mat-icon *ngIf="column.columnDef === iconColumn" [color]="column.cell(row) === 'error' ? 'accent' : 'primary'">{{ column.cell(row) }}</mat-icon>
        <i *ngIf="column.columnDef === colorColumn" class="fas fa-square fa-lg icon-margin"
          [ngStyle]="{ color: column.cell(row) }"></i>
        <img *ngIf="column.columnDef === imageColumn && column.cell(row) != 'null'" class="img-preview"
          [src]="column.cell(row)" />
        <span *ngIf="column.columnDef != colorColumn && column.columnDef != iconColumn && column.columnDef != imageColumn && column.cell(row) != 'null'">
          {{ column.cell(row) }}
        </span>
      </mat-cell>
    </ng-container>

    <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ selected: row === activeRow }"
      (click)="onRowSelect(row)"></mat-row>
  </table>
  <mat-paginator
      showFirstLastButtons
      [length]="totalCount"
      [pageSize]="defaultPageSize"
      (page)="onPageChange()"
    >
    </mat-paginator>
</div>