import { Component } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  isAuthentication: object;

  authenticationResult(event: object): void {
    this.isAuthentication = event;
  }
}
