import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../user.service';
import { NgbDateService } from '../../../../../core/services/formatter/ngb-date.service';
import { User } from '../../../models/user.model';
import { GridSetting } from '../../../../../shared/models/grid-setting.model';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  user: User;
  clientUserId: number;

  currentPage: number = 0;
  readonly defaultFilterForm: string = '';

  error: string;
  isUserBlocked: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dateService: NgbDateService
  ) { }

  ngOnInit(): void {
    this.clientUserId = this.activatedRoute.snapshot.params.value;

    this.getUserDetail(this.clientUserId);
  }

  private getUserDetail(clientUserId: number): void {
    this.userService
      .getList(
        this.defaultFilterForm,
        GridSetting.defaultPage,
        GridSetting.pageLimit,
        clientUserId
      )
      .subscribe({
        next: (userDetail) => {
          this.user = userDetail[0];
          this.user = this.dateService.zuluDateToLocale(this.user);
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onBlockUserCLick(deactivate: boolean): void {
    this.userService.deactivateUser(this.user.ClientUserId, deactivate).subscribe(() => {
    });
  }
}
