<authentication-test></authentication-test>
<navigation selectedPage="admin">
  <div fxLayout fxLayoutGap="8px">
    <div class="input-group-prepend">
      <span class="input-group-text">{{ 'ADMIN.USER' | translate }}</span>
      <div
        class="ng-autocomplete"
        [ngClass]="{
          'autocomplete-invalid': isAutocompleteInvalid
        }"
      >
        <ng-autocomplete
          [data]="userList"
          [searchKeyword]="searchKeyword"
          (selected)="onUserSelect($event)"
          (inputChanged)="onSearchChange()"
          (inputCleared)="onSearchChange()"
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate"
        >
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.Email"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
    <div class="item">
      <button
        class="btn btn-primary new-btn bigger-btn ml-2"
        (click)="onSetUserClick()"
      >
        {{ 'SALES.FILTR' | translate }}
      </button>
    </div>
  </div>

  <div class="row no-gutters" *ngIf="isAutocompleteInvalid">
    <div class="col-9">
      <span class="help-block invalid-name">{{
        'VALIDATOR.VALIDATION' | translate
      }}</span>
    </div>
  </div>

  <div
    class="row no-gutters mt-1 float-left btn-container"
    *ngIf="isUserCompanyLoaded"
  >
    <hr class="custom-hr float-left mt-2 mb-1" />

    <div class="col-12 mt-2 mb-1">
      <app-dropdown
        *ngIf="isUserCompanyLoaded"
        [(selected)]="selectedContact"
        label="{{ 'ADMIN.COMPANY' | translate }}"
        itemKey="Name"
        [items]="userContactList"
      ></app-dropdown>
      <button
        #addGroupInput
        type="text"
        class="btn btn-primary normal-btn float-right bigger-btn mt-2 mt-md-0"
        (click)="onOpenAddCompanyModal(newCompanyModal)"
      >
        <i class="fas fa-plus mr-1"></i>
        {{ 'ADMIN.COMPANY' | translate }}
      </button>
    </div>

    <hr class="custom-hr float-left mt-2 mb-1" />
    <div class="col-12">
      <div class="custom-control custom-checkbox checkbox-lg float-left">
        <input
          [(ngModel)]="user.IsContio"
          (ngModelChange)="onIsContioCheck($event)"
          type="checkbox"
          class="custom-control-input my-2"
          id="availableInput"
        />
        <label class="custom-control-label" for="availableInput">
          {{ 'ADMIN.CONTIO' | translate }}
        </label>
      </div>

      <button
        #addGroupInput
        type="text"
        class="btn btn-primary save-btn float-right bigger-btn mt-2"
        (click)="editUserIsContio()"
      >
        <i class="fas fa-save mt-1"></i>
        {{ 'PRODUCTS.SAVE' | translate }}
      </button>
    </div>
  </div>
</navigation>

<ng-template #newCompanyModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{ 'ADMIN.COMPANY' | translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text" id="nameInput">{{
          'MODAL.NAME' | translate
        }}</span>
      </div>
      <input
        #name
        [(ngModel)]="companyName"
        type="text"
        class="form-control"
        placeholder="{{ 'PRODUCTS.MODALWRITENAME' | translate }}"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="onClearSearchboxClick(); c('Close click')"
    >
      {{ 'PRODUCTS.CLOSE' | translate }}
    </button>
    <button
      type="button"
      class="btn save-btn"
      [disabled]="!companyName"
      (click)="postCompany(name.value); c('Close click')"
    >
      <i class="fas fa-save mr-1"></i>
      {{ 'PRODUCTS.SAVE' | translate }}
    </button>
  </div>
</ng-template>
