<authentication-test
  (success)="authenticationResult($event)"
></authentication-test>
<div *ngIf="isAuthentication" (window:resize)="onResize($event)">
  <navigation selectedPage="sales">
    <div class="row">
      <div class="col-xl-6 col-sm-12">
        <div class="card">
          <div class="card-content">
            <div class="row no-gutters">
              <div class="input-group ml-2 mr-2 mt-3 mb-2 mb-md-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" for="from">{{
                    'SALES.DATEFROM' | translate
                  }}</span>
                </div>
                <input
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [placement]="placement"
                  readonly="true"
                  id="from"
                  class="form-control"
                  placeholder="{{ 'SALES.DATEPLACEHOLDER' | translate }}"
                  name="dp"
                  value="{{ dateFrom | dateFormat }}"
                  [(ngModel)]="dateFrom"
                  ngbDatepicker
                  #d1="ngbDatepicker"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d1.toggle()"
                    type="button"
                  >
                    <i class="far fa-calendar-alt"></i>
                  </button>
                </div>
                <div class="ml-2 d-none d-sm-block">
                  <ngb-timepicker
                    [(ngModel)]="fromTime"
                    size="medium"
                  ></ngb-timepicker>
                </div>
              </div>

              <div class="ml-2 d-block d-sm-none">
                <ngb-timepicker
                  [(ngModel)]="fromTime"
                  size="medium"
                  class="float-center"
                ></ngb-timepicker>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="input-group ml-2 mr-2 mt-3 mb-2 mb-sm-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" for="to">{{
                    'SALES.DATETO' | translate
                  }}</span>
                </div>
                <input
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [placement]="placement"
                  readonly="true"
                  id="to"
                  class="form-control"
                  placeholder="{{ 'SALES.DATEPLACEHOLDER' | translate }}"
                  name="dp"
                  value="{{ dateTo | dateFormat }}"
                  [(ngModel)]="dateTo"
                  ngbDatepicker
                  #d2="ngbDatepicker"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary calendar"
                    (click)="d2.toggle()"
                    type="button"
                  >
                    <i class="far fa-calendar-alt"></i>
                  </button>
                </div>
                <div class="ml-2 d-none d-sm-block">
                  <ngb-timepicker
                    [(ngModel)]="tillTime"
                    size="medium"
                  ></ngb-timepicker>
                </div>
              </div>

              <div class="ml-2 mb-2 d-block d-sm-none">
                <ngb-timepicker
                  [(ngModel)]="tillTime"
                  size="medium"
                ></ngb-timepicker>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-sm-12 mt-xl-0 mt-3">
        <div class="card">
          <div class="card-content">
            <div class="row mt-3">
              <div class="col-12">
                <h5 class="card-title mt-2 mb-3 ml-3 text-left section-header">
                  {{ 'DASHBOARD.RECEIPT' | translate }}
                </h5>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="btn-group ml-2 float-left filter-by-container"
                          role="group"
                        >
                          <span
                            class="input-group-text btn-secondary filter-span"
                            [ngClass]="{ active: isValue == 0 }"
                          >
                            {{ 'SALES.FILTERBY' | translate }}
                          </span>
                          <button
                            id="FIK"
                            type="button"
                            class="btn btn-secondary filter-btn"
                            [class.active]="isButtonActive(1)"
                            (click)="openFilter($event); setActive(1)"
                          >
                            {{ 'SALES.TABLEFIK' | translate }}
                          </button>
                          <button
                            id="Method"
                            type="button"
                            class="btn btn-secondary filter-btn"
                            [class.active]="isButtonActive(2)"
                            (click)="openFilter($event); setActive(2)"
                          >
                            {{ 'SALES.FILTERMETHOD' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 card-block mt-2 mb-2">
                        <div
                          id="methodCol"
                          [ngbCollapse]="activeFilter != 'Method'"
                        >
                          <div class="card ml-2 mr-2">
                            <div class="card-block">
                              <div
                                class="col-sm-12 custom-control custom-checkbox checkbox-md ml-2 mt-1"
                              >
                                <input
                                  [(ngModel)]="cash"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="checkbox-1"
                                  [checked]="cash"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkbox-1"
                                  >{{ 'cash' | translate }}</label
                                >
                              </div>

                              <div
                                class="col-sm-12 custom-control custom-checkbox checkbox-md ml-2"
                              >
                                <input
                                  [checked]="card"
                                  [(ngModel)]="card"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="checkbox-2"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkbox-2"
                                  >{{ 'card' | translate }}</label
                                >
                              </div>

                              <div
                                class="col-sm-12 custom-control custom-checkbox checkbox-md ml-2"
                              >
                                <input
                                  [checked]="invoice"
                                  [(ngModel)]="invoice"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="checkbox-3"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkbox-3"
                                  >{{ 'invoice' | translate }}</label
                                >
                              </div>

                              <div
                                class="col-sm-12 custom-control custom-checkbox checkbox-md ml-2 mb-1"
                              >
                                <input
                                  [checked]="bitcoin"
                                  [(ngModel)]="bitcoin"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="checkbox-4"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkbox-4"
                                  >bitcoin</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>

                        <div id="FIKCol" [ngbCollapse]="activeFilter != 'FIK'">
                          <div class="card ml-2 mr-2">
                            <div class="card-block">
                              <div
                                class="col-sm-12 custom-control custom-checkbox checkbox-md ml-2 mt-1"
                              >
                                <input
                                  [checked]="ok"
                                  [(ngModel)]="ok"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="checkbox-5"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkbox-5"
                                  >{{ 'EetSent' | translate }}</label
                                >
                              </div>

                              <div
                                class="col-sm-12 custom-control custom-checkbox checkbox-md ml-2"
                              >
                                <input
                                  [checked]="whEET"
                                  [(ngModel)]="whEET"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="checkbox-6"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkbox-6"
                                  >{{ 'OnlySec' | translate }}</label
                                >
                              </div>

                              <div
                                class="col-sm-12 custom-control custom-checkbox checkbox-md ml-2"
                              >
                                <input
                                  [checked]="nocode"
                                  [(ngModel)]="nocode"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="checkbox-7"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkbox-7"
                                  >{{ 'NoCode' | translate }}</label
                                >
                              </div>

                              <div
                                class="col-sm-12 custom-control custom-checkbox checkbox-md ml-2 mb-1"
                              >
                                <input
                                  [checked]="storno"
                                  [(ngModel)]="storno"
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="checkbox-8"
                                />
                                <label
                                  class="custom-control-label"
                                  for="checkbox-8"
                                  >{{ 'SALES.STORN' | translate }}</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-6">
                    <button
                      class="btn btn-primary normal-btn float-left float-md-right mr-2 ml-2 mb-2"
                      (click)="getReceipts($event)"
                    >
                      {{ 'SALES.FILTRATE' | translate }}
                    </button>
                    <button
                      (click)="onCreateCSVClick()"
                      class="btn btn-primary normal-btn float-right"
                    >
                      <i class="fas fa-download mr-2"></i>
                      {{ 'ORDERS.CSV' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 mb-3">
      <div class="col-xl-6 col-sm-12" *ngIf="selectedStore">
        <div class="card">
          <div class="card-content">
            <div class="row no-gutters">
              <div class="col-12">
                <h5 class="card-title mt-3 mb-3 ml-3 text-left section-header">
                  {{ 'DASHBOARD.TABLESUM' | translate }}
                </h5>
              </div>
              <div class="col-xl-6 mb-2">
                <div class="input-group-prepend ml-2">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-tag line-height"
                    >
                      <span class="font-weight-normal">
                        {{ 'STORES.STORE' | translate }}
                      </span>
                    </button>
                    <div class="btn-group" ngbDropdown role="group">
                      <button
                        class="btn btn-primary dropdown-toggle-primary dropdown-box"
                        id="dropdownBasic1"
                        data-toggle="dropdown"
                        ngbDropdownToggle
                      >
                        <span class="font-weight-normal">
                          {{ selectedStore.Name }}
                        </span>
                      </button>
                      <div
                        ngbDropdownMenu
                        class="dropdown-menu"
                        aria-labelledby="dropdownBasic1"
                      >
                        <button
                          *ngFor="let store of stores"
                          class="dropdown-item"
                          (click)="onSelect(store)"
                        >
                          {{ store.Name }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 mb-2">
                <div
                  class="input-group-prepend ml-2 float-left float-xl-right mr-2"
                >
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-tag line-height"
                    >
                      <span class="font-weight-normal">
                        {{ 'BITKASA.AMOUNTCUR' | translate }}
                      </span>
                    </button>
                    <div class="btn-group" ngbDropdown role="group">
                      <button
                        class="btn btn-primary dropdown-toggle-primary dropdown-box"
                        id="dropdownCurrency"
                        data-toggle="dropdown"
                        ngbDropdownToggle
                      >
                        <span class="font-weight-normal">
                          {{ selectedCurrency.name }}
                        </span>
                      </button>
                      <div
                        ngbDropdownMenu
                        class="dropdown-menu"
                        aria-labelledby="dropdownCurrency"
                      >
                        <button
                          *ngFor="let currency of currencies"
                          class="dropdown-item"
                          (click)="onSelectCurrency(currency)"
                        >
                          {{ currency.name }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 mb-2">
                <div class="d-flex justify-content-start">
                  <button
                    (click)="storeReport()"
                    triggers="mouseenter:mouseleave"
                    class="btn btn-primary normal-btn float-right mr-2 ml-2"
                  >
                    <i class="fas fa-chart-bar"></i>
                    &nbsp;&nbsp;{{
                      'SALES.SALESREPORT' | translate
                    }}&nbsp;&nbsp;
                  </button>
                </div>
              </div>

              <div class="col-6 mb-2">
                <button
                  (click)="storesReport()"
                  triggers="mouseenter:mouseleave"
                  class="btn btn-primary normal-btn float-right mr-2"
                >
                  <i class="fas fa-chart-bar"></i>
                  &nbsp;&nbsp;{{ 'SALES.STORESREPORT' | translate }}&nbsp;&nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6 col-sm-12 mt-xl-0 mt-3" *ngIf="selectedWarehouse">
        <div class="card">
          <div class="card-content">
            <div class="row no-gutters">
              <div class="col-12">
                <h5 class="card-title mt-3 mb-3 ml-3 text-left section-header">
                  {{ 'DASHBOARD.WAREHOUSES' | translate }}
                </h5>
              </div>
              <div class="col-12 mb-2">
                <div class="input-group-prepend ml-2">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-primary dropdown-tag w-auto"
                      [ngClass]="{
                        'dropdown-invalid':
                          warehouseValidate && !selectedWarehouse
                      }"
                    >
                      <span class="font-weight-normal">
                        {{ 'STORES.WAREHOUSE' | translate }}
                      </span>
                    </button>
                    <div
                      class="btn-group"
                      *ngIf="isWarehouseLoaded"
                      placement="bottom-right"
                      ngbDropdown
                    >
                      <button
                        class="btn btn-primary dropdown-toggle-primary dropdown-box"
                        id="warehouses"
                        data-toggle="dropdown"
                        ngbDropdownToggle
                      >
                        <span class="font-weight-normal">{{
                          selectedWarehouse.Name
                        }}</span>
                      </button>
                      <div
                        ngbDropdownMenu
                        class="dropdown-menu"
                        aria-labelledby="warehouses"
                      >
                        <button
                          *ngFor="let warehouse of warehouses"
                          class="dropdown-item"
                          (click)="changeWarehouse(warehouse.Id)"
                        >
                          {{ warehouse.Name }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 mb-2">
                <div class="d-flex justify-content-start ml-2">
                  <button
                    (click)="stockTaking()"
                    triggers="mouseenter:mouseleave"
                    class="btn btn-primary normal-btn"
                  >
                    <i class="fas fa-warehouse"></i>
                    &nbsp;&nbsp;{{
                      'SALES.STOCKTAKING' | translate
                    }}&nbsp;&nbsp;
                  </button>
                </div>
              </div>
              <div class="col-6 mb-2">
                <button
                  (click)="stockTurnover()"
                  triggers="mouseenter:mouseleave"
                  class="btn btn-primary normal-btn float-right mr-2"
                >
                  <i class="fas fa-table"></i>
                  &nbsp;&nbsp;{{
                    'SALES.STOCKTURNOVER' | translate
                  }}&nbsp;&nbsp;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row no-gutters my-row w-100">
      <div class="col-12">
        <div *ngIf="loaded && screenWidth < 599">
          <ngx-datatable
            #ngx2
            class="material"
            [rows]="receiptsData"
            [headerHeight]="50"
            [footerHeight]="100"
            [columnMode]="'force'"
            [rowHeight]="'auto'"
            [limit]="pageLimit"
            [externalPaging]="true"
            [count]="receiptCount.receipt_count"
            [offset]="curPage"
            [messages]="{
              emptyMessage: 'SALES.TABLENODATA' | translate,
              totalMessage: 'PRODUCTS.TABLETOTAL' | translate
            }"
            (activate)="viewDetails($event)"
            (page)="getReceipts($event)"
          >
            <ngx-datatable-column
              prop="InternalNo"
              name="{{ 'SALES.TABLEINTERNALNO' | translate }}"
            ></ngx-datatable-column>
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{ value | amDateFormat: 'D.M.YYYY HH:mm:ss' }}
            </ng-template>
            <ngx-datatable-column
              prop="FIKmessage"
              name="&nbsp;&nbsp;{{ 'SALES.TABLEFIK' | translate }}"
            >
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <div class="fid-col mr-sm-2">
                  <span
                    class="badge badge-fik badge-warning mt-2 fik-warning-badge"
                    *ngIf="value === 'OnlySec'"
                  >
                    {{ value | translate }}
                    <i class="fas fa-angle-down float-right"></i>
                  </span>
                  <p class="pl-1 pt-1" *ngIf="row.showSEC">
                    {{ 'SALES.TABLESECCODE' | translate }}<br />
                    BKP: {{ row.EET_SecureCode }}
                  </p>

                  <span
                    class="badge badge-fik badge-danger mt-2 fik-danger-badge"
                    *ngIf="value === 'NoCode'"
                  >
                    {{ value | translate }}
                    <i class="fas fa-angle-down float-right"></i>
                  </span>
                  <p class="pl-1 pt-1" *ngIf="row.showFailMessage">
                    {{ 'SALES.TABLENOCODE' | translate }}.
                  </p>

                  <span
                    *ngIf="value === 'FID'"
                    class="badge badge-fik badge-success mt-2 fik-success-badge"
                  >
                    {{ value | translate }}
                    <i class="fas fa-angle-down float-right"></i>
                  </span>
                  <p class="pl-1 pt-1" *ngIf="row.showFIK">
                    {{ 'SALES.TABLEFIK' | translate }}: {{ row.EET_FID }}
                  </p>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              prop="ReceiptTotal"
              name="{{ 'SALES.TABLERECEIPTTOTAL' | translate }}"
            ></ngx-datatable-column>
          </ngx-datatable>
        </div>

        <div *ngIf="loaded && screenWidth > 599">
          <ngx-datatable
            #ngx2
            class="material expandable"
            [rows]="receiptsData"
            [headerHeight]="50"
            [footerHeight]="100"
            [columnMode]="'force'"
            [rowHeight]="'auto'"
            [limit]="pageLimit"
            [externalPaging]="true"
            [count]="receiptCount.receipt_count"
            [offset]="curPage"
            [messages]="{
              emptyMessage: 'SALES.TABLENODATA' | translate,
              totalMessage: 'PRODUCTS.TABLETOTAL' | translate
            }"
            (activate)="viewDetails($event)"
            (page)="getReceipts($event)"
          >
            <ngx-datatable-column
              prop="InternalNo"
              name="{{ 'SALES.TABLEINTERNALNO' | translate }}"
            ></ngx-datatable-column>

            <ngx-datatable-column
              prop="Date"
              name="{{ 'SALES.TABLEDATE' | translate }}"
            >
              <ng-template let-value="value" ngx-datatable-cell-template>
                {{ value | amDateFormat: 'D.M.YYYY HH:mm:ss' }}
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="&nbsp;{{ 'SALES.TABLETRANSTYPE' | translate }}"
              prop="TransactionType"
            >
              <ng-template let-value="value" ngx-datatable-cell-template>
                <span class="badge" *ngIf="value === 'cash'">
                  <i class="fas fa-coins"></i>
                </span>
                <span class="badge" *ngIf="value === 'card'">
                  <i class="far fa-credit-card"></i>
                </span>
                <span class="badge" *ngIf="value === 'invoice'">
                  <i class="fas fa-file-invoice-dollar"></i>
                </span>
                <span class="badge" *ngIf="value === 'bitcoin'">
                  <i class="fab fa-bitcoin"></i>
                </span>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="FIKmessage"
              name="&nbsp;&nbsp;{{ 'SALES.TABLEFIK' | translate }}"
            >
              <ng-template
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <div class="fid-col">
                  <span
                    class="badge badge-fik badge-warning mt-2 fik-warning-badge"
                    *ngIf="value === 'OnlySec'"
                  >
                    {{ value | translate }}
                    <i class="fas fa-angle-down float-right"></i>
                  </span>
                  <p class="pl-1 pt-1 tablefik-p" *ngIf="row.showSEC">
                    {{ 'SALES.TABLESECCODE' | translate }}<br />
                    BKP: {{ row.EET_SecureCode }}
                  </p>

                  <span
                    class="badge badge-fik badge-danger mt-2 fik-danger-badge"
                    *ngIf="value === 'NoCode'"
                  >
                    {{ value | translate }}
                    <i class="fas fa-angle-down float-right"></i>
                  </span>
                  <p class="pl-1 pt-1 tablefik-p" *ngIf="row.showFailMessage">
                    {{ 'SALES.TABLENOCODE' | translate }}.
                  </p>

                  <span
                    *ngIf="value === 'FID'"
                    class="badge badge-fik badge-success mt-2 fik-success-badge"
                  >
                    {{ value | translate }}
                    <i class="fas fa-angle-down float-right"></i>
                  </span>
                  <p class="pl-1 pt-1 tablefik-p" *ngIf="row.showFIK">
                    {{ 'SALES.TABLEFIK' | translate }}: {{ row.EET_FID }}
                  </p>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              prop="ReceiptTotal"
              name="{{ 'SALES.TABLERECEIPTTOTAL' | translate }}"
            ></ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-sm">
        <button
          *ngIf="screenWidth < 1036"
          (click)="exportCSV()"
          triggers="mouseenter:mouseleave"
          class="btn btn-primary normal-btn"
        >
          <i class="fas fa-download"></i>
          &nbsp;&nbsp;Export&nbsp;&nbsp;
        </button>
        <button
          *ngIf="screenWidth > 1035"
          (click)="exportCSV()"
          ngbPopover="{{ 'SALES.DOWNLOADINFO' | translate }} .CSV."
          triggers="mouseenter:mouseleave"
          popoverTitle="{{ 'SALES.DOWNLOADTITLE' | translate }}"
          class="btn btn-primary normal-btn"
        >
          <i class="fas fa-download"></i>
          &nbsp;&nbsp;Export&nbsp;&nbsp;
        </button>

        <input
          type="file"
          name="file"
          id="file"
          class="form-control inputfile"
          (change)="changeListener($event)"
          accept=".csv,.txt"
        />
        <label class="btn btn-primary normal-btn ml-2 mt-2" for="file">
          <i class="fas fa-upload"></i>
          &nbsp;&nbsp;Import&nbsp;&nbsp;
        </label>
      </div>
    </div> -->
  </navigation>
</div>
