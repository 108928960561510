<authentication-test></authentication-test>
<navigation selectedPage="contio-store">
  <div *ngIf="storeId">
    <div class="row no-gutters">
      <div class="col-8">
        <div class="row no-gutters">
          <div class="col-12 page-header">
            {{ 'SHOP.DETAILTITLE' | translate }}
          </div>
          <div class="col-12 address">
            Id: {{ storeId }}
            <!-- Adresa převzatá z dat -->
          </div>
        </div>
      </div>

      <div class="col-4">
        <button
          type="button"
          class="btn btn-primary normal-btn float-right back-btn"
          (click)="onGoToContioShopListClick()"
        >
          {{ 'REGISTRATION.BACK' | translate }}
        </button>
      </div>
    </div>
  </div>

  <hr class="line" />

  <div class="row mt-3 mb-3">
    <div class="col-xl-6 col-sm-12 mt-xl-0 mt-3">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-6">
                  <h5 class="pt-3 ml-3 text-left text-dark">
                    <i class="fas fa-user-alt mr-1"></i>
                    {{ 'SHOP.PERSONCOUNTER' | translate }}
                    {{
                      webSocketService.personCount
                        ? webSocketService.personCount
                        : 0
                    }}
                  </h5>
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    class="btn btn-outline-primary mr-3 my-2 float-right"
                    (click)="onGoToShopSettingClick()"
                    [hidden]="contioShopPrivilege && contioShopPrivilege === privilegeValue.Read"
                  >
                    {{ 'NAVIGATION.SETTINGS' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-2">
          <div class="card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-12">
                  <h5 class="card-title mt-3 ml-3 h5-header text-left">
                    {{ 'SHOP.DEVICES' | translate }}
                  </h5>
                </div>
              </div>
              <div class="row no-gutters mt-2">
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <h5 class="card-title ml-3 text-left">
                        <i class="fas fa-plug"></i>
                        {{ 'SHOP.DEVICESON' | translate }}
                        {{ deviceStateList[0].active }}
                      </h5>
                      <h5 class="card-title ml-3 text-left">
                        <i class="fas fa-plug strikethrough"></i>
                        {{ 'SHOP.DEVICESOFF' | translate }}
                        {{ deviceStateList[0].inactive }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <button
                        type="button"
                        class="btn btn-outline-primary mr-3 float-right manage-btn"
                        (click)="onGoToShopDevicesClick()"
                        [hidden]="contioShopPrivilege && contioShopPrivilege === privilegeValue.Read"
                      >
                        {{ 'SHOP.MANAGE' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-2">
          <div class="card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-6">
                  <h5 class="card-title mt-3 ml-3 text-left h5-header">
                    {{ 'SHOP.SCHEDULE' | translate }}
                  </h5>
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    class="btn btn-outline-primary mr-3 my-2 float-right"
                    (click)="onGoToShopScheduleClick()"
                  >
                    {{ 'SHOP.MANAGE' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-2">
          <div class="card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-6">
                  <h5 class="card-title mt-3 ml-3 text-left h5-header">
                    {{ 'SHOP.LASTACTIVITY' | translate }}
                  </h5>
                </div>
                <div class="col-6">
                  <button
                    type="button"
                    class="btn btn-outline-primary mr-3 my-2 float-right"
                    (click)="onGoToActivitiesList()"
                  >
                    {{ 'SALES.FILTR' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-sm-12 mt-xl-0 mt-3">
      <div class="row">
        <div class="col-12 mb-3"
        [hidden]="contioShopPrivilege && contioShopPrivilege === privilegeValue.Read"
        >
              <button
                type="button"
                class="btn btn-outline-primary mr-3 w-100 manage-btn"
                (click)="onGoToShopCamerasClick()"
              >
                {{ 'SHOP.MANAGECAMERAS' | translate }}
              </button>
        </div>

        <div class="col-12">
          <div class="card camera-card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-12">
                  <div class="text-center">
                    <img
                      src="{{ videoUrl }}"
                      class="img"
                      alt="image"
                      (click)="onPlayVideoClick(isVideoLive ? false : true)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters mt-2" *ngIf="cameraList">
        <table
          class="col-12"
          *ngFor="let cameras of cameraList | toMatrix: 3; let idx = index"
        >
          <tbody>
            <tr class="spaceUnder">
              <td *ngFor="let camera of cameras; let i = index">
                <div
                  class="camera-style text-center center"
                  (click)="onSelectCameraClick(i, idx)"
                  [ngClass]="{
                    'is-selected': selectedCameraId == i + idx * 3 + 1
                  }"
                >
                  <h4>{{ camera.Name }}</h4>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row no-gutters">
    <div class="col-12 empty-space"></div>
  </div>
</navigation>
