<div class="row mb-1">
    <div class="col-12 ml-3 mb-1">
      <h4 class="text-left header-color">
        {{ 'STORES.STORETITLE' | translate }}
      </h4>
    </div>

<div class="col-sm-12 mt-1">
    <div class="row no-gutters">
      <app-dropdown
            *ngIf="isStoreLoaded"
            [(selected)]="selectedStore"
            (selectedChange)="onChangeStoreClick()"
            label="{{ 'STORES.STORE' | translate }}"
            itemKey="Name"
            [items]="storeList"
        ></app-dropdown>
      <div
        class="input-group-append float-left ml-0 ml-md-2 mt-2 mt-md-0" 
      >
        <div class="btn-group" role="group">
          <button
            #addStore
            type="text"
            class="btn btn-primary normal-btn w-auto"
            id="addStore"
            (click)="onStorePostDialogClick()"
            [hidden]="companyPrivilege && companyPrivilege === privilegeValue.Read"
          >
            <i class="fas fa-plus mr-1"></i>
            {{ 'STORES.STORETITLE' | translate }}
          </button>
          <button
            #addStoreEdit
            *ngIf="selectedStore"
            type="text"
            class="btn btn-primary normal-btn"
            id="addStoreEdit"
            (click)="onStoreEditModalOpenClick(storeEdit)"
            [hidden]="companyPrivilege && companyPrivilege === privilegeValue.Read"
          >
            {{ 'STORES.EDIT' | translate }}
          </button>
        </div>
      </div>
      <div class="float-left pl-2 mt-2 mt-md-0">
        <button
          #addStoreEdit
          *ngIf="selectedStore"
          type="text"
          class="btn btn-primary normal-btn"
          id="addStoreEdit"
          (click)="onContioStoreEditOpenModalClick(contio)"
          [hidden]="companyPrivilege && companyPrivilege === privilegeValue.Read"
        >
        {{ 'STORES.CONTIOSETTINGS' | translate }}
        </button>
      </div>
    </div>
    <div class="row no-gutters">
      <div
        *ngIf="isStoreLoaded"
        class="col-sm-12 cashregisters mt-3 d-inline"
      >
        <div>Store ID: {{ selectedStore.Id }}</div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div
    *ngIf="isStoreLoaded"
    class="col-sm-12 cashregisters d-inline"
  >
    <app-company-settings-cash-register 
    [storeId]="selectedStore.Id"
    [companyPrivilege]="companyPrivilege"
    ></app-company-settings-cash-register>
  </div>
</div>

  <!-- MODAL FOR ADDING STORE -->
  <ng-template #storeAdd let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'STORES.ADDSTOREMODAL' | translate }}</h4>
    </div>
    <div class="modal-body">
      <div class="form-group" [formGroup]="validatorService.storeForm">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" for="storeInput">{{
              'STORES.NEWSTORE' | translate
            }}</span>
          </div>
          <input
            #newStore
            [(ngModel)]="storeInput"
            type="text"
            id="storeInput"
            class="form-control"
            placeholder="{{ 'STORES.NAME' | translate }}"
            formControlName="storeName"
            class="form-control"
            [ngClass]="{
              'is-invalid':
                canShowStoreValidation && validatorService.validate.storeName.errors
            }"
          />
          <div
            *ngIf="canShowStoreValidation && validatorService.validate.storeName.errors"
            class="invalid-feedback text-right mr-1"
          >
            <div class="input-group-append float-right">
              <div *ngIf="validatorService.validate.storeName.errors.required">
                {{ 'VALIDATOR.VALIDATION' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="c('Close click'); onCloseClick()"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>

      <button
        class="btn btn-primary save-btn"
        type="button"
        (click)="onStorePostClick(newStore.value); newStore.value && c('Close click')"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'STORES.NEW' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- MODAL FOR EDITING STORE -->
  <ng-template #storeEdit let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ selectedStore.Name }}</h4>
      <button
        class="btn btn-danger float-right"
        type="button"
        [disabled]="selectedContio.Name"
        (click)="onStoreDeleteModalOpenClick(modalStoreDelete); c('Close click')"
      >
        <i class="fas fa-trash-alt mr-1"></i>
        {{ 'PRODUCTS.DELETEGROUP' | translate }}
      </button>
    </div>
    <div class="modal-body">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="nameInput">{{
            'MODAL.NAME' | translate
          }}</span>
        </div>
        <input
          #storeNameEdit
          [(ngModel)]="storeModalEdit"
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid': !storeModalEdit && canShowStoreValidation
          }"
        />

        <div
          *ngIf="canShowStoreValidation && !storeModalEdit"
          class="invalid-feedback text-right div-right-margin"
        >
          <div class="input-group-append float-right">
            <div *ngIf="!storeModalEdit">
              {{ 'VALIDATOR.VALIDATION' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="c('Close click'); onCloseClick()"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary save-btn"
        (click)="
          onStoreEditClick(storeNameEdit.value); storeModalEdit && c('Close click')
        "
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'STOCK.UPDATE' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- MODAL WINDOW FOR DELETE STORE -->
  <ng-template #modalStoreDelete let-c="close" let-d="dismiss">
    <div class="ng-template-padding">
      <div class="modal-header text-center">
        <span class="large-font"
          >{{ 'STORES.SDIALOG' | translate }} '{{ selectedStore.Name }}'?</span
        >
      </div>
      <div class="modal-footer">
        <button
          type="bu
          tton"
          class="btn btn-secondary"
          (click)="openModalStoreEdit(storeEdit); c('Close click')"
        >
          {{ 'PRODUCTS.CLOSE' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="onStoreDeleteClick(); c('Close click')"
        >
          <i class="fas fa-trash-alt mr-1"></i>
          {{ 'PRODUCTS.DELETEGROUP' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
  <!-- MODAL FOR CONTIO -->
  <ng-template #contio let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">Contio nastavení</h4>
    </div>
    <div class="modal-body no-gutters">
      <div class="row">
        <div class="col-9 mt-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="nameInput">{{
                'MODAL.NAME' | translate
              }}</span>
            </div>
            <input
              #contioNameInput
              [(ngModel)]="selectedContio.Name"
              type="text"
              class="form-control"
              [ngClass]="{
                'is-invalid': !selectedContio.Name && canShowStoreValidation
              }"
            />

            <div
              *ngIf="canShowStoreValidation && !selectedContio.Name"
              class="invalid-feedback text-right div-right-margin"
            >
              <div class="input-group-append float-right">
                <div *ngIf="!selectedContio.Name">
                  {{ 'VALIDATOR.VALIDATION' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-3 pl-3 mb-2">
          <div
            class="custom-control custom-checkbox checkbox-lg float-left"
          >
            <input
              [(ngModel)]="selectedContio.IsAvailable"
              (ngModelChange)="onCheckboxChange($event)"
              type="checkbox"
              class="custom-control-input"
              id="availableInput"
            />
            <label class="custom-control-label" for="availableInput">
              {{ 'STORES.AVAILABLE' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="c('Close click'); onCloseClick()"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary save-btn"
        (click)="
          onContioStorePostClick(selectedContio.Name);
          selectedContio.Name && c('Close click')
        "
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVECHANGES' | translate }}
      </button>
    </div>
  </ng-template>