<authentication-test></authentication-test>
<navigation selectedPage="returns">
  <div *ngIf="isReturnLoaded">
    <div class="row no-gutters">
      <div class="col-10 page-header ">
        {{ 'RETURN.RETURNDETAIL' | translate }}
      </div>

      <div class="col-2">
        <button
          type="button"
          class="btn btn-primary normal-btn float-right"
          (click)="onGoToReturnListClick()"
        >
          {{ 'REGISTRATION.BACK' | translate }}
        </button>
      </div>
    </div>
  </div>

  <hr class="line">

  <div *ngIf="isReturnLoaded" class="row mt-3 mb-3">
    <div class="col-xl-6 col-sm-12">
      <div class="card">
        <div class="card-content">
          <div class="row no-gutters">
            <div class="col-12">
              <h5
                class="card-title mt-3 mb-3 ml-3 text-left small-header"
              >
              {{ 'RETURN.CHANGERETURNSTATUS' | translate }}
              </h5>

              <hr class="mx-2 card-hr"/>
            </div>
          </div>

          <div class="row no-gutters mb-3" *ngIf="returnTypeList">
            <div class="col-6">
              <div class="input-group-prepend pl-3">
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-primary dropdown-tag line-height"
                  >
                    <span class="font-weight-normal"> {{ 'SHOP.STATE' | translate }}: </span>
                  </button>
                  <div class="btn-group" ngbDropdown>
                    <button
                      class="btn btn-primary dropdown-toggle-primary dropdown-box"
                      id="stores"
                      data-toggle="dropdown"
                      ngbDropdownToggle
                    >
                      <span class="font-weight-normal">
                        {{ return.Description }}
                      </span>
                    </button>
                    <div
                      ngbDropdownMenu
                      class="dropdown-menu"
                      aria-labelledby="returnTypeList"
                    >
                      <button
                        *ngFor="let returnType of returnTypeList"
                        class="dropdown-item"
                        (click)="onReturnTypeSelect(returnType.Id)"
                      >
                        {{ returnType.Description }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 d-flex justify-content-end pr-3">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary normal-btn"
                  (click)="onEditClick()"
                >
                  <i class="fas fa-save mr-1"></i>
                  {{ 'RETURN.CHANGESTATE' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</navigation>
