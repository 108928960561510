<authentication-test></authentication-test>
<navigation selectedPage="telegram">
  <div class="row mt-3 mb-3">
    <div class="col-xl-6 col-sm-12 mt-xl-0 mt-3">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-12">
                  <h5
                    class="card-title mt-3 ml-3 mb-2 text-left h5-header"
                  >
                  {{ 'NAVIGATION.TELEGRAM' | translate }}
                  </h5>
                </div>

                <div class="col-12 mt-3 mb-3">
                  <div class="input-group ml-2">
                    <div class="input-group-prepend">
                      <button
                        class="btn btn-primary new-btn"
                        type="button"
                        (click)="onGenerateTelegramKeyClick()"
                      >
                        <i class="fas fa-plus mr-1"></i>
                        {{ 'TELEGRAM.NEWKEY' | translate }}
                      </button>
                    </div>
                    <input
                      [(ngModel)]="generatedTelegramKey"
                      type="text"
                      class="form-control normal-input"
                      disabled="true"
                      class="form-control"
                    />
                    <div class="input-group-append mr-3">
                      <button
                        class="btn btn-primary save-btn"
                        type="button"
                        (click)="postTelegramKey()"
                      >
                        <i class="fas fa-save mr-1"></i>
                        {{ 'PRODUCTS.SAVENEWPRODUCT' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-sm-12 mt-xl-0 mt-3">
      <div class="row">
        <div class="col-12 mb-2" *ngIf="!isUserDetailVisible">
          <div class="card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-12">
                  <h5
                    class="card-title mt-3 ml-3 text-left h5-header"
                  >
                  {{ 'USERS.USERDETAIL' | translate }}
                  </h5>
                </div>
              </div>
              <div class="row no-gutters mt-2">
                <div class="col-6 px-2">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"> Id </span>
                    </div>
                    <input
                      [(ngModel)]="telegramUser.TelegramId"
                      type="text"
                      class="form-control normal-input"
                      disabled="true"
                    />
                  </div>
                </div>
                <div class="col-6 px-2">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"> {{ 'TELEGRAM.NICKNAME' | translate }} </span>
                    </div>
                    <input
                      [(ngModel)]="telegramUser.Username"
                      type="text"
                      class="form-control normal-input"
                      disabled="true"
                    />
                  </div>
                </div>

                <div class="col-6 px-2 mt-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"> {{ 'USERS.NAME' | translate }} </span>
                    </div>
                    <input
                      [(ngModel)]="telegramUser.Name"
                      type="text"
                      class="form-control normal-input"
                      disabled="true"
                    />
                  </div>
                </div>
                <div class="col-6 px-2 mt-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"> {{ 'USERS.SURNAME' | translate }} </span>
                    </div>
                    <input
                      [(ngModel)]="telegramUser.Surname"
                      type="text"
                      class="form-control normal-input"
                      disabled="true"
                    />
                  </div>
                </div>

                <div class="col-6 px-2 my-3">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"> {{ 'TELEGRAM.KEY' | translate }} </span>
                    </div>
                    <input
                      [(ngModel)]="telegramUser.ApiKey"
                      type="text"
                      class="form-control normal-input"
                      disabled="true"
                    />
                  </div>
                </div>
                <div class="col-6 px-2 my-3">
                  <div class="input-group">
                    <input
                      [ngModel]="telegramUser.IsValid ? 'Aktivní' : 'Blokovaný'"
                      type="text"
                      class="form-control normal-input"
                      disabled="true"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary save-btn"
                        type="button"
                        (click)="onBlockUserCLick()"
                      >
                        <i class="fas fa-save mr-1"></i>
                        {{ 'TELEGRAM.BLOCK' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-12">
                  <h5
                    class="card-title mt-3 ml-3 text-left h5-header"
                  >
                  {{ 'NAVIGATION.USERS' | translate }}
                  </h5>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-12 mb-3">
                  <ngx-datatable
                    #datatable
                    class="material ml-3 mr-3 ngx-datatable-cursor"
                    [rows]="telegramUserList"
                    [columns]="[
                      { prop: 'Name', name: 'Jméno' },
                      { prop: 'Surname', name: 'Příjmení' },
                      { prop: 'ApiKey', name: 'Klíč' }
                    ]"
                    [headerHeight]="50"
                    [footerHeight]="50"
                    [columnMode]="'force'"
                    [rowHeight]="'auto'"
                    [selectionType]="'single'"
                    [limit]="pageLimit"
                    [externalPaging]="true"
                    [count]="userCount"
                    [offset]="currentPage"
                    [messages]="{
                      emptyMessage: 'PRODUCTS.TABLENODATA' | translate,
                      totalMessage: 'PRODUCTS.TABLETOTAL' | translate
                    }"
                    (select)="onViewUserDetailSelect($event.selected[0])"
                    (page)="getUsers($event)"
                  >
                  </ngx-datatable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</navigation>
