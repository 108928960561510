<authentication-test></authentication-test>
<div (window:resize)="onResize($event)">
  <navigation selectedPage="contio-store">
    <div class="row no-gutters">
      <div class="col-8">
        <div class="row no-gutters">
          <div class="col-12 page-header">
            {{ 'SHOP.ANALYTICS' | translate }}
          </div>
        </div>
      </div>

      <div class="col-4">
        <button
          class="normal-btn float-right"
          mat-button
          (click)="onGoToContioStoreDetailClick()"
        >
          {{ 'REGISTRATION.BACK' | translate }}
        </button>
      </div>
    </div>

    <hr class="line" />

    <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayoutAlign="end center">
      <div fxFlex="50%" fxFlex.gt-lg="20%">
        <app-dropdown
          *ngIf="contioChartTypeList"
          [(selected)]="selectedContioChartType"
          label="{{ 'STORES.CONTIOCHARTSETTINGS' | translate }}:"
          itemKey="chartType"
          [items]="contioChartTypeList"
          [type]="dropdownType.Analytics"
        ></app-dropdown>
      </div>
      <div fxFlex="50%" fxFlex.gt-lg="30%">
        <app-dropdown
          *ngIf="selectedContioStore"
          [(selected)]="selectedContioStore"
          label="{{ 'STORES.CONTIONAME' | translate }}:"
          itemKey="Name"
          [items]="contioStoreList"
        ></app-dropdown>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="22%" fxFlex.gt-md="50%">
        <app-input-date
          [(input)]="startDate"
          [submit]="isSubmit"
          label="{{ 'SALES.DATEFROM' | translate }}"
          placeholder="{{ 'SALES.TABLEDATE' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
          [useMaterial]="false"
        ></app-input-date>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="22%" fxFlex.gt-md="50%">
        <app-input-date
          [(input)]="endDate"
          [submit]="isSubmit"
          label="{{ 'SALES.DATETO' | translate }}"
          placeholder="{{ 'SALES.TABLEDATE' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
          [useMaterial]="false"
        ></app-input-date>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="6%" fxLayoutAlign="end">
        <button class="search-btn" mat-button (click)="onSetAnalyticsClick()">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>

    <div class="container-fluid mb-2 w-75 h-50 mt-5">
      <ngx-charts-line-chart
        [scheme]="lineScheme"
        [results]="dataForChart"
        [gradient]="isGradient"
        [xAxis]="isXAxisVisible"
        [yAxis]="isYAxisVisible"
        [legend]="isLegendVisible"
        [autoScale]="isAutoscale"
        [showXAxisLabel]="isXAxisLabelVisible"
        [showYAxisLabel]="isYAxisLabelVisible"
      >
      </ngx-charts-line-chart>
    </div>

    <div class="row">
      <div class="col-6 mt-4">
        <button
          [disabled]="selectedContioChartType.chartType === chartType.Receipt"
          (click)="onCreateCSVClick()"
          class="btn btn-primary normal-btn mx-auto d-block"
        >
          <i class="fas fa-download mr-2"></i>
          {{ 'ORDERS.CSV' | translate }}
        </button>
      </div>
      <div class="col-6 mt-4">
        <h4 class="mr-2 text-center summary">
          {{ 'DASHBOARD.SUMMARY' | translate }}
          {{ graphSummary }}
        </h4>
      </div>
      <div class="col-1"></div>
    </div>
  </navigation>
</div>
