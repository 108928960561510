<authentication-test></authentication-test>
<div class="allStores">
  <navigation selectedPage="stores">
    <div *ngIf="isCompanyDataLoaded" class="pb-4">
      <div class="row">
        <div class="col-sm">
          <div class="pl-2 page-header">
            {{ 'NAVIGATION.STORES' | translate }}
          </div>
          <div *ngIf="company.Id" class="col-sm">
            ID: {{ company.Id }}
          </div>
          <hr
            class="hr-line"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12 ml-3 mt-3 mb-1">
          <h4 class="text-left text-left header-color">
            {{ 'STORES.TITLEFIRM' | translate }}
          </h4>
        </div>

        <div
          class="col-lg-6 col-md-6 col-sm-12 mb-3 form-group mt-1"
          [formGroup]="validatorService.companyForm"
        >
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text input-widt">{{
                'STORES.COMPANYNAME' | translate
              }}</span>
            </div>
            <input
              [(ngModel)]="company.Name"
              value="{{ company.Name }}"
              type="text"
              formControlName="companyName"
              class="form-control normal-input"
              id="companyNameInput"
              [ngClass]="{
                'is-invalid':
                  canShowCompanyValidation && validatorService.validatePageForm.companyName.errors
              }"
            />
            <div
              *ngIf="canShowCompanyValidation && validatorService.validatePageForm.companyName.errors"
              class="invalid-feedback text-right div-right-margin"
            >
              <div
                *ngIf="validatorService.validatePageForm.companyName.errors.required"
              >
                {{ 'VALIDATOR.VALIDATION' | translate }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-lg-6 col-md-6 col-sm-12 mb-3 form-group mt-1"
          [formGroup]="validatorService.companyForm"
        >
          <div class="row no-gutters">
            <div class="col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text input-width">{{
                    'STORES.COMPANYBIN' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="company.Bin"
                  value="{{ company.Bin }}"
                  (keyup)="isAresDataLoaded = false"
                  type="text"
                  formControlName="companyBin"
                  class="form-control normal-input"
                  id="binInput"
                  [ngClass]="{
                    'is-invalid':
                      (canShowCompanyValidation &&
                        validatorService.validatePageForm.companyBin.errors) ||
                      (isAresModalOpen && !company.Bin) ||
                      (binFormatValidation &&
                        ((checkIfValueIsNumberValidator(company.Bin) &&
                          company.Bin.length != 8) ||
                          !checkIfValueIsNumberValidator(company.Bin))) ||
                      isAresDataLoaded
                  }"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-secondary search-btn"
                    (click)="onAresModalOpenClick(aresModal)"
                  >
                    <i class="fas fa-search mr-2"></i> ARES
                  </button>
                </div>

                <div
                  *ngIf="
                    (canShowCompanyValidation &&
                      validatorService.validatePageForm.companyBin.errors) ||
                    (isAresModalOpen && !company.Bin)
                  "
                  class="invalid-feedback div-right-margin text-right"
                >
                  <div
                    *ngIf="
                      validatorService.validatePageForm.companyBin.errors.required
                    "
                  >
                    {{ 'VALIDATOR.VALIDATION' | translate }}

                    <button
                      type="button"
                      class="btn btn-secondary hidden-btn ares-search-btn"
                      [disabled]="true"
                    >
                      <i class="fas fa-search mr-2"></i> ARES
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div
                *ngIf="
                  (binFormatValidation &&
                    (company.Bin ||
                      !validatorService.validatePageForm.companyBin.errors) &&
                    ((checkIfValueIsNumberValidator(company.Bin) &&
                      company.Bin.length != 8) ||
                      !checkIfValueIsNumberValidator(company.Bin))) ||
                  isAresDataLoaded
                "
                class="help-block invalid-name text-right div-right-margin"
              >
                <div>
                  <span class="mr-1" *ngIf="!isAresDataLoaded">{{
                    'VALIDATOR.BINVALIDATION' | translate
                  }}</span>
                  <span class="mr-1" *ngIf="isAresDataLoaded">{{
                    'VALIDATOR.BINUSERVALIDATION' | translate
                  }}</span>

                  <button
                    type="button"
                    class="btn btn-secondary hidden-btn ares-search-btn"
                    [disabled]="true"
                  >
                    <i class="fas fa-search mr-2"></i> ARES
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mt-1">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text input-width">{{
                'STORES.COMPANYBANK' | translate
              }}</span>
            </div>
            <input
              [(ngModel)]="company.Bank"
              placeholder="{{ company.Bank }}"
              type="text"
              class="form-control normal-input"
              id="bankInput"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 mt-1">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text input-width">{{
                'CONTACTS.TIN' | translate
              }}</span>
            </div>
            <input
              [(ngModel)]="company.Tin"
              value="{{ company.Tin }}"
              type="text"
              class="form-control normal-input"
              id="tinInput"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 mt-1">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text input-width">{{
                'STORES.COMPANYPHONE' | translate
              }}</span>
            </div>
            <input
              [(ngModel)]="company.Phone"
              value="{{ company.Phone }}"
              type="text"
              class="form-control normal-input"
              id="phoneInput"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 mt-1">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text input-width"
                >Email</span
              >
            </div>
            <input
              [(ngModel)]="company.Email"
              placeholder="{{ company.Email }}"
              type="text"
              class="form-control normal-input"
              id="emailInput"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 my-2">
          <div class="input-group mb-md-3">
            <div class="input-group-prepend">
              <span class="input-group-text input-width">Web</span>
            </div>
            <input
              [(ngModel)]="company.Web"
              value="{{ company.Web }}"
              type="text"
              class="form-control normal-input"
              id="webInput"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-2">
          <div
            class="custom-control custom-checkbox checkbox-lg float-left"
          >
            <input
              [(ngModel)]="company.TaxPayer"
              (ngModelChange)="onCheckboxChange($event)"
              type="checkbox"
              class="custom-control-input"
              id="taxpayerInput"
            />
            <label class="custom-control-label" for="taxpayerInput">{{
              'STORES.COMPANYPAYER' | translate
            }}</label>
          </div>
        </div>
      </div>

      <hr
        class="custom-hr mt-2 w-100"
      />

      <div class="row">
        <div class="col-12 ml-3 mb-2">
          <h4 class="header-color text-left">
            {{ 'STORES.TITLEADRESS' | translate }}
          </h4>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text input-width">{{
                'STORES.COMPANYSTATE' | translate
              }}</span>
            </div>
            <input
              [(ngModel)]="company.State"
              value="{{ company.State }}"
              type="text"
              class="form-control normal-input"
              id="stateInput"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text input-width">{{
                'STORES.COMPANYCITY' | translate
              }}</span>
            </div>
            <input
              [(ngModel)]="company.City"
              value="{{ company.City }}"
              type="text"
              class="form-control normal-input"
              id="cityInput"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text input-width">{{
                'STORES.COMPANYSTREET' | translate
              }}</span>
            </div>
            <input
              [(ngModel)]="company.Street"
              value="{{ company.Street }}"
              type="text"
              class="form-control normal-input"
              id="streetInput"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text input-width">{{
                'STORES.ZIP' | translate
              }}</span>
            </div>
            <input
              [(ngModel)]="company.Zip"
              value="{{ company.Zip }}"
              type="text"
              class="form-control normal-input"
              id="zipInput"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 float-right">
          <button
            type="button"
            class="btn btn-primary save-btn float-right"
            (click)="onCompanyDataEditClick()"
            [hidden]="companyPrivilege && companyPrivilege === privilegeValue.Read"
          >
            <i class="fas fa-save mr-1"></i>
            {{ 'PRODUCTS.SAVECHANGES' | translate }}
          </button>
        </div>
      </div>
    </div>

    <hr
      class="custom-hr mt-2 w-100"
    />

    <app-company-settings-store
    [companyPrivilege]="companyPrivilege"
    ></app-company-settings-store>

    <hr
     class="mt-2 w-100 custom-hr"
    />

    <app-company-settings-warehouse
    [isWarehouseEdit]="true"
    [companyPrivilege]="companyPrivilege"
    ></app-company-settings-warehouse>

    <div class="row no-gutters">
      <div class="col-12 empty-space"></div>
    </div>
  </navigation>

  <!-- MODAL WINDOW FOR ARES -->
  <ng-template #aresModal let-c="close" let-d="dismiss">
    <div class="ng-template-padding">
      <div class="modal-header text-center">
        <span class="large-font"
          >{{ 'CONTACTS.ARESDIALOG' | translate }} '{{
            company.Bin
          }}'?</span
        >
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="c('Close click')"
        >
          {{ 'PRODUCTS.CLOSE' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary save-btn"
          (click)="getDataFromARES(company.Bin); c('Close click')"
        >
          <i class="fas fa-align-left mr-1"></i>
          {{ 'CONTACTS.PREFILL' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
