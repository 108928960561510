import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageReader } from '../../interfaces/image/image-reader.model';

const reader = new FileReader();
const preferredSize: number = 500;
const defaultWidthHeightRatio: number = 1;

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  async compress(file: File): Promise<Observable<File>> {
    const readerOnload: ImageReader = await this.loadReader(file);

    const image = readerOnload.image;
    const imageRatio = readerOnload.imageRatio;

    return new Observable((observer) => {
      image.onload = () => {
        const element = document.createElement('canvas');

        element.width = image.width * imageRatio;
        element.height = image.height * imageRatio;

        const canvasContext = <CanvasRenderingContext2D>(
          element.getContext('2d')
        );
        canvasContext.drawImage(image, 0, 0, element.width, element.height);
        canvasContext.canvas.toBlob(
          (blob) => {
            observer.next(
              new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              })
            );
          },
          file.type,
          file.size
        );
      };
      reader.onerror = (error) => observer.error(error);
    });
  }

  isImageMatchPreferredSize(file: File): Observable<boolean> {
    reader.readAsDataURL(file);

    return new Observable((observer) => {
      reader.onload = async (event) => {
        const image = await this.createImage(event);

        if ((image.width || image.height) < preferredSize) {
          observer.next(false);
        } else {
          observer.next(true);
        }
      };
      reader.onerror = (error) => observer.error(error);
    });
  }

  isSquare(file: File): Observable<boolean> {
    reader.readAsDataURL(file);

    return new Observable((observer) => {
      reader.onload = async (event) => {
        const image = await this.createImage(event);

        if (image.width === image.height) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      };
      reader.onerror = (error) => observer.error(error);
    });
  }

  convertBase64ImageToFile(imageBase64: string): Observable<File> {
    const url = imageBase64;
    const imageType = 'image/png';
    const blob = new Blob([url], { type: imageType });

    return new Observable((observer) => {
      observer.next(
        new File([blob], imageType, {
          type: imageType,
          lastModified: Date.now(),
        })
      );
    });
  }

  private createImage(event: ProgressEvent<FileReader>): HTMLImageElement {
    let imageContent: string = <string>event.target.result;
    const image = new Image();
    image.src = imageContent;

    return image;
  }

  private loadReader(file: File): Promise<ImageReader> {
    reader.readAsDataURL(file);

    return new Promise<ImageReader>((resolve) => {
      reader.onload = async (event) => {
        const image = await this.createImage(event);

        const imageShorterSide =
          image.width > image.height ? image.height : image.width;

        const imageRatio =
          imageShorterSide > preferredSize
            ? preferredSize / imageShorterSide
            : defaultWidthHeightRatio;

        const readerOnload: ImageReader = {
          image: image,
          imageRatio: imageRatio,
        };

        resolve(readerOnload);
      };
    });
  }
}
