import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContactService } from './contact.service';
import { ValidatorService } from '../../core/services/validator/validator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contact } from './models/contact.model';
import { SnackBarService } from '../../core/services/shared/snackbar.service';
import { finalize } from 'rxjs/operators';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';
import { ContactPageable } from './models/contact-pageable.model';
import { GridSetting } from '../../shared/models/grid-setting.model';
import { AresService } from '../../core/services/shared/ares.service';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contact-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './contact-list.component.scss',
  ],
})
export class ContactListComponent implements OnInit {
  companyId: number;
  contact: Contact;
  isAresModalOpen: boolean = false;
  isAresDataLoaded: boolean = false;
  isBinValid: boolean = false;

  currentPage: number = 0;
  contactCount: number = 0;
  pageLimit: number = GridSetting.pageLimit;
  contactList: Contact[];
  selectedRow: Contact[] = [];

  contactPostResponse: CreateRecordResponse;
  contactEditResponse: CreateRecordResponse;
  contactDeleteResponse: CreateRecordResponse;
  isContactDetailDisabled: boolean = true;
  isContactInputGroupVisible: boolean = false;
  isFormSubmitted: boolean = false;

  error: string;

  constructor(
    private contactService: ContactService,
    private validatorService: ValidatorService,
    private modalService: NgbModal,
    private aresService: AresService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.companyId = this.localStorageService.getCompanyId();

    this.setContactPageCount();
    this.validatorService.contactValidator();
  }

  onOpenAresModalClick(aresModal: string): void {
    this.isAresModalOpen = true;

    if (!this.contact.Bin) {
      return;
    }

    this.modalService.open(aresModal, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  onOpenContactDeleteModalClick(deleteModal: string): void {
    this.modalService.open(deleteModal, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  onContactTableSelect(contact: Contact): void {
    this.contact = contact;

    this.isContactDetailDisabled = false;
    this.isContactInputGroupVisible = true;

    this.isFormSubmitted = false;
    this.isAresModalOpen = false;
    this.isAresDataLoaded = false;
    this.isBinValid = false;
  }

  onSetDefaultFormClick(): void {
    this.contact = <Contact>{
      Bank: '',
      Bin: '',
      City: '',
      CompanyId: 0,
      Email: '',
      Id: 0,
      Name: '',
      Phone: '',
      State: '',
      Street: '',
      TaxPayer: false,
      Tin: '',
      Web: '',
      Zip: '',
    };

    this.isContactDetailDisabled = true;
    this.isFormSubmitted = false;
    this.isAresModalOpen = false;
    this.isAresDataLoaded = false;
    this.isBinValid = false;
    this.isContactInputGroupVisible = true;

    this.unselectRow();
  }

  onContactEditClick(): void {
    this.isFormSubmitted = true;

    if (this.validatorService.contactForm.invalid) {
      return;
    }

    this.contact.CompanyId = this.companyId;

    this.contactService
      .put(this.contact)
      .pipe(
        finalize(() => {
          this.contactEditResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.contactEditResponse = successResponse;
          if (this.contactEditResponse.success) {
            this.onSetDefaultFormClick();
            this.setCompanyContacts();

            this.snackBarService.show('STORES.COMPANYEDITSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('STORES.COMPANYEDITFALSE', false);
        },
      });
  }

  onContactPostClick(): void {
    this.isFormSubmitted = true;

    if (this.validatorService.contactForm.invalid) {
      return;
    }

    this.contact.CompanyId = this.companyId;

    this.contactService
      .post(this.contact)
      .pipe(
        finalize(() => {
          this.contactPostResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.contactPostResponse = successResponse;
          if (this.contactPostResponse.success) {
            this.onSetDefaultFormClick();
            this.setContactPageCount();

            this.snackBarService.show('STORES.COMPANYADDSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('STORES.COMPANYADDFAIL', false);
        },
      });
  }

  onContactDeleteClick(contact: Contact): void {
    this.contactService
      .delete(
        contact.CompanyId,
        contact.Id
      ).subscribe({
        next: (successResponse) => {
          this.contactDeleteResponse = successResponse;
          if (this.contactDeleteResponse.success) {
            this.onSetDefaultFormClick();
            this.setContactPageCount();

            this.snackBarService.show('STORES.COMPANYDELETESUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('STORES.COMPANYDELETEFALSE', false);
        },
      });
  }

  setCompanyContacts(contact?: ContactPageable): void {
    if (contact != null) {
      this.currentPage = contact.offset;
    }

    if (!this.contactCount) {
      return;
    }

    const nextPage = this.currentPage + 1;
    this.contactService
      .getContactsForCompany(
        this.companyId,
        nextPage,
        GridSetting.pageLimit
      )
      .subscribe({
        next: (contactList) => {
          this.contactList = contactList;
        },
        error: (error) => {
          this.error = error;
        },
      });

  }

  onIsTaxPayerCheck(): void {
    this.contact.TaxPayer = !this.contact.TaxPayer;
  }

  onFormShowClick(): void {
    this.isContactInputGroupVisible = false;
  }

  onFormHideClick(): void {
    this.isContactInputGroupVisible = true;
  }

  getAresCompanyIdentity(bin: string): void {
    let aresXml;
    let aresCompany;

    this.contactService
      .getAresRegistryIdentity(bin)
      .subscribe({
        next: (successResponse) => {
          aresXml = successResponse;
          if (aresXml.message === 'false_bin_format') {
            this.snackBarService.show('CONTACTS.ARESINVALIDBIN', false);

            return this.isBinValid;
          }

          aresCompany = this.aresService.parseAresXml(aresXml);

          if (!aresCompany.Name) {
            this.isAresDataLoaded = true;
            this.snackBarService.show('CONTACTS.ARESLOADFAIL', false);

            return;
          } else {
            this.isAresDataLoaded = false;
            this.contact.Name = aresCompany.Name;
            this.contact.State = aresCompany.State;
            this.contact.City = aresCompany.City;
            this.contact.Street = aresCompany.Street;
            this.contact.Zip = aresCompany.Zip;
            this.contact.Bin = bin;
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  isNumber(value: boolean): boolean {
    return (Number.isInteger(Number(value)));
  }

  private unselectRow(): void {
    this.selectedRow = [];
  }

  private setContactPageCount(): void {
    this.contactService
      .getPageCount(this.companyId)
      .subscribe({
        next: (contactCount) => {
          this.contactCount = contactCount[0].contact_count;

          this.setCompanyContacts();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
