<authentication-test></authentication-test>
<navigation selectedPage="contio-store">
  <div class="row no-gutters">
    <div class="col-8">
      <div class="row no-gutters">
        <div class="col-12 page-header">
          {{ 'SHOP.CAMERAS' | translate }}
        </div>
        <div class="col-12 address">
          Id: {{storeId}}
        </div>
      </div>
    </div>

    <div class="col-4">
      <button type="button" class="btn btn-primary normal-btn float-right back-btn" (click)="onGoToShopDetailClick()">
        {{ 'REGISTRATION.BACK' | translate }}
      </button>
    </div>
  </div>

  <hr class="line">

  <div class="row no-gutters mt-3 w-100">
    <div class="col-6">
      <button type="text" class="btn btn-primary new-btn" (click)="onSetDefaultFormClick(false)">
        <i class="fas fa-plus mr-1"></i>
        {{ 'PRODUCTS.ADDPRODUCT' | translate }}
      </button>
    </div>

    <div class="col-6 my-col">

      <div class="row no-gutters justify-content-end">
        <div class="input-group-prepend float-right">
          <div class="input-group-prepend div-right-margin" *ngIf="isCameraInputGroupVisible">
            <button type="button" class="btn btn-secondary ml-1 line-height" (click)="onFormHideClick()">
              <i class="fas fa-chevron-up"></i>
            </button>
          </div>

          <div class="input-group-prepend div-right-margin" *ngIf="!isCameraInputGroupVisible">
            <button type="button" class="btn btn-secondary ml-1 line-height" [hidden]="isCameraDetailDisabled"
              (click)="onFormShowClick()">
              <i class="fas fa-chevron-down"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row no-gutters mt-3 w-100" *ngIf="isCameraInputGroupVisible">
    <div class="col-xl-5 col-lg-5 col-sm-12 my-2 pr-xl-3 pr-lg-2 my-col">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> {{ 'PRODUCTS.NAME' | translate }} </span>
        </div>
        <input [(ngModel)]="camera.Name" type="text" class="form-control normal-input"
          placeholder="{{ 'PRODUCTS.NAME' | translate }}" class="form-control" />
      </div>
    </div>

    <div class="col-xl-5 col-lg-5 col-sm-10 my-2 px-xl-3 pr-lg-2 my-col">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> Url </span>
        </div>
        <input [(ngModel)]="camera.Url" type="text" class="form-control normal-input" placeholder="Url" />
      </div>
    </div>

    <div class="col-xl-2 col-lg-2 col-sm-2 pl-xl-3 pl-sm-3">
      <div class="custom-control custom-checkbox checkbox-lg float-left">
        <input *ngIf="camera.IsFavourite" checked (click)="onIsFavouriteCameraCheckChange()" type="checkbox"
          class="custom-control-input" id="stateInput" />
        <input *ngIf="!camera.IsFavourite" (click)="onIsFavouriteCameraCheckChange()" type="checkbox"
          class="custom-control-input" id="stateInput" />
        <label class="custom-control-label" for="stateInput"> {{ 'SHOP.DEFAULT' | translate }} </label>
      </div>
    </div>
  </div>

  <div class="row no-gutters w-100 mt-2" *ngIf="isCameraInputGroupVisible">
    <div class="col-sm-12">
      <button [hidden]="isCameraDetailDisabled" [disabled]="isCameraDetailDisabled" (click)="onCameraEditClick()"
        class="btn btn-primary save-btn float-right">
        <i class="fas fa-save mt-1"></i>
        {{ 'PRODUCTS.SAVECHANGES' | translate }}
      </button>
      <div class="float-right">
        <button [hidden]="!isCameraDetailDisabled" [disabled]="!isCameraDetailDisabled" (click)="onCameraSaveClick()"
          class="btn btn-primary save-btn float-right">
          <i class="fas fa-save mr-1"></i>
          {{ 'PRODUCTS.SAVENEWPRODUCT' | translate }}
        </button>
      </div>
    </div>
  </div>

  <hr class="line">

  <div class="row no-gutters w-100">
    <app-table *ngIf="isDataTableLoaded" 
    [columns]="columns" 
    [data]="entityList" 
    [totalCount]="cameraCount"
    [selectable]="true" 
    (selectedRow)="onCameraTableSelect($event)"
    (pageChange)="onPageChange($event)"
    ></app-table>
  </div>
</navigation>