import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LineChart } from './models/line-chart.model';
import { News } from './models/news.model';
import { CustomChartType } from './custom-chart-type.enum';

@Injectable()
export class DashboardService {
  private dashboardUrl: string = Centrala.URL + '/api/dashboard';
  private dashboardLineChartUrl: string = this.dashboardUrl + '/linechart';
  private newsUrl: string = Centrala.URL + '/api/news';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  /**
   * Get sales for company prepared for line chart
   * @param companyId {string} company ID
   * @param type {string} graph type 'hourly', 'daily', 'monthly'
   * @param from {number} unix timestamp
   * @param to {number} unix timestamp
   */
  getLineChart(
    companyId: number,
    type: CustomChartType,
    from: number,
    to: number,
    currencyId: number
  ): Observable<LineChart[]> {
    const params = new HttpParams()
      .set('companyId', companyId.toString())
      .set('type', type)
      .set('from', from.toString())
      .set('to', to.toString())
      .set('currencyId', currencyId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));
    return this.httpClient
      .get<LineChart[]>(
        this.dashboardLineChartUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  /**
   * Retrieve homepage notification
   * @param lang {string} message language
   */
  getNews(lang: string): Observable<News[]> {
    const params = new HttpParams()
      .set('lang', lang)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<News[]>(
        this.newsUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
