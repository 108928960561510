<authentication-test></authentication-test>
<navigation selectedPage="loyalty-cards">
  <div *ngIf="isDataLoaded">
    <div class="row no-gutters">
      <div class="col-10 page-header">
        {{ 'CARDS.CARDDETAIL' | translate }}
      </div>

      <div class="col-2">
        <button
          type="button"
          class="btn btn-primary normal-btn float-right"
          (click)="onGoToLoyaltyCardListClick()"
        >
          {{ 'REGISTRATION.BACK' | translate }}
        </button>
      </div>
    </div>
  </div>

  <hr class="line" />

  <div *ngIf="isDataLoaded" class="row mt-3 mb-3">
    <div class="col-xl-6 col-sm-12">
      <div class="card">
        <div class="card-content mb-2">
          <div class="row no-gutters">
            <div class="col-12">
              <h5
                class="card-title mt-3 mb-3 ml-3 text-left dark-blue-text"
              >
                {{ 'USERS.NAME' | translate }}
                <span class="font-weight-bold dark-blue-text">
                  {{ card.Name }} {{ card.Surname }}
                </span>
              </h5>

              <hr class="mx-2 custom-hr" />
            </div>

            <div class="col-6">
              <div class="ml-3">
                <p class="text-left dark-blue-text">
                  {{ 'CARDS.SERIALNUMBER' | translate }}:
                  <span class="d-inline-block font-weight-bold">
                    {{ card.SerialNumber }}
                  </span>
                </p>
              </div>
            </div>

            <div class="col-6">
              <div class="ml-3">
                <p class="text-left dark-blue-text">
                  {{ 'CARDS.BALANCE' | translate }}:
                  <span class="d-inline-block font-weight-bold">
                    {{ card.Balance }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="row no-gutters input-group mb-1">
            <div class="col-6 ml-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"> {{ 'CARDS.AMOUNT' | translate }} </span>
                <input
                  id="loyalty-card-detail-amount"
                  type="number"
                  class="form-control search-input"
                  [(ngModel)]="increaseBalanceAmount"
                />
              </div>
            </div>
            <div class="col-4 ml-2">
              <button
                class="btn btn-primary save-btn"
                (click)="onIncreaseBalanceClick()"
                [disabled]="isIncreaseBalanceButtonDisabled"
              >
                <i class="fas fa-save mr-1"></i>
                {{ 'CARDS.INCREASEBALANCE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6 col-sm-12">
      <div class="card mt-xl-0 mt-2">
        <div class="card-content">
          <div class="row no-gutters">
            <div class="col-12">
              <h5
                class="card-title mt-3 ml-3 text-left h5-header"
              >
              {{ 'CARDS.TRANSACTONHISTORY' | translate }}:
              </h5>
            </div>
          </div>
          <div class="row no-gutters">
            <div class="col-12 mb-3">
              <ngx-datatable
                #datatable
                class="material ml-3 mr-3 ngx-datatable-cursor"
                [rows]="transactionList"
                [limit]="10"
                [headerHeight]="50"
                [footerHeight]="50"
                [columnMode]="'force'"
                [rowHeight]="'auto'"
                [limit]="pageLimit"
                [columns]="[
                  { prop: 'TransactionTime', name: 'Čas' },
                  { prop: 'Amount', name: 'Částka' },
                  { prop: 'BalanceBefore', name: 'Zůstatek před' },
                  { prop: 'BalanceAfter', name: 'Zůstatek po' }
                ]"
                [externalPaging]="true"
                [count]="transactionCount"
                [offset]="currentPage"
                [messages]="{
                  emptyMessage: 'PRODUCTS.TABLENODATA' | translate,
                  totalMessage: 'PRODUCTS.TABLETOTAL' | translate
                }"
                (page)="getTransactionList($event)"
              >
              </ngx-datatable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</navigation>
