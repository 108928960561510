import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StorageCardUnit } from '../../../core/interfaces/storage-card-unit/storage-card-unit.model';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { StorageCardUnitService } from '../../../core/services/storage-card-unit/storage-card-unit.service';
import { InputDialogComponent } from '../../../shared/dialogs/input-dialog/input-dialog.component';
import { Warehouse } from '../../company-settings/models/warehouse.model';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { EditRecordResponse } from '../../../shared/models/edit-record-response.model';

@Component({
  selector: 'app-sku-unit-dropdown',
  templateUrl: './sku-unit-dropdown.component.html',
})
export class SkuUnitDropdownComponent implements OnInit {
  @Input() selectedWarehouse: Warehouse;
  @Input() selectedUnit: StorageCardUnit;
  @Input() isDropdownSet: boolean;
  @Output() selectedUnitChange: EventEmitter<StorageCardUnit> = new EventEmitter<StorageCardUnit>();
  @Output() unitsChange: EventEmitter<StorageCardUnit[]> = new EventEmitter<[StorageCardUnit]>();

  unitList: StorageCardUnit[];

  readonly defaultInput: string = '';
  updateDialog: MatDialogRef<InputDialogComponent>;
  unitPostResponse: CreateRecordResponse;
  unitEditResponse: EditRecordResponse;

  error: string;

  constructor(
    private storageCardUnitService: StorageCardUnitService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.setUnits();
  }

  onOpenUnitPostDialogClick(): void {
    this.updateDialog = this.dialog.open(InputDialogComponent, {
      data: {
        title: this.translateService.instant('SKU.SKUUNIT'),
        value: this.defaultInput,
        isEdit: true,
      },
    });
    this.updateDialog.afterClosed().subscribe((input) => {
      if (!input) {
        return;
      }

      this.postUnit(input);
    });
  }

  onOpenUnitEditDialogClick(): void {
    this.updateDialog = this.dialog.open(InputDialogComponent, {
      data: {
        title: this.selectedUnit.Name,
        value: this.selectedUnit.Name,
        isEdit: false,
      },
    });
    this.updateDialog.afterClosed().subscribe((input) => {
      if (!input) {
        return;
      }

      this.editUnit(input);
    });
  }

  onSelectedUnitChange(unit: StorageCardUnit): void {
    this.selectedUnitChange.emit(unit);
  }

  private postUnit(unitName: string): void {
    this.storageCardUnitService
      .post(
        unitName,
        this.selectedWarehouse.Id
      )
      .subscribe({
        next: (successResponse) => {
          this.unitPostResponse = successResponse;
          this.selectedUnit.Name = unitName;

          if (successResponse.success) {
            this.setUnits();

            this.snackBarService.show('PRODUCTS.ADDCATEGORYSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('PRODUCTS.ADDCATEGORYFAIL', false);
        },
      });
  }

  private editUnit(unitName: string): void {
    this.storageCardUnitService
      .put(
        this.selectedUnit.Id,
        unitName
      ).subscribe({
        next: (successResponse) => {
          this.unitEditResponse = successResponse;
          this.selectedUnit.Name = unitName;

          if (successResponse.success) {
            this.setUnits();

            this.snackBarService.show('PRODUCTS.EDITCATEGORYSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;
          this.snackBarService.show('PRODUCTS.EDITCATEGORYFAIL', false);
        },
      });
  }

  private setUnit(): void {
    if (this.unitList.length === 0) {
      return;
    }

    if (!this.selectedUnit) {
      this.selectedUnit = this.unitList[0];
    }
  }

  private setUnits(): void {
    this.storageCardUnitService
      .get(this.selectedWarehouse.Id)
      .subscribe({
        next: (unitList) => {
          this.unitList = unitList;
          this.unitsChange.emit(unitList);
          this.setUnit();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
