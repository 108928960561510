<div class="row no-gutters my-row">
  <div class="row no-gutters mt-2 w-100">
    <div class="col-sm-6 my-2 pr-sm-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">{{
            'SETTINGS.APPID' | translate
          }}</span>
        </div>
        <input
          *ngIf="sunmiSettings"
          [(ngModel)]="sunmiSettings.AppId"
          type="text"
          class="form-control normal-input"
        />
      </div>
    </div>

    <div class="col-sm-6 my-2">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">{{
            'SETTINGS.SECRETKEY' | translate
          }}</span>
        </div>
        <input
          *ngIf="sunmiSettings"
          [(ngModel)]="sunmiSettings.SecretKey"
          type="text"
          class="form-control normal-input"
        />
      </div>
    </div>

    <div class="col-sm-6 my-2 pr-sm-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">{{
            'SETTINGS.SUNMICOMPANYNO' | translate
          }}</span>
        </div>
        <input
          *ngIf="sunmiSettings"
          [(ngModel)]="sunmiSettings.SunmiCompanyNo"
          type="text"
          class="form-control normal-input"
        />
      </div>
    </div>

    <div class="col-sm-6 my-2">
      <app-dropdown
        *ngIf="enviromentList"
        [(selected)]="selectedEnviroment"
        label="{{ 'SETTINGS.ENVIROMENT' | translate }}"
        [items]="enviromentList"
        itemKey="Enviroment"
      >
      </app-dropdown>
    </div>
  </div>
  <div class="row no-gutters mt-2 w-100">
    <div class="col-sm-12 float-right">
      <button
        type="button"
        class="btn btn-primary save-btn float-right"
        (click)="onUpdateSunmiSettingsClick()"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVECHANGES' | translate }}
      </button>
    </div>
  </div>

  <div class="row no-gutters w-100" *ngIf="!isShopVisible">
    <hr class="hr-line" />
    <div class="row no-gutters w-100">
      <div class="col-sm-12 my-2">
        <div class="btn-group">
          <button type="button" class="btn btn-primary dropdown-tag bigger-btn">
            <span class="font-weight-normal">
              {{ 'NAVIGATION.SHOP' | translate }}
            </span>
          </button>
          <div class="btn-group" ngbDropdown role="group" placement="right">
            <button
              class="btn btn-primary dropdown-toggle-primary dropdown-box"
              id="dropdown"
              data-toggle="dropdown"
              ngbDropdownToggle
            >
              <span class="font-weight-normal">
                {{ selectedShop.Name }}
              </span>
            </button>
            <div
              ngbDropdownMenu
              class="dropdown-menu"
              aria-labelledby="dropdown"
            >
              <button
                *ngFor="let contioStore of contioStores"
                class="dropdown-item"
                (click)="onSelectStore(contioStore)"
              >
                {{ contioStore.Name }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 my-2 pr-sm-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{
              'SETTINGS.CONTACTPERSON' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="selectedShop.ContactPerson"
            type="text"
            class="form-control normal-input"
            [readonly]="selectedShop.SunmiShopNo"
          />
        </div>
      </div>
      <div class="col-sm-6 my-2">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">{{
              'USERS.PHONE' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="selectedShop.Phone"
            type="text"
            class="form-control normal-input"
            [readonly]="selectedShop.SunmiShopNo"
          />
        </div>
      </div>
    </div>
    <div class="row no-gutters mt-2 w-100">
      <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-primary save-btn float-sm-left float-right"
          [disabled]="!selectedShop.SunmiShopNo"
          (click)="onSynchronizeProductClick()"
        >
          <i class="fas fa-sync-alt mr-1"></i>
          {{ 'SETTINGS.SYNCPRODUCT' | translate }}
        </button>
      </div>
      <div class="col-sm-6 float-right">
        <button
          type="button"
          class="btn btn-primary save-btn float-right mt-sm-0 mt-2"
          [disabled]="selectedShop.SunmiShopNo"
          (click)="postSunmiShop()"
        >
          <i class="fas fa-save mr-1"></i>
          {{ 'SETTINGS.ADDSUNMISHOP' | translate }}
        </button>
      </div>
    </div>
    <div class="row no-gutters mt-3 w-100" *ngIf="isProgressBarVisible">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</div>
<div class="row no-gutters spacer w-100"></div>
