import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { User } from './models/admin-user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { catchError } from 'rxjs/operators';
import { EditRecordResponse } from '../../shared/models/edit-record-response.model';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';
import { Contact } from '../contacts/models/contact.model';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private usersUrl: string = Centrala.URL + '/api/users';
  private userUrl: string = Centrala.URL + '/api/user';
  private companyUrl: string = Centrala.URL + '/api/userCompanyData';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  getUsers(): Observable<User[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));
    return this.httpClient
      .get<User[]>(this.usersUrl, { params })
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  // TODO: Should return only one User = change on API
  getUser(userId?: number): Observable<User[]> {
    const params = new HttpParams()
      .set('id', userId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<User[]>(
        this.userUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getCompanyList(userId: number): Observable<Contact[]> {
    const params = new HttpParams()
      .set('id', userId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<Contact[]>(
        this.companyUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  postCompany(
    userId: number,
    name: string
  ): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<CreateRecordResponse>(
        this.companyUrl,
        {
          id: userId,
          name: name,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  putIsContio(
    id: number,
    isContio: boolean
  ): Observable<EditRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<EditRecordResponse>(
        this.userUrl,
        {
          id: id,
          isContio: isContio,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
