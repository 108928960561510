import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ProductService } from '../../core/services/product/product.service';
import { CurrencyFormatPipe } from '../../shared/pipes/currency.pipe';
import { StoreService } from '../company-settings/store.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SkuService } from '../../core/services/sku/sku.service';
import { WarehouseService } from '../company-settings/warehouse.service';
import { IngredientService } from '../../core/services/ingredient/ingredient.service';
import { ProductGroupService } from '../../core/services/product-group/product-group.service';
import { ProductTaxService } from '../../core/services/product-tax/product-tax.service';
import { ProductRequest } from '../../core/interfaces/product/product-request.model';
import { ParserService } from '../../core/services/formatter/parser.service';
import { ValidatorService } from '../../core/services/validator/validator.service';
import { StorageCardUnitService } from '../../core/services/storage-card-unit/storage-card-unit.service';
import { CsvService } from '../../core/services/csv/csv.service';
import { SaleService } from '../../core/services/sale/sale.service';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { ProductGroup } from '../../core/interfaces/product-group/product-group.model';
import { GridSetting } from '../../shared/models/grid-setting.model';
import { CsvImportColumn } from './csv-feature/csv-import-column';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../core/services/shared/snackbar.service';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './products.component.scss',
  ],
})
export class ProductsComponent implements OnInit {
  @ViewChild('csv') contentCSV: any;

  companyId: number;
  userId: number;

  changeDesignPixels: string = '1267';

  keyword: string = 'Name';
  itemPlaceholder: string = 'Název';
  selectedProduct: any;
  grName: string = '';
  grNameEdit: string;

  productImage: string;
  imageBase64: string;

  hasTwoRows: boolean = false;
  isValue: number = 0;

  isAuthentication: any;
  isProductsLoaded: any;
  isGroupsLoaded: any;
  isTaxesLoaded: any;

  ingredientTitle: string;
  hasUnit: boolean = false;
  ingredientUnit: string;

  shadowProduct: any;
  productFilter: string = '';
  selectedItem: string = '';
  autocompleteValidate: boolean = false;

  product: ProductRequest = new ProductRequest();
  selectedPlu: number;

  isChangeDisabled: boolean = true;
  isAddProductDisabled: boolean = true;
  isVariablePriceDisabled: boolean = false;
  isVariableQuantityDisabled: boolean = false;

  currentPage: number;
  totalProducts: string = '0';
  pageLimit: number;

  isHidden: boolean = true;
  isSubmitted: boolean = false;
  isIngredientSubmitted: boolean = false;
  dropdownValidate: boolean = false;

  selected: object = [];
  selectedIngredient: any = [];
  coefficient: string;

  ingredientEdit: boolean;

  changedProductData: any;

  groups: any[];
  products: any[];
  taxes: any[];
  stores: any[] = [
    {
      checked: false,
    },
  ];

  selectedGroup: any;
  selectedTax: any;

  errorMessage: string;

  groupPostedData: any;
  productPostedData: any;

  screenWidth: number = 0;

  isStoresLoaded: boolean = false;

  selectedRow: any;
  ingredient: any = {};
  ingredients: any[];
  ingredientsCount: number;

  // StorageCardRecord
  selectedRecord: any;
  isRecordsLoaded: any;
  records: any[];
  tablePostedData: any;

  post: boolean;
  onChangeTab: boolean = true;
  skuFilter: string = '';

  // Warehouse
  selectedWarehouse: any;
  isWarehouseLoaded: any;
  warehousePostedData: any;
  warehouses: any[];

  // Unit
  unName: string = '';
  unNameEdit: string;
  selectedUnit: any;
  isUnitLoaded: any;
  unitPostedData: any;
  units: any[];

  // Sales
  itemPosted: boolean = false;

  // Import
  csvLoading: boolean = false;
  invalidImport: boolean = false;
  filterValue: number = 0;
  searchedProduct: string;
  importFilter: string = '';
  csvRecords: any[] = [];
  csvRecBug: any = [];
  checkedRecords: any[] = [];

  @ViewChild('imageUploadComponent', { static: false }) imageUploadComponent: ImageUploadComponent;

  constructor(
    private productService: ProductService,
    private skuService: SkuService,
    private warehousesService: WarehouseService,
    private modalService: NgbModal,
    private storeService: StoreService,
    private ingredientService: IngredientService,
    private productGroupService: ProductGroupService,
    private productTaxService: ProductTaxService,
    private parserService: ParserService,
    private validatorService: ValidatorService,
    private storageCardUnitService: StorageCardUnitService,
    private csvService: CsvService,
    private salesService: SaleService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.companyId = this.localStorageService.getCompanyId();
    this.userId = this.localStorageService.getUserId();

    this.screenWidth = window.innerWidth;

    if (window.innerWidth < +this.changeDesignPixels) {
      this.hasTwoRows = true;
    }

    this.pageLimit = 10;
    this.currentPage = 0;

    this.getStorageCardRecords(null);
    this.getWarehouse();
    this.getStores();
    this.getGroups(true);
    this.getUnit();
    this.getTaxes();

    this.setPage();

    this.ingredients = [];
    this.ingredientsCount = 0;

    this.setValidators(this.product);
  }

  // TODO: Move all modals to service
  openModal(content: string): void {
    this.modalService.open(content, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  openModalEdit(groupEdit: string): void {
    this.grNameEdit = this.selectedGroup.Name;
    this.modalService.open(groupEdit, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  openModalDelete(modalDelete: string): void {
    this.modalService.open(modalDelete, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  openModalUnit(contentUnit: string): void {
    this.modalService.open(contentUnit, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  openModalUnitEdit(unitEdit: string): void {
    this.unNameEdit = this.selectedUnit.Name;
    this.modalService.open(unitEdit, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  openModalUnitDelete(modalUnitDelete: string): void {
    this.modalService.open(modalUnitDelete, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  openModalImport(): void {
    this.modalService.open(this.contentCSV, {
      windowClass: 'csv-modal',
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
    });
  }

  authenticationResult(event: object): void {
    this.isAuthentication = event;
  }

  setValidators(product: any): void {
    if (product.IsDiscount) {
      this.validatorService.productDiscountValidator(product.Text);
      this.product.VariablePrice = false;
    } else {
      this.validatorService.productValidator(product.Text, product.plu);
      this.product.VariablePrice = false;
    }

    this.validatorService.ingredientValidator();
    this.isSubmitted = false;
  }

  updateFilter(val: any): void {
    const value = val.toString().toLowerCase().trim();

    this.productFilter = value;

    this.setPage();
  }

  // TODO: Move `selectedCompanyId` to enums on all pages (refactor)
  getStores() {
    this.storeService
      .getList(this.companyId)
      .subscribe(
        (extractData) => (this.stores = extractData),
        (handleError) => (this.errorMessage = <any>handleError)
      ),
      () => (this.isStoresLoaded = true);
  }

  getGroups(isAutocompleteRequest: boolean) {
    this.productGroupService.getList(
      0,
      GridSetting.listLimit,
      '',
      isAutocompleteRequest
    ).subscribe(
      (extractData) => (this.groups = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => this.setGroups()
    );
  }

  getTaxes(): void {
    this.productTaxService.getList().subscribe(
      (extractData) => (this.taxes = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => this.setTaxes()
    );
  }

  postGroup(name: string): void {
    this.productGroupService
      .post(name, localStorage.getItem(LocalStorageTags.CompanyId))
      .subscribe(
        (extractData) => (this.groupPostedData = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          if (this.groupPostedData.success == true) {
            if (this.selectedGroup) {
              this.selectedGroup.Name = name;
            }
          }
          this.getGroups(true);
        }
      );
  }

  putGroupEdit(newName: string) {
    this.productGroupService
      .put(this.selectedGroup.Id, newName)
      .subscribe();
    if (this.selectedGroup.Id && newName) {
      this.selectedGroup.Name = newName;
      this.getGroups(true);
    }
  }

  // TODO: remove unused paramter
  onGroupDelete(name: string): void {
    this.selectedGroup.Name = null;
  }

  setTaxes(): void {
    if (this.taxes != null) {
      this.selectedTax = this.taxes[0];
      this.isTaxesLoaded = true;
    }
  }

  changeTax(taxId: number): void {
    this.selectedTax = this.getSelectedTax(taxId);
  }

  // TODO: Move to service
  getSelectedTax(taxId: number): void {
    for (let i = 0; i < this.taxes.length; i++) {
      if (this.taxes[i].Id === taxId) {
        return this.taxes[i];
      }
    }
  }

  setGroups(): void {
    // TODO: invert for better readability
    if (this.groups != null && this.groups.length !== 0) {
      if (!this.selectedGroup) {
        this.selectedGroup = this.groups[0];
      } else {
        this.groups.forEach((element) => {
          if (element.Name === this.selectedGroup.Name) {
            this.selectedGroup.Id = element.Id;
          }
        });
      }

      this.isGroupsLoaded = true;
      this.dropdownValidate = false;
      this.grName = '';
    }
  }

  changeGroup(groupId: number): void {
    this.selectedGroup = this.getSelectedGroup(groupId);
  }

  selectGroup(group: ProductGroup): void {
    this.selectedGroup = group;
  }

  onGroupCleared(): void {
    this.selectedGroup = '';
  }

  onChangeGroup(groupName: string): void {
    this.selectedGroup.Name = groupName;
  }

  // TODO: move to service
  getSelectedGroup(groupId: number): any {
    for (let i = 0; i < this.groups.length; i++) {
      if (this.groups[i].Id === groupId) {
        return this.groups[i];
      }
    }

    return null;
  }

  onPrepareNewProductClick(): void {
    this.product = new ProductRequest();

    this.generatePlu();
    this.unselectCheck();
  }

  setNewProduct(): void {
    this.itemPosted = false;
    this.isHidden = false;
    this.isSubmitted = false;
    this.dropdownValidate = false;
    this.isIngredientSubmitted = false;

    this.isAddProductDisabled = false;
    this.isChangeDisabled = true;

    this.setValidators(this.product);

    if (this.groups) {
      this.selectedGroup = this.groups[0];
    }

    if (this.units) {
      this.selectedUnit = this.units[0];
    }

    this.checkStores(true);
  }

  private generatePlu(): void {
    this.productService
      .generatePlu(this.userId)
      .subscribe({
        next: (plu) => {
          this.product.plu = plu.item;

          this.setNewProduct();
        },
        error: (error) => {
          this.errorMessage = error;
        }
      });
  }

  setDefaultFields(canCheckStores: boolean): void {
    this.setNewProduct();

    this.isAddProductDisabled = false;
    this.isChangeDisabled = true;

    if (this.groups) {
      this.selectedGroup = this.groups[0];
    }

    if (this.units) {
      this.selectedUnit = this.units[0];
    }

    this.checkStores(canCheckStores);
  }

  async postProduct(): Promise<void> {
    this.isSubmitted = true;

    if (!this.selectedGroup || !this.selectedUnit) {
      this.dropdownValidate = true;
      return;
    }

    if (this.validatorService.productForm.invalid) {
      return;
    }

    if (!this.product.Price && !this.product.Discount) {
      this.productPostedData = { success: false };
      return;
    }

    const isPluValid = await this.validatePlu(this.product.plu);

    if (!isPluValid) {
      return;
    }

    let prodMapping;

    const product: ProductRequest = {
      Id: -1,
      BatchId: this.selectedGroup.Id,
      TaxId: this.selectedTax.Id,
      Text: this.product.Text,
      Price: this.product.Price,
      Unit: this.selectedUnit.Name,
      UnitId: this.selectedUnit.Id,
      mobileWaiter: this.product.mobileWaiter,
      Barcode: this.product.Barcode,
      plu: this.product.plu,
      Abbreviation: this.product.Abbreviation,
      IsHidden: this.product.IsHidden,
      IsStock: this.product.IsStock, // Nepoužívá se
      VariablePrice: this.product.VariablePrice,
      VariableName: this.product.VariableName,
      VariableQuantity: this.product.VariableQuantity,
      Kitchen: this.product.Kitchen,
      IsPackaging: this.product.IsPackaging,
      IsDiscount: this.product.IsDiscount,
      Discount: this.product.Discount,
      IsRedeemable: this.product.IsRedeemable,
      IsWeighted: this.product.IsWeighted,
      IsAdulthood: this.product.IsAdulthood,
      IsSelfcheckoutWeighted: this.product.IsSelfcheckoutWeighted,
      Image: this.imageBase64,
    };

    this.productService.post(product).subscribe(
      (extractData) => (this.productPostedData = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => {
        this.product.Id = this.productPostedData.newProductId;
        this.productService
          .postMap(this.product.Id, this.getCheckedStores())
          .subscribe(
            (extractData) => (prodMapping = extractData),
            (handleError) => (this.errorMessage = <any>handleError),
            () => {
              if (this.errorMessage) {
                this.productPostedData.success = false;
              }
              this.setPage();
              this.setDefaultFields(false);
            }
          );
      }
    );
  }

  private async validatePlu(plu: number): Promise<boolean> {
    return await new Promise((resolve) => {
      this.productService.validatePlu(
        this.userId,
        plu
      ).subscribe({
        next: (serverResponse) => {
          if (!serverResponse.success) {
            this.snackBarService.show(
              this.translateService.instant('PRODUCTS.INVALIDPLU'),
              false
            );
          }
  
          resolve(serverResponse.success);
        },
        error: (error) => {
          this.errorMessage = error;
        }
      });
    });
  }

  getCheckedStores() {
    const result = [];
    for (let i = 0; i < this.stores.length; i++) {
      if (this.stores[i].checked) {
        result.push(this.stores[i].Id);
      }
    }

    return result;
  }

  getCompanyProducts(event: any): void {
    if (event != null) {
      this.currentPage = event.offset;
    }

    if (this.totalProducts != null) {
      this.productService
        .get(
          this.companyId,
          this.productFilter,
          this.currentPage + 1,
          this.pageLimit,
          true
        )
        .subscribe(
          (extractData) => {
            this.products = extractData;
            setTimeout(() => {
              this.changedProductData = null;
              this.productPostedData = null;
            }, 1000);
          },
          (handleError) => (this.errorMessage = <any>handleError),
          () => {
            this.products.forEach((product) => {
              product.Price = new CurrencyFormatPipe().transform(product.Price);
            });
            this.setProducts();
          }
        );
    }
  }

  // TODO: Move parsing to service
  setProducts(): void {
    if (!this.products) {
      return;
    }

    for (let i = 0; i < this.products.length; i++) {
      if (!this.products[i].Abbreviation) {
        this.products[i].Abbreviation = this.products[i].Text;
      }

      if (this.products[i].IsDiscount) {
        this.products[i].Price = null;
        this.products[i].Discount = this.products[i].UnitAmount * 100;
      }

      if (this.products[i].Abbreviation.length > 6) {
        this.products[i].Abbreviation = this.products[i].Abbreviation.substring(
          0,
          6
        );
      }
    }

    if (this.isTaxesLoaded === true) {
      this.isProductsLoaded = true;
    }
  }

  // TODO: Move to service
  getProductById(productId: string): any {
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].Id === productId) {
        return this.products[i];
      }
    }
  }

  setProductUpdate(event: any): void {
    console.log(event);

    if (event.type !== 'click') {
      return;
    }

    this.setValidators(event.row);
    this.imageUploadComponent.deleteFile();

    this.itemPosted = false;
    this.isHidden = false;
    this.isChangeDisabled = false;
    this.isSubmitted = false;
    this.dropdownValidate = false;
    this.isIngredientSubmitted = false;
    this.hasUnit = false;

    let storeProductMap;
    this.productService.getMap(event.row.Id).subscribe(
      (extractData) => (storeProductMap = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => {
        this.setStoreProductMapping(storeProductMap);
      }
    );

    this.product.Id = event.row.Id;
    this.product.Text = event.row.Text;
    this.ingredientTitle = event.row.Text;
    this.product.Price = event.row.Price ? this.parserService.parseNumber(event.row.Price) : event.row.Price;
    this.selectedTax = this.getSelectedTax(event.row.TaxId);
    this.selectedGroup = this.getSelectedGroup(event.row.BatchId);
    this.selectedUnit = this.getSelectedUnit(event.row.UnitId);
    this.product.Unit = event.row.Unit;
    this.product.Barcode = event.row.Barcode;
    this.product.plu = event.row.Plu;
    this.selectedPlu = event.row.Plu;
    this.product.Abbreviation = event.row.Abbreviation;
    this.product.mobileWaiter = event.row.MobileWaiter;

    this.product.IsHidden = event.row.IsHidden;
    this.product.IsStock = event.row.IsStock;

    this.product.VariablePrice = event.row.VariablePrice;
    this.product.VariableName = event.row.VariableName;
    this.product.VariableQuantity = event.row.VariableQuantity;
    this.product.IsPackaging = event.row.IsPackaging;
    this.product.IsDiscount = event.row.IsDiscount;
    this.product.Discount = event.row.Discount;
    this.product.IsRedeemable = event.row.IsRedeemable;
    this.product.IsWeighted = event.row.IsWeighted;
    this.product.IsAdulthood = event.row.IsAdulthood;
    this.product.IsSelfcheckoutWeighted = event.row.IsSelfcheckoutWeighted;
    this.productImage = event.row.Image;

    this.isAddProductDisabled = true;

    this.changedProductData = null;
    this.productPostedData = null;

    this.onChangeTab = true;
    this.clearIngredient();
  }

  // TODO: Move to service
  setStoreProductMapping(storeProductMaps: any[]): void {
    this.checkStores(false);

    storeProductMaps.forEach((storeProductMap) => {
      this.stores.forEach((store) => {
        if (storeProductMap.StoreId === store.Id) {
          store.checked = true;
        }
      });
    });
  }

  async changeProduct(): Promise<void> {
    this.isSubmitted = true;

    if (this.validatorService.productForm.invalid) {
      return;
    }

    if (!this.product.Price && !this.product.Discount) {
      this.productPostedData = { success: false };
      return;
    }

    if (this.selectedPlu != this.product.plu) {
      const isPluValid = await this.validatePlu(this.product.plu);

      if (!isPluValid) {
        return;
      }
    }

    const product: ProductRequest = {
      Id: this.product.Id,
      BatchId: this.selectedGroup.Id,
      BatchName: this.selectedGroup.Name,
      TaxId: this.selectedTax.Id,
      Text: this.product.Text,
      Price: this.product.Price,
      Unit: this.selectedUnit.Name,
      UnitId: this.selectedUnit.Id,
      mobileWaiter: this.product.mobileWaiter,
      Barcode: this.product.Barcode,
      plu: this.product.plu,
      Abbreviation: this.product.Abbreviation,
      IsHidden: this.product.IsHidden,
      IsStock: this.product.IsStock, // Nepoužívá se
      VariablePrice: this.product.VariablePrice,
      VariableName: this.product.VariableName,
      VariableQuantity: this.product.VariableQuantity,
      Kitchen: this.product.Kitchen,
      IsPackaging: this.product.IsPackaging,
      IsDiscount: this.product.IsDiscount,
      Discount: this.product.Discount,
      IsRedeemable: this.product.IsRedeemable,
      IsWeighted: this.product.IsWeighted,
      IsAdulthood: this.product.IsAdulthood,
      IsSelfcheckoutWeighted: this.product.IsSelfcheckoutWeighted,
      Image: this.imageBase64,
    };

    let prodMapping;

    this.productService.put(product).subscribe(
      (extractData) => (this.changedProductData = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => {
        this.productService
          .postMap(this.product.Id, this.getCheckedStores())
          .subscribe(
            (extractData) => (prodMapping = extractData),
            (handleError) => (this.errorMessage = <any>handleError),
            () => {
              if (this.errorMessage) {
                this.productPostedData.success = false;
              }
              this.setPage();
              this.setDefaultFields(false);
            }
          );
      }
    );
  }

  checkStores(value: boolean): void {
    if (this.stores) {
      for (let i = 0; i < this.stores.length; i++) {
        this.stores[i].checked = value;
      }
    }
  }

  setPage(): void {
    this.productService
      .getPageCount(
        localStorage.getItem(LocalStorageTags.CompanyId),
        this.productFilter
      )
      .subscribe(
        (extractData) => (this.totalProducts = extractData[0].product_count.toString()),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          this.getCompanyProducts(null);
        }
      );

    this.currentPage = 0;
  }

  onResize(event: any): void {
    this.screenWidth = event.target.innerWidth;

    if (event.target.innerWidth < +this.changeDesignPixels) {
      this.hasTwoRows = true;
    } else if (this.hasTwoRows === true) {
      this.hasTwoRows = false;
    }
  }

  /** StorageCardRecords **/

  getStorageCardRecords(event: any): void {
    this.skuService
      .get(
        this.skuFilter,
        1,
        1000,
        this.companyId
      )
      .subscribe(
        (extractData) => (this.records = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          this.records.forEach((record) => { });
          this.setStorageCardRecords();
        }
      );
  }

  setStorageCardRecords(): void {
    if (!this.records) {
      return;
    }

    this.isRecordsLoaded = true;
  }

  addRecord() {
    this.isIngredientSubmitted = true;

    if (this.selectedItem === '' || !this.selectedRecord.Id) {
      this.autocompleteValidate = true;
    }

    if (this.validatorService.ingredientForm.invalid) {
      return;
    }

    if (!this.coefficient) { return; }
    if (!this.selectedRecord) { return; }
    if (!this.selectedRecord.Id) { return; }

    const rec = {
      Id: this.selectedRecord.Id,
      Name: this.selectedRecord.Name,
      Coefficient: this.coefficient,
      Unit: this.selectedRecord.StorageCardUnitName,
      WarehouseId: this.selectedRecord.WarehouseId,
    };

    this.ingredients.splice(0, 0, rec);
    this.ingredients = [...this.ingredients];

    this.ingredientsCount = this.ingredients.length;

    this.clearIngredient();
  }

  saveEdit(): void {
    this.isIngredientSubmitted = true;

    if (
      !this.selectedRecord.Id &&
      this.selectedRecord !== this.ingredient.Name
    ) {
      this.autocompleteValidate = true;
      return;
    }

    if (this.validatorService.ingredientForm.invalid) {
      return;
    }

    if (!this.coefficient) { return; }
    if (!this.selectedRecord) { return; }

    if (
      this.ingredient.Name !== this.selectedRecord.Name &&
      this.selectedRecord.Name !== undefined
    ) {
      this.addRecord();
      this.deleteRecord();
    } else if (
      this.ingredient.Name === this.selectedRecord &&
      this.selectedRecord.Name === undefined
    ) {
      const rec = {
        Id: this.ingredient.Id,
        Name: this.ingredient.Name,
        Coefficient: this.coefficient,
        Unit: this.ingredient.Unit,
        WarehouseId: this.ingredient.WarehouseId,
      };

      this.ingredients.splice(0, 0, rec);
      this.ingredients = [...this.ingredients];

      this.ingredientsCount = this.ingredients.length;

      this.deleteRecord();
    }
  }

  clearIngredient(): void {
    this.hasUnit = false;
    this.isIngredientSubmitted = false;
    this.ingredientEdit = false;
    this.coefficient = '';
    this.selectedRecord = '';
    this.selectedItem = '';
    this.autocompleteValidate = false;
    this.unselectIngredient();
  }

  /** WAREHOUSE **/

  changeWarehouse(id: number): void {
    this.selectedWarehouse = this.getSelectedWarehouse(id);
    localStorage.setItem('warehouseId', id.toString());
    this.getStorageCardRecords(null);
  }

  getSelectedWarehouse(selectedId: number): any {
    for (let i = 0; i < this.warehouses.length; i++) {
      if (this.warehouses[i].Id === selectedId) {
        return this.warehouses[i];
      }
    }

    return null;
  }

  setWarehouse(): void {
    if (this.warehouses != null && this.warehouses.length !== 0) {
      this.selectedWarehouse = this.warehouses[0];

      this.isWarehouseLoaded = true;
    }
  }

  getWarehouse(): void {
    this.warehousesService.getList().subscribe(
      (extractData) => {
        this.warehouses = extractData;
      },
      (handleError) => (this.errorMessage = <any>handleError),
      () => this.setWarehouse()
    );
  }

  /** AutoComplete **/

  onChangeSearch(val: string): void {
    if (val === '') {
      this.hasUnit = false;
      this.selectedItem = '';
    }
  }

  onFocused(e): void {
    // do something when input is focused
  }

  onInputCleared(): void {
    this.hasUnit = false;
    this.selectedItem = '';
  }

  selectEvent(item): void {
    console.log(item);
    this.selectedItem = item.Name;
    this.autocompleteValidate = false;

    this.hasUnit = true;
    this.ingredientUnit = item.StorageCardUnitName;
  }

  /** Ingredients **/
  onSelectRecord(): void {
    if (this.itemPosted) {
      return;
    }

    this.isHidden = false;
    this.isIngredientSubmitted = false;
    this.hasUnit = true;
    this.ingredientEdit = true;

    this.selectedRecord = this.ingredient.Name;
    this.coefficient = this.ingredient.Coefficient;
    this.ingredientUnit = this.ingredient.Unit;
    this.selectedWarehouse = this.getSelectedWarehouse(
      parseInt(this.ingredient.WarehouseId)
    );
  }

  prepareUpdateRecord(event: any): void {
    console.log(event);

    if (event.type !== 'click') {
      return;
    }

    if (event.row) {
      console.log(event.row);
      this.selectedRow = event;
      this.ingredient = event.row;
    }

    this.onSelectRecord();
  }

  deleteRecord(): void {
    for (let i = 0; i < this.ingredients.length; i++) {
      if (
        this.ingredients[i].Id === this.ingredient.Id &&
        this.ingredients[i] === this.ingredient
      ) {
        this.ingredients.splice(i, 1);
        this.ingredients = [...this.ingredients];
      }
    }

    this.clearIngredient();
  }

  postIngredients(): void {
    this.ingredientService
      .post(this.product.Id, this.ingredients)
      .subscribe(
        (extractData) => (this.tablePostedData = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          console.log('data posted');
        }
      );
  }

  putIngredients(): void {
    this.ingredientService
      .put(this.product.Id, this.ingredients)
      .subscribe(
        (extractData) => (this.tablePostedData = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          console.log('data posted');
        }
      );
  }

  getIngredients(): void {
    this.ingredientService
      .get(
        parseInt(this.product.Id.toString()),
        this.currentPage + 1,
        this.pageLimit
      )
      .subscribe(
        (extractData) => {
          this.ingredients = extractData;
          if (this.ingredients.length > 0) {
            this.post = true;
          } else {
            this.post = false;
          }
        },
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          console.log('data get');
        }
      );

    this.ingredients = [];
  }

  wasItemPosted(productId: number): void {
    let itemReceiptData: any;

    this.salesService.getProductReceipts(productId).subscribe(
      (extractData) => (itemReceiptData = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => {
        if (itemReceiptData.success == true) {
          this.itemPosted = true;
        } else {
          this.itemPosted = false;
        }
      }
    );
  }

  onPostChoice(): void {
    if (this.onChangeTab === false && this.post === false) {
      this.postIngredients();
    } else if (this.onChangeTab === false && this.post === true) {
      this.putIngredients();
    }
  }

  onTabChange(tab): void {
    if (tab.index === 1 && this.onChangeTab === true) {
      this.getIngredients();
      this.wasItemPosted(this.product.Id);
      this.onChangeTab = false;
    }
  }

  collapseAll(): void {
    this.isHidden = true;
  }

  expandAll(): void {
    this.isHidden = false;
  }

  singleSelectCheck(): object {
    return this.selected;
  }

  unselectCheck(): object {
    return (this.selected = []);
  }

  unselectIngredient(): any {
    return (this.selectedIngredient = []);
  }

  setActive(button: number): void {
    this.isValue = button;
  }

  isButtonActive(isActive: number): boolean {
    return this.isValue == isActive;
  }

  /** UNIT **/
  changeUnit(id: number): void {
    this.selectedUnit = this.getSelectedUnit(id);
  }

  getSelectedUnit(selectedId: number): any {
    for (let i = 0; i < this.units.length; i++) {
      if (this.units[i].Id === selectedId) {
        return this.units[i];
      }
    }

    return null;
  }

  setUnit(): void {
    if (this.units != null && this.units.length !== 0) {
      if (!this.selectedUnit) {
        this.selectedUnit = this.units[0];
      } else {
        this.units.forEach((element) => {
          if (element.Name === this.selectedUnit.Name) {
            this.selectedUnit.Id = element.Id;
          }
        });
      }
      this.isUnitLoaded = true;
      this.dropdownValidate = false;
      this.unName = '';
    }
  }

  groupClear(): void {
    this.unName = '';
    this.grName = '';
  }

  postUnit(name: string): void {
    this.storageCardUnitService
      .post(name, Number(localStorage.getItem(LocalStorageTags.WarehouseId)))
      .subscribe(
        (extractData) => (this.unitPostedData = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          if ((this.unitPostedData.succes = true)) {
            if (this.selectedUnit) {
              this.selectedUnit.Name = name;
            }
          }
          this.getUnit();
        }
      );
  }

  putUnitEdit(editName: string): void {
    this.storageCardUnitService
      .put(this.selectedUnit.Id, editName)
      .subscribe();
    if (this.selectedUnit.Id && editName) {
      this.selectedUnit.Name = editName;
      this.getUnit();
    }
  }

  onUnitDelete(): void {
    this.selectedUnit.Name = null;
  }

  getUnit(): void {
    this.storageCardUnitService
      .get(
        Number(localStorage.getItem('warehouseId')),
      )
      .subscribe(
        (extractData) => {
          this.units = extractData;
        },
        (handleError) => (this.errorMessage = <any>handleError),
        () => this.setUnit()
      );
  }

  /** IMPORT **/
  changeListener(e): void {
    this.openModalImport();
    this.csvService
      .parseCSV(e.srcElement.files)
      .then((data) => this.readCSV(data));
  }

  readCSV(data): void {
    for (let i = 1; i < data.length; i++) {
      if (data[i].length > data[0].length || data[i].length < data[0].length) {
        this.csvRecBug.push(data[i]);
      } else {
        this.csvRecords.push(data[i]);
      }
    }

    this.splitCSV();
  }

  splitCSV(): void {
    this.setRecordUnit(this.csvRecords).then((csvImportData) =>
      this.setRecordGroup(csvImportData)
    );
  }

  setRecordUnit(data: any[]): Promise<any> {
    return new Promise((resolve) => {
      data.forEach((importProduct) => {
        const importProductUnit = importProduct[CsvImportColumn.Unit];
        const matchUnit = this.units.find((unit) => unit.Name === importProductUnit);

        if (matchUnit) {
          importProduct[CsvImportColumn.UnitId] = matchUnit.Id;
        } else if (!matchUnit && data.length > 0) {
          importProduct[CsvImportColumn.Unit] = null;
          importProduct[CsvImportColumn.UnitId] = null;
        }
      });
      resolve(data);
    });
  }

  setRecordGroup(data: any[]): void {
    data.forEach((importProduct) => {
      const matchGroup = this.groups.find(
        (group) => group.Name === importProduct[CsvImportColumn.GroupName]
      );

      if (matchGroup) {
        importProduct[CsvImportColumn.BatchId] = matchGroup.Id;
      } else {
        importProduct[CsvImportColumn.GroupName] = null;
        importProduct[CsvImportColumn.BatchId] = null;
      }
    });

    this.setRecordTax(data);
  }

  // TODO: Move all csv related data processing to separate service and return products
  // Consider movign it to server side
  setRecordTax(data: any[]): void {
    data.forEach((importProduct) => {
      const matchTax = this.taxes.find(
        (tax) => tax.Percentage === parseInt(importProduct[CsvImportColumn.VatPercentage], 10)
      );

      if (matchTax) {
        importProduct[CsvImportColumn.TaxId] = matchTax.Id;
      }
    });

    this.csvRecords = data;
    this.csvLoading = true;
  }

  changeUnitImport(selectedId: number, productId: number): any {
    const matchUnit = this.units.find((unit) => unit.Id === selectedId);
    if (matchUnit) {
      this.csvRecords[productId][CsvImportColumn.UnitId] = matchUnit.Id;
      this.csvRecords[productId][CsvImportColumn.Unit] = matchUnit.Name;
    }
  }

  changeGroupImport(id: number, rowId: number): void {
    this.csvRecords[rowId][2] = this.getSelectedImportGroup(id, rowId);
  }

  getSelectedImportGroup(selectedId: number, productId: number): any {
    for (let i = 0; i < this.groups.length; i++) {
      if (this.groups[i].Id === selectedId) {
        this.csvRecords[productId][13] = this.groups[i].Id;
        return (this.csvRecords[productId][2] = this.groups[i].Name);
      }
    }
  }

  importProducts(): void {
    let prodMapping;
    this.checkedRecords = [];

    this.csvRecords
      .filter((csvRecord => csvRecord.checked))
      .forEach((csvRecord) => {
        const hasDuplicateRecord =
          this.checkedRecords.some((item) => item[CsvImportColumn.Name] === csvRecord[CsvImportColumn.Name]);
        if (!hasDuplicateRecord) {
          this.checkedRecords.push(csvRecord);
        }
      });

    const hasInvalidRecord = this.checkedRecords.some(
      (item) => !item[CsvImportColumn.Name]
        || !item[CsvImportColumn.Unit]
        || !item[CsvImportColumn.Plu]
        || item[CsvImportColumn.UnitId] === null
        || item[CsvImportColumn.UnitId] === undefined
        || item[CsvImportColumn.BatchId] === null
        || item[CsvImportColumn.BatchId] === undefined
    );

    if (hasInvalidRecord) {
      this.invalidImport = true;
      return;
    } else {
      this.invalidImport = false;
    }

    this.checkedRecords.forEach(importProduct => {
      const product: ProductRequest = {
        Id: -1,
        BatchId: importProduct[CsvImportColumn.BatchId],
        TaxId: importProduct[CsvImportColumn.TaxId],
        Text: importProduct[CsvImportColumn.Name],
        Price: importProduct[CsvImportColumn.Price],
        Unit: importProduct[CsvImportColumn.Unit],
        UnitId: importProduct[CsvImportColumn.UnitId],
        mobileWaiter: this.product.mobileWaiter,
        Barcode: importProduct[CsvImportColumn.Barcode],
        plu: importProduct[CsvImportColumn.Plu],
        Abbreviation: importProduct[CsvImportColumn.Abbreviation],
        IsHidden: this.product.IsHidden,
        IsStock: this.product.IsStock, // Nepoužívá se
        VariablePrice: this.product.VariablePrice,
        VariableName: this.product.VariableName,
        VariableQuantity: this.product.VariableQuantity,
        Kitchen: this.product.Kitchen,
        IsPackaging: this.product.IsPackaging,
        IsDiscount: this.product.IsDiscount,
        Discount: this.product.Discount,
        IsRedeemable: this.product.IsRedeemable,
        IsWeighted: this.product.IsWeighted,
        IsAdulthood: this.product.IsAdulthood,
        IsSelfcheckoutWeighted: this.product.IsSelfcheckoutWeighted,
      };

      this.productService.post(product).subscribe(
        (extractData) => (this.productPostedData = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          this.product.Id = this.productPostedData.newProductId;
          this.productService
            .postMap(this.product.Id, this.getCheckedStores())
            .subscribe(
              (extractData) => (prodMapping = extractData),
              (handleError) => (this.errorMessage = <any>handleError),
              () => {
                this.setPage();
                this.setDefaultFields(false);
              }
            );
        }
      );
    });
  }

  checkAll(event): void {
    this.csvRecords.forEach((x) => (x.checked = event.target.checked));
  }

  isAllChecked(): any {
    if (this.csvRecords !== undefined) {
      return this.csvRecords.every((x) => x.checked);
    }
  }

  updateImportFilter(importFilter): void {
    this.searchedProduct = importFilter;
    this.csvLoading = true;
  }

  clearImportModal(): void {
    this.csvLoading = false;
    this.invalidImport = false;
    this.searchedProduct = '';
    this.importFilter = '';
    this.filterValue = 0;
  }

  setActiveFilter(filter: number): void {
    this.filterValue = filter;
  }

  isFilterActive(isFilterActive: number): boolean {
    return this.filterValue == isFilterActive;
  }

  onImageSelect(imageBase64: string): void {
    this.imageBase64 = imageBase64;
  }
}
