import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../shared/http-handler.service';
import { catchError } from 'rxjs/operators';
import { SkuReporting } from '../../interfaces/sku-reporting/sku-reporting.model';

@Injectable({
  providedIn: 'root',
})
export class SkuReportingService {
  private storageReportingUrl: string = Centrala.URL + '/api/storageReporting';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  /**
   * Get storage card records (incomming, outgoing)
   * @param cardId {number}
   * @param dateFrom {number}
   * @param dateTo {number}
   * @param currentPage {number}
   * @param pageCount {number}
   */
  get(
    cardId: number,
    dateFrom: number,
    dateTo: number,
    currentPage: number,
    pageCount: number
  ): Observable<SkuReporting[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('cardId', cardId.toString())
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString())
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

    return this.httpClient
      .get<SkuReporting[]>(
        this.storageReportingUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
