import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../core/services/registration/registration.service';
import { Token } from '../admin-panel/models/token.model';
import { Router } from '@angular/router';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ServerResponse } from '../../core/interfaces/shared/server-response.model';

@Component({
  selector: 'activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnInit {
  copyrightYear: number = new Date().getFullYear();
  registrationData: ServerResponse = {
    action: '',
    success: false,
    message: '',
    code: null,
  };

  tokenData: Token;

  codeMessage: string;

  errorMessage: string;

  token: string; // -> var I want to init with my route parameter

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.setLanguage();
  }

  setLanguage(): void {
    this.translate.addLangs(['en', 'cs']);

    const browserLang = this.translate.getBrowserLang();

    if (this.isBrowserLanguageValid(browserLang, this.translate.getLangs())) {
      localStorage.setItem('defLang', browserLang);
      this.translate.setDefaultLang(browserLang);
      this.translate.use(browserLang);
    } else {
      localStorage.setItem('defLang', 'en');
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }

    this.translate.setDefaultLang(localStorage.getItem('defLang'));
    this.translate.use(localStorage.getItem('defLang'));
  }

  isBrowserLanguageValid(browserLang: string, langs: string[]): boolean {
    for (let i = 0; i < langs.length; i++) {
      if (langs[i] === browserLang) {
        return true;
      }
    }

    return false;
  }

  ngOnInit(): void {
    document.getElementById('button').style.visibility = 'hidden';
    document.getElementById('alertDiv').style.visibility = 'hidden';

    this.getToken();
    this.activate();
  }

  getToken(): void {
    this.route.params.subscribe((params) => {
      this.token = params['value'];
    }); // --> Name must match wanted paramter

    this.token = this.token.replace(/\|/g, '.');
  }

  activate(): void {
    this.registrationService.activate(this.token).subscribe(
      (extractData) => (this.registrationData = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => this.evaluateactivation()
    );
  }

  evaluateactivation(): void {
    document.getElementById('alertDiv').style.visibility = 'visible';
    document.getElementById('alertDiv').className =
      'alert alert-danger alert-dismissable';

    if (this.registrationData.code === 300) {
      document.getElementById('button').style.visibility = 'visible';
      document.getElementById('alertDiv').className =
        'alert alert-success alert-dismissable';
      this.codeMessage = 'CODE300';
    } else if (this.registrationData.code === 301) {
      this.codeMessage = 'CODE301';
    } else if (this.registrationData.code === 302) {
      this.codeMessage = 'CODE302';
    } else if (this.registrationData.code === 303) {
      this.codeMessage = 'CODE303';
    } else if (this.registrationData.code === -1) {
      this.codeMessage = 'CODE-1';
    }
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }
}
