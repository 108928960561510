import { PageableResponse } from './models/pageable-response.model';
import { PageableResponseAdapter } from './pageable-response.adapter';

export abstract class Adapter<T> {
    constructor(private pageableResponseAdapter: PageableResponseAdapter) {}

    abstract get(json: any): T;

    getList(arrayJson: any): T[] {
        return arrayJson.map((json) => this.get(json));
    }

    getPageable(pageableJson: any): PageableResponse<T> {
        return this.pageableResponseAdapter.get<T>(pageableJson, (json) =>
            this.get(json)
        );
    }
}
