import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputDialogOptions } from '../../../../core/interfaces/shared/input-dialog-options.model';
import { InputDialogComponent } from '../../input-dialog/input-dialog.component';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputDialogOptions,
    private dialogRef: MatDialogRef<InputDialogComponent>
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;

    this.setOptions();
  }

  setOptions(): void {
    this.dialogRef.disableClose = false;
    this.dialogRef.updateSize('500px');
    this.dialogRef.updatePosition({ top: '240px' });
  }

  onDeleteClick(): void {
      this.dialogRef.close(true);
  }
}
