import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Centrala } from '../../http/centrala';

export const WS_ENDPOINT = Centrala.wsURL;

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  wsUrl = Centrala.wsURL;

  webSocket: WebSocket;
  personCount: string;

  constructor() {}

  public openWebSocket() {
    this.webSocket = new WebSocket(this.wsUrl);

    this.webSocket.onopen = (event) => {
      console.log('Open: ', event);
    };

    this.webSocket.onmessage = (event) => {
      console.log(JSON.parse(event.data));
      const personCount = JSON.parse(event.data);
      if (!personCount.data.length) {
        this.personCount = personCount.data.text;
      } else {
        var position = personCount.data.length - 1;
        this.personCount = personCount.data[position].text;
      }
    };

    this.webSocket.onclose = (event) => {
      console.log('Close: ', event);
    };
  }

  public closeWebSocket() {
    this.webSocket.close();
  }
}
