import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StorageCardCategoryService } from '../../../core/services/storage-card-category/storage-card-category.service';
import { StoreCardCategory } from '../../../core/interfaces/store-card-category/store-card-category.model';
import { Warehouse } from '../../company-settings/models/warehouse.model';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { EditRecordResponse } from '../../../shared/models/edit-record-response.model';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InputDialogComponent } from '../../../shared/dialogs/input-dialog/input-dialog.component';

@Component({
  selector: 'app-sku-category-dropdown',
  templateUrl: './sku-category-dropdown.component.html',
})
export class SkuCategoryDropdownComponent implements OnInit {
  @Input() selectedWarehouse: Warehouse;
  @Input() selectedCategory: StoreCardCategory;
  @Input() isDropdownSet: boolean;
  @Output() categoryChange: EventEmitter<StoreCardCategory> = new EventEmitter<StoreCardCategory>();
  @Output() categoriesChange: EventEmitter<StoreCardCategory[]> = new EventEmitter<StoreCardCategory[]>();

  categoryList: StoreCardCategory[];
  isCategoryLoaded: boolean;

  readonly defaultInput: string = '';

  updateDialog: MatDialogRef<InputDialogComponent>;
  categoryPostResponse: CreateRecordResponse;
  categoryEditResponse: EditRecordResponse;

  error: string;

  constructor(
    private dialog: MatDialog,
    private storageCardCategoryService: StorageCardCategoryService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.setCategories();
  }

  onOpenCategoryPostDialogClick(): void {
    this.updateDialog = this.dialog.open(InputDialogComponent, {
      data: {
        title: this.translateService.instant('PRODUCTS.ADDGROUP'),
        value: this.defaultInput,
        isEdit: true,
      },
    });
    this.updateDialog.afterClosed().subscribe((input) => {
      if (!input) {
        return;
      }

      this.postCategory(input);
    });
  }

  onOpenCategoryEditDialogClick(): void {
    this.updateDialog = this.dialog.open(InputDialogComponent, {
      data: {
        title: this.selectedCategory.Name,
        value: this.selectedCategory.Name,
        isEdit: false,
      },
    });
    this.updateDialog.afterClosed().subscribe((input) => {
      if (!input) {
        return;
      }

      this.editCategory(input);
    });
  }

  selectedCategoryChange(category: StoreCardCategory): void {
    this.categoryChange.emit(category);
  }

  private postCategory(categoryName: string): void {
    this.storageCardCategoryService
      .post(
        categoryName,
        this.selectedWarehouse.Id
      )
      .subscribe({
        next: (successResponse) => {
          this.categoryPostResponse = successResponse;

          if (successResponse.success) {
            this.selectedCategory.Name = categoryName;
            this.setCategories();
          }

          this.snackBarService.show('PRODUCTS.ADDCATEGORYSUCCESS', successResponse.success);
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('PRODUCTS.ADDCATEGORYFAIL', false);
        },
      });
  }

  private editCategory(categoryName: string): void {
    this.storageCardCategoryService
      .put(
        this.selectedCategory.Id,
        categoryName
      )
      .subscribe({
        next: (successResponse) => {
          this.categoryEditResponse = successResponse;

          if (successResponse.success) {
            this.selectedCategory.Name = categoryName;
            this.setCategories();
          }

          this.snackBarService.show('PRODUCTS.EDITCATEGORYSUCCESS', successResponse.success);
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('PRODUCTS.EDITCATEGORYFAIL', false);
        },
      });
  }

  private setCategories(): void {
    this.storageCardCategoryService
      .get(
        this.selectedWarehouse.Id)
      .subscribe({
        next: (categoryList) => {
          this.categoryList = categoryList;
          this.isCategoryLoaded = true;

          this.categoriesChange.emit(this.categoryList);
          this.setCategory();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setCategory(): void {
    if (this.categoryList.length === 0) {
      this.isCategoryLoaded = false;
      return;
    }

    if (!this.selectedCategory) {
      this.selectedCategory = this.categoryList[0];
    }

    this.isDropdownSet = false;
  }
}
