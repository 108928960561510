<authentication-test></authentication-test>
<navigation selectedPage="contio-store">
  <div class="row no-gutters">
    <div class="col-12">
      <button
        class="normal-btn float-right"
        mat-button
        (click)="onGoToShopAnalyticsClick()"
      >
        {{ 'SHOP.ANALYTICS' | translate }}
      </button>
    </div>
  </div>

  <hr class="line" />
  <div class="row no-gutters w-100">
    <app-table
              *ngIf="isDataTableLoaded"
              [columns]="columns"
              [data]="entityList"
              [totalCount]="sunmiShopCount"
              [selectable]="true"
              (selectedRow)="onGoToContioStoreDetailClick($event)"
              (pageChange)="onPageChange($event)"
    ></app-table>
  </div>
</navigation>
