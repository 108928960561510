import { Component, Input, OnInit } from '@angular/core';
import { DateTimeService } from '../../../core/services/formatter/date-time.service';
import { SkuReportingService } from '../../../core/services/sku-reporting/sku-reporting.service';
import { ReportingService } from '../../../core/services/reporting/reporting.service';
import { NgbDateService } from '../../../core/services/formatter/ngb-date.service';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TableColumn } from '../../../core/interfaces/shared/table-columns.model';
import { SkuReporting } from '../../../core/interfaces/sku-reporting/sku-reporting.model';
import { TranslateService } from '@ngx-translate/core';
import { StorageCard } from '../../../core/interfaces/stock/storage-card.model';
import { Observable } from 'rxjs';
import { ListBaseDirective } from '../../../shared/components/authentication-test/list-base.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-sku-movement',
  templateUrl: './sku-record.component.html',
})
export class SkuRecordComponent
extends ListBaseDirective<SkuReporting>
implements OnInit {
  _selectedSku: StorageCard;

  @Input() set selectedSku(sku: StorageCard) {
    this._selectedSku = sku;
    this.isDataTableLoaded = false;
    this.setDatesForReportList();
  }

  readonly today: Date = new Date();
  readonly monthAgo: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
  readonly minDate: Partial<NgbDate> = {
    year: 2000,
    month: 1,
    day: 1,
  };

  readonly ngbDatepickerPlacement: string = 'bottom';
  maxDate: NgbDate;
  inceptionDate: NgbDate;
  finalDate: NgbDate;
  startDate: number;
  endDate: number;

  reportList: SkuReporting[];
  entityList: SkuReporting[] = [];
  getList$: Observable<SkuReporting[]>;
  isReportLoaded: boolean = false;

  columns: TableColumn[];
  activeButton: number;
  currentPage: number = 0;
  isDataTableLoaded: boolean = false;

  error: string;

  constructor(
    private reportingService: ReportingService,
    private dateTimeService: DateTimeService,
    private skuReportService: SkuReportingService,
    private dateService: NgbDateService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {
    super();
  }

  ngOnInit(): void {
    this.columns = this.getComlumns();
    this.setMaxDate();
  }

  onDownloadSkuReportsClick(): void {
    this.reportingService
      .card(
        this._selectedSku.Id,
        this.startDate,
        this.endDate,
        this._selectedSku.Name
      );
  }

  onSetDateFromFilterClick(
    startDate: NgbDate,
    dateTo: NgbDate
  ): void {
    this.isDataTableLoaded = false;

    this.startDate = this.dateTimeService.convertNgbDateToUnixTimestamp(startDate, false);
    this.endDate = this.dateTimeService.convertNgbDateToUnixTimestamp(dateTo, true);

    this.onPageChange(0);
  }

  onSetButtonActive(activeButton: number): void {
    this.activeButton = activeButton;
  }

  isButtonActive(activeButton: number): boolean {
    return this.activeButton === activeButton;
  }

  onPageChange(pageIndex: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.skuReportService
    .get(
      this._selectedSku.Id,
      this.startDate,
      this.endDate,
      this.currentPage,
      GridSetting.pageLimit
    );
    this.isDataTableLoaded = true;

    this.setList();
  }

  private getComlumns(): TableColumn[] {
    return [
      {
        columnDef: 'Operation',
        header: this.translateService.instant('SKU.SKUOPERATION'),
        cell: (element: SkuReporting) => `${element.Operation}`,
      },
      {
        columnDef: 'NoteDate',
        header: this.translateService.instant('SKU.SKUDATE'),
        cell: (element: SkuReporting) => `${this.datePipe.transform(element.NoteDate, 'd. M. y h:mm:ss')}`,
      },
      {
        columnDef: 'Quantity',
        header: this.translateService.instant('SKU.SKUQUANTITY'),
        cell: (element: SkuReporting) => `${element.Quantity}`,
      },
      {
        columnDef: 'UnitPrice',
        header: this.translateService.instant('SKU.UNITPRICE'),
        cell: (element: SkuReporting) => `${element.UnitPrice}`,
      },
      {
        columnDef: 'QuantityOnStorage',
        header: this.translateService.instant('SKU.ONSTORAGE'),
        cell: (element: SkuReporting) => `${element.QuantityOnStorage}`,
      },
    ];
  }

  private setDatesForReportList(): void {
    this.startDate = this.dateTimeService.getStartOfDayInUnixTimestamp(this.monthAgo);
    this.endDate = this.dateTimeService.getEndOfTheDayUnixTimestamp(this.today);
    this.onPageChange(0);
    this.inceptionDate = this.dateService.ngbDateFilter(this.startDate);
    this.finalDate = this.dateService.ngbDateFilter(this.endDate);
  }

  private setMaxDate(): void {
    this.maxDate = this.dateService.ngbDateMax();
  }
}
