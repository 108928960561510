import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateService } from '../../../core/services/formatter/ngb-date.service';
import { DateTimeService } from '../../../core/services/formatter/date-time.service';
import { ReturnPageable } from '../models/return-pageable.model';
import { Return } from '../models/return.model';
import { ReturnService } from '../return.service';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-return-list',
  templateUrl: './return-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './return-list.component.scss',
  ],
})
export class ReturnListComponent implements OnInit {
  today: Date = new Date();
  monthAgo: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
  readonly minDate = {
    year: 2000,
    month: 1,
    day: 1,
  };
  maxDate: NgbDate;
  startDateTimestamp: number;
  endDateTimestamp: number;
  readonly placement: string = 'bottom';

  activeButton: number = 0;
  dateFrom: NgbDate;
  dateTo: NgbDate;

  currentPage: number = 0;
  pageLimit: number = GridSetting.pageLimit;
  returnsCount: number = 0;
  returnList: Return[];

  error: string;

  constructor(
    private router: Router,
    private dateService: NgbDateService,
    private unixBoundsService: DateTimeService,
    private returnService: ReturnService,
    private localStorageService: LocalStorageService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.localStorageService.autenticateContioUser();

    this.setMaxDate();
    this.onGetAllReportClick();
  }

  onGetAllReportClick(): void {
    this.startDateTimestamp = this.unixBoundsService.getStartOfDayInUnixTimestamp(this.monthAgo);
    this.endDateTimestamp = this.unixBoundsService.getEndOfTheDayUnixTimestamp(this.today);
    this.setReturns();
    this.dateFrom = this.dateService.ngbDateFilter(this.startDateTimestamp);
    this.dateTo = this.dateService.ngbDateFilter(this.endDateTimestamp);
  }

  onGetReportByDateFilterClick(
    dateFrom: NgbDate,
    dateTo: NgbDate
  ): void {
    this.startDateTimestamp = this.unixBoundsService.getStartOfDayWithSubtractedDayInUnixTimestamp(dateFrom);
    this.endDateTimestamp = this.unixBoundsService.getEndOfDayWithSubtractedDayInUnixTimestamp(dateTo);
    this.setReturns();
  }

  setReturns(report?: ReturnPageable): void {
    if (report != null) {
      this.currentPage = report.offset;
    }

    const nextPage = this.currentPage + 1;
    this.returnService
      .get(
        this.startDateTimestamp,
        this.endDateTimestamp,
        nextPage,
        GridSetting.pageLimit
      )
      .subscribe({
        next: (returnList) => {
          this.returnList = returnList;
          this.getReturnPageCount();
          this.returnDateFormat();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onGoToReturnDetailClick(returnDetail: Return): void {
    this.router.navigate(['/returns/detail/' + returnDetail.Id]);
  }

  onSetButtonActiveClick(activeButton: number): void {
    this.activeButton = activeButton;
  }

  isButtonActive(activeButton: number): boolean {
    return this.activeButton === activeButton;
  }

  private setMaxDate(): void {
    this.maxDate = this.dateService.ngbDateMax();
  }

  private getReturnPageCount(): void {
    this.returnService
      .getPageCount(
        this.startDateTimestamp,
        this.endDateTimestamp
      ).subscribe({
        next: (returnCount) => {
          this.returnsCount = returnCount[0].totalCount;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private returnDateFormat(): void {
    if (this.returnList != null && this.returnList.length !== 0) {
      this.dateService.zuluReturnsFormatter(this.returnList);
    }
  }
}
