<authentication-test></authentication-test>
<navigation selectedPage="contio-store">
  <div class="row no-gutters">
    <div class="col-8">
      <div class="row no-gutters">
        <div class="col-12 page-header">
          {{ 'NAVIGATION.SETTINGS' | translate }}
        </div>
        <div class="col-12 address">Id: {{ storeId }}</div>
      </div>
    </div>

    <div class="col-4">
      <button
        type="button"
        class="btn btn-primary normal-btn float-right back-btn"
        (click)="onGoToContioShopDetailClick()"
      >
        {{ 'REGISTRATION.BACK' | translate }}
      </button>
    </div>
  </div>

  <hr class="line" />

  <mat-tab-group mat-stretch-tabs animationDuration="0ms">
    <mat-tab label="{{ 'PRODUCTS.INFORMATION' | translate }}">
      <div class="tab-content">
      <div *ngIf="store" class="row mt-3 mb-3">
        <div class="col-xl-6 col-sm-12">
          <div class="card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-12">
                  <h5 class="card-title mt-3 ml-3 h5-header text-left">
                    {{ 'SHOP.INTERVALTITLE' | translate }}
                  </h5>
                </div>
              </div>
              <div class="row no-gutters mt-2">
                <div class="input-group mb-3 mx-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{
                      'SHOP.INTERVAL' | translate
                    }}</span>
                  </div>
                  <input
                    [(ngModel)]="store.SecurityOpenDoorInterval"
                    type="number"
                    class="form-control normal-input"
                    step="1"
                    min="0"
                  />
                  <div class="input-group-append">
                    <button
                      type="text"
                      class="btn btn-primary save-btn float-right"
                      (click)="
                        onEditContioDoorIntervalClick(
                          store.SecurityOpenDoorInterval,
                          doorInterval
                        )
                      "
                    >
                      <i class="fas fa-save mt-1"></i>
                      {{ 'PRODUCTS.SAVE' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="store" class="row mt-3 mb-3">
        <div class="col-xl-6 col-sm-12">
          <div class="card">
            <div class="card-content">
              <div class="row no-gutters">
                <div class="col-12">
                  <h5 class="card-title mt-3 ml-3 h5-header text-left">
                    {{ 'SHOP.ENTRANCEINTERVALTITLE' | translate }}
                  </h5>
                </div>
              </div>
              <div class="row no-gutters mt-2">
                <div class="input-group mb-3 mx-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{
                      'SHOP.INTERVAL' | translate
                    }}</span>
                  </div>
                  <input
                    [(ngModel)]="store.EntranceOpenDoorInterval"
                    type="number"
                    class="form-control normal-input"
                    step="1"
                    min="0"
                  />
                  <div class="input-group-append">
                    <button
                      type="text"
                      class="btn btn-primary save-btn float-right"
                      (click)="
                        onEditContioDoorIntervalClick(
                          store.EntranceOpenDoorInterval,
                          lockInterval
                        )
                      "
                    >
                      <i class="fas fa-save mt-1"></i>
                      {{ 'PRODUCTS.SAVE' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="contioStore && verificationLevels.length > 0"
        class="row mt-3 mb-3"
      >
        <div class="col-xl-6 col-sm-12">
          <div class="card">
            <div class="row no-gutters">
              <div class="col-12">
                <h5 class="card-title mt-3 ml-3 h5-header text-left">
                  {{ 'STORES.VERIFICATIONLEVEL' | translate }}
                </h5>
              </div>
            </div>
            <app-dropdown
              class="ml-3 mb-3"
              [(selected)]="selectedVerificationLevel"
              (selectedChange)="
                onVerificationLevelChange(selectedVerificationLevel)
              "
              label="{{ 'STORES.LEVEL' | translate }}"
              itemKey="Id"
              [items]="verificationLevels"
            ></app-dropdown>
          </div>
        </div>
      </div>

      <div *ngIf="contioStore" class="row mt-3 mb-3">
        <div class="col-xl-6 col-sm-12">
          <div class="card">
            <div class="row no-gutters">
              <div class="col-12">
                <h5 class="card-title mt-3 ml-3 h5-header text-left">
                  {{ 'STORES.GENERAL' | translate }}
                </h5>
              </div>
            </div>
            <div class="row no-gutters mt-2">
              <div class="input-group mx-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{
                    'SHOP.AGE' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="contioStore.MinimumAge"
                  type="number"
                  class="form-control normal-input"
                  step="1"
                  min="0"
                />
              </div>
              <app-checkbox
                class="ml-3 mt-2"
                label="{{ 'SHOP.MANUALREG' | translate }}"
                labelPosition="after"
                [checked]="contioStore.IsManualRegistration"
                (checkedChange)="onCheckChange($event)"
              ></app-checkbox>
              <div class="input-group mx-3 mt-2 mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ 'SHOP.CONDITIONSURL' | translate }}</span>
                </div>
                <input
                  [(ngModel)]="contioStore.ConditionsUrl"
                  type="string"
                  class="form-control normal-input"
                />
              </div>
              <div class="input-group mx-3 mt-2 mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ 'SHOP.ESHOPURL' | translate }}</span>
                </div>
                <input
                  [(ngModel)]="contioStore.EshopUrl"
                  type="string"
                  class="form-control normal-input"
                />
              </div>
              <div class="input-group mx-3 mt-2 mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{
                    'SHOP.LATITUDE' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="contioStore.Latitude"
                  (ngModelChange)="onGeolocationChanged()"
                  type="number"
                  class="form-control normal-input"
                />
              </div>
              <div class="input-group mx-3 mt-2 mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{
                    'SHOP.LONGITUDE' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="contioStore.Longitude"
                  (ngModelChange)="onGeolocationChanged()"
                  type="number"
                  class="form-control normal-input"
                />
              </div>
              <app-checkbox
                [hidden]="!contioStore.Latitude || !contioStore.Longitude"
                class="ml-3 mt-2"
                label="{{ 'SHOP.SCANANDGOALLOWED' | translate }}"
                labelPosition="after"
                [checked]="contioStore.IsScanAndGoAllowed"
                (checkedChange)="onScanAndGoChange($event)"
            ></app-checkbox>
              <div class="input-group mx-3 mt-2 mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{
                    'SHOP.GOPAYID' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="contioStore.GoPayId"
                  type="number"
                  class="form-control normal-input"
                />
              </div>
              <div class="input-group mx-3 mt-2 mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{
                    'SHOP.GOPAYCLIENTID' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="contioStore.GoPayClientId"
                  type="number"
                  class="form-control normal-input"
                />
              </div>
              <div class="input-group mx-3 mt-2 mb-2">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{
                    'SHOP.GOPAYCLIENTSECRET' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="contioStore.GoPayClientSecret"
                  type="text"
                  class="form-control normal-input"
                />
              </div>
            </div>
            <div class="row no-gutters mt-2 mb-3 mx-3">
              <div class="col-lg-12">
                <button
                  type="text"
                  class="btn btn-primary save-btn float-right"
                  (click)="
                    onGeneralSettingsSaveClick(
                      contioStore.MinimumAge,
                      contioStore.IsManualRegistration,
                      contioStore.ConditionsUrl,
                      contioStore.EshopUrl,
                      contioStore.IsScanAndGoAllowed,
                      contioStore.Latitude,
                      contioStore.Longitude,
                      contioStore.GoPayId,
                      contioStore.GoPayClientId,
                      contioStore.GoPayClientSecret
                    )
                  "
                >
                  <i class="fas fa-save mt-1"></i>
                  {{ 'PRODUCTS.SAVE' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </mat-tab>

    <mat-tab class="tab-content" label="{{ 'SHOP.LOGO' | translate }}">
      <app-image-upload
        *ngIf="contioStore"
        #imageUploadComponent
        [image]="contioStore.Logo"
        (imageBase64)="onImageSelect($event)"
      ></app-image-upload>
    </mat-tab>
  </mat-tab-group>
</navigation>
