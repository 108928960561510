import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
    transform(value: number): string {

        var charArr = value.toString().split('');

        for (var i = 1; i <= value.toString().length; i++) {
            if (i % 3 === 0) {
                charArr.splice(i + (i / 3) - 1, 0, ' ');
            }
        }

        return charArr.join('');
    }
}
