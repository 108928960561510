import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['../../../../../assets/scss/input.scss'],
})
export class InputSelectComponent implements OnInit {
  selectedItem: string;

  @Input() items: string[];
  @Input() label: string;
  @Input() isPipeForActivityType?: boolean;

  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void {
    this.selectedItem = this.items[0];
  }

  onChange(selectedItem?: string): void {
    this.inputChange.emit(selectedItem);
  }
}
