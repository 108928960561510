import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../shared/http-handler.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IngredientService {
  private recipeUrl: string = Centrala.URL + '/api/recipe';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  /**
   * Create ingredients for product
   * @param productId {number}
   * @param ingredients {any} list of ingredients
   */
  post(
    productId: number,
    ingredients: any
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.recipeUrl,
        {
          ProductId: productId,
          Ingrediences: ingredients,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        {headers}
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Update product ingredients
   * @param productId {number}
   * @param ingredients {any} list of ingredients
   */
  put(
    productId: number,
     ingredients: any
     ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put(
        this.recipeUrl,
        {
          ProductId: productId,
          Ingrediences: ingredients,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        {headers}
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Get product ingredients
   * @param productId {number} Product ID
   * @param currentPage {number} current page
   * @param pageCount {number} page count
   */
  get(
    productId: number,
    currentPage: number,
    pageCount: number
  ): Observable<any[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('productId', productId.toString())
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

    return this.httpClient
      .get<any[]>(
        this.recipeUrl, {params}
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
