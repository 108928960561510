import { Component, OnInit } from '@angular/core';
import { SunmiService } from '../../../../core/services/sunmi/sunmi.service';
import { SunmiSettings } from '../../../../../app/core/interfaces/esl-settings';
import { SunmiShop } from './models/sunmi-shop.model';
import { SunmiEnviroment } from '../../../../../app/core/enums/sunmi-enviroment.enum';
import { PostResponse } from '../../../../core/interfaces/post-response.model';
import { ContioService } from '../../../../modules/contio-store/contio.service';
import { GridSetting } from '../../../../shared/models/grid-setting.model';
import { SunmiSettingsEnvironment } from './models/sunmi-settings-enviroment.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../core/services/shared/snackbar.service';

@Component({
  selector: 'app-sunmi-shop',
  templateUrl: './sunmi-shop.component.html',
  styleUrls: ['./sunmi-shop.component.scss'],
})
export class SunmiShopComponent implements OnInit {
  sunmiSettings: SunmiSettings;
  error: string;
  enviromentList: SunmiSettingsEnvironment[] = [];
  selectedEnviroment: SunmiSettingsEnvironment;
  contioStores: SunmiShop[];
  selectedShop: SunmiShop;
  isShopVisible: boolean = true;
  isProgressBarVisible: boolean = false;
  sunmiShops: SunmiShop[];
  synchronizeState: boolean;
  postResponse: PostResponse;
  isSettingsAlertVisible: boolean = false;
  readonly esl_settings = 'esl_settings';
  readonly create_shop = 'create_shop';

  constructor(
    private sunmiService: SunmiService,
    private contioService: ContioService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.prepareNewSunmiShop();

    Object.keys(SunmiEnviroment)
      .map(key => {
        const enviroment: SunmiSettingsEnvironment = { Enviroment: SunmiEnviroment[key] };
        this.enviromentList.push(enviroment);
      });

    this.selectedEnviroment = this.enviromentList[0];

    this.getEslSettings();
    this.getContioStores();
  }

  onUpdateSunmiSettingsClick(): void {
    this.sunmiSettings.Enviroment = this.selectedEnviroment.Enviroment;

    this.sunmiService
      .postSettings(this.sunmiSettings)
      .subscribe({
        next: (settingsResponse) => {
          this.postResponse = settingsResponse;

          if (settingsResponse.success) {
            this.getEslSettings();
          }

          this.showAlert(settingsResponse.success);
        },
        error: (error) => {
          this.error = error;

          this.showAlert(false);
        },
      });
  }

  getEslSettings(): void {
    this.sunmiService
      .getSettings()
      .subscribe({
        next: (eslSettings) => {
          if (eslSettings) {
            this.sunmiSettings = eslSettings;
            this.selectedEnviroment = this.enviromentList.find(enviroment => enviroment.Enviroment === eslSettings.Enviroment);
            this.isShopVisible = false;

            this.getSunmiShops();
          } else {
            this.prepareNewSunmiShop();
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  getContioStores(): void {
    this.contioService
      .getList(
        GridSetting.firstPage,
        GridSetting.listLimit
      )
      .subscribe({
        next: (contioStores) => {
          this.contioStores = contioStores;
          if (contioStores) {
            this.getSunmiShops();
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onSelectStore(shop: SunmiShop): void {
    this.selectedShop = shop;
  }

  postSunmiShop(): void {
    this.sunmiService
      .postSunmiShop(this.selectedShop, this.sunmiSettings)
      .subscribe({
        next: (shopPostResponse) => {
          this.postResponse = shopPostResponse;

          if (shopPostResponse.success) {
            this.getContioStores();
          }

          this.showAlert(shopPostResponse.success);
        },
        error: (error) => {
          this.error = error;

          this.showAlert(false);
        },
      });
  }

  getSunmiShops(): void {
    this.sunmiService
      .getSunmiShops()
      .subscribe({
        next: (sunmiShops) => {
          this.sunmiShops = sunmiShops;

          if (sunmiShops) {
            this.setStoreBinding();
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  setStoreBinding(): void {
    let merged = [];

    this.contioStores.forEach((contio) => {
      merged.push({
        ...contio,
        ...this.sunmiShops.find((item) => item.StoreId === contio.StoreId),
      });
    });

    this.contioStores = merged;

    if (this.selectedShop.StoreId) {
      this.selectedShop = this.contioStores.find(
        (shop) => shop.StoreId === this.selectedShop.StoreId
      );
    }
  }

  onSynchronizeProductClick(): void {
    this.isProgressBarVisible = true;
    this.sunmiService
      .uploadProducts(this.selectedShop, this.sunmiSettings)
      .subscribe({
        next: (uploadResponse) => {
          this.synchronizeState = uploadResponse;

          if (uploadResponse) {
            this.isProgressBarVisible = false;
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private showAlert(success: boolean): void {
    if (success) {
      this.snackBarService.show(this.translateService.instant('ADMIN.SUCCESS'), true);
      return;
    }

    this.snackBarService.show(this.translateService.instant('ADMIN.FAIL'), false);
  }

  private prepareNewSunmiShop(): void {
    this.selectedShop = {
      ContactPerson: '',
      Phone: '',
    };

    this.sunmiSettings = {
      AppId: '',
      SecretKey: '',
      SunmiCompanyNo: '',
      Enviroment: SunmiEnviroment.uat,
    };
  }
}
