import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { Centrala } from '../../http/centrala';
import { saveAs } from 'file-saver';
import { ListResponse } from '../../interfaces/shared/list-response.interface';
import { CashRecord } from '../../interfaces/cash-record/cash-record.interface';

@Injectable({
  providedIn: 'root'
})
export class CashRecordService {
  private cashRecordUrl: string = Centrala.URL + '/api/cash-record/filter';
  private cashRecordCsvUrl: string = Centrala.URL + '/api/cash-record/csv';

  constructor(
    private httpClient: HttpClient,
  ) { }

  getCashRecord(cashRegisterId: number, currencyId: number, userId: number, page_count?: number, current_page?: number, dateFrom?: Date, dateTo?: Date, allRecords?: boolean) {
    const body = {
      page_count: page_count,
      current_page: current_page,
      cashRegisterId: cashRegisterId,
      currencyId: currencyId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      allRecords: allRecords,
      userId: userId,
      token: localStorage.getItem(LocalStorageTags.Token)
    }

    return this.httpClient
      .post<ListResponse<CashRecord>>(
        this.cashRecordUrl, body
      )
  }

  getCashRecordCSV(cashRegisterId: number, currencyId: number, userId: number, page_count?: number, current_page?: number, dateFrom?: Date, dateTo?: Date, allRecords?: boolean) {
    const body = {
      page_count: page_count,
      current_page: current_page,
      cashRegisterId: cashRegisterId,
      currencyId: currencyId,
      dateFrom: dateFrom,
      dateTo: dateTo,
      allRecords: allRecords,
      userId: userId,
      token: localStorage.getItem(LocalStorageTags.Token)
    }

    return this.httpClient
      .post<Blob>(
        this.cashRecordCsvUrl, body, { responseType: 'blob' as 'json' }
      ).subscribe((response: Blob) => {
        const blob = new Blob([response], { type: 'text/csv' });
        saveAs(blob, 'cashRecord.csv');
      });
  }
}
