import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from '../dashboard/dashboard.service';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { AdminService } from '../admin-panel/admin.service';
import { Company } from '../company-settings/models/company.model';
import { StockRoute } from '../stock/stock-list/stock-route.enum';
import { TwilioRoute } from '../twilio/twilio-route.enum';
import { SubaccountRoute } from '../subaccounts/subaccount-route.enum';
import { Privilege } from '../subaccounts/models/privilege.model';
import { PrivilegeType } from '../subaccounts/privilege-type.enum';
import { SubaccountValue } from '../subaccounts/subaccount-value.enum';
import { ResponsiveSetting } from '../../shared/models/responsive-setting.model';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  public stockRoute = StockRoute;
  public twilioRoute = TwilioRoute;
  public subaccountRoute = SubaccountRoute;

  @Input() selectedPage: string;

  @ViewChild('scroller') private scroller: ElementRef;

  bannerData: any;

  isBannerDataLoaded: boolean = false;

  userId: number;
  userName: string = '';
  isAdminLogin: boolean = false;
  isContioOwner: boolean = false;
  usernameAbbreviated: string = '';

  errorMessage: string;
  selectedCompany: Company;

  privilegeType: any = PrivilegeType;
  privilegeValue: any = SubaccountValue;
  privilegeList: Privilege[];

  companyData: Company[];

  isMobileMenuVisible: boolean = false;
  isReducedMenuVisible: boolean = false;
  screenWidth: number = 0;
  screenHeight: number = 0;

  constructor(
    private router: Router,
    public translateService: TranslateService,
    private dashboardService: DashboardService,
    private adminService: AdminService,
    private localStorageService: LocalStorageService,
  ) {
    this.setLanguage();
  }

  setLanguage() {
    this.translateService.addLangs(['cs', 'de', 'en', 'sk']);

    const hasDefaultLanguage = this.translateService.getDefaultLang();

    if (hasDefaultLanguage) {
      this.translateService.use(this.translateService.getDefaultLang());
      return;
    }

    // Set user language
    const defaultLanguage = localStorage.getItem('defLang');

    if (defaultLanguage) {
      this.translateService.setDefaultLang(defaultLanguage);
      this.translateService.use(defaultLanguage);
      return;
    }

    const browserLang = this.translateService.getBrowserLang();
    const isBrowserLanguageValid = this.isBrowserLanguageValid(
      browserLang,
      this.translateService.getLangs()
    );

    if (isBrowserLanguageValid) {
      // Translation for browser language was found

      localStorage.setItem('defLang', browserLang);
      this.translateService.setDefaultLang(browserLang);
      this.translateService.use(browserLang);
    } else {
      // Default to english

      localStorage.setItem('defLang', 'en');
      this.translateService.setDefaultLang('en');
      this.translateService.use('en');
    }
  }

  isBrowserLanguageValid(
    browserLanguage: string,
    translatedLanguages: string[]
  ) {
    translatedLanguages.forEach(translatedLanguage => {
      if (translatedLanguage === browserLanguage) {
        return true;
      }
    });

    return false;
  }

  translateChange(language: string) {
    this.translateService.use(language);
    this.translateService.setDefaultLang(language);

    localStorage.setItem('defLang', language);

    if (this.selectedPage === 'sales') {
      window.location.reload();
    }
  }

  logout(): void {
    localStorage.clear();
    this.goToLogin();
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }

  async ngOnInit(): Promise<void> {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    if (this.screenHeight < ResponsiveSetting.reduceMenuPixelThreshold) {
      this.isReducedMenuVisible = true;
    } else {
      this.isReducedMenuVisible = false;
    }

    this.bannerData = null;

    this.companyData = JSON.parse(localStorage.getItem(LocalStorageTags.CompanyList));

    if (!localStorage.getItem(LocalStorageTags.CompanyId)) {
      localStorage.setItem(
        LocalStorageTags.CompanyId,
        this.companyData[0].Id.toString()
      );
    }

    this.selectedCompany = this.getSelectedCompany(
      +localStorage.getItem(LocalStorageTags.CompanyId)
    );

    this.userName = localStorage.getItem(LocalStorageTags.Email);

    if (this.userName.length > 20) {
      this.usernameAbbreviated = this.userName.substring(
        0,
        this.userName.indexOf('@')
      );
    } else {
      this.usernameAbbreviated = this.userName;
    }

    if (this.selectedPage === 'dashboard') {
      this.getBannerData();
    }

    this.userId = this.localStorageService.getUserId();

    if (this.userId === Centrala.AdminId) {
      this.isAdminLogin = true;
    }

    this.getPrivilege();

    await this.getUserInfo();
  }

  async getUserInfo(): Promise<boolean> {
    return await new Promise((resolve) => {
      this.adminService
        .getUser(this.userId)
        .subscribe((extractData) => {
          resolve(this.isContioOwner = extractData[0].IsContio);
        });
    });
  }

  getPrivilege(): void {
    this.privilegeList = JSON.parse(localStorage.getItem(LocalStorageTags.Privilege));
  }

  getBannerData(): void {
    this.dashboardService
      .getNews(this.translateService.getDefaultLang())
      .subscribe(
        (extractData) => (this.bannerData = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          this.isBannerDataLoaded = true;
        }
      );
  }

  changeCompany(id: number) {
    localStorage.setItem(LocalStorageTags.CompanyId, id.toString());
    this.selectedCompany = this.getSelectedCompany(id);

    window.location.reload();
  }

  getSelectedCompany(selectedId: number) {
    for (let i = 0; i < this.companyData.length; i++) {
      if (this.companyData[i].Id === selectedId) {
        return this.companyData[i];
      }
    }
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.screenHeight = event.target.innerHeight;

    if (this.screenWidth > ResponsiveSetting.mobileMenuPixelThreshold) {
      this.isMobileMenuVisible = false;
    }

    if (this.screenHeight < ResponsiveSetting.reduceMenuPixelThreshold) {
      this.isReducedMenuVisible = true;
    } else {
      this.isReducedMenuVisible = false;
    }
  }

  collapseClick() {
    if (
      this.isMobileMenuVisible &&
      this.scroller.nativeElement.scrollTop < 20
    ) {
      this.isMobileMenuVisible = false;
    } else {
      this.isMobileMenuVisible = true;

      document.getElementById(
        this.selectedPage + '-mobile'
      ).style.backgroundColor = '#dedede';
    }

    if (this.scroller.nativeElement.scrollTop > 20) {
      this.scroller.nativeElement.scrollTop = 0;
    }
  }
}
