import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableService } from './table.service';
import { ZoneService } from './zone.service';
import { StoreService } from '../company-settings/store.service';
import { ValidatorService } from '../../core/services/validator/validator.service';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { GridSetting } from '../../shared/models/grid-setting.model';
import { Zone } from '../../core/interfaces/zone/zone.model';
import { finalize } from 'rxjs/operators';
import { Store } from '../company-settings/models/store.model';
import { SnackBarService } from '../../core/services/shared/snackbar.service';
import { TablePageable } from './models/table-pageable.model';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';
import { Table } from './models/table.model';
import { EditRecordResponse } from '../../shared/models/edit-record-response.model';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';

@Component({
  selector: 'app-table-component',
  templateUrl: './tables.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./tables.component.scss'],
})
export class TablesComponent implements OnInit {
  selectedTableId: number;
  companyId: number;

  isZoneLoaded: boolean = false;
  isInputGroupHidden: boolean = true;
  isEdit: boolean = false;
  isFormSubmited: boolean = false;

  table: Table;
  zoneInput: string = '';
  isSelectedStoreValidate: boolean = false;

  storeList: Store[];
  selectedStore: Store;
  isStoreLoaded: boolean;

  zoneList: Zone[];
  selectedZone: Zone;

  tableList: Table[];

  selectedRow: Table[] = [];

  currentPage: number = 0;
  tableCount: number = 0;
  pageLimit: number = GridSetting.pageLimit;

  tablePostResponse: CreateRecordResponse;
  tableEditResponse: CreateRecordResponse;
  zonePostResponse: CreateRecordResponse;
  zoneEditResponse: EditRecordResponse;

  error: string;

  constructor(
    private modalService: NgbModal,
    private tableService: TableService,
    private zoneService: ZoneService,
    private storeService: StoreService,
    private validatorService: ValidatorService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.companyId = this.localStorageService.getCompanyId();
    this.validatorService.tableValidator();

    this.getStores();
  }

  onPrepareNewTableClick(): void {
    this.isSelectedStoreValidate = true;

    if (!this.selectedStore) {
      return;
    }

    this.table = new Table({
      id: 0,
      tableCategory: {},
    });

    this.getZones();

    this.isInputGroupHidden = false;
    this.isEdit = false;
    this.isFormSubmited = false;
  }

  onChangeStoreClick(id: number): void {
    this.selectedStore = this.storeList.find(store => store.Id === id);
    this.getZones();
    this.getTablePageCount();
  }

  onZoneTableSelect(table: Table): void {
    this.isInputGroupHidden = false;
    this.isEdit = true;

    this.table = table;
    this.selectZoneByName(table.tableCategory.name);
  }

  getStoreTables(tablePageable?: TablePageable): void {
    if (tablePageable != null) {
      this.currentPage = tablePageable.offset;
    }

    const nextPage = this.currentPage + 1;
    this.tableService
      .getList(
        this.selectedStore.Id,
        nextPage,
        this.pageLimit
      )
      .subscribe({
        next: (tableList) => {
          this.tableList = tableList;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onChangeZoneClick(zone: Zone): void {
    this.selectedZone = zone;
  }

  onTableAddClick(): void {
    this.isFormSubmited = true;

    if (this.validatorService.tableForm.invalid) {
      return;
    }

    this.tableService
      .post(
        this.selectedZone.Id,
        this.table.tableCategory.number
      )
      .pipe(
        finalize(() => {
          this.tablePostResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          if (successResponse.success) {
            this.isInputGroupHidden = true;
            this.tablePostResponse = successResponse;

            this.onPrepareNewTableClick();
            this.getTablePageCount();

            this.snackBarService.show('TABLE.TABLEADDSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('TABLE.TABLEADDFAIL', false);
        },
      });
  }

  onTableEditClick(): void {
    this.isFormSubmited = true;

    if (this.validatorService.tableForm.invalid) {
      return;
    }

    this.tableService
      .put(
        this.table.id,
        this.table.tableCategory.id,
        this.table.tableCategory.number
      )
      .pipe(
        finalize(() => {
          this.tableEditResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          if (successResponse.success) {
            this.tableEditResponse = successResponse;

            this.hideForm();
            this.getTablePageCount();

            this.snackBarService.show('TABLE.TABLEEDITSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('TABLE.TABLEEDITFAIL', false);
        },
      });
  }

  onOpenZoneAddModalClick(content: string): void {
    this.modalService
      .open(
        content,
        { windowClass: 'dark-modal' }
      );
  }

  onOpenZoneEditModalClick(zoneEdit: string): void {
    this.modalService
      .open(
        zoneEdit,
        { windowClass: 'dark-modal' }
      );
  }

  onZoneInputClearClick(): void {
    this.zoneInput = '';
  }

  onZonePostClick(zoneName: string): void {
    this.zoneService
      .post(
        zoneName,
        this.selectedStore.Id
      )
      .pipe(
        finalize(() => {
          this.zonePostResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          if (successResponse.success) {
            this.zonePostResponse = successResponse;

            this.getZones();
            this.selectZoneByName(zoneName);

            this.snackBarService.show('TABLE.ZONEADDSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('TABLE.ZONEADDFAIL', false);
        },
      });
  }

  onZoneEditClick(zoneName: string): void {
    this.zoneService
      .put(
        zoneName,
        this.selectedZone.Id
      )
      .pipe(
        finalize(() => {
          this.zoneEditResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          if (successResponse.success) {
            this.zoneEditResponse = successResponse;

            this.getZones();
            this.selectZoneByName(zoneName);
            this.getTablePageCount();

            this.snackBarService.show('TABLE.ZONEEDITSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('TABLE.ZONEEDITFAIL', false);
        },
      });
  }

  private hideForm(): void {
    this.isInputGroupHidden = true;
  }

  private setStore(): void {
    if (this.storeList.length === 0) {
      return;
    }

    if (!this.selectedStore) {
      this.selectedStore = this.storeList[0];
    }

    this.isStoreLoaded = true;
    this.getZones();
    this.getTablePageCount();
  }

  private getStores(): void {
    this.storeService
      .getList(this.companyId)
      .subscribe({
        next: (storeList) => {
          this.storeList = storeList;
          this.setStore();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setZone(): void {
    if (this.zoneList.length === 0) {
      this.selectedZone = null;
      this.isZoneLoaded = false;
      return;
    }

    this.selectedZone = this.zoneList[0];
    this.isZoneLoaded = true;
    this.zoneInput = '';
  }

  private selectZoneByName(zoneName: string): void {
    this.selectedZone = this.zoneList.find(zone => zone.Name === zoneName);
  }

  private getZones(): void {
    this.zoneService
      .getList(this.selectedStore.Id)
      .subscribe({
        next: (zoneList) => {
          this.zoneList = zoneList;
          this.setZone();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private getTablePageCount(): void {
    this.tableService
      .getPageCount(this.selectedStore.Id)
      .subscribe({
        next: (tableCount) => {
          this.tableCount = tableCount[0].table_count;
          this.getStoreTables();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
