import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SaleService } from '../../core/services/sale/sale.service';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ReportingService } from '../../core/services/reporting/reporting.service';
import { StoreService } from '../company-settings/store.service';
import { NgbDateService } from '../../core/services/formatter/ngb-date.service';
import { DateFormatterService } from '../../core/services/formatter/sales/date-formatter.service';
import { WarehouseService } from '../company-settings/warehouse.service';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';
import { Currency } from '../../core/interfaces/currency.model';
import { CsvService } from '../../core/services/csv/csv.service';

@Component({
  selector: 'sales',
  templateUrl: './sales.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './sales.component.scss',
  ],
  providers: [],
})
export class SalesComponent implements OnInit {
  isAuthentication: any;
  companyId: number;

  minDate = { year: 2000, month: 1, day: 1 };
  maxDate: any;
  placement = 'bottom-right';

  activeFilter = '';

  isValue: number = 0;

  dateFrom: any;
  dateTo: any;

  receiptCount: any = 0;
  receiptsData: any[];
  loaded = false;

  screenWidth = 0;
  pageLimit: number;
  curPage: number;

  afterUploadData: any;
  errorMessage: string;

  filterBy = {
    from: 0,
    to: 0,
    eet: [],
    storno: false,
    method: [''],
    allRecords: false,
  };

  fromTime: NgbTimeStruct;
  tillTime: NgbTimeStruct;

  cash: true;
  card: true;
  invoice: true;
  bitcoin: true;
  storno: boolean;
  whEET: true;
  nocode: true;
  ok: true;

  //Warehouse
  selectedWarehouse: any;
  isWarehouseLoaded: any;
  warehousePostedData: any;
  warehouseValidate = false;
  warehouses: any[];

  selectedStore: any;
  isStoreDataLoaded: boolean;
  stores: any[];

  currencies: Currency[] = [{ 'id': 1, 'name': 'CZK' }, { 'id': 2, 'name': 'EUR' }];
  selectedCurrency: Currency;

  constructor(
    private salesService: SaleService,
    config: NgbTimepickerConfig,
    private router: Router,
    private reportingService: ReportingService,
    private storeService: StoreService,
    private dateService: NgbDateService,
    private warehousesService: WarehouseService,
    private dateFormatterService: DateFormatterService,
    private localStorageService: LocalStorageService,
    private csvService: CsvService
  ) {
    this.cash = true;
    this.card = true;
    this.invoice = true;
    this.bitcoin = true;
    this.storno = false;
    this.whEET = true;
    this.nocode = true;
    this.ok = true;

    this.fromTime = { hour: 0o0, minute: 0o00, second: 0o00 };
    this.tillTime = { hour: 23, minute: 59, second: 59 };
    config.seconds = true;
    config.spinners = false;
    config.size = 'small';
  }

  authenticationResult(event: object) {
    this.isAuthentication = event;
  }

  ngOnInit(): void {
    this.companyId = this.localStorageService.getCompanyId();
    this.screenWidth = window.innerWidth;

    this.dateFrom = this.dateFormatterService.setPeriod();
    this.dateTo = this.dateFormatterService.setPeriod();

    this.selectedCurrency = this.currencies[0];
    this.pageLimit = 10;
    this.curPage = 0;
    this.getReceipts(null);

    this.getCompanySales();
    this.getWarehouse();

    this.getDataCompanyId();
    this.setMaxDate();
  }

  setMaxDate() {
    this.maxDate = this.dateService.ngbDateMax();
  }

  getCompanySales(): void {
    this.salesService.getReceipts(this.selectedCurrency.id, this.curPage + 1, this.pageLimit).subscribe(
      (extractData) => (this.receiptsData = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => { }
    );
  }

  getDataCompanyId(): void {
    this.storeService
      .getList(this.companyId)
      .subscribe(
        (extractData) => (this.stores = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => this.setData()
      );
  }

  setData() {
    if (this.stores.length === 0) {
      return;
    }

    let selectedStore;

    if (this.selectedStore) {
      this.stores.forEach((element) => {
        if (element.Name === this.selectedStore.Name) {
          selectedStore = element;
        }
      });
    } else {
      selectedStore = this.stores[0];
    }

    this.onSelect(selectedStore);
  }

  onSelect(store: any): void {
    this.selectedStore = store;
    localStorage.setItem(LocalStorageTags.StoreId, store.Id);
    this.isStoreDataLoaded = true;
  }

  onSelectCurrency(currency: Currency): void {
    this.selectedCurrency = currency;
  }

  stockTaking() {
    console.log('stock taking..');

    const fromDate = this.getDate(this.dateFrom, this.fromTime);
    const toDate = this.getDate(this.dateTo, this.tillTime);

    this.reportingService.stockTaking(
      fromDate.getTime(),
      toDate.getTime(),
      this.selectedStore.Id,
      this.selectedStore.Name
    );
  }

  stockTurnover() {
    console.log('stock turnover..');

    const fromDate = this.getDate(this.dateFrom, this.fromTime);
    const toDate = this.getDate(this.dateTo, this.tillTime);
    const unixFrom = fromDate.getTime() / 1000;
    const unixTo = toDate.getTime() / 1000;
    this.reportingService.stockTurnover(
      unixFrom,
      unixTo,
      this.selectedWarehouse.InitDate,
      this.selectedWarehouse.Id,
      this.selectedStore.Id,
      this.selectedStore.Name
    );
  }

  storeReport() {
    console.log('sales report..');

    const formatedFrom =
      `${this.dateFrom.year}-${this.dateFrom.month}-${this.dateFrom.day}`;
    const formatedTo =
      `${this.dateTo.year}-${this.dateTo.month}-${this.dateTo.day}`;

    this.reportingService.storeReport(
      formatedFrom,
      formatedTo,
      this.selectedStore.Id,
      this.selectedStore.Name,
      this.selectedCurrency.id
    );
  }

  storesReport() {
    console.log('sales report..');

    const formatedFrom =
      `${this.dateFrom.year}-${this.dateFrom.month}-${this.dateFrom.day}`;
    const formatedTo =
      `${this.dateTo.year}-${this.dateTo.month}-${this.dateTo.day}`;

    this.reportingService.storesReport(
      formatedFrom,
      formatedTo,
      localStorage.getItem(LocalStorageTags.CompanyId),
      this.selectedCurrency.id
    );
  }

  getDate(date: any, time: any): Date {
    const dtFrom = new Date();
    dtFrom.setDate(date.day);
    dtFrom.setMonth(date.month - 1);
    dtFrom.setFullYear(date.year);
    dtFrom.setHours(time.hour);
    dtFrom.setMinutes(time.minute);
    dtFrom.setSeconds(time.second);

    return dtFrom;
  }

  getReceipts(event: any) {
    try {
      if (event.offset || event.offset === 0) {
        this.curPage = event.offset;
      }
    } catch (exception) { }

    const dtFrom = new Date();
    dtFrom.setDate(this.dateFrom.day);
    dtFrom.setMonth(this.dateFrom.month - 1);
    dtFrom.setFullYear(this.dateFrom.year);
    dtFrom.setHours(this.fromTime.hour);
    dtFrom.setMinutes(this.fromTime.minute);
    dtFrom.setSeconds(this.fromTime.second);

    const dtTo = new Date();
    dtTo.setDate(this.dateTo.day);
    dtTo.setMonth(this.dateTo.month - 1);
    dtTo.setFullYear(this.dateTo.year);
    dtTo.setHours(this.tillTime.hour);
    dtTo.setMinutes(this.tillTime.minute);
    dtTo.setSeconds(this.tillTime.second);

    this.filterBy.from = dtFrom.getTime();
    this.filterBy.to = dtTo.getTime();
    this.filterBy.allRecords = false;
    this.filterBy.storno = this.storno;

    this.filterBy.eet = [];

    if (this.whEET === true) {
      this.filterBy.eet.push(0);
    }
    if (this.ok === true) {
      this.filterBy.eet.push(1);
    }
    if (this.nocode === true) {
      this.filterBy.eet.push(2);
    }

    this.filterBy.method = [];

    if (this.cash === true) {
      this.filterBy.method.push('cash');
    }
    if (this.card === true) {
      this.filterBy.method.push('card');
    }
    if (this.invoice === true) {
      this.filterBy.method.push('invoice');
    }
    if (this.bitcoin === true) {
      this.filterBy.method.push('bitcoin');
    }

    this.salesService
      .filterReceipts(this.selectedCurrency.id, this.curPage + 1, this.pageLimit, this.filterBy)
      .subscribe(
        (extractData) => (this.receiptsData = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          this.setFIKmessage();
          this.loaded = true;
        }
      );
    if (this.receiptCount === undefined) {
      this.setReceipts(dtFrom.getTime(), dtTo.getTime());
    } else {
      this.getCount(dtFrom.getTime(), dtTo.getTime());
    }
  }

  setFIKmessage() {
    for (let i = 0; i < this.receiptsData.length; i++) {
      if (
        (this.receiptsData[i].EET_FID === null ||
          this.receiptsData[i].EET_FID === '') &&
        (this.receiptsData[i].EET_SecureCode === null ||
          this.receiptsData[i].EET_SecureCode === '')
      ) {
        this.receiptsData[i].FIKmessage = 'NoCode';
        this.receiptsData[i].showSEC = false;
      } else if (
        (this.receiptsData[i].EET_FID === null ||
          this.receiptsData[i].EET_FID === '') &&
        (this.receiptsData[i].EET_SecureCode != null ||
          this.receiptsData[i].EET_SecureCode !== '')
      ) {
        this.receiptsData[i].FIKmessage = 'OnlySec';
        this.receiptsData[i].showFailMessage = false;
      } else if (
        this.receiptsData[i].EET_FID != null &&
        this.receiptsData[i].EET_FID !== ''
      ) {
        this.receiptsData[i].FIKmessage = 'FID';
        this.receiptsData[i].showFIK = false;
      }
    }
  }

  viewDetails(event: any) {
    if (event.type !== 'click') {
      return;
    }

    if (event.value === 'NoCode') {
      event.row.showFailMessage = !event.row.showFailMessage;
    } else if (event.value === 'OnlySec') {
      event.row.showSEC = !event.row.showSEC;
    } else if (event.value === 'FID') {
      event.row.showFIK = !event.row.showFIK;
    } else {
      this.router.navigate(['/sales/detail/' + event.row.Id]);
    }
  }

  setReceipts(dateFrom, dateTo) {
    this.salesService.getPageCount(this.selectedCurrency.id, dateFrom, dateTo).subscribe(
      (extractData) => (this.receiptCount = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => this.getReceipts(null)
    );
  }

  getCount(dateFrom, dateTo) {
    this.salesService.getPageCount(this.selectedCurrency.id, dateFrom, dateTo).subscribe(
      (extractData) => (this.receiptCount = extractData),
      (handleError) => (this.errorMessage = <any>handleError)
    );
  }

  exportCSV() {
    // this.csvService.download(this.receiptsData, 'centrala-receipts');
  }

  openFilter(event: any) {
    if (this.activeFilter === event.target.id) {
      this.activeFilter = '';
    } else {
      this.activeFilter = event.target.id;
    }
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = function (e) {
      // you can perform an action with readed data here
    };

    if (file.type !== 'application/vnd.ms-excel') {
      // err
    }
    if (file.size !== 10000000) {
      // err
    }

    // TODO: finish upload
  }

  onResize(event) {
    this.screenWidth = event.target.innerWidth;
  }

  setActive(btn: number) {
    if (this.isValue === btn) {
      this.isValue = 0;
    } else {
      this.isValue = btn;
    }
  }

  isButtonActive(btn: number): boolean {
    return this.isValue == btn;
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  /** WAREHOUSE **/

  changeWarehouse(id: number) {
    this.selectedWarehouse = this.getSelectedWarehouse(id);
    localStorage.setItem('warehouseId', id.toString());
  }

  getSelectedWarehouse(selectedId: number) {
    for (let i = 0; i < this.warehouses.length; i++) {
      if (this.warehouses[i].Id === selectedId) {
        return this.warehouses[i];
      }
    }

    return null;
  }

  setWarehouse() {
    if (this.warehouses != null && this.warehouses.length !== 0) {
      this.selectedWarehouse = this.warehouses[0];

      localStorage.setItem('warehouseId', this.selectedWarehouse.Id);
      this.isWarehouseLoaded = true;
    }
  }

  getWarehouse() {
    this.warehousesService.getList().subscribe(
      (extractData) => {
        this.warehouses = extractData;
      },
      (handleError) => (this.errorMessage = <any>handleError),
      () => this.setWarehouse()
    );
  }

  onCreateCSVClick(): void {
    const dtFrom = new Date();
    dtFrom.setDate(this.dateFrom.day);
    dtFrom.setMonth(this.dateFrom.month - 1);
    dtFrom.setFullYear(this.dateFrom.year);
    dtFrom.setHours(this.fromTime.hour);
    dtFrom.setMinutes(this.fromTime.minute);
    dtFrom.setSeconds(this.fromTime.second);

    const dtTo = new Date();
    dtTo.setDate(this.dateTo.day);
    dtTo.setMonth(this.dateTo.month - 1);
    dtTo.setFullYear(this.dateTo.year);
    dtTo.setHours(this.tillTime.hour);
    dtTo.setMinutes(this.tillTime.minute);
    dtTo.setSeconds(this.tillTime.second);

    this.filterBy.from = dtFrom.getTime();
    this.filterBy.to = dtTo.getTime();
    this.filterBy.allRecords = true;

    this.salesService.filterReceipts(this.selectedCurrency.id, null, null, this.filterBy).subscribe((receipts) => {
      this.csvService.postReceiptsCSV(receipts);
    });

  }
}
