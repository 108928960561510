import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { ProductRequest } from '../../interfaces/product/product-request.model';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../shared/http-handler.service';
import { ProductPageCount } from '../../interfaces/product/product-page-count.model';
import { catchError } from 'rxjs/operators';
import { ProductMapping } from '../../interfaces/product/product-mapping.model';
import { Product } from '../../interfaces/product/product.model';
import { ImportProductBase } from '../../../modules/sku/models/import-product-base.model';
import { ItemResponse } from '../../interfaces/shared/item-response.model copy';
import { TwilioServerResponse } from '../../../../app/modules/twilio/models/twilio-server-response.model';
import { centralaRequestHeaders } from '../../../../app/core/http/http-request';

@Injectable()
export class ProductService {
  private productsUrl: string = Centrala.URL + '/api/product';
  private productsImportUrl: string = Centrala.URL + '/api/productimport';
  private paginationUrl: string = Centrala.URL + '/api/product/pagination';
  private productsMapUrl: string = Centrala.URL + '/api/productmap';
  private generatePluUrl: string = Centrala.URL + '/api/generatePlu';
  private validatePluUrl: string = Centrala.URL + '/api/validatePlu';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService,
  ) { }

  /**
   * Get total page count for company products
   * @param companyId {string}
   */
  getPageCount(
    companyId: string,
    filter: string
  ): Observable<ProductPageCount> {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('filter', filter)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<ProductPageCount>(
        this.paginationUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  post(prod: ProductRequest): Observable<any> {
    const body = {
      token: localStorage.getItem(LocalStorageTags.Token),
      BatchId: prod.BatchId,
      TaxId: prod.TaxId,
      Text: prod.Text,
      Price: prod.Price,
      UnitAmount: 1,
      Unit: prod.Unit,
      UnitId: prod.UnitId,
      MobileWaiter: prod.mobileWaiter,
      Barcode: prod.Barcode,
      Plu: prod.plu,
      Abbreviation: prod.Abbreviation,
      IsHidden: prod.IsHidden,
      IsStock: prod.IsStock,
      VariablePrice: prod.VariablePrice,
      VariableName: prod.VariableName,
      VariableQuantity: prod.VariableQuantity,
      Kitchen: prod.Kitchen,
      IsPackaging: prod.IsPackaging,
      IsDiscount: prod.IsDiscount,
      Discount: prod.Discount,
      IsRedeemable: prod.IsRedeemable,
      IsWeighted: prod.IsWeighted,
      IsAdulthood: prod.IsAdulthood,
      IsSelfcheckoutWeighted: prod.IsSelfcheckoutWeighted,
      Image: prod.Image,
      companyId: localStorage.getItem(LocalStorageTags.CompanyId),
    };

    return this.httpClient
      .post(
        this.productsUrl,
        body,
        { 'headers': centralaRequestHeaders }
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Get company products
   * @param companyId {number} company ID
   * @param currentPage {number}
   * @param pageCount {number}
   */
  get(
    companyId: number,
    filter: string,
    currentPage: number,
    pageCount: number,
    isImageIncluded?: boolean,
  ): Observable<Product[]> {
    const imageIncluded = isImageIncluded ? isImageIncluded.toString() : null;
    const params = new HttpParams()
      .set('companyId', companyId.toString())
      .set('filter', filter)
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString())
      .set('isImageIncluded', imageIncluded)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<Product[]>(
        this.productsUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getImport(
    companyId: string
  ): Observable<ImportProductBase[]> {
    const params = new HttpParams()
      .set('companyId', companyId)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<ImportProductBase[]>(
        this.productsImportUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  put(prod: ProductRequest): Observable<any> {
    const body = {
      Id: prod.Id,
      BatchId: prod.BatchId,
      BatchName: prod.BatchName,
      TaxId: prod.TaxId,
      Text: prod.Text,
      Price: prod.Price,
      UnitAmount: 1,
      Unit: prod.Unit,
      UnitId: prod.UnitId,
      MobileWaiter: prod.mobileWaiter,
      Barcode: prod.Barcode,
      Plu: prod.plu,
      Abbreviation: prod.Abbreviation,
      IsHidden: prod.IsHidden,
      IsStock: prod.IsStock,
      VariablePrice: prod.VariablePrice,
      VariableName: prod.VariableName,
      VariableQuantity: prod.VariableQuantity,
      Kitchen: prod.Kitchen,
      IsPackaging: prod.IsPackaging,
      IsDiscount: prod.IsDiscount,
      Discount: prod.Discount,
      IsRedeemable: prod.IsRedeemable,
      IsWeighted: prod.IsWeighted,
      IsAdulthood: prod.IsAdulthood,
      IsSelfcheckoutWeighted: prod.IsSelfcheckoutWeighted,
      Image: prod.Image,
      token: localStorage.getItem(LocalStorageTags.Token),
      companyId: localStorage.getItem(LocalStorageTags.CompanyId),
    };

    return this.httpClient
      .put(
        this.productsUrl,
        body,
        { 'headers': centralaRequestHeaders }
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Create product association for stores
   * @param productId {number} product ID
   * @param StoreId {string[]}
   */
  postMap(
    productId: number,
    storeId: string[]
  ): Observable<any> {
    // tslint:disable-next-line: prefer-const
    let productMap = new Array(storeId.length);

    for (let i = 0; i < storeId.length; i++) {
      productMap[i] = { StoreId: storeId[i], ProductId: productId };
    }

    return this.httpClient
      .post(
        this.productsMapUrl + '?productId=' + productId,
        {
          maps: productMap,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { 'headers': centralaRequestHeaders }
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Gets store associations for product
   * @param productId {number}
   */
  getMap(productId: number): Observable<ProductMapping[]> {
    const params = new HttpParams()
      .set('productId', productId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));
    return this.httpClient
      .get<ProductMapping[]>(
        this.productsMapUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  generatePlu(userId: number): Observable<ItemResponse<number>> {
    const params = new HttpParams()
      .set('userId', userId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<ItemResponse<number>>(
        this.generatePluUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  validatePlu(
    userId: number,
    plu: number,
  ): Observable<TwilioServerResponse> {
    const params = new HttpParams()
      .set('userId', userId.toString())
      .set('plu', plu.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<TwilioServerResponse>(
        this.validatePluUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
