<authentication-test></authentication-test>
  <navigation selectedPage="stock">
    <app-stock-note
    [entityListUpdate]="entityList"
    [invoiceDate]="invoiceDate"
    [invoiceNumber]="invoiceNumber"
    (selectedWarehouseChange)="onSelectedWarehouseChange($event)"
    (fileUploadChange)="onChangeFileInput($event)"
    (storageCardListChange)="onStorageCardListChange($event)"
    ></app-stock-note>

  <ng-template #invoice let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'STOCK.INVOICE' | translate }}</h4>
      <button
        type="button"
        class="close"
        (click)="onClearStorageCardDataClick(); c('Close click')"
      >
        <span aria-hidden="false">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row no-gutters">
        <table class="table table-bordered table-hover">
          <tr>
            <th>{{ 'STOCK.DATE' | translate }}</th>
            <td>{{ invoiceDate }}</td>
            <th>{{ 'STOCK.SUPPLIER' | translate }}:</th>
            <td>{{ invoiceSupplier }}</td>
            <th>{{ 'STOCK.INVOICENO' | translate }}:</th>
            <td>{{ invoiceNo }}</td>
          </tr>
        </table>
        <table class="table table-bordered table-hover">
          <ng-template [ngTemplateOutlet]="tableHeader"></ng-template>
          <ng-container *ngFor="let item of isDocRows; let i = index">
            <tbody>
              <tr id="row{{ i + 1 }}" (click)="onTableRowDetailClick(i)">
                <td>{{ item.catalogueId }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.unit }}</td>
                <td>{{ item.unitPrice }}</td>
                <ng-container *ngFor="let affected of affectedRows">
                  <td *ngIf="affected === i">
                    <i class="fas fa-times-circle text-danger"></i>
                  </td>
                </ng-container>
                <ng-container *ngFor="let ok of checkedRows">
                  <td *ngIf="ok === i">
                    <i class="fas fa-check-circle text-success"></i>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </ng-container>
        </table>
        <div
          class="col-12 invalid-name text-right"
          *ngIf="isMissingCardsMessage"
        >
          {{ 'VALIDATOR.CARDMISSING' | translate }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn normal-btn"
        (click)="onImportInvoiceClick(); missingCards.length === 0 && c('Close click')"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVE' | translate }}
      </button>
    </div>
  </ng-template>

  <ng-template #invoiceRowDetail let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'STOCK.EDITLINE' | translate }}</h4>
      <button
        type="button"
        class="close"
        (click)="onCloseClick(); c('Close click')"
      >
        <span aria-hidden="false">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row no-gutters">
        <table class="table table-bordered">
          <ng-template [ngTemplateOutlet]="tableHeader"></ng-template>
          <ng-container *ngTemplateOutlet="tableContent;context:{data:invoiceDetailData}">
          </ng-container>
        </table>
      </div>
      <div class="row no-gutters">
        <div class="col-6">
          <button
            type="button"
            class="btn normal-btn float-left ml-2"
            (click)="onCreateOrAddToExistingClick(false)"
          >
            {{ 'STOCK.TAKETOCARD' | translate }}
          </button>
        </div>
        <div class="col-6">
          <button
            type="button"
            class="btn btn-outline-primary float-right"
            (click)="onCreateOrAddToExistingClick(true)"
          >
            {{ 'STOCK.NEWCARD' | translate }}
          </button>
        </div>
      </div>

      <div
        class="row no-gutters w-100"
        *ngIf="isCardDetailSelect"
      >
        <div class="col-12">
          <hr
            class="hr-line"
          />
        </div>
        <div class="col-12">
          <div class="input-group-prepend ml-2 mt-3">
            <span class="input-group-text" id="spInvoiceNo">{{
              'SKU.SKU' | translate
            }}</span>

            <div
              class="ng-autocomplete w-100"
              [ngClass]="{
                'autocomplete-invalid': isCardSelected
              }"
            >
              <ng-autocomplete
                *ngIf="storageCardList"
                [data]="storageCardList"
                [searchKeyword]="keyword"
                [(ngModel)]="selectedRecordForImport"
                (selected)="onStorageCardSelected($event)"
                (inputChanged)="onChangeImportStorageCardSearch($event)"
                (inputCleared)="onInputCleared()"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
              >
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.Name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
          <span class="help-block invalid-name" *ngIf="isCardSelected">{{
            'VALIDATOR.VALIDATION' | translate
          }}</span>
        </div>
        <div
          class="row no-gutters mt-3 w-100"
          *ngIf="isExistingCardSelected"
        >
          <div class="col-12 mb-2 ml-2 text-left">
            {{ 'STOCK.TOSTOCK' | translate }}
            <span class="italic-font"
              >{{ invoiceDetailData.quantity
              }}{{ invoiceDetailData.unit }}</span
            >
            {{ invoiceDetailData.name }} {{ 'STOCK.AS' | translate }}
            <span class="italic-font"
              >{{ invoiceDetailData.quantity }}{{ detailDataChange.unit }}</span
            >
            {{ detailDataChange.name }}?
          </div>
          <table class="table table-bordered">
            <ng-template [ngTemplateOutlet]="tableHeader"></ng-template>
            <ng-container *ngTemplateOutlet="tableContent;context:{data:invoiceDetailData}">
            </ng-container>
          </table>
        </div>
      </div>

      <div
        class="row no-gutters w-100"
        *ngIf="createNewStorageCard"
      >
        <div class="col-12">
          <hr
            class="hr-line"
          />
        </div>

        <div class="col-12">
          <div class="row no-gutters">
            <div class="col-5" [formGroup]="storageCardForm">
              <div class="row no-gutters">
                <div class="input-group mt-3 ml-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="numberInput">{{
                      'SKU.SKUNUMBER' | translate
                    }}</span>
                  </div>
                  <input
                    [(ngModel)]="cardNumber"
                    type="number"
                    class="form-control normal-input"
                    id="numberInput"
                    formControlName="storageCardNumber"
                    aria-describedby="numberInput"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': isFormSubmited && validateStorageCardForm.storageCardNumber.errors
                    }"
                  />
                </div>
              </div>
              <div
                class="row no-gutters invalid-name float-right"
                *ngIf="isFormSubmited && validateStorageCardForm.storageCardNumber.errors"
              >
                <div>
                  {{ 'VALIDATOR.VALIDATION' | translate }}
                </div>
              </div>
            </div>
            <div class="col-2"></div>
            <div class="col-5" [formGroup]="storageCardForm">
              <div class="row no-gutters">
                <div class="input-group mt-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="nameInput">{{
                      'SKU.SKUNAME' | translate
                    }}</span>
                  </div>
                  <input
                    [(ngModel)]="cardName"
                    type="text"
                    class="form-control normal-input"
                    id="nameInput"
                    formControlName="storageCardName"
                    placeholder="{{ 'PRODUCTS.NAME' | translate }}"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': isFormSubmited && validateStorageCardForm.storageCardName.errors
                    }"
                  />
                </div>
              </div>
              <div
                class="row no-gutters invalid-name float-right"
                *ngIf="isFormSubmited && validateStorageCardForm.storageCardName.errors"
              >
                <div>
                  {{ 'VALIDATOR.VALIDATION' | translate }}
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row no-gutters w-100 mt-3">
                <div class="col-6">
                    <app-dropdown
                        [ngClass]="{
                          'dropdown-invalid': isMissingCategory && !selectedCategory
                        }"
                        *ngIf="selectedCategory"
                        [(selected)]="selectedCategory"
                        label="{{ 'PRODUCTS.TABLEPRODUCTGROUP' | translate }}"
                        itemKey="Name"
                        [items]="categoryList"
                    ></app-dropdown>
                  </div>
                <div class="col-6">
                  <app-dropdown
                    class="float-right"
                    [ngClass]="{
                      'dropdown-invalid': isMissingUnit && !selectedUnit
                    }"
                    *ngIf="selectedUnit"
                    [(selected)]="selectedUnit"
                    label="{{ 'PRODUCTS.PRODUCTUNIT' | translate }}"
                    itemKey="Name"
                    [items]="unitList"
                ></app-dropdown>
                </div>
              </div>
              <div class="row no-gutters w-100">
                <div class="col-6">
                  <span
                    class="help-block invalid-group-detail ml-2"
                    *ngIf="isMissingCategory"
                    >{{ 'VALIDATOR.DROPDOWNVALID' | translate }}</span
                  >
                </div>
                <div class="col-6 text-right">
                  <span class="help-block invalid-group" *ngIf="isMissingUnit">{{
                    'VALIDATOR.DROPDOWNUNIT' | translate
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal-footer"
      *ngIf="isCardDetailSelect || createNewStorageCard"
    >
      <button
        type="button"
        class="btn normal-btn"
        (click)="onApllyDetailChangeClick(); !isCardSelected && c('Close click')"
        [hidden]="createNewStorageCard"
        [disabled]="createNewStorageCard"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVE' | translate }}
      </button>

      <button
        type="button"
        class="btn normal-btn"
        (click)="
          onPostStorageCardClick(cardNumber, cardName);
            (!selectedUnit && c('Close click'))
        "
        [hidden]="isCardDetailSelect"
        [disabled]="isCardDetailSelect"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'STOCK.TOCARD' | translate }}
      </button>
    </div>
  </ng-template>
</navigation>

<ng-template #tableHeader>
  <tr>
    <th>{{ 'STOCK.CARDNUMBER' | translate }}</th>
    <th>{{ 'STOCK.NAME' | translate }}</th>
    <th>{{ 'STOCK.QUANTITY' | translate }}</th>
    <th>{{ 'STOCK.UNIT' | translate }}</th>
    <th>{{ 'STOCK.UNITPRICE' | translate }}</th>
  </tr>
</ng-template>

<ng-template #tableContent let-data="data">
  <tr>
    <td>{{ data.catalogueId }}</td>
    <td>{{ data.name }}</td>
    <td>{{ data.quantity }}</td>
    <td>{{ data.unit }}</td>
    <td>{{ data.unitPrice }}</td>
  </tr>
</ng-template>