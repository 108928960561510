<div class="custom-input">
  <mat-button-toggle-group appearance="legacy">
    <mat-button-toggle
      [ngClass]="{ 'input-error': form.invalid && submit }"
      disabled
    >
      {{ label }}{{ required ? '*' : '' }}
    </mat-button-toggle>
    <mat-form-field>
      <input
        matInput
        class="color-picker"
        [(ngModel)]="input"
        (ngModelChange)="onChange(input)"
        [ngxMatColorPicker]="picker"
      />
      <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
      <ngx-mat-color-picker #picker></ngx-mat-color-picker>
    </mat-form-field>
  </mat-button-toggle-group>
  <mat-error *ngIf="form.invalid && submit">{{ errorMessage }}</mat-error>
</div>