import { Injectable } from '@angular/core';
import { Centrala } from '../../http/centrala';
import { Http, ResponseContentType } from '@angular/http';
import { saveAs } from 'file-saver';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private stockTakingUrl: string = Centrala.URL + '/api/stock-taking';
  private salesUrl: string = Centrala.URL + '/api/sales';
  private storesReportUrl: string = Centrala.URL + '/api/storesReport';
  private stockTurnoverUrl: string = Centrala.URL + '/api/stock-turnover';
  private storageCardReportUrl: string = Centrala.URL + '/api/storage-card';
  private fileExtension: string = 'application/pdf';
  private reportStockTakingName: string = 'inventory.pdf';
  private reportSalesName: string = 'sales.pdf';

  constructor(
    private http: Http,
    private httpClient: HttpClient
  ) { }

  // TODO: convert dates to timestamp
  /**
   * Sales overview
   * @param dateFrom {string} unix timestamp
   * @param dateTo {string} unix timestamp
   * @param storeId {number} store ID
   * @param storeName {string} store name
   */
  storeReport(
    dateFrom: string,
    dateTo: string,
    storeId: number,
    storeName: string,
    currencyId: number
  ): any {
    let url =
      this.salesUrl +
      '?token=' +
      localStorage.getItem(LocalStorageTags.Token) +
      '&dateFrom=' +
      dateFrom +
      '&dateTo=' +
      dateTo +
      '&currencyId=' +
      currencyId;

    if (storeId) {
      url += '&storeId=' + storeId;
      url += '&storeName=' + storeName;
    }

    this.http
      .get(
        url,
        { responseType: ResponseContentType.Blob }
      )
      .subscribe(
        (response) => {
          const blob = new Blob([response.blob()], { type: this.fileExtension });
          saveAs(blob, this.reportSalesName);
        },
        (e) => {
          /*throwError(e);*/
        }
      );
  }

  storesReport(
    dateFrom: string,
    dateTo: string,
    companyId: string,
    currencyId: number
  ): void {
    this.http
      .get(
        this.storesReportUrl +
        '?token=' +
        localStorage.getItem(LocalStorageTags.Token) +
        '&dateFrom=' +
        dateFrom +
        '&dateTo=' +
        dateTo +
        '&companyId=' +
        companyId +
        '&currencyId=' +
        currencyId,
        { responseType: ResponseContentType.Blob }
      )
      .subscribe(
        (response) => {
          const blob = new Blob([response.blob()], { type: this.fileExtension });
          saveAs(blob, this.reportSalesName);
        },
        (e) => { }
      );
  }

  /**
   * Get stock inventory
   * @param dateFrom {number} unix timestamp
   * @param dateTo {number} unix timestamp
   * @param storeId {number}
   * @param storeName {number}
   */
  stockTaking(
    dateFrom: number,
    dateTo: number,
    storeId: string,
    storeName: string
  ): void {
    let url =
      this.stockTakingUrl +
      '?token=' +
      localStorage.getItem(LocalStorageTags.Token) +
      '&dateFrom=' +
      dateFrom +
      '&dateTo=' +
      dateTo;

    if (storeId) {
      url += '&storeId=' + storeId;
      url += '&storeName=' + storeName;
    }

    this.http.get(url, { responseType: ResponseContentType.Blob }).subscribe(
      (response) => {
        const blob = new Blob([response.blob()], { type: this.fileExtension });
        saveAs(blob, this.reportStockTakingName);
      },
      (e) => { }
    );
  }

  /**
   * Get stock tunrnover
   * @param dateFrom {number} unix timestamp
   * @param dateTo {number} unix timestamp
   * @param storeId {number}
   * @param storeName {number}
   */
  stockTurnover(
    dateFrom: number,
    dateTo: number,
    initDate: number,
    warehouseId: number,
    storeId: string,
    storeName: string
  ): any {
    let url =
      this.stockTurnoverUrl +
      '?token=' +
      localStorage.getItem(LocalStorageTags.Token) +
      '&dateFrom=' +
      dateFrom +
      '&dateTo=' +
      dateTo +
      '&initDate=' +
      initDate +
      '&warehouseId=' +
      warehouseId;

    if (storeId) {
      url += '&storeId=' + storeId;
      url += '&storeName=' + storeName;
    }

    this.http
      .get(
        url,
        { responseType: ResponseContentType.Blob }
      )
      .subscribe(
        (response) => {
          const blob = new Blob([response.blob()], { type: this.fileExtension });
          saveAs(blob, this.reportStockTakingName);
        },
        (e) => {
          /*throwError(e);*/
        }
      );
  }

  /**
   * Stock card report
   * @param cardId {number}
   * @param dateFrom {number} unix timestamp
   * @param dateTo {number} unix timestamp
   * @param cardName {string} card name
   */
  card(
    cardId: number,
    dateFrom: number,
    dateTo: number,
    cardName: string
  ): any {
    this.http
      .get(
        this.storageCardReportUrl +
        '?token=' +
        localStorage.getItem(LocalStorageTags.Token) +
        '&cardId=' +
        cardId +
        '&dateFrom=' +
        dateFrom +
        '&dateTo=' +
        dateTo +
        '&cardName=' +
        cardName,
        { responseType: ResponseContentType.Blob }
      )
      .subscribe(
        (response) => {
          const blob = new Blob([response.blob()], { type: this.fileExtension });
          saveAs(blob, this.reportStockTakingName);
        },
        (e) => {
          /*throwError(e);*/
        }
      );
  }
}
