<authentication-test></authentication-test>
<navigation selectedPage="{{ selectedStoreId == 0 ? 'devices' : 'contio-store' }}">
  <div *ngIf="selectedStoreId > 0">
    <div class="row no-gutters">
      <div class="col-8">
        <div class="row no-gutters">
          <div class="col-12 page-header">
            {{ 'SHOP.DEVICES' | translate }}
          </div>
          <div
            class="col-12 address"
          >
            Id: {{selectedStoreId}}
          </div>
        </div>
      </div>

      <div class="col-4">
        <button
          type="button"
          class="btn btn-primary normal-btn float-right back-btn"
          (click)="onGoToShopDetailClick()"
        >
          {{ 'REGISTRATION.BACK' | translate }}
        </button>
      </div>
    </div>

    <hr class="line">
  </div>

  <div class="row no-gutters mt-3 w-100">
    <div class="col-6">
      <button
        type="text"
        class="btn btn-primary new-btn"
        (click)="onNewDeviceClick(false)"
      >
        <i class="fas fa-plus mr-1"></i>
        {{'SHOP.NEW' | translate}}
      </button>
    </div>

    <div class="col-6 my-col">
      <div class="row no-gutters justify-content-end">
        <div class="input-group-prepend float-right">
          <div
            class="input-group-prepend div-right-margin"
            *ngIf="!isDeviceInputGroupVisible"
          >
            <button
              type="button"
              class="btn btn-secondary ml-1 line-height"
              (click)="onFormShowClick()"
            >
              <i class="fas fa-chevron-up"></i>
            </button>
          </div>

          <div
            class="input-group-prepend div-right-margin"
            *ngIf="isDeviceInputGroupVisible"
          >
            <button
              type="button"
              class="btn btn-secondary ml-1 line-height"
              (click)="onFormHideClick()"
            >
              <i class="fas fa-chevron-down"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row no-gutters mt-3 w-100"
    *ngIf="!isDeviceInputGroupVisible"
  >
    <div class="col-lg-4 col-sm-12 my-2 pr-xl-3 pr-lg-2 my-col">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> {{ 'PRODUCTS.NAME' | translate }} </span>
        </div>
        <input
          [(ngModel)]="device.Name"
          type="text"
          class="form-control normal-input"
          placeholder="{{ 'PRODUCTS.NAME' | translate }}"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-lg-3 col-sm-12 my-2 pr-xl-3 pr-lg-2 my-col">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> Shelly </span>
        </div>
        <input
          [(ngModel)]="device.ShellyMqttPath"
          type="text"
          class="form-control normal-input"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-lg-3 col-sm-10 my-2 pr-xl-3 pr-lg-2 my-col">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> Ip </span>
        </div>
        <input
          [(ngModel)]="device.Ip"
          type="text"
          class="form-control normal-input"
        />
      </div>
    </div>

    <div class="col-xl-2 col-lg-2 col-sm-2 pl-xl-3 pl-sm-3">
      <div
        class="custom-control custom-checkbox checkbox-lg float-left"
      >
        <input
          *ngIf="device.IsRunning"
          checked
          (click)="onIsRunningCheckboxChange()"
          type="checkbox"
          class="custom-control-input"
          id="stateInput"
        />
        <input
          *ngIf="!device.IsRunning"
          (click)="onIsRunningCheckboxChange()"
          type="checkbox"
          class="custom-control-input"
          id="stateInput"
        />
        <label class="custom-control-label" for="stateInput"> {{ 'SHOP.STATE' | translate }} </label>
      </div>
    </div>
  </div>

  <div
    class="row no-gutters w-100 mt-2"
    *ngIf="!isDeviceInputGroupVisible"
  >
    <div class="col-sm-12">
      <button
        [hidden]="isDeviceDetailDisabled"
        [disabled]="isDeviceDetailDisabled"
        (click)="onEditDeviceClick()"
        class="btn btn-primary save-btn float-right"
        id="saveDeviceChangeButton"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVECHANGES' | translate }}
      </button>
      <div class="float-right">
        <button
          [hidden]="!isDeviceDetailDisabled"
          [disabled]="!isDeviceDetailDisabled"
          (click)="onPostDeviceClick()"
          class="btn btn-primary save-btn float-right"
          id="saveDeviceButton"
        >
          <i class="fas fa-save mr-1"></i>
          {{ 'PRODUCTS.SAVENEWPRODUCT' | translate }}
        </button>
      </div>
    </div>
  </div>

  <hr class="line">

  <div class="row no-gutters w-100">
    <div class="col-sm my-col data-box">
      <ngx-datatable
        #datatable
        class="material ngx-datatable-cursor"
        [rows]="deviceList"
        [headerHeight]="50"
        [footerHeight]="50"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        [selectionType]="'single'"
        [selected]="selectedDevice"
        [limit]="pageLimit"
        [externalPaging]="true"
        [count]="deviceCount"
        [offset]="currentPage"
        [messages]="{
          emptyMessage: 'PRODUCTS.TABLENODATA' | translate,
          totalMessage: 'PRODUCTS.TABLETOTAL' | translate
        }"
        (select)="onDeviceTableSelect($event.selected[0])"
        (page)="getDevices($event)"
      >
        <ngx-datatable-column prop="Name" name="{{'PRODUCTS.TABLEPRODUCTNAME' | translate}}"></ngx-datatable-column>
        <ngx-datatable-column
          prop="ShellyMqttPath"
          name="Shelly"
        ></ngx-datatable-column>
        <ngx-datatable-column prop="Ip" name="{{'SHOP.IP' | translate}}"></ngx-datatable-column>
        <ngx-datatable-column prop="IsRunning" name="{{'STOCK.STATE' | translate}}">
          <ng-template let-value="value" ngx-datatable-cell-template>
            <span *ngIf="value">{{'SHOP.ON' | translate}}</span>
            <span *ngIf="!value">{{'SHOP.OFF' | translate}}</span>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</navigation>
