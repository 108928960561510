import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { TelegramPageCount } from './models/telegram-page-count.model';
import { TelegramUser } from './models/telegram-user.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { PostResponse } from '../../core/interfaces/post-response.model';
import { ServerResponse } from '../../shared/models/server-response.model';

@Injectable({
  providedIn: 'root',
})
export class TelegramService {
  private telegramUrl: string = Centrala.URL + '/api/telegram';
  private telegramCountUrl: string = Centrala.URL + '/api/telegram/count';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  post(
    key: string,
    isValid: boolean,
  ): Observable<ServerResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<ServerResponse>(
        this.telegramUrl,
        {
          key: key,
          isValid: isValid,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  getUsersByUserId(
    currentPage: number,
    pageCount: number
  ): Observable<TelegramUser[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());
    return this.httpClient
      .get<TelegramUser[]>(
        this.telegramUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getPageCount(): Observable<TelegramPageCount> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));
    return this.httpClient
      .get<TelegramPageCount>(
        this.telegramCountUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  blockUser(
    isValid: boolean,
    id: number
  ): Observable<PostResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<PostResponse>(
        this.telegramUrl,
        {
          isValid: isValid,
          id: id,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
