import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../modules/admin-panel/admin.service';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { Route } from '../../enums/route.enum';
import { Centrala } from '../../http/centrala';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  userId: number;
  isContioOwner: boolean = false;

  constructor(
    private router: Router,
    private adminService: AdminService
  ) { }

  getUserId(): number {
    return Number(JSON.parse(localStorage.getItem(LocalStorageTags.UserId)));
  }

  getCompanyId(): number {
    return Number(localStorage.getItem(LocalStorageTags.CompanyId));
  }

  autenticateAdmin(): void {
    this.userId = this.getUserId();

    if (this.userId !== Centrala.AdminId) {
      this.router.navigate([Route.Dashboard]);
    }
  }

  autenticateContioUser(): void {
    this.userId = this.getUserId();

    this.setIsContioOwner().then(() => {
      if (this.userId !== Centrala.AdminId && !this.isContioOwner) {
        this.router.navigate([Route.Dashboard]);
      }
    });
  }

  async setIsContioOwner(): Promise<boolean> {
    return new Promise((resolve) => {
      this.adminService
        .getUser(this.userId)
        .subscribe((userDetail) => {
          resolve(this.isContioOwner = userDetail[0].IsContio);
        });
    });
  }
}
