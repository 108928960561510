import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpHandlerService } from '../shared/http-handler.service';
import { HttpClient } from '@angular/common/http';
import { ChangePasswordResponse } from '../../interfaces/password/change-password-response.model';

@Injectable()
export class PasswordService {
  private changePasswordUrl: string = Centrala.URL + '/api/users/password_change/';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  changePassword(
    email: string,
    currentPassword: string,
    newPassword: string,
    newPasswordConfirmation: string
  ): Observable<ChangePasswordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<ChangePasswordResponse>(
        this.changePasswordUrl + localStorage.getItem('userId'),
        {
          'token': localStorage.getItem(LocalStorageTags.Token),
          'user_id': email,
          'old_pwd': currentPassword,
          'new_pwd': newPassword,
          'new_pwd_conf': newPasswordConfirmation,
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
