<authentication-test></authentication-test>
<navigation selectedPage="stock">
  <div class="pb-4">
    <div class="row">
      <div class="col-sm">
        <div class="page-header pl-2">
          <span *ngIf="noteId">
            {{'STOCK.EDITINTERNAL' | translate }}
          </span>
          <span *ngIf="!noteId">
            {{'STOCK.NEWINTERNAL' | translate }}
          </span>
          {{ dynamicTitle | translate }}

          <button class="save-btn float-right" mat-button (click)="onSaveStockCardClick()">
            <i class="fas fa-save"></i>
            <span class="icon-name">{{ 'PRODUCTS.SAVECHANGES' | translate }}</span>
          </button>
        </div>
        <hr class="hr-line" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6">
      <form class="form-inline">
        <div class="form-group">
          <div class="input-group input-height">
            <div class="input-group-prepend">
              <span class="input-group-text" for="to">{{
                'SALES.TABLEDATE' | translate
                }}</span>
            </div>
            <div class="input-group-prepend"></div>
            <input class="form-control input-height" [minDate]="minDate" [maxDate]="maxDate" [placement]="placement"
              readonly="false" name="dp" value="{{ document.Date | dateFormat }}" [(ngModel)]="document.Date"
              ngbDatepicker #d="ngbDatepicker" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"
                [disabled]="isStockNoteSelected">
                <i class="far fa-calendar-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-2">
      <app-dropdown *ngIf="selectedSourceWarehouse" [(selected)]="selectedSourceWarehouse"
        label="{{ 'SKU.REMOVEFROMWAREHOUSE' | translate }}" itemKey="Name" [items]="warehouseList"></app-dropdown>
        <div class="row no-gutters" *ngIf="warehouseList.length <= 0">
          <span class="help-block invalid-group w-auto text-right">{{ 'VALIDATOR.WAREHOUSEVALID' | translate }}
            <span class="text-undeline">
              <a routerLink="/company-settings">{{
                'VALIDATOR.ROUTER' | translate
                }}</a> </span>.
          </span>
        </div>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 mt-2">
      <app-dropdown *ngIf="selectedTargetWarehouse" [(selected)]="selectedTargetWarehouse"
        label="{{ 'SKU.MOVETOWAREHOUSE' | translate }}" itemKey="Name" [items]="warehouseList"></app-dropdown>
    </div>
  </div>

  <div class="pb-4">
    <div class="row">
      <div class="col-sm">
        <hr class="hr-line" />
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="storageCardList">
    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-3 float-left">
      <div class="input-group-prepend">
        <span class="input-group-text" id="spInvoiceNo">{{
          'SKU.SKU' | translate
          }}</span>

        <div class="ng-autocomplete w-100 input-height" [ngClass]="{
              'autocomplete-invalid':
                isRecordSubmitted && isAutocompleteRecordValidate
            }">
          <ng-autocomplete *ngIf="storageCardList" [data]="storageCardList" [searchKeyword]="keywordName"
            [(ngModel)]="selectedStorageCard" (selected)="selectEvent($event)"
            (inputChanged)="onChangeStorageCardSearch($event)" (inputCleared)="onInputCleared()"
            [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.Name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </div>
      <span class="help-block invalid-name" *ngIf="isRecordSubmitted && isAutocompleteRecordValidate">{{
        'VALIDATOR.VALIDATION' | translate }}</span>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 form-group mb-3" [formGroup]="stockNoteForm">
      <div class="input-group">
        <div class="input-group-prepend input-height">
          <span class="input-group-text" id="nameInput">{{
            'STOCK.QUANTITY' | translate
            }}</span>
        </div>
        <input [(ngModel)]="quantity" type="number" class="form-control normal-input input-height" id="inpQuantity"
          placeholder="0" formControlName="stockNoteQuantity" [ngClass]="{
              'is-invalid':
                isRecordSubmitted &&
                validateStockNoteForm.stockNoteQuantity.errors
            }" />
        <div *ngIf="
              isRecordSubmitted && validateStockNoteForm.stockNoteQuantity.errors
            " class="invalid-feedback text-right div-right-margin">
          <div *ngIf="validateStockNoteForm.stockNoteQuantity.errors.required">
            {{ 'VALIDATOR.VALIDATION' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 overflow-auto">
      <button (click)="onDeleteNoteRecordClick()" class="btn btn-danger float-right" id="btnDeleteRecord"
        [hidden]="!isStockNoteSelected" [disabled]="!isStockNoteSelected">
        <i class="fas fa-trash-alt mr-1"></i>
        {{ 'STOCK.DELETERECORD' | translate }}
      </button>
      <button (click)="onAddNoteRecordClick()" class="btn btn-primary new-btn float-right mr-1" id="btnAddRecord"
        [hidden]="isStockNoteSelected" [disabled]="isStockNoteSelected">
        <i class="fas fa-plus mr-1"></i>
        {{ 'STOCK.ADDRECORD' | translate }}
      </button>
    </div>
  </div>
  <app-table [columns]="columns" [data]="entityList" [totalCount]="recordsCount" [currentPage]="currentPage"
    [selectable]="true" (selectedRow)="onNoteRecordTableSelect($event)" (pageChange)="onPageChange($event)"></app-table>
</navigation>