import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SunmiSettings } from '../../interfaces/esl-settings';
import { SunmiShop } from '../../../modules/settings/settings-tabs/sunmi-shop/models/sunmi-shop.model';
import { PostResponse } from '../../interfaces/post-response.model';

@Injectable({
  providedIn: 'root',
})
export class SunmiService {
  private sunmiSettingsUrl: string = Centrala.URL + '/api/esl/sunmi/settings';
  private sunmiShopUrl: string = Centrala.URL + '/api/esl/shop/create';
  private uploadProductsUrl: string = Centrala.URL + '/api/esl/product/bulk-upload';

  constructor(private httpClient: HttpClient) {}

  postSettings(sunmiSettings: SunmiSettings): Observable<PostResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.sunmiSettingsUrl,
        {
          SunmiSettings: sunmiSettings,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .map((data: any) => {
        return data;
      });
  }

  getSettings(): Observable<SunmiSettings> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<SunmiSettings>(this.sunmiSettingsUrl, { params })
      .map((data: any) => {
        return data;
      });
  }

  postSunmiShop(
    sunmiShop: SunmiShop,
    sunmiSettings: SunmiSettings,
  ): Observable<PostResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.sunmiShopUrl,
        {
          sunmiShop: sunmiShop,
          sunmiSettings: sunmiSettings,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .map((data: any) => {
        return data;
      });
  }

  getSunmiShops(): Observable<SunmiShop[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<SunmiShop>(
        this.sunmiShopUrl, { params }
      )
      .map((data: any) => {
        return data;
      });
  }

  uploadProducts(
    sunmiShop: SunmiShop,
    sunmiSettings: SunmiSettings,
  ): Observable<boolean> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.uploadProductsUrl,
        {
          sunmiShop: sunmiShop,
          sunmiSettings: sunmiSettings,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .map((data: any) => {
        return data;
      });
  }
}
