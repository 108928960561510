import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyService } from '../company.service';
import { ValidatorService } from '../../../core/services/validator/validator.service';
import { Company } from '../models/company.model';
import { ContactService } from '../../contacts/contact.service';
import { LocalStorageTags } from '../../../core/enums/local-storage-tags.enum';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { finalize } from 'rxjs/operators';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { AresService } from '../../../core/services/shared/ares.service';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { PrivilegeType } from '../../subaccounts/privilege-type.enum';
import { SubaccountValue } from '../../subaccounts/subaccount-value.enum';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['../../../../assets/scss/company-settings.scss', './company-settings.component.scss'],
})
export class CompanySettingsComponent implements OnInit {
  companyId: number;
  userId: number;

  isAresModalOpen: boolean = false;
  isAresDataLoaded: boolean = false;
  isBinValid: boolean = false;

  privilegeValue: any = SubaccountValue;
  companyPrivilege: string;
  company: Company;
  isCompanyDataLoaded: boolean = false;
  canShowCompanyValidation: boolean = false;

  companyDataEditResponse: CreateRecordResponse;

  error: string;

  constructor(
    private companyService: CompanyService,
    private modalService: NgbModal,
    private validatorService: ValidatorService,
    private contactService: ContactService,
    private aresService: AresService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.companyId = this.localStorageService.getCompanyId();
    this.userId = this.localStorageService.getUserId();

    this.getCompanyPrivilege();
    this.setCompany();
    this.validatorService.companyValidator();
  }

  onAresModalOpenClick(aresModal: string): void {
    this.isAresModalOpen = true;

    if (!this.company.Bin) {
      return;
    }

    this.modalService.open(aresModal, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  onCompanyDataEditClick(): void {
    this.canShowCompanyValidation = true;

    if (this.validatorService.companyForm.invalid) {
      return;
    }

    this.companyService
      .edit(this.company)
      .pipe(
        finalize(() => {
          this.companyDataEditResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.companyDataEditResponse = successResponse;
          if (successResponse.success) {
            this.setNewCompanyToLocalStorage();

            this.snackBarService.show('STORES.COMPANYEDITSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('STORES.COMPANYEDITFALSE', false);
        },
      });
  }

  resetAddValidator(): void {
    this.canShowCompanyValidation = false;
    this.isAresModalOpen = false;
    this.isBinValid = false;
  }

  getDataFromARES(bin: string): void {
    let aresXml;
    let aresCompany;

    this.contactService
      .getAresRegistryIdentity(bin)
      .subscribe({
        next: (successResponse) => {
          aresXml = successResponse;
          if (aresXml.message === 'false_bin_format') {
            this.snackBarService.show('CONTACTS.ARESINVALIDBIN', false);

            return this.isBinValid;
          }

          aresCompany = this.aresService.parseAresXml(aresXml);

          if (!aresCompany.Name) {
            this.isAresDataLoaded = true;
            this.snackBarService.show('CONTACTS.ARESLOADFAIL', false);

            return;
          } else {
            this.isAresDataLoaded = false;
            this.company = aresCompany;
            this.company.Id = this.companyId;
            this.company.UserId = this.userId;
            this.company.Bin = bin;
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  checkIfValueIsNumberValidator(value: string): boolean {
    return Number.isInteger(Number(value));
  }

  onCheckboxChange(isChecked: boolean): boolean {
    return isChecked ? true : false;
  }

  private getCompanyPrivilege(): void {
    const privilegeList = JSON.parse(localStorage.getItem(LocalStorageTags.Privilege));

    if (privilegeList) {
      this.companyPrivilege = privilegeList[PrivilegeType.Company].Value;
    }
  }

  private setCompany(): void {
    this.companyService
      .getById(this.companyId)
      .subscribe({
        next: (companyData) => {
          this.company = companyData[0];
          this.isCompanyDataLoaded = true;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setNewCompanyToLocalStorage(): void {
    this.canShowCompanyValidation = false;

    const localStorageCompanies: Company[] = JSON.parse(localStorage.getItem(LocalStorageTags.CompanyList));

    localStorageCompanies.forEach((company, index) => {
      if (company.Id === this.companyId &&
        company.Name !== this.company.Name) {
        localStorageCompanies[index] = this.company;
        localStorage.setItem(LocalStorageTags.CompanyList, JSON.stringify(localStorageCompanies));
        window.location.reload();
      }
    });
  }
}
