import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { SnackBarService } from '../../core/services/shared/snackbar.service';
import { ImageService } from '../../core/services/image/image.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnChanges {
  @Input() image: string | ArrayBuffer;
  @Output() imageBase64: EventEmitter<string> = new EventEmitter<string>();

  file: File;
  isUpload: boolean = false;
  isExpectedSize: boolean = false;
  isSquareRatio: boolean = false;
  cropSize: string = '500';
  croppedImage: string;
  canShowCropDetail: boolean = false;

  error: string;

  constructor(
    private imageService: ImageService,
    private snackBarService: SnackBarService
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const currentValue = changes.image.currentValue;

    if (currentValue) {
      const currentFile = await this.base64ToFile(currentValue);
      this.isSquareRatio = await this.checkSquareRatio(currentFile);
    }
  }

  onInputFileUpload(upload: Event): void {
    const target: HTMLInputElement = upload.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    this.onFileUpload(file);
  }

  async onFileUpload(file: File): Promise<void> {
    this.isExpectedSize = await this.checkDimensions(file);

    if (!this.isExpectedSize) {
      this.snackBarService.show('PICTURE.TOOSMALL', false);
      return;
    }

    this.isSquareRatio = await this.checkSquareRatio(file);
    this.file = await this.getCompressedImage(file);
    this.isUpload = true;
    this.setConvertedFileToBase64(this.file);
  }

  onDeleteFileClick(): void {
    this.deleteFile();
  }

  deleteFile(): void {
    this.file = null;
    this.image = null;
    this.croppedImage = null;
    this.isExpectedSize = false;
    this.isUpload = false;
    this.canShowCropDetail = false;
    this.imageBase64.emit(null);
  }

  onImageCrop(image: ImageCroppedEvent): void {
    this.croppedImage = image.base64;
    this.imageBase64.emit(image.base64);

    if (this.croppedImage) {
      this.isUpload = false;
    }
  }

  onShowCropDetailClick(canShowDetail: boolean): void {
    this.canShowCropDetail = !canShowDetail;
  }

  private getCompressedImage(image: File): Promise<File> {
    return new Promise(async (resolve) => {
      (await this.imageService.compress(image)).subscribe({
        next: (compressedImage) => {
          resolve(compressedImage);
        },
        error: (error: string) => {
          this.error = error;

          this.snackBarService.show('PICTURE.UNEXPECTEDERROR', false);
        },
      });
    });
  }

  private checkDimensions(image: File): Promise<boolean> {
    return new Promise((resolve) => {
      this.imageService.isImageMatchPreferredSize(image).subscribe({
        next: (isExpectedSize) => {
          resolve(isExpectedSize);
        },
        error: (error: string) => {
          this.error = error;

          this.snackBarService.show('PICTURE.UNEXPECTEDERROR', false);
        },
      });
    });
  }

  private checkSquareRatio(image: File): Promise<boolean> {
    return new Promise((resolve) => {
      this.imageService.isSquare(image).subscribe({
        next: (isSquareRatio) => {
          resolve(isSquareRatio);
        },
        error: (error: string) => {
          this.error = error;

          this.snackBarService.show('PICTURE.UNEXPECTEDERROR', false);
        },
      });
    });
  }

  private base64ToFile(imageBase64: string): Promise<File> {
    return new Promise((resolve) => {
      this.imageService.convertBase64ImageToFile(imageBase64).subscribe({
        next: (image) => {
          resolve(image);
        },
        error: (error: string) => {
          this.error = error;

          this.snackBarService.show('PICTURE.UNEXPECTEDERROR', false);
        },
      });
    });
  }

  private setConvertedFileToBase64(image: File): void {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      this.image = reader.result;
    };
  }
}
