<authentication-test></authentication-test>
<navigation selectedPage="user-detail-tabs">
  <div class="row justify-content-end">
    <button
      class="normal-btn mr-2"
      mat-button
      (click)="onGoToUserListClick()"
    >
      {{ 'REGISTRATION.BACK' | translate }}
    </button>
  </div>

  <mat-tab-group mat-stretch-tabs animationDuration="0ms">
    <mat-tab label="{{ 'USERS.USERDETAIL' | translate }}">
      <app-user-detail></app-user-detail>
    </mat-tab>
    <mat-tab label="{{ 'SHOP.LASTACTIVITY' | translate }}">
      <app-user-activities></app-user-activities>
    </mat-tab>
  </mat-tab-group>
</navigation>
