import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { LoyaltyCardPageCount } from './models/loyalty-card-page-count.model';
import { catchError } from 'rxjs/operators';
import { ServerResponse } from '../../shared/models/server-response.model';
import { centralaRequestHeaders } from '../../core/http/http-request';
import { LoyaltyCard } from './models/loyalty-card.model';
import { LoyaltyCardTransaction } from './models/loyalty-card-transaction.model';
import { LoyaltyCardTransactionPageCount } from './models/loyalty-card-transaction-page-count.model';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyCardService {
  private cardsUrl: string = Centrala.URL + '/api/cards';
  private cardsCountUrl: string = Centrala.URL + '/api/cards/count';
  private cardTransaction: string = Centrala.URL + '/api/card/transaction';
  private cardTransactionCount: string = Centrala.URL + '/api/card/transaction/count';
  private userWithoutAppUrl: string = Centrala.URL + '/api/user';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService,
  ) { }

  post(
    clientUserId: number,
    serialNumber: string,
  ): Observable<ServerResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<ServerResponse>(
        this.cardsUrl,
        {
          clientUserId: clientUserId.toString(),
          serialNumber: serialNumber,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  increaseBalance(
    cardId: number,
    amount: number,
  ): Observable<any> {
    const endpoint: string = `${this.cardsUrl}/${cardId}/balance-increase`;
    return this.httpClient
      .post(
        endpoint,
        {
          amount: amount,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        centralaRequestHeaders
      )
      .catch(this.httpHandlerService.handleError);
  }

  getCardList(
    filter: string,
    currentPage: number,
    pageCount: number,
    loyaltyCardId?: number,
  ): Observable<LoyaltyCard[]> {
    let params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('filter', filter)
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

    if (loyaltyCardId) {
      params = params.set('loyaltyCardId', loyaltyCardId.toString());
    }

    return this.httpClient
      .get<LoyaltyCard[]>(
        this.cardsUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getPageCount(filter: string): Observable<LoyaltyCardPageCount> {
    const params = new HttpParams()
      .set('filter', filter)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<LoyaltyCardPageCount>(
        this.cardsCountUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getTransactions(
    loyaltyCardId: number,
    currentPage: number,
    pageCount: number
  ): Observable<LoyaltyCardTransaction[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('loyaltyCardId', loyaltyCardId.toString())
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

    return this.httpClient
      .get<LoyaltyCardTransaction[]>(
        this.cardTransaction, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getTransactionPageCount(loyaltyCardId: number): Observable<LoyaltyCardTransactionPageCount> {
    const params = new HttpParams()
      .set('loyaltyCardId', loyaltyCardId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<LoyaltyCardTransactionPageCount>(
        this.cardTransactionCount, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  postWithoutApp(
    name: string,
    surname: string,
    phone: string,
    email: string,
    street: string,
    city: string,
    zip: string,
    country: string,
    birthDate: Date,
    contioStoreId: number
  ): Observable<ServerResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<ServerResponse>(
        this.userWithoutAppUrl,
        {
          name: name,
          surname: surname,
          phone: phone,
          email: email,
          street: street,
          city: city,
          zip: zip,
          country: country,
          birthDate: birthDate,
          contioStoreId: contioStoreId,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
