<authentication-test></authentication-test>
<navigation selectedPage="stock">
  <div class="row no-gutters">
    <div class="col-12">
      <button class="normal-btn" mat-button (click)="onGoToNewStockReceiveClick()">
        <i class="fas fa-arrow-down"></i>
        <span class="icon-name">{{ 'STOCK.NEWRECEIVE' | translate }}</span>
      </button>
      <button class="normal-btn ml-2 mt-2 mt-sm-0" mat-button (click)="onGoToNewStockWithdrawalClick()">
        <i class="fas fa-arrow-up"></i>
        <span class="icon-name">{{ 'STOCK.NEWPICKING' | translate }}</span>
      </button>
      <button class="normal-btn ml-0 ml-sm-2 mt-2 mt-sm-0" mat-button (click)="onGoToNewStockInternalClick()">
        <i class="fas fa-exchange-alt"></i>
        <span class="icon-name">{{ 'STOCK.NEWINTERNAL' | translate }}</span>
      </button>
    </div>
  </div>

  <hr class="hr-line" />

  <div class="row no-gutters w-100">
    <app-table 
    *ngIf="isDataTableLoaded"
    [columns]="columns"
    [data]="entityList"
    [totalCount]="stockCount"
    [currentPage]="currentPage"
    [selectable]="true"
    (selectedRow)="onGoToStockDetailClick($event)"
    (pageChange)="onPageChange($event)"
    ></app-table>
  </div>
</navigation>