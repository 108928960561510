<authentication-test></authentication-test>
<navigation selectedPage="orders">
  <div fxLayout fxLayoutGap="8px">
    <div class="input-group-prepend">
      <span class="input-group-text">{{ 'STOCK.SUPPLIER' | translate }}</span>
      <ng-autocomplete
        [data]="contacts"
        [searchKeyword]="keywordName"
        [(ngModel)]="selectedContact"
        *ngIf="contacts"
        (selected)="onSelectContact($event)"
        (inputCleared)="onInputCleared()"
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"
      >
      </ng-autocomplete>

      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item[keywordName]"></a>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
    <div class="item">
      <button
        class="btn btn-primary new-btn bigger-btn ml-2"
        (click)="onSelectContactClick()"
      >
        {{ 'ORDERS.SELECT' | translate }}
      </button>
    </div>
  </div>

  <hr class="line" [hidden]="isHidden" />

  <div class="row no-gutters" *ngIf="!isHidden">
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            {{ 'PRODUCTS.SEARCH' | translate }}</span
          >
        </div>
        <input
          id="search"
          type="search"
          class="form-control"
          aria-label="{{ 'PRODUCTS.SEARCH' | translate }}"
          aria-describedby="basic-addon1"
          [(ngModel)]="userSearch"
          autocomplete="on"
        />
      </div>
    </div>

    <div class="col-6">
      <button
        (click)="createDataForCSVClick()"
        class="btn btn-primary normal-btn float-right"
      >
        <i class="fas fa-download mr-2"></i>
        {{ 'ORDERS.CSV' | translate }}
      </button>
    </div>

    <table
      class="table table-bordered table-hover table-striped w-100 no-gutters"
    >
      <thead>
        <tr>
          <th scope="col" class="checkbox-th">
            <input
              type="checkbox"
              class="checkboxTableTitle"
              [checked]="isAllChecked()"
              (change)="onCheckAllChange($event)"
            />
          </th>
          <th scope="col" class="amount-td">
            <label class="titleLabel my-1">{{
              'PRODUCTS.PRODUCTAMOUNT' | translate
            }}</label>
          </th>
          <th scope="col" class="name-td">
            <label class="titleLabel my-1">{{
              'PRODUCTS.PRODUCTPLUIMPORT' | translate
            }}</label>
          </th>
          <th scope="col" class="name-td">
            <label class="titleLabel my-1">{{
              'PRODUCTS.TABLEPRODUCTNAME' | translate
            }}</label>
          </th>
          <th scope="col" class="group-td">
            <label class="titleLabel my-1">{{
              'PRODUCTS.TABLEPRODUCTGROUP' | translate
            }}</label>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="
            let storageCard of storageCards | filter: userSearch;
            index as i
          "
        >
          <th scope="row{{ i + 1 }}">
            <input
              type="checkbox"
              class="checkboxTable"
              [(ngModel)]="storageCard.Checked"
            />
          </th>
          <td>
            <input
              type="number"
              step="0.01"
              min="0"
              class="form-control"
              [disabled]="!storageCard.Checked"
              [ngClass]="{
                'is-invalid': !storageCard.Amount && storageCard.Checked
              }"
              [(ngModel)]="storageCard.Amount"
            />
          </td>
          <td>
            <label class="itemLabel my-1">{{ storageCard.CardNumber }}</label>
          </td>
          <td>
            <label class="itemLabel my-1">{{ storageCard.Name }}</label>
          </td>
          <td>
            <label class="itemLabel my-1">{{
              storageCard.StorageCardCategoryName
            }}</label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</navigation>
