<authentication-test></authentication-test>
<navigation selectedPage="categories">
  <mat-tab-group mat-stretch-tabs animationDuration="0ms">
    <mat-tab label="{{ 'NAVIGATION.CATEGORIES' | translate }}">
      <app-categories
        [categoryImage]="uploadedImageBase64"
        (imageBase64)="onCategorySelect($event)"
      ></app-categories>
    </mat-tab>
    <mat-tab label="{{ 'PICTURE.PICTURE' | translate }}">
      <app-image-upload
        #imageUploadComponent
        [image]="selectedCategoryImage"
        (imageBase64)="onImageUpload($event)"
      ></app-image-upload>
    </mat-tab>
  </mat-tab-group>
</navigation>
