import { Injectable } from '@angular/core';
import { Company } from '../../../modules/company-settings/models/company.model';
import { IsdocService } from '../isdoc/isdoc.service';

@Injectable({
  providedIn: 'root',
})
export class AresService {
  constructor(
    private isdocService: IsdocService,
  ) { }

  parseAresXml(xml: any): Company {
    let company: Company = new Company();
    const xmlData: any = this.isdocService.parseAres(xml);
    const aresCompany = xmlData.__zone_symbol__value;
    company.Name = aresCompany.name;
    company.State = aresCompany.state;
    company.City = aresCompany.city;
    company.Street = aresCompany.street;
    company.Zip = aresCompany.zip;
    return company;
  }
}
