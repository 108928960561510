import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DropdownType } from './dropdown-type.enum';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent<T> implements OnInit, OnChanges {
  dropdownType: any = DropdownType;
  name: string;
  isMenuOpen: boolean = false;
  activeItem: T;
  isDropdownInvalid: boolean = false;

  @Input() items: T[];
  @Input() itemKey: string;
  @Input() label: string;
  @Input() selected: T;
  @Input() type?: string;

  @Output() selectedChange = new EventEmitter<T>();

  ngOnInit(): void {
    if (this.items && this.selected && this.selected[this.itemKey]) {
      this.activeItem = this.items.find((x) => x[this.itemKey] === this.selected[this.itemKey]);
      this.name = this.activeItem[this.itemKey];
      this.selectedChange.emit(this.activeItem);
    } else {
      this.isDropdownInvalid = true;
    }
  }

  ngOnChanges(): void {
    if (this.name) {
      this.isDropdownInvalid = false;
      this.activeItem = this.items.find((x) => x[this.itemKey] === this.selected[this.itemKey]);
      this.name = this.activeItem[this.itemKey];
      this.selectedChange.emit(this.activeItem);
    }
  }

  onSelectItem(item: T): void {
    this.name = item[this.itemKey];
    this.selectedChange.emit(item);
  }

  onMenuOpen() {
    this.isMenuOpen = true;
  }

  onMenuClose() {
    this.isMenuOpen = false;
  }
}
