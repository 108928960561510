import { TableCategory } from './table-category.model';

export class Table {
    id: number;

    tableCategory: TableCategory;

    constructor(table: Partial<Table>) {
        Object.assign(this, table);
    }
}
