import { Directive, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { GridSetting } from '../../models/grid-setting.model';

@Directive()
export abstract class ListBaseDirective<T> {
    @ViewChild(MatPaginator) paginator?: MatPaginator;

    pageSize: number = GridSetting.pageLimit;

    abstract getList$: Observable<T[]>;
    abstract currentPage: number;
    abstract entityList: T[];

    constructor() { }

    abstract onPageChange(pageIndex: number): void;

    setList(): void {
        this.getList$.subscribe(
            (entityList) => { this.entityList = entityList; }
        );
    }
}
