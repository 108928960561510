import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '../../../../../core/interfaces/shared/table-columns.model';
import { Observable } from 'rxjs';
import { Activity } from '../../../../../modules/contio-store/contio-store-activity-list/models/activity.model';
import { ActivityTypePipe } from '../../../../../shared/pipes/activity-type.pipe';
import { ListBaseDirective } from '../../../../../shared/components/authentication-test/list-base.component';
import { ActivityService } from '../../../../../modules/contio-store/activity.service';
import { LocalStorageService } from '../../../../../core/services/shared/local-storage.service';
import { GridSetting } from '../../../../../shared/models/grid-setting.model';

@Component({
  selector: 'app-user-activities',
  templateUrl: './user-activities.component.html',
})
export class UserActivitiesComponent
  extends ListBaseDirective<Activity>
  implements OnInit {
  clientUserId: number;
  userId: number;

  isDataTableLoaded: boolean = false;
  currentPage: number;
  activityCount: number;
  entityList: Activity[];
  columns: TableColumn[];
  getList$: Observable<Activity[]>;

  error: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private activityTypePipe: ActivityTypePipe,
    private activityService: ActivityService,
    private localStorageService: LocalStorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.clientUserId = this.activatedRoute.snapshot.params.value;
    this.userId = this.localStorageService.getUserId();

    this.columns = this.getUserActivitiesColums();

    this.onPageChange(0);
  }

  onPageChange(currentPage: number): void {
    this.activityService
      .getActivitiesByClientUserId(
        this.userId,
        this.clientUserId,
        currentPage,
        GridSetting.pageLimit,
      ).subscribe({
        next: (activityList) => {
          this.activityCount = activityList.totalCount;
          this.entityList = activityList.items;

          this.isDataTableLoaded = true;
        },
        error: (error) => {
          this.error = error;
        }
      });
  }

  private getUserActivitiesColums(): TableColumn[] {
    return [
      {
        columnDef: 'Type',
        header: this.translateService.instant('BITKASA.TXTYPE'),
        cell: (element: Activity) => `${this.activityTypePipe.transform(element.Type)}`,
      },
      {
        columnDef: 'LogLevel',
        header: '',
        cell: (element: Activity) => `${element.LogLevel}`,
      },
      {
        columnDef: 'Description',
        header: this.translateService.instant('BITKASA.DESC'),
        cell: (element: Activity) => `${element.Description}`,
      },
      {
        columnDef: 'Timestamp',
        header: this.translateService.instant('BITKASA.TIMESTAMP'),
        cell: (element: Activity) => `${this.datePipe.transform(element.Timestamp, 'd. M. y h:mm:ss')}`,
      },
    ];
  }
}
