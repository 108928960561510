import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateService } from '../../../core/services/formatter/ngb-date.service';
import { StorageCard } from '../../../core/interfaces/stock/storage-card.model';
import { Warehouse } from '../../company-settings/models/warehouse.model';
import { Contact } from '../../contacts/models/contact.model';
import { StockGetNoteRecord } from '../../../core/interfaces/stock/stock-get-note-record.model';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { StockBaseDirective } from '../stock-base.component';
import { TranslateService } from '@ngx-translate/core';
import { StockService } from '../stock.service';
import { StoreService } from '../../company-settings/store.service';
import { ContactService } from '../../contacts/contact.service';
import { SkuService } from '../../../core/services/sku/sku.service';
import { WarehouseService } from '../../company-settings/warehouse.service';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { StockNoteType } from '../stock-note-type.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StockNote } from '../models/stock.model';

@Component({
  selector: 'app-stock-note',
  templateUrl: './stock-note.component.html',
  styleUrls: ['./stock-note.component.scss'],
})
export class StockNoteComponent
  extends StockBaseDirective
  implements OnInit {
  @Input() set entityListUpdate(entityList: StockGetNoteRecord[]) {
    entityList.forEach(record => {
      this.entityList.splice(0, 0, record);
      this.entityList = [...this.entityList];
    });

    this.recordsCount = this.entityList.length;
  }

  @Input() set invoiceDate(date: Date) {
    if (date) {
      this.document.Date = this.dateService.ngbDateFilter(
        new Date(date).getTime() / 1000
      );
    }
  }

  @Input() set invoiceNumber(invoiceNo: number) {
    if (invoiceNo) {
      this.document.InvoiceNo = invoiceNo;
    }
  }

  @Output() selectedWarehouseChange: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();
  @Output() fileUploadChange: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() storageCardListChange: EventEmitter<StorageCard[]> = new EventEmitter<StorageCard[]>();

  isSubmitted: boolean = false;
  isAutocompleteRecordValidate: boolean = false;
  isDropdownValidate: boolean = false;
  isStockNoteRecordSubmitted: boolean = false;
  isStorageCardListLoaded: boolean = false;
  isStockRowEdit: boolean = false;
  isStoreDataLoaded: boolean;

  noteId: number;
  noteType: string;
  stockNoteTypes = StockNoteType;

  validateRecord: string = '';
  isDataTableLoaded: boolean;
  contactList: Contact[];
  selectedContact: Contact;

  document: any;

  storageCardList: StorageCard[];
  selectedStorageCard: StorageCard;

  recordsCount: number = 0;
  unitPrice: number;
  quantity: number;

  selectedEntity: StockGetNoteRecord;

  today: Date = new Date();
  readonly placement: string = 'bottom';
  readonly keyword: string = 'Name';
  readonly defaultFilter: string = '';
  readonly minDate: Partial<NgbDate> = {
    year: 2000,
    month: 1,
    day: 1,
  };
  maxDate: NgbDate;

  warehouseList: Warehouse[];
  selectedWarehouse: Warehouse;
  stockCardResponse: CreateRecordResponse;

  stockForm: FormGroup;
  stockNoteForm: FormGroup;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public localStorageService: LocalStorageService,
    public translateService: TranslateService,
    public stockService: StockService,
    public storeService: StoreService,
    public dateService: NgbDateService,
    public contactService: ContactService,
    private skuService: SkuService,
    private warehouseService: WarehouseService,
    private snackBarService: SnackBarService,
  ) {
    super(
      router,
      route,
      storeService,
      localStorageService,
      contactService,
      translateService,
      stockService,
      dateService,
    );
  }

  ngOnInit(): void {
    this.selectedContact = null;
    this.noteId = Number(this.route.snapshot.paramMap.get('value'));

    this.route.pathFromRoot[1].url.subscribe(urlSegment => {
      if (urlSegment[1].path === 'receive') {
        this.noteType = StockNoteType.Receive;
      } else {
        this.noteType = StockNoteType.Withdrawal;
      }

      if (urlSegment[2].path === 'new') {
        this.isDataTableLoaded = true;
      } else {
        this.fetchNote();
      }
    });

    this.setMaxDate();
    this.setWarehouseList();
    this.getCompanyContacts();

    this.document = {};
    this.document.Date = this.maxDate;

    this.isDropdownValidate = true;

    this.stockForm = this.formBuilder.group({
      invoiceNumber: ['', Validators.required],
    });

    this.stockNoteForm = this.formBuilder.group({
      stockNoteQuantity: ['', Validators.required],
      stockNoteUnitPrice: ['', Validators.required],
    });

    this.onPageChange(0);
  }

  get validateStockNoteForm() {
    return this.stockNoteForm.controls;
  }

  get validateStockForm() {
    return this.stockForm.controls;
  }

  onNoteRecordTableSelect(noteRecord: StockGetNoteRecord): void {
    if (noteRecord) {
      this.selectedStorageCard = this.storageCardList.find(storageCard => storageCard.Id === noteRecord.StorageCardId);
      this.selectedEntity = noteRecord;
      this.isStockRowEdit = true;
      this.quantity = noteRecord.Quantity;
      this.unitPrice = noteRecord.UnitPrice;
      this.isAutocompleteRecordValidate = false;
    } else {
      this.clear();
    }
  }

  onSaveStockCardClick(): void {
    if (!this.selectedContact) {
      this.isSubmitted = true;
      return;
    }

    if (this.stockForm.invalid) {
      return;
    }

    if (this.entityList.length <= 0) {
      this.snackBarService.show('VALIDATOR.ROWVALID', false);
      return;
    }

    const note: StockNote = {
      Id: 0,
      CompanyId: this.localStorageService.getCompanyId(),
      ContactId: this.selectedContact.Id,
      WarehouseId: this.selectedWarehouse.Id,
      InvoiceNo: this.document.InvoiceNo,
      Date: this.document.Date,
      Issuer: this.selectedContact.Name,
      IsFinished: true,
      Type: this.noteType,
    };

    if (this.noteId) {
      if (!note.ContactId || note.ContactId !== this.document.ContactId) {
        this.document.ContactId = note.ContactId;
      }

      if (!this.document.Date.year) {
        const unixDate = Date.parse(this.document.Date);
        const tmpDate = new Date(unixDate);

        this.document.Date = {};
        this.document.Date.year = tmpDate.getFullYear();
        this.document.Date.month = tmpDate.getMonth();
        this.document.Date.day = tmpDate.getDate();

        note.Date = this.document.Date;
      }

      this.setTotalCount();

      note.Id = this.noteId;
      this.stockService
        .put(
          note,
          this.entityList)
        .subscribe({
          next: (successResponse) => {
            this.stockCardResponse = successResponse;

            if (this.noteType === StockNoteType.Receive) {
              this.snackBarService.show('SKU.EDITINCOMECARDSUCESS', successResponse.success);
            } else {
              this.snackBarService.show('SKU.EDITWITHDRAWALCARDSUCESS', successResponse.success);
            }

            this.redirectToList();
          },
          error: (error) => {
            this.error = error;

            if (this.noteType === StockNoteType.Receive) {
              this.snackBarService.show('SKU.EDITINCOMECARDFAIL', false);
            } else {
              this.snackBarService.show('SKU.EDITWITHDRAWALCARDFAIL', false);
            }
          },
        });
    } else {
      this.document.Date.month = this.document.Date.month - 1;
      this.stockService
        .post(
          note,
          this.entityList
        )
        .subscribe({
          next: (successResponse) => {
            this.stockCardResponse = successResponse;

            if (this.noteType === StockNoteType.Receive) {
              this.snackBarService.show('SKU.ADDINCOMECARDSUCESS', successResponse.success);
            } else {
              this.snackBarService.show('SKU.ADDWITHDRAWALCARDSUCESS', successResponse.success);
            }
            this.redirectToList();
          },
          error: (error) => {
            this.error = error;

            if (this.noteType === StockNoteType.Receive) {
              this.snackBarService.show('SKU.ADDINCOMECARDFAIL', false);
            } else {
              this.snackBarService.show('SKU.ADDWITHDRAWALCARDFAIL', false);
            }
          },
        });
    }
  }

  onAddNoteRecordClicl(): void {
    this.isStockNoteRecordSubmitted = true;

    if (this.validateRecord === '' || !this.selectedStorageCard.Id) {
      this.isAutocompleteRecordValidate = true;
      return;
    }

    if (this.stockNoteForm.invalid) {
      return;
    }

    const noteRecord: StockGetNoteRecord = {
      StorageCardId: this.selectedStorageCard.Id,
      Name: this.selectedStorageCard.Name,
      Quantity: this.quantity,
      UnitPrice: this.unitPrice,
      Total: this.quantity * this.unitPrice,
    };

    this.entityList.splice(0, 0, noteRecord);
    this.entityList = [...this.entityList];
    this.recordsCount = this.entityList.length;

    this.clear();
  }

  onEditNoteRecordClick(): void {
    this.isStockNoteRecordSubmitted = true;

    if (!this.selectedStorageCard.Id) {
      this.isAutocompleteRecordValidate = true;
      return;
    }

    if (this.stockNoteForm.invalid) {
      return;
    }

    const arrayIndex = this.entityList.findIndex((note) => note.StorageCardId === this.selectedEntity.StorageCardId);

    const noteRecord: StockGetNoteRecord = {
      StorageCardId: this.selectedStorageCard.Id,
      Name: this.selectedStorageCard.Name,
      Quantity: this.quantity,
      UnitPrice: this.unitPrice,
      Total: this.quantity * this.unitPrice,
    };

    this.entityList[arrayIndex] = noteRecord;
    this.entityList = [...this.entityList];
    this.recordsCount = this.entityList.length;

    this.clear();
  }

  onDeleteNoteRecordClick(): void {
    const arrayIndex = this.entityList.findIndex((note) => note === this.selectedEntity);

    if (arrayIndex > -1) {
      this.entityList.splice(arrayIndex, 1);
      this.entityList = [...this.entityList];
      this.recordsCount = this.entityList.length;
    }

    this.clear();
  }

  onContactChangeSearch(contact: Contact): void {
    this.selectedContact = contact;
  }

  onInputCleared(): void {
    this.validateRecord = '';
  }

  selectEvent(contact: Contact): void {
    this.validateRecord = contact.Name;
    this.isAutocompleteRecordValidate = false;
  }

  onChangeStorageCardSearch(storageCard: StorageCard): void {
    this.selectedStorageCard = storageCard;
  }

  onWarehouseSelectChange(): void {
    this.selectedWarehouseChange.emit(this.selectedWarehouse);
  }

  setStorageCardList(): void {
    this.skuService
      .get(
        this.defaultFilter,
        GridSetting.firstPage,
        GridSetting.listLimit
      )
      .subscribe({
        next: (storageCardList) => {
          this.storageCardList = storageCardList;
          this.storageCardListChange.emit(storageCardList);
          this.isStorageCardListLoaded = true;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onChangeFileInput(file: Event): void {
    this.fileUploadChange.emit(file);
  }

  private setWarehouse(): void {
    if (this.warehouseList != null && this.warehouseList.length > 0) {
      this.selectedWarehouse = this.warehouseList[0];

      this.onWarehouseSelectChange();
      this.setStorageCardList();
    }
  }

  private setWarehouseList(): void {
    this.warehouseService
      .getList()
      .subscribe({
        next: (warehouseList) => {
          this.warehouseList = warehouseList;

          this.setWarehouse();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private clear(): void {
    this.isStockNoteRecordSubmitted = false;
    this.validateRecord = '';
    this.isStockRowEdit = false;
    this.selectedStorageCard = null;
    this.quantity = null;
    this.unitPrice = null;
    this.isAutocompleteRecordValidate = false;
  }

  private setTotalCount(): void {
    this.entityList.forEach(note => {
      note.Total = note.Quantity * note.UnitPrice;
    });
  }
}
