import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { TableColumn } from '../../../core/interfaces/shared/table-columns.model';
import { ListBaseDirective } from '../../../shared/components/authentication-test/list-base.component';
import { Activity } from './models/activity.model';
import { ActivityService } from '../activity.service';
import { TranslateService } from '@ngx-translate/core';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { DatePipe } from '@angular/common';
import { ActivityTypePipe } from '../../../shared/pipes/activity-type.pipe';
import { ActivityType } from './activity-type.enum';
import { DateTimeService } from '../../../core/services/formatter/date-time.service';
import { ActiveButton } from '../../../core/enums/active-button.enum';

@Component({
  selector: 'app-contio-store-activity-list',
  templateUrl: './contio-store-activity-list.component.html',
  styleUrls: ['./contio-store-activity-list.component.scss'],
})
export class ContioStoreActivityListComponent
  extends ListBaseDirective<Activity>
  implements OnInit {
  storeId: number;

  startDate: Date;
  endDate: Date;
  monthAgo: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
  today: Date = new Date();
  startDateTimestamp: number;
  endDateTimestamp: number;
  activityType: ActivityType;

  activityCount: number;
  entityList: Activity[];
  currentPage: number = 0;
  columns: TableColumn[];
  isDataTableLoaded: boolean = false;
  getList$: Observable<Activity[]>;
  activeButton: ActiveButton;
  buttonStateType: any = ActiveButton;

  activityTypeList: string[] = [ActivityType.All, ActivityType.Entrance, ActivityType.Kiosk, ActivityType.Receipt];

  error: string;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private activityService: ActivityService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private activityTypePipe: ActivityTypePipe,
    private dateTimeService: DateTimeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.storeId = Number(this.activatedRoute.snapshot.params.value);
    this.columns = this.getContioStoreActivitiesColums();

    this.onShowAllItemsClick();
  }

  onPageChange(pageIndex?: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.activityService.getActivitiesByStoreId(
      this.storeId,
      true,
      this.currentPage,
      GridSetting.pageLimit,
      this.startDateTimestamp,
      this.endDateTimestamp,
      this.activityType
    );

    this.setList();
  }

  onUpdateFilterClick(
    dateFrom: Date,
    dateTo: Date,
    activityType: ActivityType
  ): void {
    this.startDateTimestamp = this.dateTimeService.convertDateToUnixTimestamp(dateFrom);
    this.endDateTimestamp = this.dateTimeService.convertDateToUnixTimestamp(dateTo);
    this.activityType = activityType;

    this.setActivityPageCount();
  }

  onShowAllItemsClick(): void {
    this.startDateTimestamp = this.dateTimeService.convertDateToUnixTimestamp(this.monthAgo);
    this.endDateTimestamp = this.dateTimeService.convertDateToUnixTimestamp(this.today);
    this.activityType = null;
    this.startDate = this.monthAgo;
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate = this.today;

    this.setActivityPageCount();
  }

  onSetButtonActiveClick(buttonType: ActiveButton): void {
    this.activeButton = buttonType;
  }

  isButtonActive(buttonType: ActiveButton): boolean {
    return this.activeButton === buttonType;
  }

  onGoToContioShopDetailClick(): void {
    this.location.back();
  }

  private setActivityPageCount(): void {
    this.activityService
      .getPageCount(
        this.storeId,
        this.startDateTimestamp,
        this.endDateTimestamp,
        this.activityType
      )
      .subscribe({
        next: activityCount => {
          this.activityCount = activityCount[0].activityCount;
          this.isDataTableLoaded = true;

          this.onPageChange(0);
        },
        error: error => {
          this.error = error;
        },
      });
  }

  private getContioStoreActivitiesColums(): TableColumn[] {
    return [
      {
        columnDef: 'Type',
        header: this.translateService.instant('BITKASA.TXTYPE'),
        cell: (element: Activity) => `${this.activityTypePipe.transform(element.Type)}`,
      },
      {
        columnDef: 'LogLevel',
        header: '',
        cell: (element: Activity) => `${element.LogLevel}`,
      },
      {
        columnDef: 'Description',
        header: this.translateService.instant('BITKASA.DESC'),
        cell: (element: Activity) => `${element.Description}`,
      },
      {
        columnDef: 'Timestamp',
        header: this.translateService.instant('BITKASA.TIMESTAMP'),
        cell: (element: Activity) => `${this.datePipe.transform(element.Timestamp, 'd. M. y h:mm:ss')}`,
      },
    ];
  }
}
