import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  skuForm: FormGroup;
  productForm: FormGroup;
  contactForm: FormGroup;
  tableForm: FormGroup;
  ingredientForm: FormGroup;
  storeForm: FormGroup;
  warehouseForm: FormGroup;
  cashRegisterForm: FormGroup;
  companyForm: FormGroup;
  loyaltyCardForm: FormGroup;

  active: number = 0;
  activeForm: number = 0;
  activePageForm: number = 0;
  activeSubForm: number = 0;

  constructor(private formBuilder: FormBuilder) {}

  skuValidator(): void {
    this.active = 1;
    this.skuForm = this.formBuilder.group({
      cardNumber: ['', Validators.required],
      cardName: ['', Validators.required],
    });
  }

  productValidator(
    name: string,
    plu: number): void {
    this.active = 2;
    this.productForm = this.formBuilder.group({
      productName: [name, Validators.required],
      productPrice: ['', Validators.required],
      productPlu: [plu, Validators.required],
    });
  }

  productDiscountValidator(name: string): void {
    this.active = 2;
    this.productForm = this.formBuilder.group({
      productName: [name, Validators.required],
      productDiscount: ['', Validators.required],
    });
  }

  contactValidator(): void {
    this.active = 4;
    this.contactForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      companyBin: ['', Validators.required],
    });
  }

  tableValidator(): void {
    this.active = 5;
    this.tableForm = this.formBuilder.group({
      tableNumber: ['', Validators.required],
    });
  }

  storeValidator(): void {
    this.active = 6;
    this.storeForm = this.formBuilder.group({
      storeName: ['', Validators.required],
    });
  }

  loyaltyCardValidator(): void {
    this.active = 7;
    this.loyaltyCardForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
    });
  }

  get validate() {
    if (this.active === 1) {
      return this.skuForm.controls;
    }

    if (this.active === 2) {
      return this.productForm.controls;
    }

    if (this.active === 4) {
      return this.contactForm.controls;
    }

    if (this.active === 5) {
      return this.tableForm.controls;
    }

    if (this.active === 6) {
      return this.storeForm.controls;
    }

    if (this.active === 7) {
      return this.loyaltyCardForm.controls;
    }
  }

  ingredientValidator(): void {
    this.activeForm = 1;
    this.ingredientForm = this.formBuilder.group({
      ingredientCoefficient: ['', Validators.required],
    });
  }

  warehouseValidator(): void {
    this.activeForm = 2;
    this.warehouseForm = this.formBuilder.group({
      warehouseName: ['', Validators.required],
    });
  }

  get validateForm() {
    if (this.activeForm === 1) {
      return this.ingredientForm.controls;
    }

    if (this.activeForm === 2) {
      return this.warehouseForm.controls;
    }
  }

  cashRegisterValidator(): void {
    this.activeSubForm = 1;
    this.cashRegisterForm = this.formBuilder.group({
      cashregisterName: ['', Validators.required],
    });
  }

  get validateSubForm() {
    if (this.activeSubForm === 1) {
      return this.cashRegisterForm.controls;
    }
  }

  companyValidator(): void {
    this.activePageForm = 1;
    this.companyForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      companyBin: ['', Validators.required],
    });
  }

  get validatePageForm() {
    if (this.activePageForm === 1) {
      return this.companyForm.controls;
    }
  }
}
