import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { Activity } from './contio-store-activity-list/models/activity.model';
import { ActivityPageCount } from './contio-store-activity-list/models/activity-page-count.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ActivityType } from './contio-store-activity-list/activity-type.enum';
import { ListResponse } from '../../core/interfaces/shared/list-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private clientActivitiesUrl: string = Centrala.URL + '/api/client/activities';
  private activitiesUrl: string = Centrala.URL + '/api/activities';
  private activitiesCountUrl: string = Centrala.URL + '/api/activities/count';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  getActivitiesByStoreId(
    storeId: number,
    subscribeMqtt: boolean,
    currentPage: number,
    pageCount: number,
    dateFrom: number,
    dateTo: number,
    type?: ActivityType,
  ): Observable<Activity[]> {
    let params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('StoreId', storeId.toString())
      .set('SubscribeMqtt', subscribeMqtt.toString())
      .set('current_page', currentPage.toString())
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString())
      .set('page_count', pageCount.toString());

      if (type) {
        params = params.append('type', type);
      }

    return this.httpClient
      .get<Activity[]>(
        this.activitiesUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getActivitiesByClientUserId(
    userId: number,
    clientUserId: number,
    currentPage: number,
    pageCount: number,
  ): Observable<ListResponse<Activity>> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('userId', userId.toString())
      .set('clientUserId', clientUserId.toString())
      .set('currentPage', currentPage.toString())
      .set('pageCount', pageCount.toString());

    return this.httpClient
      .get<ListResponse<Activity>>(
        this.clientActivitiesUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getPageCount(
    storeId: number,
    dateFrom: number,
    dateTo: number,
    type: ActivityType,
  ): Observable<ActivityPageCount> {
    let params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString())
      .set('StoreId', storeId.toString())
      .set('type', type);

    return this.httpClient
      .get<ActivityPageCount>(
        this.activitiesCountUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
