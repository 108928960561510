export class ProductRequest {
  Id: number;
  BatchId: number;
  BatchName?: string;
  TaxId: number;
  Text: string = '';
  Price: number;
  Unit: string;
  UnitId: number;
  mobileWaiter: boolean = false;
  Barcode: string = '';
  plu: number;
  Abbreviation: string = '';
  IsHidden: boolean = false;
  IsStock: boolean = false;
  VariablePrice: boolean = false;
  VariableName: boolean = false;
  VariableQuantity: boolean = false;
  Kitchen: boolean = false;
  IsPackaging: boolean = false;
  IsDiscount: boolean = false;
  Discount: number;
  IsRedeemable: boolean = false;
  IsWeighted: boolean = false;
  IsAdulthood: boolean = false;
  IsSelfcheckoutWeighted: boolean = false;
  Image?: string = null;
}
