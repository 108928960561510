import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../shared/http-handler.service';
import { SkuPageCount } from '../../interfaces/sku/sku-page-count.model';
import { catchError } from 'rxjs/operators';
import { SkuCard } from '../../interfaces/sku/sku-card.model';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { SkuPostResponse } from '../../../modules/sku/models/sku-post-response.model';
import { StorageCard } from '../../interfaces/stock/storage-card.model';
import { SkuIngredient } from '../../../../app/modules/sku/models/sku-ingredient.model';

@Injectable({
  providedIn: 'root',
})
export class SkuService {
  private storageCardUrl: string = Centrala.URL + '/api/storageCard';
  private storageCardWithIngredientUrl: string = Centrala.URL + '/api/recipe/addCard';
  private paginationUrl: string = Centrala.URL + '/api/storageCard/pagination';
  private storageCardByContactIdUrl: string = Centrala.URL + '/api/storageCardByContactId';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  getCardListForContact(
    contactId: number,
    currentPage: number,
    pageCount: number
  ): Observable<SkuCard[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('contactId', contactId.toString())
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

    return this.httpClient
      .get<SkuCard[]>(
        this.storageCardByContactIdUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getPageCount(
    warehouseId: number,
    filter: string
  ): Observable<SkuPageCount> {
    const params = new HttpParams()
      .set('warehouseId', warehouseId.toString())
      .set('filter', filter)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<SkuPageCount>(
        this.paginationUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  post(sku: StorageCard): Observable<SkuPostResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<SkuPostResponse>(
        this.storageCardUrl,
        {
          CardNumber: sku.CardNumber,
          Name: sku.Name,
          Barcode: sku.Barcode,
          StorageCardUnitsId: sku.StorageCardUnitsId,
          MinState: sku.MinState,
          Packaging: sku.Packaging,
          MinDurability: sku.MinDurability,
          StorageCardCategoryId: sku.StorageCardCategoryId,
          WarehouseId: sku.WarehouseId,
          Note: sku.Note,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  postWithIngredient(skuIngredient: SkuIngredient): Observable<SkuPostResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<SkuPostResponse>(
        this.storageCardWithIngredientUrl,
        {
          StorageCard: skuIngredient.StorageCard,
          ProductId: skuIngredient.ProductId,
          Coefficient: skuIngredient.Coefficient,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  put(sku: StorageCard): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<CreateRecordResponse>(
        this.storageCardUrl,
        {
          Id: sku.Id,
          CardNumber: sku.CardNumber,
          Name: sku.Name,
          Barcode: sku.Barcode,
          StorageCardUnitsId: sku.StorageCardUnitsId,
          MinState: sku.MinState,
          Packaging: sku.Packaging,
          MinDurability: sku.MinDurability,
          StorageCardCategoryId: sku.StorageCardCategoryId,
          WarehouseId: sku.WarehouseId,
          Note: sku.Note,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Get sku note records
   * @param warehouseId {number}
   * @param currentPage {number}
   * @param pageCount {number}
   */
  get(
    filter: string,
    currentPage: number,
    pageCount: number,
    warehouseId?: number
  ): Observable<StorageCard[]> {
    let params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('filter', filter)
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

    if (warehouseId) {
      params = params.append('warehouseId', warehouseId.toString());
    }

    return this.httpClient
      .get<StorageCard[]>(
        this.storageCardUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
