import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { User } from '../models/user.model';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { TableColumn } from '../../../core/interfaces/shared/table-columns.model';
import { TranslateService } from '@ngx-translate/core';
import { ListBaseDirective } from '../../../shared/components/authentication-test/list-base.component';
import { Observable } from 'rxjs';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserListComponent
  extends ListBaseDirective<User>
  implements OnInit {
  activeButton: number = 0;
  userInputFilter: string = '';
  userFilter: string = '';

  currentPage: number = 0;
  pageIndex: number;
  userCount: number = 0;
  entityList: User[] = [];

  isIncompletedRegistration: boolean = false;

  columns: TableColumn[];
  isDataTableLoaded: boolean = false;
  getList$: Observable<User[]>;

  error: string;

  readonly allowedFormats: Array<string> = ['.csv'];

  constructor(
    private router: Router,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.columns = this.getUserListColums();
    this.localStorageService.autenticateContioUser();

    this.getUserPageCount();
  }

  onUpdateFilterClick(filter: string): void {
    const filteredUsers = filter.toString().toLowerCase().trim();
    this.userFilter = filteredUsers;

    this.getUserPageCount();
  }

  onGoToUserDetailCLick(user: User): void {
    this.router.navigate(['/user/detail/' + user.ClientUserId]);
  }

  onSetButtonActiveClick(activeButton: number): void {
    this.activeButton = activeButton;
  }

  onPageChange(pageIndex?: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.userService.getList(
      this.userFilter,
      this.currentPage,
      GridSetting.pageLimit,
      null,
      this.isIncompletedRegistration
    );
    this.setList();
  }

  isButtonActive(activeButton: number): boolean {
    return this.activeButton === activeButton;
  }

  onCheckChange(event: boolean): void {
    this.isIncompletedRegistration = event;
    this.getUserPageCount();
  }

  private getUserPageCount(): void {
    this.userService
      .getPageCount(
        this.userFilter,
        this.isIncompletedRegistration,
      )
      .subscribe({
        next: (userCount) => {
          this.userCount = userCount[0].clientCount;
          this.isDataTableLoaded = true;

          this.onPageChange(0);
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private getUserListColums(): TableColumn[] {
    return [
      {
        columnDef: 'Name',
        header: this.translateService.instant('USERS.NAME'),
        cell: (element: User) => `${element.Name}`,
      },
      {
        columnDef: 'Surname',
        header: this.translateService.instant('USERS.SURNAME'),
        cell: (element: User) => `${element.Surname}`,
      },
      {
        columnDef: 'PhoneNumber',
        header: this.translateService.instant('USERS.PHONE'),
        cell: (element: User) => `${element.PhoneNumber}`,
      },
      {
        columnDef: 'Email',
        header: this.translateService.instant('USERS.EMAIL'),
        cell: (element: User) => `${element.Email}`,
        isResponsive: true,
      },
    ];
  }

  onFileUpload(upload): void {
    let file = upload.target.files.item(0);
    let reader = new FileReader();

    reader.readAsText(file);
    reader.onload = (event: any) => {
      let csvBase64 = btoa(encodeURIComponent(event.target.result));
      this.userService.uploadFile(csvBase64).subscribe({
        next: importResponse => {
          if (importResponse.success) {
            this.snackBarService.show('USERS.IMPORTSUCCESS', true);
          } else {
            this.snackBarService.show('USERS.IMPORTFAIL', false, importResponse.message);
          }
        },
        error: () => {
          this.snackBarService.show('USERS.IMPORTFAIL', false);
        },
      });
    }
  }
}
