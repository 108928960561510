<div class="initial-row w-100">
  <button class="normal-btn" mat-button (click)="onSetNewGroupTemplateClick(!areInputElementsVisible)">
    <i *ngIf="areInputElementsVisible" class="fas fa-plus"></i>
    <i *ngIf="!areInputElementsVisible" class="fas fa-minus"></i>
    <span class="icon-name">{{ 'PRODUCTS.ADDPRODUCT' | translate }}</span>
  </button>
</div>

<div class="content" *ngIf="!areInputElementsVisible">
  <div fxLayout="row wrap" fxLayoutGap="16px grid">
    <div fxFlex="100%" fxFlex.gt-md="50%">
      <app-input-text
        *ngIf="selectedLanguage && selectedProductGroup.Translation"
        [(input)]="selectedProductGroup.Translation[selectedLanguage.Language]"
        [submit]="isSubmit"
        type="text"
        label="[{{ selectedLanguage.Language }}] {{ 'MODAL.NAME' | translate }}"  
        placeholder="{{ 'PRODUCTS.NAME' | translate }}"
        errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
        [required]="true"
      ></app-input-text>
    </div>
    <div fxFlex="100%" fxFlex.gt-md="50%">
      <app-input-color-picker
        [(input)]="selectedProductGroup.HexColor"
        type="text"
        label="{{ 'PRODUCTS.HEXCOLOR' | translate }}"
      ></app-input-color-picker>
    </div>
    <div fxFlex="100%" fxFlex.gt-sm="50%">
      <app-dropdown
        *ngIf="selectedLanguage"
        [(selected)]="selectedLanguage"
        label="{{ 'CATEGORIES.LANGUAGE' | translate }}"
        itemKey="Language"
        [items]="languages"
      ></app-dropdown>
    </div>
    <div fxFlex="100%" fxFlex.gt-sm="50%">
      <app-dropdown
        *ngIf="selectedParentGroup"
        [(selected)]="selectedParentGroup"
        label="{{ 'CATEGORIES.PARENTGROUP' | translate }}"
        itemKey="Name"
        [items]="parentGroups"
      ></app-dropdown>
    </div>
    <div fxFlex="100%" fxLayoutAlign="end">
      <button class="save-btn" mat-button (click)="onGroupSaveClick(isEdit)">
        <i class="fas fa-save"></i>
        <span *ngIf="!isEdit" class="icon-name">{{ 'STORES.NEW' | translate }}</span>
        <span *ngIf="isEdit" class="icon-name">{{ 'PRODUCTS.SAVE' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<mat-divider></mat-divider>
<mat-progress-bar *ngIf="!isDataTableLoaded" mode="indeterminate"></mat-progress-bar>
<app-table
  *ngIf="isDataTableLoaded"
  [activeRow]="selectedProductGroup"
  [columns]="columns"
  [data]="entityList"
  [totalCount]="categoryCount"
  [currentPage]="currentPage"
  [selectable]="true"
  (selectedRow)="onRowSelect($event)"
  (pageChange)="onPageChange($event)"
></app-table>
<mat-divider></mat-divider>
