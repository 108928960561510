<authentication-test
  (success)="authenticationResult($event)"
></authentication-test>
<navigation selectedPage="settings">
  <mat-tab-group mat-stretch-tabs animationDuration="0ms">
    <mat-tab label="{{ 'SETTINGS.SUNMI' | translate }}">
      <app-sunmi-shop></app-sunmi-shop>
    </mat-tab>
  </mat-tab-group>
</navigation>
