import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageTags } from '../../../core/enums/local-storage-tags.enum';
import { Centrala } from '../../../core/http/centrala';
import { HttpHandlerService } from '../../../core/services/shared/http-handler.service';
import { VerificationLevel } from './models/verification-level.model';

@Injectable({
  providedIn: 'root',
})
export class VerificationLevelService {
  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService,
  ) { }

  getList(): Observable<VerificationLevel[]> {
    const url: string = Centrala.URL + '/api/verification-levels';
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<VerificationLevel[]>(
        url, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
