import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../shared/http-handler.service';
import { StoreCardCategory } from '../../interfaces/store-card-category/store-card-category.model';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StorageCardCategoryService {
  private categoryUrl: string = Centrala.URL + '/api/storageCardCategory';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  post(
    name: string,
    warehouseId: number
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.categoryUrl,
        {
          storeId: warehouseId,
          name: name,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  put(
    id: number,
    name: string
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put(
        this.categoryUrl,
        {
          Id: id,
          name: name,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  get(storeId: number): Observable<StoreCardCategory[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('storeId', storeId.toString());

    return this.httpClient
      .get<StoreCardCategory[]>(
        this.categoryUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
