import { environment } from './../../../environments/environment';

export namespace Centrala {
  export const URL: string = environment.useProductionServer
    ? environment.serverUrl
    : 'http://localhost:443';
  export const wsURL: string = environment.useProductionServer
    ? 'wss://arrowsys.biz:1337/'
    : 'ws://localhost:1337/';

  export const AdminId: number = 1060;
}
