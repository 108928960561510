import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { map, catchError } from 'rxjs/operators';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { TableAdapter } from './models/table-adapter.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TablePageCount } from './models/table-page-count.model';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';
import { Table } from './models/table.model';

@Injectable({
    providedIn: 'root',
})
export class TableService {
    private tableUrl: string = Centrala.URL + '/api/table';
    private paginationUrl: string = Centrala.URL + '/api/table/pagination';

    constructor(
        private httpClient: HttpClient,
        private httpHandlerService: HttpHandlerService,
        private tableAdapter: TableAdapter
    ) { }

    getPageCount(storeId: number): Observable<TablePageCount> {
        const params = new HttpParams()
            .set('storeId', storeId.toString())
            .set('token', localStorage.getItem(LocalStorageTags.Token));
        return this.httpClient
            .get<TablePageCount>(
                this.paginationUrl, { params }
            )
            .pipe(
                catchError(this.httpHandlerService.handleError)
            );
    }

    getList(
        storeId: number,
        currentPage: number,
        pageCount: number
    ): Observable<Table[]> {
        const params = new HttpParams()
            .set('token', localStorage.getItem(LocalStorageTags.Token))
            .set('storeId', storeId.toString())
            .set('current_page', currentPage.toString())
            .set('page_count', pageCount.toString());
        return this.httpClient
            .get<Table[]>(
                this.tableUrl, { params }
            )
            .pipe(
                map((json) => this.tableAdapter.getList(json)),
                catchError((error) =>
                    this.httpHandlerService.handleError(error)
                )
            );
    }

    post(
        zoneId: number,
        tableCategoryNumber: number
    ): Observable<CreateRecordResponse> {
        const headers = { 'Content-Type': 'application/json' };

        return this.httpClient
            .post<CreateRecordResponse>(
                this.tableUrl,
                {
                    zoneId: zoneId,
                    number: tableCategoryNumber,
                    token: localStorage.getItem(LocalStorageTags.Token),
                },
                { headers }
            )
            .catch(this.httpHandlerService.handleError);
    }

    put(
        tableId: number,
        zoneId: number,
        tableCategoryNumber: number
    ): Observable<CreateRecordResponse> {
        const headers = { 'Content-Type': 'application/json' };

        return this.httpClient
            .put<CreateRecordResponse>(
                this.tableUrl,
                {
                    tableId: tableId,
                    zoneId: zoneId,
                    number: tableCategoryNumber,
                    token: localStorage.getItem(LocalStorageTags.Token),
                },
                { headers }
            )
            .catch(this.httpHandlerService.handleError);
    }
}
