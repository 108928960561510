import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { Zone } from '../../core/interfaces/zone/zone.model';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';
import { EditRecordResponse } from '../../shared/models/edit-record-response.model';

@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  private zoneUrl: string = Centrala.URL + '/api/zone';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  post(
    name: string,
    storeId: number
  ): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<CreateRecordResponse>(
        this.zoneUrl,
        {
          storeId: storeId,
          name: name,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  getList(storeId: number): Observable<Zone[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('storeId', storeId.toString());
    return this.httpClient
      .get<Zone[]>(
        this.zoneUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  put(
    name: string,
    id: number
  ): Observable<EditRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<EditRecordResponse>(
        this.zoneUrl,
        {
          name: name,
          Id: id,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
