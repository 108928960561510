import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../shared/http-handler.service';
import { StorageCardUnit } from '../../interfaces/storage-card-unit/storage-card-unit.model';
import { catchError } from 'rxjs/operators';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { EditRecordResponse } from '../../../shared/models/edit-record-response.model';

@Injectable({
  providedIn: 'root',
})
export class StorageCardUnitService {
  private unitUrl: string = Centrala.URL + '/api/storageCardUnit';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  /**
   * Create new unit
   * @param name {string}
   * @param storeId {string}
   */
  post(
    name: string,
    storeId: number
  ): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<CreateRecordResponse>(
        this.unitUrl,
        {
          storeId: storeId,
          name: name,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Edit unit
   * @param id {number}
   * @param name {string}
   */
  put(
    id: number,
    name: string
  ): Observable<EditRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<EditRecordResponse>(
        this.unitUrl,
        {
          Id: id,
          name: name,
          token: localStorage.getItem(LocalStorageTags.Token),
          companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Get unit for Store
   * @param storeId {number}
   * @param currentPage {number}
   * @param pageCount {number}
   */
  get(
    storeId: number,
  ): Observable<StorageCardUnit[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('storeId', storeId.toString());

    return this.httpClient
      .get<StorageCardUnit[]>(
        this.unitUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
