import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from '../../../core/interfaces/shared/table-columns.model';
import { GridSetting } from '../../models/grid-setting.model';
import { ResponsiveSetting } from '../../models/responsive-setting.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit {
  @Input() activeRow?: T;
  @Input() data: T[];
  @Input() columns: TableColumn[];
  @Input() pageSize: number;
  @Input() totalCount: number;
  @Input() set currentPage(pageIndex: number) {
    if (pageIndex === undefined) {
      return;
    }

    if (this.paginator) {
      this.paginator.pageIndex = pageIndex - 1;
    }
  }
  @Input() selectable: boolean = false;
  @Output() selectedRow: EventEmitter<T> = new EventEmitter<T>();
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(MatPaginator) paginator?: MatPaginator;

  tableColumns: TableColumn[];
  displayedColumns: string[];
  dataSource: MatTableDataSource<T>;

  isResponsiveView: boolean;

  colorColumn: string = 'HexColor';
  imageColumn: string = 'Image';
  iconColumn: string = 'LogLevel';

  readonly defaultPageSize: number = GridSetting.pageLimit;

  constructor() { }

  ngOnInit(): void {
    const screenWidth = window.innerWidth;

    if (screenWidth < ResponsiveSetting.mobileMenuPixelThreshold) {
      this.isResponsiveView = true;
    }
    
    this.setColumns();
  }

  setColumns(): void {
    if (this.isResponsiveView) {
      this.displayedColumns = this.columns.filter((c) => !c.isResponsive ).map((c) => c.columnDef);
    } else {
      this.displayedColumns = this.columns.map((c) => c.columnDef);
    }
  }

  onResize(event: any) {
    const screenWidth = event.target.innerWidth;

    if (screenWidth < ResponsiveSetting.mobileMenuPixelThreshold) {
      this.isResponsiveView = true;
    } else {
      this.isResponsiveView = false;
    }

    this.setColumns();
  }

  onPageChange(): void {
    this.pageChange.emit(this.paginator?.pageIndex);
  }

  onRowSelect(row: T): void {
    if (this.activeRow === row && this.selectable) {
      this.activeRow = null;
      this.emitSelectedRow(null);
    } else if (this.activeRow !== row && this.selectable) {
      this.activeRow = row;
      this.emitSelectedRow(row);
    }
  }

  emitSelectedRow(row: T): void {
    this.selectedRow.emit(row);
  }
}
