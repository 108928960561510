<div class="custom-input">
  <mat-button-toggle-group appearance="legacy">
    <mat-button-toggle
    disabled
    >
      {{ label }}
    </mat-button-toggle>

    <mat-form-field>
      <mat-select required [(ngModel)]="selectedItem" (ngModelChange)="onChange(selectedItem)">
        <mat-option *ngFor="let item of items" [value]="item" class="pb-1">
          <span *ngIf="isPipeForActivityType">{{item | activityType: item}}</span>
          <span *ngIf="!isPipeForActivityType">{{item}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-button-toggle-group>
</div>
