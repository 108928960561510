import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminService } from './admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditRecordResponse } from '../../shared/models/edit-record-response.model';
import { finalize } from 'rxjs/operators';
import { SnackBarService } from '../../core/services/shared/snackbar.service';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';
import { User } from './models/admin-user.model';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';
import { Contact } from '../contacts/models/contact.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  isAutocompleteInvalid: boolean = false;
  isUserCompanyLoaded: boolean = false;

  companyName: string = '';
  readonly searchKeyword: string = 'Email';

  userList: User[];
  user: User;
  selectedUser: User;
  selectedContact: Contact;
  userContactList: Contact[];

  companyPostResponse: CreateRecordResponse;
  userContioEdit: EditRecordResponse;

  error: string;

  constructor(
    private adminService: AdminService,
    private modalService: NgbModal,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
  ) { }

  onOpenAddCompanyModal(content: string): void {
    this.modalService
      .open(
        content,
        { windowClass: 'dark-modal' }
      );
  }

  ngOnInit(): void {
    this.localStorageService.autenticateAdmin();
    this.setUsers();
  }

  onUserSelect(user: User): void {
    this.selectedUser = user;
  }

  onSearchChange(): void {
    this.selectedUser = null;
    this.selectedContact = null;
    this.isUserCompanyLoaded = false;
  }

  onSetUserClick(): void {
    this.setUserCompanies();
    this.setUser();
  }

  postCompany(name: string): void {
    this.adminService
      .postCompany(
        this.selectedUser.Id,
        name
      )
      .subscribe({
        next: successResponse => {
          this.companyPostResponse = successResponse;
          if (this.companyPostResponse.success && this.selectedContact) {
            this.selectedContact.Name = name;
          }
          this.setUserCompanies();
        },
        error: error => {
          this.error = error;
        },
      });
  }

  editUserIsContio(): void {
    this.adminService
      .putIsContio(
        this.user.Id,
        this.user.IsContio
      )
      .pipe(
        finalize(() => {
          this.userContioEdit = null;
        })
      )
      .subscribe({
        next: putResponse => {
          this.userContioEdit = putResponse;

          this.snackBarService.show('ADMIN.SUCCESS', true);
        },
        error: error => {
          this.error = error;

          this.snackBarService.show('ADMIN.FAIL', false);
        },
      });
  }

  onClearSearchboxClick(): void {
    this.companyName = '';
  }

  onIsContioCheck(isContioCheckbox: boolean): boolean {
    return isContioCheckbox ? true : false;
  }

  private setUser(): void {
    this.adminService
      .getUser(this.selectedUser.Id)
      .subscribe({
        next: (users) => {
          this.user = users[0];
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setUserCompanies(): void {
    if (!this.selectedUser) {
      this.isAutocompleteInvalid = true;
      return;
    }

    this.adminService
      .getCompanyList(this.selectedUser.Id)
      .subscribe({
        next: (userContactList) => {
          this.userContactList = userContactList;
          this.setSelectedCompany();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setSelectedCompany(): void {
    if (this.userContactList.length === 0) {
      return;
    }

    if (!this.selectedContact) {
      this.selectedContact = this.userContactList[0];
    }

    this.isUserCompanyLoaded = true;
    this.isAutocompleteInvalid = false;
  }

  private setUsers(): void {
    this.adminService
      .getUsers()
      .subscribe({
        next: (userList) => {
          this.userList = userList;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
