import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParserService {

  constructor() { }

  parseNumber(input: string): number {
    var num = 0;
    num = +(input.toString().replace(',', '.'));
    return num;
  }
}
