<authentication-test></authentication-test>
<navigation selectedPage="sku">
  <mat-tab-group
    mat-stretch-tabs
    animationDuration="0ms"
    (selectedTabChange)="onTabChange($event)"
  >
    <mat-tab label="{{ 'SKU.STORAGECARD' | translate }}">
      <div class="row no-gutters table-row">
        <div class="row no-gutters mt-3 w-100">
          <div class="col-6">
            <button class="normal-btn" mat-button (click)="onNewSkuClick(!areInputElementsVisible);">
              <i *ngIf="areInputElementsVisible" class="fas fa-plus"></i>
              <i *ngIf="!areInputElementsVisible" class="fas fa-minus"></i>
              <span class="icon-name">{{ 'PRODUCTS.ADDPRODUCT' | translate }}</span>
            </button>
          </div>

          <div class="col-6 my-col">
            <div class="row no-gutters justify-content-end">
              <app-company-settings-warehouse
              [isWarehouseEdit]="false"
              [selectedWarehouse]="selectedWarehouse"
              (setSelectedWarehouse)="onSelectedWarehouseChange($event)"
              ></app-company-settings-warehouse>
            </div>
            <div
              class="row no-gutters justify-content-end"
              *ngIf="isWarehouseValidate && !selectedWarehouse"
            >
              <span
                class="help-block invalid-group w-auto text-right"
                >{{ 'VALIDATOR.WAREHOUSEVALID' | translate }}
                <span class="text-underline">
                  <a routerLink="/company-settings">{{
                    'VALIDATOR.ROUTER' | translate
                  }}</a> </span
                >.
              </span>
            </div>
          </div>
        </div>

        <div
          class="row no-gutters mt-3 w-100"
          *ngIf="!areInputElementsVisible"
        >
          <div
            class="col-xl-3 col-lg-6 col-md-12 col-sm-12 pr-xl-3 pr-lg-2 my-col form-group"
            [formGroup]="validatorService.skuForm"
          >
            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <span class="input-group-text" id="numberInput">{{
                  'SKU.SKUNUMBER' | translate
                }}</span>
              </div>
              <input
                [(ngModel)]="selectedSku.CardNumber"
                type="number"
                class="form-control normal-input"
                id="numberInput"
                aria-describedby="numberInput"
                formControlName="cardNumber"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    isSubmit && validatorService.validate.cardNumber.errors
                }"
              />
              <div
                *ngIf="isSubmit && validatorService.validate.cardNumber.errors"
                class="invalid-feedback div-right-margin text-right"
              >
                <div *ngIf="validatorService.validate.cardNumber.errors.required">
                  {{ 'VALIDATOR.VALIDATION' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-xl-3 col-lg-6 col-md-12 col-sm-12 px-xl-3 pl-lg-2 my-col form-group"
            [formGroup]="validatorService.skuForm"
          >
            <div class="input-group mb-1">
              <div class="input-group-prepend">
                <span class="input-group-text" id="nameInput">{{
                  'SKU.SKUNAME' | translate
                }}</span>
              </div>
              <input
                [(ngModel)]="selectedSku.Name"
                type="text"
                class="form-control normal-input"
                id="nameInput"
                placeholder="{{ 'PRODUCTS.NAME' | translate }}"
                formControlName="cardName"
                class="form-control"
                [ngClass]="{
                  'is-invalid': isSubmit && validatorService.validate.cardName.errors
                }"
              />
              <div
                *ngIf="isSubmit && validatorService.validate.cardName.errors"
                class="invalid-feedback div-right-margin text-right"
              >
                <div *ngIf="validatorService.validate.cardName.errors.required">
                  {{ 'VALIDATOR.VALIDATION' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-xl-3 col-lg-6 col-md-12 col-sm-12 px-xl-3 pr-lg-2 my-col"
          >
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="barcodeInput">{{
                  'PRODUCTS.PRODUCTBARCODE' | translate
                }}</span>
              </div>
              <input
                [(ngModel)]="selectedSku.Barcode"
                type="text"
                class="form-control normal-input"
                id="barcodeInput"
              />
            </div>
          </div>

          <div
            class="col-xl-3 col-lg-6 col-md-12 col-sm-12 pl-xl-3 pl-lg-2 my-col"
          >
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text" id="unitInput">{{
                  'SKU.SKUNOTE' | translate
                }}</span>
              </div>
              <input
                [(ngModel)]="selectedSku.Note"
                type="text"
                class="form-control normal-input"
                id="noteInput"
              />
            </div>
          </div>

          <div class="row no-gutters w-100">
            <div
              class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-xl-3 pr-xl-2 my-col overflow-auto"
            >
              <div class="input-group mb-3 mt-1">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="conditionInput">{{
                    'SKU.SKUMINIMUM' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="selectedSku.MinState"
                  type="number"
                  class="form-control normal-input"
                  id="conditionInput"
                />
              </div>
            </div>

            <div
              class="col-xl-4 col-lg-6 col-md-6 col-sm-12 my-col px-xl-3 mt-1"
            >
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="packagingInput">{{
                    'SKU.SKUPACKAGING' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="selectedSku.Packaging"
                  type="text"
                  class="form-control normal-input"
                  id="packagingInput"
                />
              </div>
            </div>

            <div
              class="col-xl-4 col-lg-6 col-md-6 col-sm-12 my-col pl-xl-3 pl-lg-2 pl-md-2 mt-1"
            >
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="shelfLifeInput">{{
                    'SKU.SKUSHELFLIFE' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="selectedSku.MinDurability"
                  type="text"
                  class="form-control normal-input"
                  id="shelfLifeInput"
                />
              </div>
            </div>
          </div>

          <div class="row no-gutters w-100">
            <div class="col-md-6 col-12 my-col w-auto">
              <app-sku-category-dropdown
              [isDropdownSet]="isDropdownSet"
              [selectedWarehouse]="selectedWarehouse"
              (categoriesChange)="onCategoryListChange($event)"
              [selectedCategory]="selectedCategory"
              (categoryChange)="onCategoryChange($event)"
              ></app-sku-category-dropdown>
            </div>
            <div class="col-md-6 col-12 w-auto mt-1">
              <app-sku-unit-dropdown
              [isDropdownSet]="isDropdownSet"
              [selectedWarehouse]="selectedWarehouse"
              (unitsChange)="onUnitListChange($event)"
              [selectedUnit]="selectedUnit"
              (selectedUnitChange)="onUnitChange($event)"
              ></app-sku-unit-dropdown>
            </div>
          </div>

          <div class="row no-gutters w-100 mt-2">
            <div class="col-sm-12">
              <app-sku-import
              [categoryList]="categoryList"
              [unitList]="unitList"
              [selectedWarehouse]="selectedWarehouse"
              (selectWarehouse)="onSelectedWarehouseChange($event)"
              (isImportSuccessChange)="onIsImportSuccessChange()"
              ></app-sku-import>
              <button
                [hidden]="isChangeSkuDisabled"
                [disabled]="isChangeSkuDisabled"
                (click)="onEditSkuClick();"
                class="btn btn-primary save-btn float-right"
                id="saveButton"
              >
                <i class="fas fa-save mr-1"></i>
                {{ 'PRODUCTS.SAVECHANGES' | translate }}
              </button>
              <div class="float-right">
                <button
                  [hidden]="isAddSkuDisabled"
                  [disabled]="isAddSkuDisabled"
                  (click)="onAddSkuClick();"
                  class="btn btn-primary save-btn float-right"
                  id="saveSkuButton"
                >
                  <i class="fas fa-save mr-1"></i>
                  {{ 'PRODUCTS.SAVENEWPRODUCT' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr
          class="hr-line"
        />

        <div class="row no-gutters w-100">
          <div class="col-sm-12">
            <div class="input-group-prepend float-right">
              <div class="input-group mt-1">
                <div class="input-group-prepend ">
                  <span class="input-group-text" id="basic-addon1">
                    {{ 'PRODUCTS.SEARCH' | translate }}</span
                  >
                </div>
                <input
                  id="search"
                  type="search"
                  class="form-control search-input"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  [(ngModel)]="skuFilter"
                />

                <button
                  type="button"
                  class="btn btn-secondary search-btn mr-1 ml-1"
                  [class.active]="isButtonActive(2)"
                  (click)="onUpdateFilterClick(skuFilter); setActive(2)"
                >
                  <i class="fas fa-search"></i>
                </button>

                <button
                  type="button"
                  class="btn btn-secondary search-btn mr-1"
                  [class.active]="isButtonActive(3)"
                  (click)="onUpdateFilterClick(''); setActive(3)"
                >
                  {{ 'PRODUCTS.ALL' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row no-gutters w-100">
          <mat-progress-bar *ngIf="!isDataTableLoaded" mode="indeterminate"></mat-progress-bar>
          <app-table
              *ngIf="isDataTableLoaded"
              [columns]="columns"
              [activeRow]="selectedSku"
              [data]="entityList"
              [totalCount]="skuCount"
              [selectable]="true"
              (selectedRow)="onRowSelect($event)"
              (pageChange)="onPageChange($event)"
          ></app-table>
        </div>
      </div>
    </mat-tab>

    <mat-tab
      label="{{ 'SKU.STORAGECARDMOVEMENTS' | translate }}"
      [disabled]="isReportDisabled"
    >
      <app-sku-movement
      *ngIf="isSkuSelected"
      [selectedSku]="selectedSku"
      ></app-sku-movement>
    </mat-tab>
  </mat-tab-group>
</navigation>
