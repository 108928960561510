import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubaccountValue } from './subaccount-value.enum';

@Pipe({
  name: 'subaccountAccess',
})
export class SubaccountAccessPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
  ) {}

  transform(subaccountValue: SubaccountValue): string {
    switch (subaccountValue) {
      case SubaccountValue.NoAccess:
        return this.translateService.instant('SHOP.NOACCESS');
      case SubaccountValue.Read:
        return this.translateService.instant('SHOP.READONLY');
      case SubaccountValue.ReadWrite:
        return this.translateService.instant('SHOP.READWRITE');
    }
  }

}
