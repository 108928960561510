<authentication-test
  (success)="authenticationResult($event)"
></authentication-test>

<div *ngIf="isAuthentication">
  <navigation selectedPage="user-settings">
    <mat-tab-group
      class="ml-3 ml-lg-2"
      mat-stretch-tabs
      animationDuration="0ms"
    >
      <!-- Tab 1 -->

      <mat-tab label="{{ 'NAVIGATION.SETTINGS' | translate }}">
        <div class="row no-gutters tab-overflow">
          <div class="col-12 col-lg-6 mt-3">
            <div class="card mr-lg-1">
              <div class="card-content">
                <h5 class="card-title mt-3 mb-3 ml-3 text-left h5-header">
                  {{ 'USERSETTINGS.TITLE' | translate }}
                </h5>

                <div class="row no-gutters mx-2">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{
                              'USERSETTINGS.OLDPASSWD' | translate
                            }}</span>
                          </div>
                          <input
                            type="password"
                            class="form-control normal-input"
                            id="oldPassword"
                            [(ngModel)]="oldPassword"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{
                              'USERSETTINGS.NEWPASSWD' | translate
                            }}</span>
                          </div>
                          <input
                            type="password"
                            class="form-control normal-input"
                            id="newPassword"
                            [(ngModel)]="newPassword"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text">{{
                              'USERSETTINGS.NEWPASSWDAGAIN' | translate
                            }}</span>
                          </div>
                          <input
                            type="password"
                            class="form-control normal-input"
                            id="newPasswordAgain"
                            [(ngModel)]="newPasswordAgain"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm">
                        <button
                          type="button"
                          class="btn normal-btn float-right mb-3"
                          (click)="
                            sendPasswordChangeReq(
                              oldPassword,
                              newPassword,
                              newPasswordAgain
                            )
                          "
                        >
                          {{ 'USERSETTINGS.SUBMIT' | translate }}
                        </button>
                      </div>
                    </div>

                    <div
                      *ngIf="changePassFormat.success"
                      class="alert alert-success alert-dismissable"
                    >
                      <strong *ngIf="translationCodeFormat">{{
                        translationCodeFormat | translate
                      }}</strong>
                    </div>
                    <div
                      *ngIf="changePassFormat.success === false"
                      class="alert alert-danger alert-dismissable"
                    >
                      <strong *ngIf="translationCodeFormat">{{
                        translationCodeFormat | translate
                      }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 mt-3">
            <div class="card ml-lg-1">
              <div class="card-content">
                <h5 class="card-title mt-3 mb-3 ml-3 text-left h5-header">
                  {{ 'USERSETTINGS.DEFAULTLANGUAGE' | translate }}
                </h5>

                <div class="row no-gutters mx-2 mb-3">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm">
                        <app-dropdown
                          *ngIf="selectedLanguage"
                          [(selected)]="selectedLanguage"
                          label="{{ 'CATEGORIES.LANGUAGE' | translate }}"
                          itemKey="Language"
                          [items]="languages"
                        ></app-dropdown>

                        <button class="save-btn float-right" mat-button (click)="onDefaultLanguageSaveClick()">
                          <i class="fas fa-save"></i>
                          <span class="icon-name">{{ 'PRODUCTS.SAVE' | translate }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <!-- Tab 2 -->

      <mat-tab label="{{ 'NAVIGATION.DANGER' | translate }}">
        <div class="row no-gutters tab-overflow">
          <div class="col-12 col-xl-6 mt-3">
            <div class="card">
              <div class="card-content">
                <h5 class="card-title mt-3 mb-3 ml-3 h5-header text-left">
                  {{ 'USERSETTINGS.RECEIPTDELETETITLE' | translate }}
                </h5>

                <div class="row no-gutters mx-2">
                  <div class="col-12">
                    <div class="row mb-3">
                      <div class="col-6">
                        <div class="input-group-prepend">
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-primary dropdown-tag line-height"
                            >
                              <span class="font-weight-normal">
                                {{ 'NAVIGATION.STORES' | translate }}
                              </span>
                            </button>
                            <div class="btn-group" ngbDropdown role="group">
                              <button
                                class="
                                  btn btn-primary
                                  dropdown-toggle-primary dropdown-box
                                "
                                id="dropdownBasic1"
                                data-toggle="dropdown"
                                ngbDropdownToggle
                              >
                                <span class="font-weight-normal">
                                  {{ selectedCompany.Name.substring(0, 27) }}
                                </span>
                              </button>
                              <div
                                ngbDropdownMenu
                                class="dropdown-menu"
                                aria-labelledby="dropdownBasic1"
                              >
                                <button
                                  *ngFor="let company of companyData"
                                  class="dropdown-item"
                                  (click)="changeCompany(company.Id)"
                                >
                                  {{
                                    company.Name.length > 45
                                      ? company.Name.substring(0, 45) + ' ...'
                                      : company.Name
                                  }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-6">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-danger"
                            (click)="openModalDanger(dangerModal)"
                          >
                            <i class="fas fa-trash-alt mr-1"></i>
                            {{ 'PRODUCTS.DELETEGROUP' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="receiptDeletedData.success == true"
                      class="alert alert-success alert-dismissable"
                    >
                      <strong>{{
                        'USERSETTINGS.RECEIPTDELETETRUE' | translate
                      }}</strong>
                    </div>
                    <div
                      *ngIf="receiptDeletedData.success == false"
                      class="alert alert-danger alert-dismissable"
                    >
                      <strong>{{
                        'USERSETTINGS.RECEIPTDELETEFALSE' | translate
                      }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 empty-space"></div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </navigation>

  <!-- MODAL DELETE DANGER ALERT -->
  <ng-template #dangerModal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'USERSETTINGS.AREYOUSURE' | translate }}</h4>
    </div>
    <div class="modal-body">
      <div class="row no-gutters">
        <div class="col-12">
          <div class="alert alert-danger alert-dismissable mt-3 text-center">
            <p>
              <strong>{{ 'VALIDATOR.IRREVERSIBLE' | translate }}</strong>
            </p>
            <span>
              {{ 'VALIDATOR.IRREVERSIBLEANNOTATION' | translate }}
              <p class="font-weight-normal d-inline-block">
                {{ selectedCompany.Name }}
              </p>
              ?
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="c('Close click')"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>

      <button
        type="button"
        class="btn btn-danger"
        (click)="deleteCompanyReceipts(selectedCompany.Id); c('Close click')"
      >
        <i class="fas fa-trash-alt mr-1"></i>
        {{ 'PRODUCTS.DELETEGROUP' | translate }}
      </button>
    </div>
  </ng-template>
</div>
