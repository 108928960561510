import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Warehouse } from './models/warehouse.model';
import { catchError } from 'rxjs/operators';
import { EditRecordResponse } from '../../shared/models/edit-record-response.model';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService {
  private warehousesUrl: string = Centrala.URL + '/api/warehouse';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  post(
    name: string,
    date: number,
    companyId: string
  ): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<CreateRecordResponse>(
        this.warehousesUrl,
        {
          CompanyId: companyId,
          name: name,
          date: date,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  put(
    companyId: number,
    id: number,
    name: string
  ): Observable<EditRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<EditRecordResponse>(
        this.warehousesUrl,
        {
          CompanyId: companyId,
          Id: id,
          name: name,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Get company warehouses
   */
  getList(): Observable<Warehouse[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('compId', localStorage.getItem(LocalStorageTags.CompanyId));
    return this.httpClient
      .get<Warehouse[]>(
        this.warehousesUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  delete(
    companyId: number,
    id: number
  ): Observable<CreateRecordResponse> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        'CompanyId': companyId,
        'Id': id,
        'token': localStorage.getItem(LocalStorageTags.Token),
      },
    };

    return this.httpClient
      .delete<CreateRecordResponse>(this.warehousesUrl, options)
      .catch(this.httpHandlerService.handleError);
  }
}
