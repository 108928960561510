<div class="row no-gutters mt-1">
        <app-dropdown
            *ngIf="isCategoryLoaded"
            [(selected)]="selectedCategory"
            (selectedChange)="selectedCategoryChange($event)"
            label="{{ 'PRODUCTS.CHOOSEGROUP' | translate }}"
            itemKey="Name"
            [items]="categoryList"
        ></app-dropdown>
        <div class="float-left">
            <mat-button-toggle-group appearance="legacy" aria-label="Choose Group" class="ml-1">
                <mat-button-toggle 
                class="normal-btn"
                [ngClass]="{
                    'dropdown-invalid':
                    isDropdownSet && !selectedCategory
                  }" 
                  (click)="onOpenCategoryPostDialogClick()"
                
                ><i class="fas fa-plus mr-1"></i>{{ 'PRODUCTS.ADDGROUP' | translate }}</mat-button-toggle>
                <mat-button-toggle 
                class="normal-btn mat-btn-group"
                [hidden]="!selectedCategory" 
                (click)="onOpenCategoryEditDialogClick()"
                >{{ 'STORES.EDIT' | translate }}</mat-button-toggle>
              </mat-button-toggle-group>
        </div>
    </div>
    <div class="row no-gutters" *ngIf="isDropdownSet && !selectedCategory">
        <div class="btn-group float-left">
            <button type="button" class="btn btn-primary hidden-btn mr-1 btn-heigh" [disabled]="true">
                <span class="font-weight-normal">
                    {{ 'PRODUCTS.CHOOSEGROUP' | translate }}
                </span>
            </button>

            <span class="help-block invalid-group text-right btn-width">{{ 'VALIDATOR.DROPDOWNVALID' | translate
                }}</span>
        </div>
</div>