import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { catchError } from 'rxjs/operators';
import { Analytics } from './contio-store-analytics/models/analytics.model';
import { ListResponse } from '../../core/interfaces/shared/list-response.interface';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private analyticsUlr: string = Centrala.URL + '/api/analytics';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService,
  ) { }

  getActivity(
    userId: number,
    storeId: number,
    dateFrom: Date,
    dateTo: Date,
  ): Observable<ListResponse<Analytics>> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('userId', userId.toString())
      .set('contioStoreId', storeId.toString())
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString());

    return this.httpClient
      .get<ListResponse<Analytics>>(
        this.analyticsUlr + '/activity', { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getBankId(
    storeId: number,
    dateFrom: Date,
    dateTo: Date,
  ): Observable<ListResponse<Analytics>> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('contioStoreId', storeId.toString())
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString());

    return this.httpClient
      .get<ListResponse<Analytics>>(
        this.analyticsUlr + '/bankId', { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getReceipt(
    userId: number,
    storeId: number,
    dateFrom: Date,
    dateTo: Date,
  ): Observable<ListResponse<Analytics>> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('userId', userId.toString())
      .set('contioStoreId', storeId.toString())
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString());

    return this.httpClient
      .get<ListResponse<Analytics>>(
        this.analyticsUlr + '/receipt', { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
