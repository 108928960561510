<div *ngIf="user" class="row mt-3 mb-3 w-100">
  <div class="col-xl-6 col-sm-12">
    <div class="card">
      <div class="card-content">
        <div class="row no-gutters">
          <div class="col-12">
            <h5 class="card-title mt-3 mb-3 ml-3 text-left detail-text">
              {{ 'USERS.NAME' | translate }}:
              <span class="font-weight-bold detail-text">
                {{ user.Name }} {{ user.Surname }}
              </span>
            </h5>

            <hr class="mx-2 custom-hr" />
          </div>

          <div class="col-6">
            <div class="ml-3">
              <p class="text-left detail-text">
                {{ 'USERS.PHONE' | translate }}:
                <span class="d-inline-block font-weight-bold">
                  {{ user.PhoneNumber }}
                </span>
              </p>
            </div>
          </div>

          <div class="col-6">
            <div class="ml-3">
              <p class="text-left detail-text">
                {{ 'USERS.EMAIL' | translate }}:
                <span class="d-inline-block font-weight-bold">
                  {{ user.Email }}
                </span>
              </p>
            </div>
          </div>

          <div class="col-6">
            <div class="ml-3">
              <p class="text-left detail-text">
                {{ 'USERS.GENDER' | translate }}:
                <span class="d-inline-block font-weight-bold">
                  {{ user.Gender | gender }}
                </span>
              </p>
            </div>
          </div>

          <div class="col-6">
            <div class="ml-3">
              <p class="text-left detail-text">
                {{ 'USERS.BIRTHDATE' | translate }}:
                <span class="d-inline-block font-weight-bold">
                  {{ user.BirthDate }}
                </span>
              </p>
            </div>
          </div>

          <div class="col-6">
            <div class="ml-3">
              <div class="custom-control custom-checkbox checkbox-lg float-left mb-4">
                <input type="checkbox" [(ngModel)]="user.Deactivated" class="custom-control-input" id="checkbox-3"
                  checked="isUserBlock" (ngModelChange)="onBlockUserCLick($event)" />
                <label class="custom-control-label" for="checkbox-3">{{
                  'USERS.BLOCK' | translate
                  }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>