import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputDialogOptions } from '../../../core/interfaces/shared/input-dialog-options.model';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements OnInit {
  minDate: Date;
  maxDate: Date;

  title: string;
  value?: string;
  isEdit: boolean;
  isDelete?: boolean;
  submit: boolean = false;

  updateDialog: MatDialogRef<DeleteDialogComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputDialogOptions,
    private dialogRef: MatDialogRef<InputDialogComponent>,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.value = this.data.value;
    this.isEdit = this.data.isEdit;
    this.isDelete = this.data.isDelete;

    this.setOptions();
  }

  setOptions(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize('500px');
    this.dialogRef.updatePosition({ top: '240px' });
  }

  onSubmitClick(input?: string): void {
    this.submit = true;

    if (input) {
      this.dialogRef.close(input);
    }
  }

  onOpenDeleteDialogClick(): void {
    this.updateDialog = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: this.title,
      },
    });
    this.updateDialog.afterClosed().subscribe((input) => {
      if (input) {
        this.dialogRef.close(true);
      }
    });
  }
}
