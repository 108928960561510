export enum LocalStorageTags {
    CompanyId = 'selectedCompanyId',
    Token = 'token',
    UserId = 'userId',
    Email = 'email',
    Iss = 'iss',
    // TODO: Containst array, should refactor to use only object
    // https://github.com/arrowsys/centrala-client/issues/133
    CompanyList = 'companyData',
    DefLang = 'defLang',
    StoreId = 'storeId',
    WarehouseId = 'warehouseId',
    Privilege = 'privilege',
}
