<div class="container pt-5 container-logo text-center">
  <img
    src="../img/centrala.png"
    alt="logo"
    class="w-75 h-75 pt-3 pb-4"
  />
</div>

<div
  (keyup.enter)="sendLogin(email.value, password.value)"
  class="container pt-5 input-div"
>
  <h1>{{ 'LOGIN.TITLE' | translate }}</h1>
  <div class="pt-2">
    <label for="name">Email</label>
    <input #email type="text" class="form-control" id="name" required />
  </div>

  <div class="pt-2">
    <label for="password">{{ 'LOGIN.PASSWORD' | translate }}</label>
    <input #password type="password" class="form-control" id="password" />
  </div>

  <div class="mt-3">
    <button
      type="button"
      class="btn btn-success"
      (click)="sendLogin(email.value, password.value)"
    >
      {{ 'LOGIN.SIGNIN' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary ml-2"
      (click)="goToRegistration()"
    >
      {{ 'LOGIN.REGISTRATION' | translate }}
    </button>
  </div>

  <div *ngIf="isDialogVisible" id="alertDiv" class="alert alert-danger alert-dismissable mt-3">
    <strong>{{ 'LOGIN.DANGERMESSAGE' | translate }}</strong>
    {{ codeMessage | translate }}
  </div>
</div>

<div
  class="text-center w-100 bg-white footer-div"
>
  <p class="pt-2">
    © {{ copyrightYear }}
    <a class="footer-a" href="https://www.arrowsys.cz"
      >ArrowSys s.r.o.</a
    >
  </p>
</div>
