import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ListResponse } from '../../core/interfaces/shared/list-response.interface';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { Centrala } from '../../core/http/centrala';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { Privilege } from './models/privilege.model';
import { Subaccount } from './models/subaccount.model';

@Injectable({
  providedIn: 'root',
})
export class SubaccountService {
  private subaccountUrl: string = Centrala.URL + '/api/subaccount';
  private subaccountDetailUrl: string = Centrala.URL + '/api/subaccount/';
  private priviligeUrl: string = Centrala.URL + '/api/privilege';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  getList(
    userId: number,
    currentPage: number,
    pageCount: number,
  ): Observable<ListResponse<Subaccount>> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('userId', userId.toString())
      .set('currentPage', currentPage.toString())
      .set('pageCount', pageCount.toString());

    return this.httpClient
      .get<ListResponse<Subaccount>>(
        this.subaccountUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  get(
    subaccountId: number,
  ): Observable<Subaccount> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('subaccountId', subaccountId.toString());

    return this.httpClient
      .get<Subaccount>(
        this.subaccountDetailUrl + subaccountId, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  post(
    subaccount: Subaccount,
    userId: number,
    priviligeList: Privilege[]
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
    .post<any>(
      this.subaccountUrl,
      {
        UserId: userId.toString(),
        Email: subaccount.Email,
        Name: subaccount.Name,
        Surname: subaccount.Surname,
        Password: subaccount.Password,
        IsDeactivated: subaccount.IsDeactivated,
        IsEmailConfirmed: subaccount.IsEmailConfirmed,
        token: localStorage.getItem(LocalStorageTags.Token),
        companyId: localStorage.getItem(LocalStorageTags.CompanyId),
        PrivilegeList: priviligeList,
      },
      { headers }
    )
    .catch(this.httpHandlerService.handleError);
  }

  putPrivilige(
    priviligeList: Privilege[],
  ): Observable<any> {
    return this.httpClient
      .put<any>(
        this.priviligeUrl,
        {
          PrivilegeList: priviligeList,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
      )
      .catch(this.httpHandlerService.handleError);
  }
}

