import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../core/services/login/login.service';
import { Token } from '../admin-panel/models/token.model';
import { Company } from '../company-settings/models/company.model';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../company-settings/company.service';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { Languages } from '../../core/enums/languages.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('modal')
  copyrightYear: number;
  codeMessage: string = '';
  companyData: Company[];
  tokenData: Token;
  errorMessage: string;
  validLogin: any;

  isDialogVisible: boolean = false;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private translate: TranslateService,
    private companyService: CompanyService
  ) {
    this.setLanguage();
  }

  setLanguage() {
    this.translate.addLangs([Languages.EN, Languages.CZ]);

    const browserLang = this.translate.getBrowserLang();

    if (this.isBrowserLanguageValid(browserLang, this.translate.getLangs())) {
      localStorage.setItem(LocalStorageTags.DefLang, browserLang);
      this.translate.setDefaultLang(browserLang);
      this.translate.use(browserLang);
    } else {
      localStorage.setItem(LocalStorageTags.DefLang, Languages.EN);
      this.translate.setDefaultLang(Languages.EN);
      this.translate.use(Languages.EN);
    }

    this.translate.setDefaultLang(localStorage.getItem(LocalStorageTags.DefLang));
    this.translate.use(localStorage.getItem(LocalStorageTags.DefLang));
  }

  isBrowserLanguageValid(browserLang: string, langs: string[]) {
    for (let i = 0; i < langs.length; i++) {
      if (langs[i] === browserLang) {
        return true;
      }
    }

    return false;
  }

  ngOnInit(): void {
    if (localStorage.getItem(LocalStorageTags.Token) && localStorage.getItem(LocalStorageTags.UserId)) {
      this.getCompanies();
      return;
    }

    localStorage.clear();
  }

  ngAfterViewInit() {
    this.updateCopyrightYear();
  }

  updateCopyrightYear() {
    setTimeout(() => {
      this.copyrightYear = new Date().getFullYear();
    }, 0);
  }

  sendLogin(
    email: string,
    password: string
  ): void {
    if (email === '' || password === '') {
      this.isDialogVisible = true;
      this.codeMessage = 'CODE201';
      return;
    }

    this.loginService
    .singIn(email, password)
    .subscribe({
      next: (loginData) => {
        this.validLogin = loginData;

        if (!this.validLogin.success) {
          this.setErrorMessage();
        } else {
          this.tokenData = this.getDataFromToken(this.validLogin.token);
          this.setTokenData(this.validLogin.token, this.tokenData);
        }
      },
      error: (error) => {
        this.errorMessage = error;
      },
    });
  }

  setErrorMessage() {
    this.isDialogVisible = true;

    switch (this.validLogin.code) {
      case 201:
        this.codeMessage = 'CODE201';
        break;
      case 202:
        this.codeMessage = 'CODE201';
        break;
      case 203:
        this.codeMessage = 'CODE203';
        break;
      case 204:
        this.codeMessage = 'CODE204';
        break;
      case -1:
        this.codeMessage = 'CODE-1';
        break;
    }
  }

  setTokenData = (rawToken: any, tokenData: any) => {
    localStorage.setItem(LocalStorageTags.Token, rawToken);
    localStorage.setItem(LocalStorageTags.Iss, tokenData.iss);
    localStorage.setItem(LocalStorageTags.UserId, JSON.stringify(tokenData.userId));
    localStorage.setItem(LocalStorageTags.Email, tokenData.email);

    if (tokenData.isSubAccount) {
      localStorage.setItem(LocalStorageTags.Privilege, JSON.stringify(tokenData.privilegeList));
    }

    this.getCompanies();
  }

  getDataFromToken(token: string): Token {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  setCompaniesToLocalStorage(): void {
    localStorage.setItem(LocalStorageTags.CompanyList, JSON.stringify(this.companyData));
    localStorage.setItem(
      LocalStorageTags.CompanyId,
      this.companyData[0].Id.toString()
    );

    this.goToDashboard();
  }

  getCompanies(): void {
    let error;

    this.companyService.getByUserId(localStorage.getItem(LocalStorageTags.UserId)).subscribe(
      (extractData) => (this.companyData = extractData),
      (handleError) => (error = <any>handleError),
      () => {
        if (!localStorage.getItem(LocalStorageTags.CompanyId)) {
          this.setCompaniesToLocalStorage();
        } else {
          this.goToDashboard();
        }
      }
    );
  }

  goToRegistration(): void {
    this.router.navigate(['/registration']);
  }

  goToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }
}
