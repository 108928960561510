import { Color } from '@angular-material-components/color-picker';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-color-picker',
  templateUrl: './input-color-picker.component.html',
  styleUrls: ['../../../../../assets/scss/input.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputColorPickerComponent<T extends string | number> implements OnInit {
  formControlName: string = 'name';
  floatLabelControl: FormControl = new FormControl('never');
  hideRequiredControl: FormControl = new FormControl(true);
  type: string = 'color';

  @Input() input?: T;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() errorMessage: string;
  @Input() required: boolean = false;
  @Input() submit: boolean = false;

  @Output() inputChange: EventEmitter<string | number> = new EventEmitter<string | number>();

  form: FormControl = new FormControl('', [Validators.required]);

  ngOnInit(): void {
    this.hideRequiredControl = new FormControl(!this.required);
  }

  onChange(input?: T): void {
    if (input instanceof Color) {
      this.inputChange.emit(`#${input.hex}`);
    }
  }
}
