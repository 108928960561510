<div class="custom-input">
  <mat-button-toggle-group appearance="legacy">
    <mat-button-toggle
      [ngClass]="{ 'input-error': form.invalid && submit }"
      disabled
    >
      {{ label }}{{ required ? '*' : '' }}
    </mat-button-toggle>

    <mat-form-field
      [ngClass]="{ invalid: submit && !input }"
      [floatLabel]="floatLabelControl.value"
      [hideRequiredMarker]="hideRequiredControl.value"
      novalidate
    >
      <input
        matInput
        [min]="minDate"
        [max]="maxDate"
        [matDatepicker]="picker"
        [formControl]="form"
        [placeholder]="placeholder"
        [value]="input"
        [(ngModel)]="input"
        (ngModelChange)="onChange(input)"
        [disabled]="useMaterial"
      />
      <mat-datepicker #picker></mat-datepicker>
      <mat-icon matSuffix class="error" *ngIf="form.invalid && submit"
        >error_outline</mat-icon
      >
    </mat-form-field>

    <mat-button-toggle [disabled]="useMaterial" mat-raised-button (click)="picker.open()">
      <i class="far fa-calendar-alt text-dark"></i>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-error *ngIf="form.invalid && submit">{{ errorMessage }}</mat-error>
</div>
