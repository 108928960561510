import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'day',
})
export class DayPipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(dayOfWeek: number): string {
    switch (dayOfWeek) {
      case 0:
        return this.translateService.instant('DASHBOARD.DAYS.1');
      case 1:
        return  this.translateService.instant('DASHBOARD.DAYS.2');
      case 2:
        return this.translateService.instant('DASHBOARD.DAYS.3');
      case 3:
        return this.translateService.instant('DASHBOARD.DAYS.4');
      case 4:
        return this.translateService.instant('DASHBOARD.DAYS.5');
      case 5:
        return this.translateService.instant('DASHBOARD.DAYS.6');
      case 6:
        return this.translateService.instant('DASHBOARD.DAYS.7');
    }
  }

}
