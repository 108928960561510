import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { ReturnPageCount } from './models/return-page-count.model';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Return } from './models/return.model';
import { ReturnType } from './models/return-type.model';
import { ServerResponse } from '../../shared/models/server-response.model';

@Injectable({
  providedIn: 'root',
})
export class ReturnService {
  private returnsUrl: string = Centrala.URL + '/api/returns';
  private returnsCountUrl: string = Centrala.URL + '/api/returns/count';
  private returnTypesUrl: string = Centrala.URL + '/api/returnTypes';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  get(
    dateFrom: number,
    dateTo: number,
    currentPage: number,
    pageCount: number,
    returnId?: number,
  ): Observable<Return[]> {
    let params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString())
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

      if (returnId) {
        params = params.set('returnId', returnId.toString());
      }

    return this.httpClient
      .get<Return[]>(
        this.returnsUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getPageCount(
    dateFrom: number,
    dateTo: number
  ): Observable<ReturnPageCount> {
    const params = new HttpParams()
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<ReturnPageCount>(
        this.returnsCountUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getTypes(): Observable<ReturnType[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<ReturnType[]>(
        this.returnTypesUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  edit(
    returnId: number,
    returnTypeId: number
  ): Observable<ServerResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<ServerResponse>(
        this.returnTypesUrl,
        {
          returnTypeId: returnTypeId,
          returnId: returnId,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
