<div
  *ngIf="!file && !image"
  class="dropzone mt-5"
  appDragAndDrop
  (onFileDropped)="onFileUpload($event)"
>
  <input
    type="file"
    [accept]="allowedFormats"
    (change)="onInputFileUpload($event)"
  />
  <i class="fas fa-upload fa-2x mb-3 mt-2"></i>
  <h3>{{ 'PICTURE.DRAGNDROP' | translate }}</h3>
  <h3>{{ 'PICTURE.OR' | translate }}</h3>
  <label class="mt-2">{{ 'PICTURE.BROWSE' | translate }}</label>
  <h4 class="mt-2">{{ 'PICTURE.RECOMANDATION' | translate }}</h4>
</div>
<div *ngIf="file || image" class="files-list">
  <div class="single-file">
    <div class="decription">
      <div class="info">
        <div>
          <h4 *ngIf="file && image" class="name">
            {{ file?.name }}
          </h4>
          <p *ngIf="file && image" class="size">
            {{ file?.size | fileSize }}
          </p>
        </div>
        <div class="buttons">
          <button
            *ngIf="!isUpload && !isSquareRatio"
            class="btn btn-secondary show-crop"
            [class.active]="showCropDetail"
            (click)="onShowCropDetailClick(canShowCropDetail);"
          >
            <i class="fas fa-search"></i>
          </button>
          <button
            *ngIf="!isUpload || image"
            class="btn btn-danger img-delete"
            (click)="onDeleteFileClick()"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
      <mat-progress-bar *ngIf="isUpload" mode="indeterminate"> </mat-progress-bar>
    </div>
    <div>
      <image-cropper
        *ngIf="!isUpload || image"
        class="img-preview mt-2"
        [imageBase64]="image"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [cropperMinWidth]="cropSize"
        [cropperMinHeight]="cropSize"
        [cropperMaxWidth]="cropSize"
        [cropperMaxHeight]="cropSize"
        [hideResizeSquares]="isSquareRatio"
        format="png"
        (imageCropped)="onImageCrop($event)"
      ></image-cropper>
      <img
        *ngIf="canShowCropDetail"
        class="cropped-image mt-2"
        [src]="croppedImage"
      />
    </div>
  </div>
</div>
