import { Component, ViewChild } from '@angular/core';
import { ImageUploadComponent } from '../image-upload/image-upload.component';

@Component({
  selector: 'app-categories-tabs',
  templateUrl: './categories-tabs.component.html'
})
export class CategoriesTabsComponent {
  selectedCategoryImage: string;
  uploadedImageBase64: string;

  @ViewChild('imageUploadComponent', { static: false }) imageUploadComponent: ImageUploadComponent;

  onCategorySelect(imageBase64: string): void {
    this.imageUploadComponent.deleteFile();
    this.selectedCategoryImage = imageBase64;
    this.uploadedImageBase64 = imageBase64;
  }

  onImageUpload(imageBase64: string): void {
    this.uploadedImageBase64 = imageBase64;
  }
}
