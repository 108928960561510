import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { NgbDateService } from '../../core/services/formatter/ngb-date.service';
import { DateTimeService } from '../../core/services/formatter/date-time.service';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';
import { CustomChartType } from './custom-chart-type.enum';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { LineChart } from './models/line-chart.model';
import { CurrencyService } from '../../core/services/currency/currency.service';
import { Currency } from '../../core/interfaces/currency/currency.interface';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public customChartType = CustomChartType;

  today: Date = new Date();
  yearAgo: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  monthAgo: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
  weekAgo: Date = new Date(new Date().setDate(new Date().getDate() - 7));
  readonly minDate: Partial<NgbDate> = {
    year: 2000,
    month: 1,
    day: 1,
  };
  maxDate: NgbDate;
  readonly placement: string = 'bottom';

  filterType: CustomChartType;
  startDate: number;
  endDate: number;
  pastDay: number;
  dateFrom: NgbDate;
  dateTo: NgbDate;

  activeButton: CustomChartType = CustomChartType.Daily;

  screenWidth: number = 0;
  companyId: number;

  lineChart: LineChart[];
  graphData: any[];
  graphSum: number = 0;

  currencies: Currency[] = [];
  selectedCurrency: Currency;

  readonly isXAxisVisible: boolean = true;
  readonly isYAxisVisible: boolean = true;
  readonly isXAxisLabelVisible: boolean = false;
  readonly isYAxisLabelVisible: boolean = false;
  readonly isLegendVisible: boolean = false;
  readonly isGradient: boolean = true;
  readonly isAutoscale: boolean = false;

  lineScheme: object = {
    domain: ['rgba(80,80,160,1)'],
  };

  error: string;

  constructor(
    private dashboardService: DashboardService,
    private ngbDateService: NgbDateService,
    private dateTimeService: DateTimeService,
    private localStorageService: LocalStorageService,
    private currencyService: CurrencyService
  ) { }

  ngOnInit(): void {
    this.loadCurrency();
    this.companyId = this.localStorageService.getCompanyId();

    setTimeout(() => {
      this.activeButton = CustomChartType.Hourly;
      this.onSetDataClick(CustomChartType.Hourly);
      this.setMaxDate();
    }, 1000);
  }

  setMaxDate(): void {
    this.maxDate = this.ngbDateService.ngbDateMax();
  }

  onSetDataClick(filterType: CustomChartType): void {
    this.resetDate();
    this.setBounds();
    this.filterType = filterType;
    this.setData();
  }

  onResize(event: any): void {
    this.screenWidth = event.target.innerWidth;
  }

  onSetActiveClick(selectedDisplayMode: CustomChartType): void {
    this.activeButton = selectedDisplayMode;
  }

  isButtonActive(selectedDisplayMode: CustomChartType): boolean {
    return this.activeButton === selectedDisplayMode;
  }

  getDateFilter(
    dateFrom: NgbDate,
    dateTo: NgbDate
  ): void {
    this.startDate = this.dateTimeService.getStartOfDayWithSubtractedDayInUnixTimestamp(dateFrom);
    this.endDate = this.dateTimeService.getEndOfDayWithSubtractedDayInUnixTimestamp(dateTo);

    const dayInSeconds = 86400;
    const threeMonthsInSeconds = 7776000;
    const filterSpread = this.endDate - this.startDate;

    if (filterSpread < dayInSeconds) {
      this.filterType = CustomChartType.Hourly;
    } else if (filterSpread >= dayInSeconds && filterSpread < threeMonthsInSeconds) {
      this.filterType = CustomChartType.Daily;
    } else if (filterSpread >= threeMonthsInSeconds) {
      this.filterType = CustomChartType.Montly;
    }

    this.getLineChartData();
  }

  onCurrencyChange(currency?: Currency): void {
    this.selectedCurrency = currency;
  }
  
  private loadCurrency(): void {
    this.currencyService.getCurrencies().subscribe((currencie) => {
      this.currencies = currencie;
      this.selectedCurrency = this.currencies[0];
    });
  }

  private resetDate(): void {
    this.dateFrom = null;
    this.dateTo = null;
  }

  private getLineChartData(): void {
    this.dashboardService
      .getLineChart(
        this.companyId,
        this.filterType,
        this.startDate,
        this.endDate,
        this.selectedCurrency.Id
      )
      .subscribe({
        next: (chartData) => {
          this.lineChart = chartData;

          if (this.filterType === CustomChartType.Hourly) {
            this.lineChart.forEach((dataLine, index) => {
              if (index < 10) {
                dataLine.name = '0' + dataLine.name + ':00';
              } else {
                dataLine.name = dataLine.name + ':00';
              }
            });
          } else {
            this.formatGraphData();
          }

          const dataForChart = [
            {
              name: '',
              series: this.lineChart,
            },
          ];

          this.graphData = dataForChart;

          this.sumGraphData();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private formatGraphData(): void {
    this.lineChart.forEach((data) => {
      data.name = new Date(data.name).toLocaleDateString();
    });
  }

  private sumGraphData(): void {
    this.graphSum = 0;

    this.lineChart.forEach(data => {
      this.graphSum = this.graphSum + data.value;
    });
  }

  private setBounds(): void {
    this.startDate = this.dateTimeService.getStartOfDayInUnixTimestamp(this.today);
    this.endDate = this.dateTimeService.getEndOfTheDayUnixTimestamp(this.today);

    if (this.activeButton === CustomChartType.Montly) {
      this.pastDay = this.dateTimeService.getStartOfDayInUnixTimestamp(this.yearAgo);
    } else if (this.activeButton === CustomChartType.Weekly) {
      this.pastDay = this.dateTimeService.getStartOfDayInUnixTimestamp(this.monthAgo);
    } else if (this.activeButton === CustomChartType.Daily) {
      this.pastDay = this.dateTimeService.getStartOfDayInUnixTimestamp(this.weekAgo);
    }
  }

  private setData(): void {
    if (this.filterType !== CustomChartType.Hourly) {
      this.startDate = this.pastDay;
    }

    this.getLineChartData();

    this.dateFrom = this.ngbDateService.ngbDateFilter(this.startDate);
    this.dateTo = this.ngbDateService.ngbDateFilter(this.endDate);
  }
}
