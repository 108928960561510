<authentication-test></authentication-test>
  <navigation selectedPage="contacts">
    <div class="row">
      <div class="col-sm my-col">
        <button
          type="text"
          class="btn btn-primary new-btn"
          id="addContact"
          (click)="onSetDefaultFormClick()"
        >
          <i class="fas fa-plus mr-1"></i>
          {{ 'TABLE.NEWTABLE' | translate }}
        </button>

        <div class="input-group-prepend float-right" *ngIf="isContactInputGroupVisible">
          <button
            [hidden]="isContactDetailDisabled"
            [disabled]="isContactDetailDisabled"
            type="button"
            class="btn btn-danger mr-2"
            (click)="onOpenContactDeleteModalClick(deleteModal)"
          >
            <i class="fas fa-trash-alt mr-1"></i>
            {{ 'PRODUCTS.DELETEGROUP' | translate }}
          </button>

          <div class="btn-group">
            <button
              type="button"
              class="btn btn-secondary bigger-btn"
              (click)="onFormShowClick()"
            >
              <i class="fas fa-chevron-up"></i>
            </button>
          </div>
        </div>

        <div
          class="input-group-prepend ml-1 div-right-margin float-right"
          *ngIf="!isContactInputGroupVisible"
        >
          <button
            type="button"
            class="btn btn-secondary bigger-btn"
            [hidden]="isContactDetailDisabled"
            (click)="onFormHideClick()"
          >
            <i class="fas fa-chevron-down"></i>
          </button>
        </div>

        <hr
          class="hr-line"
        />
      </div>
    </div>

    <div class="row my-row" *ngIf="isContactInputGroupVisible">
      <div class="col-12 ml-3 mt-3 mb-1">
        <h4 class="text-left titlefirm">
          {{ 'STORES.TITLEFIRM' | translate }}
        </h4>
      </div>

      <div
        class="col-lg-6 col-md-6 col-sm-12 my-col form-group mb-3 mt-1"
        [formGroup]="validatorService.contactForm"
      >
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">{{
              'STORES.COMPANYNAME' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="contact.Name"
            type="text"
            class="form-control normal-input"
            id="companyNameInput"
            formControlName="companyName"
            class="form-control"
            [ngClass]="{
              'is-invalid': isFormSubmitted && validatorService.validate.companyName.errors
            }"
          />
          <div
            *ngIf="isFormSubmitted && validatorService.validate.companyName.errors"
            class="invalid-feedback div-right-margin text-right"
          >
            <div *ngIf="validatorService.validate.companyName.errors.required">
              {{ 'VALIDATOR.VALIDATION' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-lg-6 col-md-6 col-sm-12 my-col form-group mb-3 mt-1"
        [formGroup]="validatorService.contactForm"
      >
        <div class="row no-gutters">
          <div class="col-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text input-label-width">{{
                  'STORES.COMPANYBIN' | translate
                }}</span>
              </div>
              <input
                [(ngModel)]="contact.Bin"
                (keyup)="isAresDataLoaded = false"
                type="text"
                class="form-control normal-input"
                id="binInput"
                formControlName="companyBin"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    (isFormSubmitted && validatorService.validate.companyBin.errors) ||
                    (isAresModalOpen && !contact.Bin) ||
                    (isBinValid &&
                      ((isNumber(contact.Bin) && contact.Bin.length != 8) ||
                        !isNumber(contact.Bin))) ||
                    isAresDataLoaded
                }"
              />
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary search-btn"
                  (click)="onOpenAresModalClick(aresModal)"
                >
                  <i class="fas fa-search mr-2"></i> ARES
                </button>
              </div>

              <div
                *ngIf="
                  (isFormSubmitted && validatorService.validate.companyBin.errors) ||
                  (isAresModalOpen && !contact.Bin)
                "
                class="invalid-feedback div-right-margin text-right"
              >
                <div *ngIf="validatorService.validate.companyBin.errors.required">
                  {{ 'VALIDATOR.VALIDATION' | translate }}

                  <button
                    type="button"
                    class="btn btn-secondary hidden-btn ares-btn"
                    [disabled]="true"
                  >
                    <i class="fas fa-search mr-2"></i> ARES
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div
              *ngIf="
                (isBinValid &&
                  (contact.Bin || !validatorService.validate.companyBin.errors) &&
                  ((isNumber(contact.Bin) && contact.Bin.length != 8) ||
                    !isNumber(contact.Bin))) ||
                  isAresDataLoaded
              "
              class="help-block invalid-name div-right-margin text-right"
            >
              <div>
                <span class="mr-1" *ngIf="!isAresDataLoaded">{{
                  'VALIDATOR.BINVALIDATION' | translate
                }}</span>
                <span class="mr-1" *ngIf="binWithoutUser">{{
                  'VALIDATOR.BINUSERVALIDATION' | translate
                }}</span>

                <button
                  type="button"
                  class="btn btn-secondary hidden-btn ares-btn"
                  [disabled]="true"
                >
                  <i class="fas fa-search mr-2"></i> ARES
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row my-row" *ngIf="isContactInputGroupVisible">
      <div class="col-lg-6 col-md-6 col-sm-12 my-col mt-1">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">{{
              'STORES.COMPANYBANK' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="contact.Bank"
            type="text"
            class="form-control normal-input"
            id="bankInput"
          />
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 my-col mt-1">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">{{
              'CONTACTS.TIN' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="contact.Tin"
            type="text"
            class="form-control normal-input"
            id="tinInput"
          />
        </div>
      </div>
    </div>

    <div class="row my-row" *ngIf="isContactInputGroupVisible">
      <div class="col-lg-6 col-md-6 col-sm-12 my-col mt-1">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">{{
              'STORES.COMPANYPHONE' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="contact.Phone"
            type="text"
            class="form-control normal-input"
            id="phoneInput"
          />
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 my-col mt-1">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">Email</span>
          </div>
          <input
            [(ngModel)]="contact.Email"
            type="text"
            class="form-control normal-input"
            id="emailInput"
          />
        </div>
      </div>
    </div>

    <div class="row my-row" *ngIf="isContactInputGroupVisible">
      <div class="col-lg-6 col-md-6 col-sm-12 my-col my-2">
        <div class="input-group mb-md-3">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">Web</span>
          </div>
          <input
            [(ngModel)]="contact.Web"
            type="text"
            class="form-control normal-input"
            id="webInput"
          />
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-2">
        <div
          class="custom-control custom-checkbox checkbox-lg float-left"
        >
          <input
            *ngIf="contact.TaxPayer"
            checked
            (click)="onIsTaxPayerCheck()"
            type="checkbox"
            class="custom-control-input"
            id="taxpayerInput"
          />
          <input
            *ngIf="!contact.TaxPayer"
            (click)="onIsTaxPayerCheck()"
            type="checkbox"
            class="custom-control-input"
            id="taxpayerInput"
          />
          <label class="custom-control-label" for="taxpayerInput">{{
            'STORES.COMPANYPAYER' | translate
          }}</label>
        </div>
      </div>
    </div>

    <hr
      *ngIf="isContactInputGroupVisible"
      class="custom-hr mt-2 w-100"
    />

    <div class="row my-row" *ngIf="isContactInputGroupVisible">
      <div class="col-12 ml-3 mb-2">
        <h4 class="text-left titlefirm">
          {{ 'STORES.TITLEADRESS' | translate }}
        </h4>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 my-col mt-1">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">{{
              'STORES.COMPANYSTATE' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="contact.State"
            type="text"
            class="form-control normal-input"
            id="stateInput"
          />
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 my-col mt-1">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">{{
              'STORES.COMPANYCITY' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="contact.City"
            type="text"
            class="form-control normal-input"
            id="cityInput"
          />
        </div>
      </div>
    </div>

    <div class="row my-row" *ngIf="isContactInputGroupVisible">
      <div class="col-lg-6 col-md-6 col-sm-12 my-col mt-1">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">{{
              'STORES.COMPANYSTREET' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="contact.Street"
            type="text"
            class="form-control normal-input"
            id="streetInput"
          />
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 my-col mt-1">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text input-label-width">{{
              'STORES.ZIP' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="contact.Zip"
            type="text"
            class="form-control normal-input"
            id="zipInput"
          />
        </div>
      </div>
    </div>

    <div class="row my-row" *ngIf="isContactInputGroupVisible">
      <div class="col-lg-12 col-md-12 col-sm-12 my-col mt-1">
        <button
          [hidden]="!isContactDetailDisabled"
          [disabled]="!isContactDetailDisabled"
          (click)="onContactPostClick()"
          class="btn btn-primary save-btn float-right mt-1"
          id="btnAddContact"
        >
          <i class="fas fa-save mr-1"></i>
          {{ 'PRODUCTS.SAVENEWPRODUCT' | translate }}
        </button>
        <button
          [hidden]="isContactDetailDisabled"
          [disabled]="isContactDetailDisabled"
          (click)="onContactEditClick()"
          class="btn btn-primary save-btn float-right mt-1"
          id="btnEditContact"
        >
          <i class="fas fa-save mr-1"></i>
          {{ 'PRODUCTS.SAVECHANGES' | translate }}
        </button>
      </div>
    </div>

    <div class="row no-gutters my-row mt-3 w-100">
      <div class="col-sm">
          <ngx-datatable
            class="material ngx-datatable-cursor"
            [rows]="contactList"
            [columns]="[
              { prop: 'Name', name: 'CONTACTS.NAME' | translate },
              { prop: 'Bin', name: 'CONTACTS.BIN' | translate }
            ]"
            [headerHeight]="50"
            [footerHeight]="50"
            [columnMode]="'force'"
            [rowHeight]="'auto'"
            [limit]="pageLimit"
            [externalPaging]="true"
            [selectionType]="'single'"
            [selected]="selectedRow"
            [count]="contactCount"
            [offset]="currentPage"
            [messages]="{
              emptyMessage: 'PRODUCTS.TABLENODATA' | translate,
              totalMessage: 'PRODUCTS.TABLETOTAL' | translate
            }"
            (select)="onContactTableSelect($event.selected[0])"
            (page)="setCompanyContacts($event)"
          >
          </ngx-datatable>
      </div>
    </div>
  </navigation>

  <!-- MODAL WINDOW FOR ARES -->
  <ng-template #aresModal let-c="close" let-d="dismiss">
    <div class="ng-template-padding">
      <div class="modal-header text-center">
        <span class="modal-header-span"
          >{{ 'CONTACTS.ARESDIALOG' | translate }} '{{ contact.Bin }}'?</span
        >
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="c('Close click')"
        >
          {{ 'PRODUCTS.CLOSE' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary save-btn"
          (click)="getAresCompanyIdentity(contact.Bin); c('Close click')"
        >
          <i class="fas fa-align-left mr-1"></i>
          {{ 'CONTACTS.PREFILL' | translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <!-- MODAL WINDOW FOR DELETE CONTACT -->
  <ng-template #deleteModal let-c="close" let-d="dismiss">
    <div class="ng-template-padding">
      <div class="modal-header text-center">
        <span class="modal-header-span"
          >{{ 'CONTACTS.DELETEDIALOG' | translate }} '{{ contact.Name }}'?</span
        >
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="c('Close click')"
        >
          {{ 'PRODUCTS.CLOSE' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="onContactDeleteClick(contact); c('Close click')"
        >
          <i class="fas fa-trash-alt mr-1"></i>
          {{ 'PRODUCTS.DELETEGROUP' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
