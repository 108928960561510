import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SkuService } from '../../../core/services/sku/sku.service';
import { StorageCard } from '../../../core/interfaces/stock/storage-card.model';
import { ValidatorService } from '../../../core/services/validator/validator.service';
import { Warehouse } from '../../company-settings/models/warehouse.model';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { StoreCardCategory } from '../../../core/interfaces/store-card-category/store-card-category.model';
import { TableColumn } from '../../../core/interfaces/shared/table-columns.model';
import { TranslateService } from '@ngx-translate/core';
import { StorageCardUnit } from '../../../core/interfaces/storage-card-unit/storage-card-unit.model';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { SkuPostResponse } from '../models/sku-post-response.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ListBaseDirective } from '../../../shared/components/authentication-test/list-base.component';
import { Observable } from 'rxjs';
import { ResponsiveSetting } from '../../../shared/models/responsive-setting.model';

@Component({
  selector: 'app-sku-overview',
  templateUrl: './sku-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './sku-list.component.scss',
  ],
})
export class SkuListComponent
  extends ListBaseDirective<StorageCard>
  implements OnInit {
  areInputElementsVisible: boolean = true;
  isSubmit: boolean = false;
  isAddSkuDisabled: boolean = true;
  isChangeSkuDisabled: boolean = true;
  isDropdownSet: boolean = false;

  categoryList: StoreCardCategory[];
  selectedCategory: StoreCardCategory;

  unitList: StorageCardUnit[];
  selectedUnit: StorageCardUnit;

  selectedWarehouse: Warehouse;
  isWarehouseValidate: boolean = false;

  currentPage: number = 0;
  skuCount: number = 0;
  activeButton: number = 0;
  isDataTableLoaded: boolean = false;

  skuFilter: string = '';

  isReportDisabled: boolean = true;

  entityList: StorageCard[] = [];
  selectedSku: StorageCard;
  isSkuSelected: boolean = false;

  columns: TableColumn[];
  getList$: Observable<StorageCard[]>;

  skuPostResponse: SkuPostResponse;
  skuEditResponse: CreateRecordResponse;

  error: string;

  constructor(
    private skuService: SkuService,
    private validatorService: ValidatorService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.columns = this.getColumns();
    this.validatorService.skuValidator();
  }

  onUpdateFilterClick(filterValue: string): void {
    const value = filterValue.toString().toLowerCase().trim();

    this.skuFilter = value;

    this.setSkuPageCount();
  }

  getStorageCard(): StorageCard {
    const sku: StorageCard = {
      CardNumber: this.selectedSku.CardNumber,
      Name: this.selectedSku.Name,
      Barcode: this.selectedSku.Barcode,
      StorageCardUnitsId: this.selectedUnit.Id,
      MinState: this.selectedSku.MinState,
      Packaging: this.selectedSku.Packaging,
      MinDurability: this.selectedSku.MinDurability,
      StorageCardCategoryId: this.selectedCategory.Id,
      WarehouseId: this.selectedWarehouse.Id,
      Note: this.selectedSku.Note,
    };

    return sku;
  }

  onAddSkuClick(): any {
    this.isSubmit = true;

    if (!this.categoryList) {
      this.isDropdownSet = true;
      return;
    }

    if (this.validatorService.skuForm.invalid) {
      return;
    }

    const sku: StorageCard = this.getStorageCard();

    this.skuService
      .post(sku)
      .subscribe({
        next: (successResponse) => {
          this.skuPostResponse = successResponse;
          if (successResponse.success) {
            this.onNewSkuClick(false);
            this.setSkuPageCount();
          }

          this.snackBarService.show('SKU.ADDSKUSUCCESS', successResponse.success);
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('SKU.ADDSKUFAIL', false);
        },
      });
  }

  onEditSkuClick(): void {
    this.isSubmit = true;

    if (this.validatorService.skuForm.invalid) {
      return;
    }

    const sku: StorageCard = this.getStorageCard();
    sku.Id = this.selectedSku.Id;

    this.skuService
      .put(sku)
      .subscribe({
        next: (successResponse) => {
          this.skuEditResponse = successResponse;
          if (successResponse.success) {
            this.onNewSkuClick(false);
            this.setSkuPageCount();
            this.snackBarService.show('SKU.EDITSKUSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('SKU.EDITSKUFAIL', false);
        },
      });
  }

  onNewSkuClick(areInputElementsVisible: boolean): void {
    this.isWarehouseValidate = true;

    if (!this.selectedWarehouse) {
      return;
    }

    this.selectedSku = new StorageCard();

    this.isSkuSelected = false;
    this.isSubmit = false;
    this.isDropdownSet = false;
    this.areInputElementsVisible = areInputElementsVisible;
    this.isAddSkuDisabled = false;
    this.isReportDisabled = true;
    this.isChangeSkuDisabled = true;
  }

  onCategoryListChange(category: StoreCardCategory[]): void {
    this.categoryList = category;
  }

  onCategoryChange(category: StoreCardCategory): void {
    this.selectedCategory = category;
  }

  onUnitListChange(unit: StoreCardCategory[]): void {
    this.unitList = unit;
  }

  onUnitChange(unit: StorageCardUnit): void {
    this.selectedUnit = unit;
  }

  onIsImportSuccessChange(): void {
    this.setSkuPageCount();
  }

  onSelectedWarehouseChange(warehouse: Warehouse): void {
    this.selectedWarehouse = warehouse;

    this.onNewSkuClick(true);
    this.setSkuPageCount();
  }

  onRowSelect(selectedSku: StorageCard): void {
    this.selectedSku = new StorageCard();
    this.selectedCategory = new StoreCardCategory();
    this.selectedUnit = new StorageCardUnit();

    this.selectedCategory.Name = selectedSku.StorageCardCategoryName;
    this.selectedUnit.Name = selectedSku.StorageCardUnitName;

    this.selectedSku = selectedSku;
    this.areInputElementsVisible = false;
    this.isSubmit = false;

    this.isChangeSkuDisabled = false;
    this.isAddSkuDisabled = true;
    this.isSkuSelected = true;
    this.isReportDisabled = false;
  }

  onTabChange(tab: MatTabChangeEvent): void {
    const skuTabIndex: number = 1;
    if (tab.index === skuTabIndex) {
      this.activeButton = 0;
    }
  }

  setActive(activeButton: number): void {
    this.activeButton = activeButton;
  }

  isButtonActive(activeButton: number): boolean {
    return this.activeButton === activeButton;
  }

  onPageChange(pageIndex: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.skuService
      .get(
        this.skuFilter,
        this.currentPage,
        GridSetting.pageLimit,
        this.selectedWarehouse.Id
      );
    this.isDataTableLoaded = true;

    this.setList();
  }

  private setSkuPageCount(): void {
    this.skuService
      .getPageCount(
        this.selectedWarehouse.Id,
        this.skuFilter
      )
      .subscribe({
        next: (skuCount) => {
          this.skuCount = skuCount[0].product_count;
          this.isDataTableLoaded = false;

          this.onPageChange(0);
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private getColumns(): TableColumn[] {
    return [
      {
        columnDef: 'CardNumber',
        header: this.translateService.instant('SKU.SKUNUMBER'),
        cell: (element: StorageCard) => `${element.CardNumber}`,
      },
      {
        columnDef: 'Name',
        header: this.translateService.instant('SKU.SKUNAME'),
        cell: (element: StorageCard) => `${element.Name}`,
      },
      {
        columnDef: 'StorageCardCategoryName',
        header: this.translateService.instant('SKU.SKUPRODUCTTYPETABLE'),
        cell: (element: StorageCard) => `${element.StorageCardCategoryName}`,
        isResponsive: true,
      },
      {
        columnDef: 'StorageCardUnit',
        header: this.translateService.instant('PRODUCTS.PRODUCTUNIT'),
        cell: (element: StorageCard) => `${element.StorageCardUnitName}`,
        isResponsive: true,
      },
    ];
  }
}
