import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { NgbDateService } from '../../../core/services/formatter/ngb-date.service';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { LoyaltyCardService } from '../loyalty-card.service';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { LoyaltyCardTransaction } from '../models/loyalty-card-transaction.model';
import { LoyaltyCard } from '../models/loyalty-card.model';
import { LoyaltyCardTransactionPageable } from '../models/loyalty-card-transaction-pageable.model';

@Component({
  selector: 'app-loyalty-card-detail',
  templateUrl: './loyalty-card-detail.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./loyalty-card-detail.component.scss'],
})
export class LoyaltyCardDetailComponent implements OnInit {
  card: LoyaltyCard;
  loyaltyCardId: number;
  isDataLoaded: boolean = false;
  isIncreaseBalanceButtonDisabled: boolean = false;
  increaseBalanceAmount: number = 0;

  currentPage: number = 0;
  pageLimit: number = GridSetting.pageLimit;
  transactionCount: number = 0;
  transactionList: LoyaltyCardTransaction[];

  error: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private loyaltyCardService: LoyaltyCardService,
    private ngbDateService: NgbDateService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.loyaltyCardId = this.activatedRoute.snapshot.params.value;

    this.getCard(this.loyaltyCardId);
  }

  onGoToLoyaltyCardListClick(): void {
    this.location.back();
  }

  onIncreaseBalanceClick(): void {
    this.isIncreaseBalanceButtonDisabled = true;
    this.loyaltyCardService
      .increaseBalance(this.loyaltyCardId, this.increaseBalanceAmount)
      .subscribe({
        next: (successResponse) => {
          if (successResponse.success) {
            this.snackBarService.show(
              'CARDS.INCREASEBALANCESUCCESS',
              true
            );
            setTimeout(() => {
              this.increaseBalanceAmount = 0;
              location.reload();
            }, 1000);
          }
        },
        error: (error) => {
          this.error = error;
          this.snackBarService.show(
            'CARDS.INCREASEBALANCEFAIL',
            false
          );
        },
      });
  }

  getTransactionList(loyaltyCard?: LoyaltyCardTransactionPageable): void {
    if (loyaltyCard != null) {
      this.currentPage = loyaltyCard.offset;
    }

    const nextPage = this.currentPage + 1;

    this.loyaltyCardService
      .getTransactions(
        this.card.Id,
        nextPage,
        this.pageLimit)
      .subscribe({
        next: (transactionList) => {
          this.transactionList = transactionList;

          this.getTransactionPaginationCount();
          this.transactionDateFormat();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private getTransactionPaginationCount(): void {
    this.loyaltyCardService
      .getTransactionPageCount(this.card.Id)
      .subscribe({
        next: (transactionCount) => {
          this.transactionCount = transactionCount[0].transactionCount;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private transactionDateFormat(): void {
    if (this.transactionList != null && this.transactionList.length !== 0) {
      this.ngbDateService.zuluTransactionFormatter(this.transactionList);
    }
  }

  private getCard(loyaltyCardId: number): void {
    this.loyaltyCardService
      .getCardList(
        '',
        0,
        this.pageLimit,
        loyaltyCardId
      ).subscribe({
        next: (cardData) => {
          this.card = cardData[0];
          this.isDataLoaded = true;

          this.getTransactionList();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
