import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Gender } from '../../core/enums/gender.enum';

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    switch (value) {
      case Gender.Male:
        return this.translateService.instant('GENDER.MALE');
      case Gender.Female:
        return this.translateService.instant('GENDER.FEMALE');
      case Gender.Other:
        return this.translateService.instant('GENDER.OTHER');
    }
    return value;
  }
}
