import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { catchError } from 'rxjs/operators';
import { ContactPageCount } from './models/contact-page-count.model';
import { Contact } from './models/contact.model';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private contactsUrl: string = Centrala.URL + '/api/contact';
  private paginationUrl: string = Centrala.URL + '/api/contact/pagination';
  private aresUrl: string = Centrala.URL + '/api/contact/ares';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService,
  ) { }

  getContactsForCompany(
    companyId: number,
    currentPage: number,
    pageCount: number
  ): Observable<Contact[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('companyId', companyId.toString())
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

    return this.httpClient
      .get<Contact[]>(
        this.contactsUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getPageCount(companyId: number): Observable<ContactPageCount> {
    const params = new HttpParams()
      .set('companyId', companyId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<ContactPageCount>(
        this.paginationUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  post(contact: Contact): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<CreateRecordResponse>(
        this.contactsUrl,
        {
          contact: JSON.stringify(contact),
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  put(contact: Contact): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    const body = {
      contact: JSON.stringify(contact),
      token: localStorage.getItem(LocalStorageTags.Token),
    };

    return this.httpClient
      .put<CreateRecordResponse>(
        this.contactsUrl,
        body,
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  delete(
    companyId: number,
    id: number
  ): Observable<CreateRecordResponse> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        CompanyId: companyId,
        Id: id,
        token: localStorage.getItem(LocalStorageTags.Token),
      },
    };

    return this.httpClient
      .delete<CreateRecordResponse>(this.contactsUrl, options)
      .catch(this.httpHandlerService.handleError);
  }

  getAresRegistryIdentity(bin: string): Observable<any[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('bin', bin);
    return this.httpClient
      .get<any[]>(
        this.aresUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
