<h1 mat-dialog-title>{{ title }}</h1>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="input" fxFlex="100%">
    <p>Opravdu chcete odstranit "{{ title }}"</p>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <div fxFlex="100%" fxLayoutAlign="end">
    <button class="close-btn" mat-button mat-dialog-close>
      {{ "DIALOG.CLOSE" | translate }}
    </button>
    <button class="delete-btn" mat-button (click)="onDeleteClick()">
        <i class="fas fa-trash-alt mr-1"></i>
        <span class="icon-name">{{ 'PRODUCTS.DELETEGROUP' | translate }}</span>
    </button>
  </div>
</div>
