import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CameraService } from './camera.service';
import { Camera } from './models/camera.model';
import { finalize } from 'rxjs/operators';
import { SnackBarService } from '../../core/services/shared/snackbar.service';
import { ServerResponse } from '../../shared/models/server-response.model';
import { EditRecordResponse } from '../../shared/models/edit-record-response.model';
import { GridSetting } from '../../shared/models/grid-setting.model';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';
import { contioStoreDetailUrl } from '../contio-store/route';
import { TableColumn } from '../../core/interfaces/shared/table-columns.model';
import { TranslateService } from '@ngx-translate/core';
import { YesNoPipe } from '../../shared/pipes/yes-no-pipe/yes-no.pipe';
import { ListBaseDirective } from '../../shared/components/authentication-test/list-base.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-camera',
  templateUrl: './camera-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CameraListComponent
  extends ListBaseDirective<Camera>
  implements OnInit {
  storeId: number;
  camera: Camera;

  cameraCount: number = 0;
  currentPage: number = 1;
  entityList: Camera[] = [];

  isDataTableLoaded: boolean;
  columns: TableColumn[];
  getList$: Observable<Camera[]>;

  isCameraInputGroupVisible: boolean = false;
  isCameraDetailDisabled: boolean = false;
  cameraPostResponse: ServerResponse;
  cameraEditResponse: EditRecordResponse;

  error: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cameraService: CameraService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private yesNoPipe: YesNoPipe,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.storeId = this.activatedRoute.snapshot.params.value;
    this.localStorageService.autenticateContioUser();
    this.columns = this.getUserListColums();

    this.setCameraPageCount();
  }

  onPageChange(pageIndex?: number): void {
    if (pageIndex) {
      this.currentPage = pageIndex + 1;
    }

    this.getList$ = this.cameraService.get(this.storeId, this.currentPage, GridSetting.pageLimit);

    this.setList();
  }

  onSetDefaultFormClick(isCameraDetailVisible: boolean): void {
    this.isCameraInputGroupVisible = !isCameraDetailVisible;
    this.isCameraDetailDisabled = true;
    this.camera = <Camera>{
      Name: '',
      Url: '',
      IsFavourite: false,
    };
  }

  onCameraTableSelect(camera: Camera): void {
    if (!camera) {
      this.onSetDefaultFormClick(true);
      return;
    }

    this.isCameraInputGroupVisible = false;
    this.isCameraDetailDisabled = false;

    this.camera = camera;
  }

  onCameraSaveClick(): void {
    this.cameraService
      .post(
        this.storeId,
        this.camera.Name,
        this.camera.Url,
        this.camera.IsFavourite
      )
      .pipe(
        finalize(() => {
          this.cameraPostResponse = null;
        })
      )
      .subscribe(
        {
          next: successResponse => {
            this.cameraPostResponse = successResponse;
            if (successResponse.success) {
              this.onPageChange();
              this.onSetDefaultFormClick(false);

              this.snackBarService.show('SHOP.ADDCAMSUCCESS', successResponse.success);
            }
          },
          error: error => {
            this.error = error;

            this.snackBarService.show('SHOP.ADDCAMFAILED', false);
          },
        }
      );
  }

  onCameraEditClick(): void {
    this.cameraService
      .put(
        this.storeId,
        this.camera.Id,
        this.camera.Name,
        this.camera.Url,
        this.camera.IsFavourite
      )
      .pipe(
        finalize(() => {
          this.cameraEditResponse = null;
        })
      )
      .subscribe({
        next: successResponse => {
          if (successResponse.success) {
            this.onPageChange();
            this.onSetDefaultFormClick(false);

            this.snackBarService.show('SHOP.EDITCAMSUCCESS', successResponse.success);
          }
        },
        error: error => {
          this.error = error;

          this.snackBarService.show('SHOP.EDITCAMFAILED', false);
        },
      });
  }

  onFormHideClick(): void {
    this.isCameraInputGroupVisible = false;
  }

  onFormShowClick(): void {
    this.isCameraInputGroupVisible = true;
  }

  onIsFavouriteCameraCheckChange(): void {
    this.camera.IsFavourite = !this.camera.IsFavourite;
  }

  onGoToShopDetailClick(): void {
    this.router.navigate([contioStoreDetailUrl + this.storeId]);
  }

  private getUserListColums(): TableColumn[] {
    return <TableColumn[]>[
      {
        columnDef: 'Name',
        header: this.translateService.instant('PRODUCTS.TABLEPRODUCTNAME'),
        cell: (element: Camera) => `${element.Name}`,
      },
      {
        columnDef: 'Url',
        header: 'Url',
        cell: (element: Camera) => `${element.Url}`,
      },
      {
        columnDef: 'IsFavourite',
        header: this.translateService.instant('SHOP.DEFAULT'),
        cell: (element: Camera) => `${this.yesNoPipe.transform(element.IsFavourite)}`,
      },
    ];
  }

  private setCameraPageCount(): void {
    this.cameraService
      .getPageCount(this.storeId)
      .subscribe(
        {
          next: cameraCount => {
            this.cameraCount = cameraCount[0].cameraCount;
            this.isDataTableLoaded = true;

            this.onPageChange();
            this.onSetDefaultFormClick(true);
          },
          error: error => {
            this.error = error;
          },
        }
      );
  }
}

