<button (click)="onPrepareImportProductCLick()" class="btn btn-primary save-btn float-left"
  id="saveSkuButton">
  <i class="fas fa-upload mr-1"></i>
  {{ 'PRODUCTS.IMPORT' | translate }}
</button>

<ng-template #importModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">
      {{ 'PRODUCTS.IMPORTMODALTITLE' | translate }}
    </h4>
    <button type="button" class="close" (click)="onClearImportModal(); c('Close click')">
      <span aria-hidden="false">&times;</span>
    </button>
  </div>

  <div class="modal-header">
    <app-company-settings-warehouse 
    [isWarehouseEdit]="false"
    [selectedWarehouse]="selectedWarehouse"
    (selectedWarehouseChange)="getSelectedWarehouse($event)"
      ></app-company-settings-warehouse>

    <button type="button" class="btn normal-btn float-right" (click)="
                  onImportProductsClick();
                      !isImportValid && onClearImportModal();
                      !isImportValid && c('Close click')
                    ">
      <i class="fas fa-save mr-1"></i>
      {{ 'PRODUCTS.SAVE' | translate }}
    </button>
  </div>

  <div class="modal-footer">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          {{ 'PRODUCTS.SEARCH' | translate }}</span>
      </div>
      <input id="search" type="search" class="form-control search-input" aria-label="Search"
        aria-describedby="basic-addon1" [(ngModel)]="productFilter" autocomplete="on" />

      <button type="button" class="btn btn-secondary search-btn ml-1" [class.active]="isButtonActive(1)"
        (click)="updateProductFilter(productFilter); setActive(1)">
        <i class="fas fa-search"></i>
      </button>

      <button type="button" class="btn btn-secondary search-btn ml-1" [class.active]="isButtonActive(2)" (click)="
                        updateProductFilter(''); onClearImportModal(); setActive(2)
                      ">
        {{ 'PRODUCTS.ALL' | translate }}
      </button>
    </div>

    <div *ngIf="products && isProductLoading && areProductsLoaded"
      class="alert alert-danger alert-dismissable mb-3 text-center w-100">
      <strong>{{ 'VALIDATOR.ROWPRODUCTVALID' | translate }}</strong>
    </div>

    <div *ngIf="isImportValid" class="alert alert-danger alert-dismissable mb-3 text-center w-100">
      <strong>{{ 'VALIDATOR.FIELDISEMPTY' | translate }}</strong>
    </div>

    <table class="table table-bordered table-hover">
      <tr>
        <th>
          <input type="checkbox" class="checkboxTableTitle" [checked]="isAllCheck" (change)="onCheckAllChange($event)" />
        </th>
        <th>
          <label class="titleLabel">{{
            'PRODUCTS.PRODUCTPLUIMPORT' | translate
            }}</label>
        </th>
        <th>
          <label class="titleLabel">{{
            'PRODUCTS.TABLEPRODUCTNAME' | translate
            }}</label>
        </th>
        <th>
          <label class="titleLabel">{{
            'PRODUCTS.PRODUCTUNIT' | translate
            }}</label>
        </th>
        <th>
          <label class="titleLabel">{{
            'PRODUCTS.TABLEPRODUCTGROUP' | translate
            }}</label>
        </th>
        <th>
          <label class="titleLabel">{{
            'PRODUCTS.INGREDIENTS' | translate
            }}</label>
        </th>
      </tr>

      <ng-container *ngFor="
                        let product of products | filter: productSearchPhrase;
                        let i = index
                      ">
        <tbody>
          <tr id="row{{ i + 1 }}">
            <td>
              <input type="checkbox" class="checkboxTable" [(ngModel)]="product.checked" />
            </td>
            <td>
              <input type="text" class="form-control" [ngClass]="{
                                'is-invalid': !product.Plu
                              }" [(ngModel)]="product.Plu" />
            </td>
            <td>
              <input type="text" class="form-control" [ngClass]="{
                                'is-invalid': product.Text.length < 3
                              }" [(ngModel)]="product.Text" />
            </td>
            <td>
              <app-dropdown
                *ngIf="selectedUnitList[i]" 
                [(selected)]="selectedUnitList[i]"
                (selectedChange)="onImportUnitChange(selectedUnitList[i].Id, product)"
                label="{{ 'PRODUCTS.CHOOSEGROUP' | translate }}"
                itemKey="Name"
                [items]="unitList"
                ></app-dropdown>
            </td>
            <td>
              <app-dropdown 
                *ngIf="selectedCategoryList[i]" 
                [(selected)]="selectedCategoryList[i]"
                (selectedChange)="onImportCategoryChange(selectedCategoryList[i].Id, product)"
                label="{{ 'PRODUCTS.CHOOSEGROUP' | translate }}"
                itemKey="Name" 
                [items]="categoryList"
                ></app-dropdown>
            </td>
            <td>
              <input type="checkbox" class="checkboxTable" [(ngModel)]="product.IsIngredient" />
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
    <div class="col-12 text-center">
      <div *ngIf="!isProductLoading" class="spinner-border mt-3 mb-2 product-loading" role="status"></div>
    </div>
  </div>
</ng-template>