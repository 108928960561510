import { Injectable } from '@angular/core';
import { PageableResponse } from './models/pageable-response.model';

@Injectable({
    providedIn: 'root',
})
export class PageableResponseAdapter {
    get<T>(pageableJson: any, get: (json: Object) => T): PageableResponse<T> {
        const pageableResponse = <PageableResponse<T>>{};
        pageableResponse.totalRowCount = pageableResponse.totalRowCount;
        pageableResponse.items = pageableJson.items.map((itemJson) =>
            get(itemJson)
        );
        return pageableResponse;
    }
}
