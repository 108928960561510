import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContioService } from '../contio.service';
import { ContioStore } from '../models/contio-store.model';
import { SunmiShop } from '../../settings/settings-tabs/sunmi-shop/models/sunmi-shop.model';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { TableColumn } from '../../../core/interfaces/shared/table-columns.model';
import { TranslateService } from '@ngx-translate/core';
import { YesNoPipe } from '../../../shared/pipes/yes-no-pipe/yes-no.pipe';
import { Observable } from 'rxjs';
import { ListBaseDirective } from '../../../shared/components/authentication-test/list-base.component';
import { GridSetting } from '../../../shared/models/grid-setting.model';

@Component({
  selector: 'app-contio-store-list',
  templateUrl: './contio-store-list.component.html',
})
export class ContioStoreListComponent
  extends ListBaseDirective<SunmiShop>
  implements OnInit {
  entityList: SunmiShop[] = [];
  sunmiShopCount: number = 0;

  currentPage: number = 0;
  columns: TableColumn[];
  isDataTableLoaded: boolean = false;
  getList$: Observable<SunmiShop[]>;

  error: string;

  constructor(
    private router: Router,
    private contioService: ContioService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private yesNoPipe: YesNoPipe,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.localStorageService.autenticateContioUser();
    this.columns = this.getUserListColums();

    this.setContioStorePageCount();
  }

  onGoToContioStoreDetailClick(contioStore: ContioStore): void {
    this.router.navigate(['/contio-store/detail/' + contioStore.StoreId]);
  }

  onGoToShopAnalyticsClick(): void {
    this.router.navigate([`/contio-store/analytics`]);
  }

  onPageChange(pageIndex?: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.contioService.getList(
      this.currentPage,
      GridSetting.pageLimit,
    );

    this.setList();
  }

  private setContioStorePageCount(): void {
    this.contioService
      .getPageCount()
      .subscribe({
        next: (contioStoreCount) => {
          this.sunmiShopCount = contioStoreCount[0].paginationCount;
          this.isDataTableLoaded = true;

          this.onPageChange(0);
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private getUserListColums(): TableColumn[] {
    return [
      {
        columnDef: 'Name',
        header: this.translateService.instant('STORES.CONTIONAME'),
        cell: (element: SunmiShop) => `${element.Name}`,
      },
      {
        columnDef: 'IsAvailable',
        header: this.translateService.instant('STORES.ISAVAILABLE'),
        cell: (element: SunmiShop) => `${this.yesNoPipe.transform(element.IsAvailable)}`,
      },
    ];
  }
}
