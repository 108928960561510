import { Injectable } from '@angular/core';
import xml2js from 'xml2js';

@Injectable({
  providedIn: 'root',
})
export class IsdocService {

  constructor() { }

  parseXML(data: any): Promise<any[]> {
    return new Promise((resolve) => {
      const isdocItems = [];
      const parser = new xml2js.Parser({
        trim: true,
        explicitArray: true,
      });

      parser.parseString(data, function (err: any, result: any) {
        const invoiceLines = result.Invoice.InvoiceLines[0];
        invoiceLines.InvoiceLine.forEach(invoiceLine => {
          isdocItems.push({
            date: result.Invoice.IssueDate[0],
            supplier: result.Invoice.AccountingSupplierParty[0].Party[0].PartyName[0].Name[0],
            invoiceNo: result.Invoice.ID[0],
            quantity: invoiceLine.InvoicedQuantity[0]._,
            unit: invoiceLine.InvoicedQuantity[0].$.unitCode,
            unitPrice: invoiceLine.UnitPrice[0],
            name: invoiceLine.Item[0].Description[0],
            catalogueId: invoiceLine.Item[0].CatalogueItemIdentification[0].ID[0],
          });
        });
        resolve(isdocItems);
      });
    });
  }

  parseAres(data) {
    return new Promise((resolve) => {
      var k: any,
        arr = [],
        parser = new xml2js.Parser({
          trim: true,
          explicitArray: true,
        });
      parser.parseString(data, function (err, result) {
        if (result['are:Ares_odpovedi']['are:Odpoved'][0]['are:Pocet_zaznamu'][0] == '0') {
          arr.push({ success: false, message: 'bin_not_assigned' });
        } else {
          var userInfo =
            result['are:Ares_odpovedi']['are:Odpoved'][0]['are:Zaznam'][0];

          var userAdress =
            result['are:Ares_odpovedi']['are:Odpoved'][0]['are:Zaznam'][0]['are:Identifikace'][0]['are:Adresa_ARES'][0];

          if (userAdress['dtt:Cislo_orientacni']) {
            arr.push({
              name: userInfo['are:Obchodni_firma'][0],
              state: 'Česká republika',
              city: userAdress['dtt:Nazev_obce'][0],
              street:
                userAdress['dtt:Nazev_ulice'][0] +
                ' ' +
                userAdress['dtt:Cislo_domovni'][0] +
                '/' +
                userAdress['dtt:Cislo_orientacni'][0],
              zip: userAdress['dtt:PSC'][0],
            });
          } else {
            arr.push({
              name: userInfo['are:Obchodni_firma'][0],
              state: 'Česká republika',
              city: userAdress['dtt:Nazev_obce'][0],
              street:
                userAdress['dtt:Nazev_ulice'][0] +
                ' ' +
                userAdress['dtt:Cislo_domovni'][0],
              zip: userAdress['dtt:PSC'][0],
            });
          }
        }
        resolve(arr[0]);
      });
    });
  }
}
