import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { Twilio } from './models/twilio.model';
import { TwilioServerResponse } from './models/twilio-server-response.model';
import { centralaRequestHeaders } from '../../core/http/http-request';

@Injectable({
  providedIn: 'root',
})
export class TwilioService {
  private twilioUrl: string = Centrala.URL + '/api/twilio';

  constructor(
    private httpHandlerService: HttpHandlerService,
    private httpClient: HttpClient
  ) {}

  getList(userId: number): Observable<Twilio[]> {
    const params = new HttpParams()
      .set('userId', userId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<Twilio[]>(
        this.twilioUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  post(twilio: Twilio): Observable<TwilioServerResponse> {
    return this.httpClient
      .post<TwilioServerResponse>(
        this.twilioUrl,
        {
          Name: twilio.Name,
          ContioStoreId: twilio.ContioStoreId,
          TwilioAccountSid: twilio.TwilioAccountSid,
          TwilioAuthToken: twilio.TwilioAuthToken,
          TwilioAppService: twilio.TwilioAppService,
          IsActive: twilio.IsActive,
          IsSmsService: twilio.IsSmsService,
          Phone: twilio.Phone,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { 'headers': centralaRequestHeaders }
      )
      .catch(this.httpHandlerService.handleError);
  }

  put(twilio: Twilio): Observable<TwilioServerResponse> {
    return this.httpClient
      .put<TwilioServerResponse>(
        this.twilioUrl,
        {
          Id: twilio.Id,
          Name: twilio.Name,
          ContioStoreId: twilio.ContioStoreId,
          TwilioAccountSid: twilio.TwilioAccountSid,
          TwilioAuthToken: twilio.TwilioAuthToken,
          TwilioAppService: twilio.TwilioAppService,
          IsActive: twilio.IsActive,
          IsSmsService: twilio.IsSmsService,
          Phone: twilio.Phone,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { 'headers': centralaRequestHeaders }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
