import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartType } from '../../modules/contio-store/contio-store-analytics/chart-type.emum';

@Pipe({
  name: 'analyticsType'
})
export class AnalyticsTypePipe implements PipeTransform {
  analyticsType: any = ChartType;
  
  constructor(
    private translateService: TranslateService,
  ) { }

  transform(type: string): string {
    switch (type) {
      case this.analyticsType.Activity:
        return this.translateService.instant('SHOP.ANALYTICSACTIVITY');
      case this.analyticsType.BankId:
        return this.translateService.instant('SHOP.ANALYTICSBANKID');
      case this.analyticsType.Receipt:
        return this.translateService.instant('SHOP.ANALYTICSRECEIPT');
      default:
        return '';
    }
  }
}
