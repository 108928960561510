import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { Centrala } from '../../http/centrala';
import { Settings } from '../../interfaces/settings/settings.model';
import { HttpHandlerService } from '../shared/http-handler.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly url: string = Centrala.URL + '/api/settings';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) {}

  post(language: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.url,
        {
          language: language,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  get(): Observable<Settings[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<Settings[]>(this.url, { params })
      .pipe(catchError(this.httpHandlerService.handleError));
  }
}
