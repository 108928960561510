import { Injectable } from '@angular/core';
import { Http, ResponseContentType } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../shared/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  private header: boolean = false;
  private orderUrl: string = Centrala.URL + '/api/order-csv';
  private receiptUrl: string = Centrala.URL + '/api/receipt/csv';
  private entranceAnalyticsUrl: string = Centrala.URL + '/api/analytics/entrance-csv';
  private bankIdAnalyticsUrl: string = Centrala.URL + '/api/analytics/bankid-csv';

  constructor(
    private ngxCsvParser: NgxCsvParser,
    private http: Http,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
  ) { }

  parseCSV(data: any): any {
    return new Promise(resolve => {
      this.ngxCsvParser
        .parse(data[0], { header: this.header, delimiter: ',' })
        .pipe()
        .subscribe(
          (result: any[]) => {
            resolve(result);
          },
          (error: NgxCSVParserError) => {
            console.log('Error', error);
          }
        );
    });
  }

  postCSV(csv: string[]): any {
    return this.http
      .post(
        this.orderUrl,
        {
          csv: csv,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { responseType: ResponseContentType.Blob }
      )
      .subscribe((response) => {
        const blob = new Blob([response.blob()], { type: 'text/csv' });
        saveAs(blob, 'orders.csv');
      });
  }

  postReceiptsCSV(csv: string[]): any {
    return this.http
      .post(
        this.receiptUrl,
        {
          csv: csv,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { responseType: ResponseContentType.Blob }
      )
      .subscribe((response) => {
        const blob = new Blob([response.blob()], { type: 'text/csv' });
        saveAs(blob, 'receipts.csv');
      });
  }

  postEntranceCSV(
    userId: number,
    storeId: number,
    unixDateFrom: number,
    unixDateTo: number,
  ): any {
    return this.http
      .post(
        this.entranceAnalyticsUrl,
        {
          userId: userId,
          storeId: storeId,
          dateFrom: unixDateFrom,
          dateTo: unixDateTo,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { responseType: ResponseContentType.Blob }
      )
      .subscribe((response) => {
        if (response.statusText != 'false') {
          const blob = new Blob([response.blob()], { type: 'text/csv' });
          saveAs(blob, 'entrances.csv');
        } else {
          this.snackBarService.show(this.translateService.instant('CONTACTS.ARESLOADFAIL'), false);
        }
      });
  }

  postBankIdCSV(
    userId: number,
    storeId: number,
    unixDateFrom: number,
    unixDateTo: number,
  ): any {
    return this.http
      .post(
        this.bankIdAnalyticsUrl,
        {
          userId: userId,
          storeId: storeId,
          dateFrom: unixDateFrom,
          dateTo: unixDateTo,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { responseType: ResponseContentType.Blob }
      )
      .subscribe((response) => {
        if (response.statusText != 'false') {
          const blob = new Blob([response.blob()], { type: 'text/csv' });
          saveAs(blob, 'bank_id.csv');
        } else {
          this.snackBarService.show(this.translateService.instant('CONTACTS.ARESLOADFAIL'), false);
        }
      });
  }
}
