import { Component, OnInit } from '@angular/core';
import { GridSetting } from '../../shared/models/grid-setting.model';
import { CsvService } from '../../core/services/csv/csv.service';
import { SkuService } from '../../core/services/sku/sku.service';
import { ContactService } from '../contacts/contact.service';
import { Contact } from '../contacts/models/contact.model';
import { OrdersStorageCard } from './models/orders-storage-card.model';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  companyId: number;

  selectedContact: Contact;
  contacts: Contact[];
  submitted: boolean = false;
  isHidden: boolean = true;

  readonly keywordName: string = 'Name';

  userSearch: string;
  storageCardTotalCount: number = 0;
  storageCards: OrdersStorageCard[];
  error: string;
  csvPostedData: string[];

  constructor(
    private skuService: SkuService,
    private csvService: CsvService,
    private contactService: ContactService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.companyId = this.localStorageService.getCompanyId();

    this.getCompanyContacts();
  }

  onSelectContact(contact: Contact): void {
    this.selectedContact = contact;
  }

  onInputCleared(): void {
    this.isHidden = true;
    this.selectedContact = null;
  }

  onSelectContactClick(): void {
    this.getStorageCardsByContactId(this.selectedContact.Id);

    this.isHidden = false;
  }

  getCompanyContacts(): void {
    this.contactService
      .getContactsForCompany(
        this.companyId,
        GridSetting.firstPage,
        GridSetting.listLimit
      )
      .subscribe({
        next: (contactList) => {
          this.contacts = contactList;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  createDataForCSVClick(): void {
    const checkedStorageCards = [];

    this.storageCards.forEach((element) => {
      if (element.Checked) {
        checkedStorageCards.push(element);
      }
    });

    this.generateCSV(checkedStorageCards);
  }

  generateCSV(file: string[]): void {
    this.csvService.postCSV(file);
  }

  onCheckAllChange(event: any): void {
    this.storageCards.forEach((storageCard) => (storageCard.Checked = event.target.checked));
  }

  isAllChecked(): boolean {
    if (this.storageCards !== undefined) {
      return this.storageCards.every((storageCard) => storageCard.Checked);
    }
  }

  private getStorageCardsByContactId(contactId: number): void {
    this.skuService
      .getCardListForContact(
        contactId,
        GridSetting.firstPage,
        GridSetting.pageLimit
      )
      .subscribe({
        next: (storageCardList) => {
          this.storageCards = storageCardList;
        },
        error: (error) => {

          this.error = error;
        },
      });
  }
}
