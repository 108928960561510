<div class="row no-gutters justify-content-md-end">
    <app-dropdown
            *ngIf="unitList"
            [(selected)]="selectedUnit"
            (selectedChange)="onSelectedUnitChange($event)"
            label="{{ 'PRODUCTS.CHOOSEGROUP' | translate }}"
            itemKey="Name"
            [items]="unitList"
        ></app-dropdown>
        <div class="float-left">
            <mat-button-toggle-group appearance="legacy" aria-label="Choose Group" class="ml-1">
                <mat-button-toggle 
                class="normal-btn"
                [ngClass]="{
                    'dropdown-invalid':
                    isDropdownSet && !selectedUnit
                  }" 
                  (click)="onOpenUnitPostDialogClick()"
                
                ><i class="fas fa-plus mr-1"></i>{{ 'SKU.SKUUNIT' | translate }}</mat-button-toggle>
                <mat-button-toggle 
                class="normal-btn mat-btn-group"
                [hidden]="!selectedUnit" 
                (click)="onOpenUnitEditDialogClick()"
                >{{ 'STORES.EDIT' | translate }}</mat-button-toggle>
              </mat-button-toggle-group>
        </div>
    </div>
  <div
    class="row no-gutters justify-content-md-end"
    *ngIf="isDropdownSet && !selectedUnit"
  >
    <div class="btn-group float-md-right float-left">
      <button
        type="button"
        class="btn btn-primary hidden-btn mr-1 btn-height"
        [disabled]="true"
      >
        <span class="font-weight-normal">
          {{ 'PRODUCTS.CHOOSEGROUP' | translate }}
        </span>
      </button>

      <span
        class="help-block invalid-group text-right unit-input"
        >{{ 'VALIDATOR.DROPDOWNUNIT' | translate }}</span
      >
    </div>
  </div>