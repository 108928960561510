<div #scroller class="data-container">
  <div class="vertical-nav">
    <ul class="nav flex-column">
      <li class="nav-item mt-3">
        <a
          id="user-settings"
          #sideLinkUser
          routerLink="/user-settings"
          routerLinkActive="active"
          class="nav-link font-weight-bold"
        >
          <i class="fas fa-user"></i>
          &nbsp;&nbsp;&nbsp;&nbsp;{{ usernameAbbreviated }}
        </a>
      </li>
      <li class="divider dropdown-divider mt-3"></li>

      <li ngbDropdown class="nav-item" *ngIf="isReducedMenuVisible">
        <a routerLinkActive="active" class="nav-link" ngbDropdownToggle>
          <i class="fas fa-book"></i>
          &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.REVIEWS' | translate }}
        </a>
        <div
          ngbDropdownMenu
          class="menu-dropdown"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <ng-container [ngTemplateOutlet]="overviews"></ng-container>
        </div>
      </li>

      <ng-container
        [ngTemplateOutlet]="overviews"
        *ngIf="!isReducedMenuVisible"
      ></ng-container>

      <li ngbDropdown class="nav-item" *ngIf="isReducedMenuVisible">
        <a routerLinkActive="active" class="nav-link" ngbDropdownToggle>
          <i class="fas fa-book"></i>
          &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.PRICELIST' | translate }}
        </a>
        <div
          ngbDropdownMenu
          class="menu-dropdown"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <ng-container [ngTemplateOutlet]="priceList"></ng-container>
        </div>
      </li>

      <ng-container
        [ngTemplateOutlet]="priceList"
        *ngIf="!isReducedMenuVisible"
      ></ng-container>

      <li ngbDropdown class="nav-item">
        <a routerLinkActive="active" class="nav-link" ngbDropdownToggle>
          <i class="fas fa-warehouse"></i>
          &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.STOCKMANAGEMENT' | translate }}
        </a>
        <div
          ngbDropdownMenu
          class="menu-dropdown"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <a
            id="stock-notes"
            [routerLink]="['/' + stockRoute.Stock]"
            routerLinkActive="active"
            class="nav-link"
          >
            <i class="fas fa-truck-loading"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.STOCK' | translate }}
          </a>
          <a
            id="orders"
            routerLink="/orders"
            routerLinkActive="active"
            class="nav-link"
          >
            <i class="fas fa-box"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.ORDERS' | translate }}
          </a>
          <a
            id="sku"
            routerLink="/sku"
            routerLinkActive="active"
            class="nav-link"
          >
            <i class="fas fa-boxes"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SKU' | translate }}
          </a>
        </div>
      </li>

      <li ngbDropdown class="nav-item" *ngIf="isReducedMenuVisible">
        <a routerLinkActive="active" class="nav-link" ngbDropdownToggle>
          <i class="fas fa-cog"></i>
          &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SETTINGS' | translate }}
        </a>
        <div
          ngbDropdownMenu
          class="menu-dropdown"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <ng-container [ngTemplateOutlet]="settings"></ng-container>
        </div>
      </li>

      <ng-container
        [ngTemplateOutlet]="settings"
        *ngIf="!isReducedMenuVisible"
      ></ng-container>

      <div
        class="divider dropdown-divider"
        [hidden]="!isAdminLogin && !isContioOwner"
      ></div>

      <li
        ngbDropdown
        class="nav-item"
        *ngIf="isReducedMenuVisible"
        [hidden]="!isAdminLogin && !isContioOwner"
      >
        <a routerLinkActive="active" class="nav-link" ngbDropdownToggle>
          <i class="fas fa-warehouse"></i>
          &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SHOP' | translate }}
        </a>
        <div
          ngbDropdownMenu
          class="menu-dropdown"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <ng-container [ngTemplateOutlet]="contioShop"></ng-container>
        </div>
      </li>

      <li
        ngbDropdown
        class="nav-item"
        [hidden]="!isAdminLogin && !isContioOwner"
      >
        <a
          class="nav-link"
          id="drop"
          routerLinkActive="active"
          ngbDropdownToggle
        >
          <i class="fas fa-user-shield"></i>
          &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.ADMINDROP' | translate }}
        </a>
        <div
          ngbDropdownMenu
          class="menu-dropdown"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <a
            class="nav-link mb-1"
            id="admin"
            routerLink="/admin"
            routerLinkActive="active"
            [hidden]="!isAdminLogin"
          >
            <i class="fas fa-user-cog"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.ADMIN' | translate }}
          </a>
          <a
            class="nav-link mb-1"
            id="admin"
            [routerLink]="['/' + subaccountRoute.SubaccountList]"
            routerLinkActive="active"
            [hidden]="privilegeList"
          >
            <i class="fas fa-user-edit"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SUBACCOUNTS' | translate }}
          </a>

          <ng-container
            [ngTemplateOutlet]="contioShop"
            *ngIf="!isReducedMenuVisible"
          ></ng-container>

          <a
            class="nav-link"
            id="telegram"
            routerLink="/telegram"
            routerLinkActive="active"
          >
            <i class="fab fa-telegram-plane"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.TELEGRAM' | translate }}
          </a>

          <a
            class="nav-link"
            id="twilio"
            [routerLink]="['/' + twilioRoute.TwilioList]"
            routerLinkActive="active"
          >
            <i class="fas fa-sms"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.TWILIO' | translate }}
          </a>
        </div>
      </li>

      <ng-container *ngTemplateOutlet="sidebarFooter"></ng-container>
    </ul>
  </div>

  <div class="horizontal-nav">
    <div class="horizontal-nav-logo">
      <a href="https://www.arrowsys.cz">
        <img class="nav-logo" src="../img/centrala.png" />
      </a>
    </div>

    <div class="collapse-visible">
      <button
        (window:resize)="onResize($event)"
        type="button"
        class="navbar-toggler btn"
        (click)="collapseClick()"
        aria-controls="exCollapsingNavbar2"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button>
    </div>

    <div class="horizontal-nav-rightalign">
      <div ngbDropdown class="float-left pr-2" *ngIf="selectedCompany">
        <button class="btn" id="dropdownBasic1" ngbDropdownToggle>
          {{ selectedCompany.Name.substring(0, 27) }}
        </button>
        <div
          ngbDropdownMenu
          class="dropdown-menu"
          aria-labelledby="dropdownBasic1"
        >
          <button
            *ngFor="let company of companyData"
            class="dropdown-item"
            (click)="changeCompany(company.Id)"
          >
            {{
              company.Name.length > 45
                ? company.Name.substring(0, 45) + ' ...'
                : company.Name
            }}
          </button>
        </div>
      </div>

      <div *ngIf="screenWidth > 375" ngbDropdown class="float-left pr-2">
        <button class="btn" id="dropdownBasic2" ngbDropdownToggle>
          {{ translateService.getDefaultLang() }}
        </button>
        <div
          ngbDropdownMenu
          class="dropdown-menu dropdown-li-size"
          aria-labelledby="dropdownBasic2"
        >
          <button
            *ngFor="let language of translateService.getLangs()"
            class="dropdown-item"
            (click)="translateChange(language)"
          >
            {{ language }}
          </button>
        </div>
      </div>

      <div class="float-right" *ngIf="screenWidth > 400">
        <button
          type="button"
          class="btn new-btn mr-2 pr-3"
          id="settings"
          routerLink="/settings"
          routerLinkActive="active"
        >
          <i class="fas fa-cog"></i>
        </button>

        <button type="button" class="btn btn-logout" (click)="logout()">
          <i class="fas fa-sign-out-alt"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="side-menu-for-mobile" [ngbCollapse]="!isMobileMenuVisible">
    <!-- Mobile (toggle version) -->
    <ngb-accordion #acc="ngbAccordion" closeOthers="true">
      <ngb-panel>
        <ng-template ngbPanelTitle>
          <a
            id="user-settings-mobile"
            routerLink="/user-settings"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-user"> </i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ userName }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <a
            id="dashboard-mobile"
            routerLink="/dashboard"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-columns"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.DASHBOARD' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel
        [hidden]="
          privilegeList &&
          privilegeList[privilegeType.Reports].Value === privilegeValue.NoAccess
        "
      >
        <ng-template ngbPanelTitle>
          <a
            id="sales-mobile"
            routerLink="/sales/0"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-chart-area"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SALES' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <a
            id="products-mobile"
            routerLink="/products"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-store"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.PRODUCTS' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <a
            id="stock-mobile"
            [routerLink]="['/' + stockRoute.Stock]"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-truck-loading"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.STOCK' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <a
            id="orders-mobile"
            routerLink="/orders"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-box"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.ORDERS' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <a
            id="sku-mobile"
            routerLink="/sku"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-boxes"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SKU' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <a
            id="contacts-mobile"
            routerLink="/contacts"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-address-book"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.CONTACTS' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel>
        <ng-template ngbPanelTitle>
          <a
            id="tables"
            routerLink="/tables"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-chair"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.TABLES' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel
        [hidden]="
          privilegeList &&
          privilegeList[privilegeType.Company].Value === privilegeValue.NoAccess
        "
      >
        <ng-template ngbPanelTitle>
          <a
            id="stores-mobile"
            routerLink="/company-settings"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-building"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.STORES' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel *ngIf="isAdminLogin">
        <ng-template ngbPanelTitle>
          <a
            id="admin-mobile"
            routerLink="/admin"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-user-cog"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.ADMIN' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel *ngIf="isAdminLogin" [hidden]="privilegeList">
        <ng-template ngbPanelTitle>
          <a
            id="subaccounts-mobile"
            [routerLink]="['/' + subaccountRoute.SubaccountList]"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-user-edit"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SUBACCOUNTS' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="isAdminLogin"
        [hidden]="
          privilegeList &&
          privilegeList[privilegeType.Users].Value === privilegeValue.NoAccess
        "
      >
        <ng-template ngbPanelTitle>
          <a
            id="users-mobile"
            routerLink="/users"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-users"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.USERS' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="isAdminLogin"
        [hidden]="
          privilegeList &&
          privilegeList[privilegeType.LoyaltyCard].Value ===
            privilegeValue.NoAccess
        "
      >
        <ng-template ngbPanelTitle>
          <a
            id="users-loyalty-cards"
            routerLink="/loyalty-cards"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-heart"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.LOYALTYCARDS' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel *ngIf="isAdminLogin">
        <ng-template ngbPanelTitle>
          <a
            id="users-returns"
            routerLink="/returns"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-undo"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.RETURNRECORDS' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel
        *ngIf="isAdminLogin"
        [hidden]="
          privilegeList &&
          privilegeList[privilegeType.ContioShop].Value ===
            privilegeValue.NoAccess
        "
      >
        <ng-template ngbPanelTitle>
          <a
            id="shop-mobile"
            routerLink="/contio-store"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-store-alt"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SHOP' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel *ngIf="isAdminLogin">
        <ng-template ngbPanelTitle>
          <a
            id="telegram-mobile"
            routerLink="/telegram"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fab fa-telegram-plane"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.TELEGRAM' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel *ngIf="isAdminLogin">
        <ng-template ngbPanelTitle>
          <a
            id="twilio-mobile"
            [routerLink]="['/' + twilioRoute.TwilioList]"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-sms"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.TWILIO' | translate }}
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>

      <ngb-panel *ngIf="screenWidth < 401">
        <ng-template ngbPanelTitle>
          <a
            id="logout"
            (click)="logout()"
            routerLinkActive="active"
            class="nav-link nav-link-mobile-menu"
          >
            <i class="fas fa-sign-out-alt"></i>
            &nbsp;&nbsp;&nbsp;&nbsp;Logout
          </a>
        </ng-template>

        <ng-template ngbPanelContent> </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <div class="space-for-content" [hidden]="isMobileMenuVisible">
    <ng-content class="text"></ng-content>
  </div>
</div>

<ng-template #overviews>
  <li class="nav-item">
    <a
      id="dashboard"
      routerLink="/dashboard"
      routerLinkActive="active"
      class="nav-link"
    >
      <i class="fas fa-columns"></i>
      &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.DASHBOARD' | translate }}
    </a>
  </li>

  <li class="nav-item">
    <a
      id="sales"
      routerLink="/sales/0"
      routerLinkActive="active"
      class="nav-link"
      [hidden]="
        privilegeList &&
        privilegeList[privilegeType.Reports].Value === privilegeValue.NoAccess
      "
    >
      <i class="fas fa-chart-area"></i>
      &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SALES' | translate }}
    </a>
  </li>

  <li class="nav-item">
    <a
      id="cashregister"
      routerLink="/cashregister"
      routerLinkActive="active"
      class="nav-link"
    >
      <i class="fas fa-cash-register"></i>
      &nbsp;&nbsp;&nbsp;&nbsp;{{ 'CASHREGISTERS.CASHREGISTER' | translate }}
    </a>
  </li>
</ng-template>

<ng-template #priceList>
  <li class="nav-item">
    <a
      id="products"
      routerLink="/products"
      routerLinkActive="active"
      class="nav-link"
    >
      <i class="fas fa-store"></i>
      &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.PRODUCTS' | translate }}
    </a>
  </li>

  <li class="nav-item">
    <a
      id="categories"
      routerLink="/categories"
      routerLinkActive="active"
      class="nav-link"
    >
      <i class="fas fa-wine-bottle"></i>
      &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.CATEGORIES' | translate }}
    </a>
  </li>
</ng-template>

<ng-template #settings>
  <li class="nav-item">
    <a
      id="contacts"
      routerLink="/contacts"
      routerLinkActive="active"
      class="nav-link"
    >
      <i class="fas fa-address-book"></i>
      &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.CONTACTS' | translate }}
    </a>
  </li>

  <li class="nav-item">
    <a
      id="tables"
      routerLink="/tables"
      routerLinkActive="active"
      class="nav-link"
    >
      <i class="fas fa-chair"></i>
      &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.TABLES' | translate }}
    </a>
  </li>

  <li
    class="nav-item mb-2"
    [hidden]="
      privilegeList &&
      privilegeList[privilegeType.Company].Value === privilegeValue.NoAccess
    "
  >
    <a
      id="stores"
      routerLink="/company-settings"
      routerLinkActive="active"
      class="nav-link"
    >
      <i class="fas fa-building"></i>
      &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.STORES' | translate }}
    </a>
  </li>
</ng-template>

<ng-template #contioShop>
  <a
    class="nav-link mb-1"
    id="users"
    routerLink="/users"
    routerLinkActive="active"
    [hidden]="
      privilegeList &&
      privilegeList[privilegeType.Users].Value === privilegeValue.NoAccess
    "
  >
    <i class="fas fa-users"></i>
    &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.USERS' | translate }}
  </a>
  <a
    class="nav-link"
    id="loyalty-cards"
    routerLink="/loyalty-cards"
    routerLinkActive="active"
    [hidden]="
      privilegeList &&
      privilegeList[privilegeType.LoyaltyCard].Value === privilegeValue.NoAccess
    "
  >
    <i class="fas fa-heart"></i>
    &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.LOYALTYCARDS' | translate }}
  </a>
  <a
    class="nav-link"
    id="returns"
    routerLink="/returns"
    routerLinkActive="active"
  >
    <i class="fas fa-undo"></i>
    &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.RETURNRECORDS' | translate }}
  </a>
  <a
    class="nav-link"
    id="contio-store"
    routerLink="/contio-store"
    routerLinkActive="active"
    [hidden]="
      privilegeList &&
      privilegeList[privilegeType.ContioShop].Value === privilegeValue.NoAccess
    "
  >
    <i class="fas fa-store-alt"></i>
    &nbsp;&nbsp;&nbsp;&nbsp;{{ 'NAVIGATION.SHOP' | translate }}
  </a>
</ng-template>

<ng-template #sidebarFooter>
  <li class="nav-item-down position-fixed footer-li">
    <div class="divider dropdown-divider"></div>
    <div class="ml-3">
      <a href="https://www.arrowsys.cz"> ArrowSys s.r.o. </a>
      <br />
      <a href="https://lilka.cz/cs/licencni-podminky-lilka/">
        Licenční podmínky
      </a>
    </div>
  </li>
</ng-template>
