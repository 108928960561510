import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductGroupService } from '../../../../core/services/product-group/product-group.service';
import { ProductGroup } from '../../../../core/interfaces/product-group/product-group.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../../core/services/shared/snackbar.service';
import { LocalStorageTags } from '../../../../core/enums/local-storage-tags.enum';
import { ServerResponse } from '../../../../core/interfaces/shared/server-response.model';
import { TableColumn } from '../../../../core/interfaces/shared/table-columns.model';
import { LanguageService } from '../../../../core/services/shared/language.service';
import { Language, TranslationLanguage } from '../../../../core/interfaces/language/language.model';
import { SettingsService } from '../../../../core/services/settings/settings.service';
import { ListBaseDirective } from '../../../../shared/components/authentication-test/list-base.component';
import { GridSetting } from '../../../../shared/models/grid-setting.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent
  extends ListBaseDirective<ProductGroup>
  implements OnInit {
  @Input() categoryImage: string;
  @Output() imageBase64: EventEmitter<string> = new EventEmitter<string>();

  areInputElementsVisible: boolean = true;
  isSubmit: boolean = false;
  isEdit: boolean = false;

  columns: TableColumn[];

  selectedProductGroup: ProductGroup;
  selectedParentGroup: ProductGroup = new ProductGroup();

  isDataTableLoaded: boolean = false;
  listFilter: string = '';

  categoryCount: number = 0;
  currentPage: number = 0;
  entityList: ProductGroup[];
  getList$: Observable<ProductGroup[]>;

  autocompleteGroups: ProductGroup[];
  parentGroups: ProductGroup[] = [new ProductGroup()];

  languages: Language[];
  selectedLanguage: Language;
  userDefaultLanguage: string;

  error: string;

  constructor(
    private productGroupService: ProductGroupService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private languageService: LanguageService,
    private settingsService: SettingsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.columns = this.getProductGroupColums();

    this.setGroupPageCount(0);
    this.getAutocompleteGroups(true);
    this.getGlobalLanguages();
  }

  onPageChange(pageIndex?: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.productGroupService.getList(
      this.currentPage,
      GridSetting.pageLimit,
      this.listFilter,
      false,
      true,
      true
    );
    this.setList();
  }

  onSetNewGroupTemplateClick(areInputElementsVisible: boolean): void {
    this.setNewGroupTemplate(areInputElementsVisible);
  }

  onGroupSaveClick(isEdit: boolean): void {
    this.isSubmit = true;

    this.selectedProductGroup.Name = this.selectedProductGroup.Translation[this.userDefaultLanguage];
    if (!this.selectedProductGroup.Name) {
      this.snackBarService.show(
        (this.translateService.instant('CATEGORIES.DEFAULTLANGUAGEFAIL') + `'${this.userDefaultLanguage}'.`),
        false
      );
      return;
    }

    if (!isEdit) {
      this.postGroup(
        this.selectedProductGroup.Name,
        this.selectedProductGroup.HexColor,
        this.selectedParentGroup.Id,
        this.categoryImage,
        this.selectedProductGroup.Translation
      );
    } else {
      this.editGroup(
        this.selectedProductGroup.Id,
        this.selectedProductGroup.Name,
        this.selectedProductGroup.HexColor,
        this.categoryImage,
        this.selectedProductGroup.Translation
      );

      this.changeGroupChild(
        this.selectedProductGroup.Id,
        this.selectedParentGroup.Id
      );
    }
  }

  onRowSelect(selectedGroup: ProductGroup): void {
    if (!selectedGroup) {
      this.clearGroups();
      return;
    }

    this.areInputElementsVisible = false;
    this.selectedProductGroup = selectedGroup;
    if (!selectedGroup.Translation) {
      this.setProductGroupDefaultTranslation();
    }
    this.parentGroups = this.setParentGroup();
    this.parentGroups = this.parentGroups.filter(
      (group) => group !== selectedGroup
    );
    this.selectedParentGroup = this.parentGroups.find(
      (group) => group.Id === selectedGroup.ParentBatchId
    );
    if (!this.selectedProductGroup.Translation[this.userDefaultLanguage]) {
      this.selectedProductGroup.Translation[this.userDefaultLanguage] =
        this.selectedProductGroup.Name;
    }
    this.setDefaultLanguage();
    if (!this.selectedParentGroup) {
      this.selectedParentGroup = new ProductGroup();
    }
    this.isEdit = true;
    this.isSubmit = false;
    this.imageBase64.emit(this.selectedProductGroup.Image);
  }

  private setGroupPageCount(currentPage: number
  ): void {
    this.productGroupService
      .getPageCount(this.listFilter)
      .subscribe({
        next: (categoryCount) => {
          this.categoryCount = categoryCount[0].batchCount;
          this.isDataTableLoaded = true;

          this.setNewGroupTemplate(true);
          this.onPageChange(currentPage);
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private postGroup(
    name: string,
    hexColor: string,
    parentBatchId: number,
    image: string,
    translation?: TranslationLanguage[],
  ): void {
    this.productGroupService
      .post(
        name,
        localStorage.getItem(LocalStorageTags.CompanyId),
        hexColor,
        parentBatchId,
        image,
        translation,
      )
      .subscribe({
        next: (postResponse: ServerResponse) => {
          if (!postResponse) {
            return;
          }
          if (postResponse.success) {
            const nextPage = this.currentPage - 1;

            this.setGroupPageCount(nextPage);
            this.getAutocompleteGroups(true);
          }
          this.showPostGroupSnackBar(postResponse.success);
        },
        error: () => {
          this.showPostGroupSnackBar(false);
        },
      });
  }

  private editGroup(
    batchId: number,
    batchName: string,
    hexColor: string,
    image: string,
    translation?: TranslationLanguage[],
  ): void {
    this.productGroupService
      .put(batchId, batchName, hexColor, image, translation)
      .subscribe({
        next: (putResponse: ServerResponse) => {
          if (!putResponse) {
            return;
          }
          if (putResponse.success) {
            const nextPage = this.currentPage - 1;

            this.setGroupPageCount(nextPage);
            this.getAutocompleteGroups(true);
          }
          this.showEditGroupSnackBar(putResponse.success);
        },
        error: () => {
          this.showEditGroupSnackBar(false);
        },
      });
  }

  private changeGroupChild(batchId: number, parentBatchId: number): void {
    if (batchId && parentBatchId) {
      this.postGroupChild(batchId, parentBatchId);
    } else if (batchId && !parentBatchId) {
      this.deleteGroupChild(batchId);
    } else {
      this.snackBarService.show(
        this.translateService.instant('CATEGORIES.UNEXPECTEDERROR'),
        false
      );
    }
  }

  private postGroupChild(batchId: number, parentBatchId: number): void {
    this.productGroupService.postGroupChild(batchId, parentBatchId).subscribe({
      next: (postResponse: ServerResponse) => {
        if (!postResponse) {
          return;
        }
        this.showGroupChildSnackBar(postResponse.success);
      },
      error: () => {
        this.showGroupChildSnackBar(false);
      },
    });
  }

  private deleteGroupChild(batchId: number): void {
    this.productGroupService.deleteGroupChild(batchId).subscribe({
      next: (postResponse: ServerResponse) => {
        if (!postResponse) {
          return;
        }
        this.showGroupChildSnackBar(postResponse.success);
      },
      error: () => {
        this.showGroupChildSnackBar(false);
      },
    });
  }

  private getAutocompleteGroups(isAutocompleteRequest: boolean): void {
    this.productGroupService
      .getList(
        0,
        GridSetting.listLimit,
        '',
        isAutocompleteRequest
      )
      .subscribe({
        next: (groups: ProductGroup[]) => {
          this.autocompleteGroups = groups;
        },
        error: () => {
          this.snackBarService.show(
            this.translateService.instant('ADMIN.FAIL'),
            false
          );
        },
      });
  }

  private setNewGroupTemplate(areInputElementsVisible: boolean): void {
    this.areInputElementsVisible = areInputElementsVisible;
    this.isSubmit = areInputElementsVisible;
    this.clearGroups();
  }

  private clearGroups(): void {
    this.isEdit = false;
    this.selectedProductGroup = new ProductGroup();
    this.selectedParentGroup = new ProductGroup();
    this.parentGroups = this.setParentGroup();
    this.imageBase64.emit(this.selectedProductGroup.Image);
    this.setProductGroupDefaultTranslation();
  }

  private setParentGroup(): ProductGroup[] {
    if (this.autocompleteGroups) {
      return [new ProductGroup()].concat(this.autocompleteGroups);
    } else {
      return [new ProductGroup()];
    }
  }

  private getProductGroupColums(): TableColumn[] {
    return [
      {
        columnDef: 'Name',
        header: this.translateService.instant('PRODUCTS.NAME'),
        cell: (element: ProductGroup) => `${element.Name}`,
      },
      {
        columnDef: 'HexColor',
        header: this.translateService.instant('PRODUCTS.HEXCOLOR'),
        cell: (element: ProductGroup) => `${element.HexColor}`,
      },
      {
        columnDef: 'Image',
        header: this.translateService.instant('PICTURE.PICTURE'),
        cell: (element: ProductGroup) => `${element.Image}`,
      },
    ];
  }

  private getGlobalLanguages(): void {
    this.languages = this.languageService.getList();
    this.setDefaultLanguageFromSettings();
  }

  private setDefaultLanguageFromSettings(): void {
    this.settingsService.get().subscribe({
      next: (language) => {
        if (language.length === 0) {
          return;
        }
        this.userDefaultLanguage = language[0].Language;
        this.selectedLanguage = this.languages.find(
          (lang) => lang.Language === this.userDefaultLanguage
        );
      },
    });
  }

  private setProductGroupDefaultTranslation(): void {
    let translation = this.selectedProductGroup.Translation;

    this.languages.forEach((language) => {
      const lang = {};
      lang[language.Language] = '';
      translation = Object.assign(
        {},
        translation,
        lang
      );
    });

    this.selectedProductGroup.Translation = translation;
    this.setDefaultLanguage();
  }

  private setDefaultLanguage(): void {
    this.selectedLanguage = this.languages.find(
      (lang) => lang.Language === this.userDefaultLanguage
    );
  }

  private showPostGroupSnackBar(success: boolean): void {
    if (success) {
      this.snackBarService.show(
        this.translateService.instant('CATEGORIES.POSTSUCCESS'),
        true
      );
    } else {
      this.snackBarService.show(
        this.translateService.instant('CATEGORIES.POSTFAIL'),
        false
      );
    }
  }

  private showEditGroupSnackBar(success: boolean): void {
    if (success) {
      this.snackBarService.show(
        this.translateService.instant('CATEGORIES.PUTSUCCESS'),
        true
      );
    } else {
      this.snackBarService.show(
        this.translateService.instant('CATEGORIES.PUTFAIL'),
        false
      );
    }
  }

  private showGroupChildSnackBar(success: boolean): void {
    if (success) {
      this.snackBarService.show(
        this.translateService.instant('CATEGORIES.CHILDSUCCESS'),
        true
      );
    } else {
      this.snackBarService.show(
        this.translateService.instant('CATEGORIES.CHILDFAIL'),
        false
      );
    }
  }
}
