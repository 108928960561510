import { Component, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { StockBaseDirective } from '../stock-base.component';
import { StorageCard } from '../../../core/interfaces/stock/storage-card.model';
import { Contact } from '../../contacts/models/contact.model';
import { StockGetNoteRecord } from '../../../core/interfaces/stock/stock-get-note-record.model';
import { Warehouse } from '../../company-settings/models/warehouse.model';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { StockService } from '../stock.service';
import { ContactService } from '../../contacts/contact.service';
import { StoreService } from '../../company-settings/store.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { SkuService } from '../../../core/services/sku/sku.service';
import { NgbDateService } from '../../../core/services/formatter/ngb-date.service';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { WarehouseService } from '../../company-settings/warehouse.service';
import { StockNote } from '../models/stock.model';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { StockNoteType } from '../stock-note-type.enum';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-stock-note-internal',
  templateUrl: './stock-note-internal.component.html',
  styleUrls: ['./stock-note-internal.component.scss'],
})
export class StockNoteInternalComponent
  extends StockBaseDirective
  implements OnInit {
  today: Date = new Date();
  readonly minDate: Partial<NgbDate> = {
    year: 2000,
    month: 1,
    day: 1,
  };
  readonly placement: string = 'bottom';
  maxDate: NgbDate;

  dynamicTitle: string;

  readonly keywordName: string = 'Name';
  readonly defaultStorageCardFilter: string = '';

  companyId: number;

  isAutocompleteRecordValidate: boolean = false;
  isRecordSubmitted: boolean = false;
  isStockNoteSelected: boolean = false;
  isProductsLoaded: boolean = false;
  isDataTableLoaded: boolean;
  isContactsLoaded: boolean;
  isStoreDataLoaded: boolean;

  isSaveDisabled: boolean;

  storageCardList: StorageCard[];
  selectedStorageCard: StorageCard;

  quantity: number;
  validateRecord: string = '';

  document: any;

  contactList: Contact[];
  selectedContact: Contact;

  entityList: StockGetNoteRecord[] = [];
  selectedEntity: StockGetNoteRecord;

  warehouseList: Warehouse[];
  selectedSourceWarehouse: Warehouse;
  selectedTargetWarehouse: Warehouse;

  recordsCount: number = 0;
  noteId: number;

  stockNoteForm: FormGroup;

  stockCardResponse: CreateRecordResponse;
  error: string;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    public contactService: ContactService,
    public stockService: StockService,
    public storeService: StoreService,
    public route: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public translateService: TranslateService,
    public dateService: NgbDateService,
    private snackBarService: SnackBarService,
    private skuService: SkuService,
    private warehouseService: WarehouseService,
  ) {
    super(
      router,
      route,
      storeService,
      localStorageService,
      contactService,
      translateService,
      stockService,
      dateService
    );
  }

  ngOnInit(): void {
    this.companyId = this.localStorageService.getCompanyId();

    this.setWarehouseList();
    this.setMaxDate();

    this.document = {};
    this.document.Date = this.maxDate;

    this.stockNoteForm = this.formBuilder.group({
      stockNoteQuantity: ['', Validators.required],
    });
  }

  get validateStockNoteForm() {
    return this.stockNoteForm.controls;
  }

  clear(): void {
    this.quantity = null;
    this.selectedStorageCard = null;
    this.isStockNoteSelected = false;
    this.isRecordSubmitted = false;
  }

  onNoteRecordTableSelect(noteRecord: StockGetNoteRecord): void {
    if (noteRecord) {
      this.selectedStorageCard = this.storageCardList.find(storageCard => storageCard.Id === noteRecord.StorageCardId);
      this.selectedEntity = noteRecord;
      this.isStockNoteSelected = true;
      this.quantity = noteRecord.Quantity;
      this.isAutocompleteRecordValidate = false;
    } else {
      this.clear();
    }
  }

  onAddNoteRecordClick(): void {
    this.isRecordSubmitted = true;

    if (this.validateRecord === '' || !this.selectedStorageCard.Id) {
      this.isAutocompleteRecordValidate = true;
      return;
    }

    if (this.stockNoteForm.invalid) {
      return;
    }

    const noteRecord: StockGetNoteRecord = {
      StorageCardId: this.selectedStorageCard.Id,
      Name: this.selectedStorageCard.Name,
      Quantity: this.quantity,
      UnitPrice: 1,
      Total: 1,
    };

    this.entityList.splice(0, 0, noteRecord);
    this.entityList = [...this.entityList];
    this.recordsCount = this.entityList.length;

    this.clear();
  }

  onDeleteNoteRecordClick(): void {
    this.entityList.forEach((note, index) => {
      if (note.Name === this.selectedEntity.Name) {
        this.entityList.splice(index, 1);
        this.entityList = [...this.entityList];
        this.recordsCount = this.entityList.length;
      }
    });

    this.clear();
  }

  onSaveStockCardClick(): void {
    if (this.entityList.length <= 0) {
      this.snackBarService.show('VALIDATOR.ROWVALID', false);
      return;
    }

    this.document.Date.month = this.document.Date.month - 1;

    const noteRecive: StockNote = {
      Id: 0,
      CompanyId: this.companyId,
      ContactId: 1,
      WarehouseId: this.selectedSourceWarehouse.Id,
      InvoiceNo: StockNoteType.Internal,
      Date: this.document.Date,
      Issuer: StockNoteType.Internal,
      IsFinished: true,
      Type: StockNoteType.Withdrawal,
    };

    this.entityList.forEach(entity => {
      entity.Quantity = -Math.abs(entity.Quantity);
    });

    this.postStockNote(
      noteRecive,
      this.entityList
    ).subscribe(() => {
      const noteWithdrawal: StockNote = {
        Id: 0,
        CompanyId: this.companyId,
        ContactId: 1,
        WarehouseId: this.selectedTargetWarehouse.Id,
        InvoiceNo: StockNoteType.Internal,
        Date: this.document.Date,
        Issuer: StockNoteType.Internal,
        IsFinished: true,
        Type: StockNoteType.Receive,
      };

      this.entityList.forEach(entity => {
        entity.Quantity = Math.abs(entity.Quantity);
      });

      this.postNoteCard(noteWithdrawal, this.entityList);

      this.redirectToList();
    });
  }

  onInputCleared(): void {
    this.validateRecord = '';
  }

  selectEvent(contact: Contact): void {
    this.validateRecord = contact.Name;
    this.isAutocompleteRecordValidate = false;
  }

  onChangeStorageCardSearch(storageCard: StorageCard): void {
    this.selectedStorageCard = storageCard;
  }

  private postNoteCard(
    note: StockNote,
    entityList: StockGetNoteRecord[]
  ): void {
    this.postStockNote(
      note,
      entityList
    )
      .subscribe({
        next: (successResponse) => {
          this.stockCardResponse = successResponse;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private postStockNote(
    note: StockNote,
    entityList: StockGetNoteRecord[]
  ): Observable<any> {
    return this.stockService
      .post(
        note,
        entityList
      );
  }

  private setStorageCardList(): void {
    this.skuService
      .get(
        this.defaultStorageCardFilter,
        GridSetting.firstPage,
        GridSetting.listLimit
      )
      .subscribe({
        next: (storageCardList) => {
          this.storageCardList = storageCardList;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setWarehouse(): void {
    if (this.warehouseList && this.warehouseList.length > 0) {
      this.selectedSourceWarehouse = this.warehouseList[0];
      this.selectedTargetWarehouse = this.warehouseList[0];

      this.setStorageCardList();
    }
  }

  private setWarehouseList(): void {
    this.warehouseService
      .getList()
      .subscribe({
        next: (warehouseList) => {
          this.warehouseList = warehouseList;

          this.setWarehouse();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
