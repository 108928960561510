import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ContioStore } from './models/contio-store.model';
import { catchError } from 'rxjs/operators';
import { ContioPageCount } from './models/contio-page-count.model';
import { ServerResponse } from '../../shared/models/server-response.model';
import { centralaRequestHeaders } from '../../../app/core/http/http-request';

@Injectable({
  providedIn: 'root',
})
export class ContioService {
  private contioUrl: string = Centrala.URL + '/api/contio/stores';
  private contioCountUrl: string = Centrala.URL + '/api/contio/stores/count';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  getList(
    currentPage: number,
    pageLimit: number,
  ): Observable<ContioStore[]> {
    var params = new HttpParams()
      .set('page_count', pageLimit.toString())
      .set('current_page', currentPage.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<ContioStore[]>(
        this.contioUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getContioStoreByStoreId(
    contioStoreId: number,
    isLogoIncluded?: boolean
  ): Observable<ContioStore> {
    const logoIncluded = isLogoIncluded ? isLogoIncluded.toString() : null;
    const params = new HttpParams()
      .set('storeId', contioStoreId.toString())
      .set('isLogoIncluded', logoIncluded)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    const url: string = Centrala.URL + '/api/contio-app/auth/stores';

    return this.httpClient
      .get<ContioStore>(
        url, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getPageCount(): Observable<ContioPageCount[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));
    return this.httpClient
      .get<ContioPageCount[]>(
        this.contioCountUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  post(
    storeId: number,
    contioName: string,
    isAvailable: boolean,
  ): Observable<ServerResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<ServerResponse>(
        this.contioUrl,
        {
          storeId: storeId,
          contioName: contioName,
          isAvailable: isAvailable,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  setVerificationLevel(
    verificationLevelId: number,
    contioStoreId: number,
  ): Observable<any> {
    const url: string = Centrala.URL + `/api/contio-store/${contioStoreId}/set-verification-level`;

    const body = {
      verificationLevelId: verificationLevelId,
      token: localStorage.getItem(LocalStorageTags.Token),
    };

    return this.httpClient
      .put(
        url,
        body,
        centralaRequestHeaders,
      )
      .catch(this.httpHandlerService.handleError);
  }

  setGeneralSettings(
    minimumAge: number,
    isManualRegistration: boolean,
    conditionsUrl: string,
    eshopUrl: string,
    logo: string,
    isScanAndGoAllowed: boolean,
    latitude: number,
    longitude: number,
    goPayId: number,
    goPayClientId: number,
    goPayClientSecret: string,
    contioStoreId: number,
  ): Observable<any> {
    const url: string = Centrala.URL + `/api/contio-store/${contioStoreId}/set-general-settings`;

    const body = {
      minimumAge: minimumAge,
      isManualRegistration: isManualRegistration,
      conditionsUrl: conditionsUrl,
      eshopUrl: eshopUrl,
      logo: logo,
      isScanAndGoAllowed: isScanAndGoAllowed,
      latitude: latitude,
      longitude: longitude,
      goPayId: goPayId,
      goPayClientId: goPayClientId,
      goPayClientSecret: goPayClientSecret,
      token: localStorage.getItem(LocalStorageTags.Token),
    };

    return this.httpClient
      .put(
        url,
        body,
        centralaRequestHeaders,
      )
      .catch(this.httpHandlerService.handleError);
  }
}
