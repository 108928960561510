import { Component, OnInit } from '@angular/core';
import { RegistrationService } from '../../core/services/registration/registration.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ServerResponse } from '../../core/interfaces/shared/server-response.model';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  copyrightYear = new Date().getFullYear();

  registrationData: ServerResponse = {
    action: '',
    success: false,
    message: '',
    code: null,
  };

  codeMessage: string;

  errorMessage: string;

  alertStrongMessage = 'REGISTRATION.DANGERMESSAGE';

  constructor(
    private registrationService: RegistrationService,
    private location: Location,
    private translateService: TranslateService
  ) {
    this.setLanguage();
  }

  setLanguage() {
    this.translateService.addLangs(['en', 'cs']);

    const browserLang = this.translateService.getBrowserLang();

    if (this.isBrowserLanguageValid(browserLang, this.translateService.getLangs())) {
      localStorage.setItem('defLang', browserLang);
      this.translateService.setDefaultLang(browserLang);
      this.translateService.use(browserLang);
    } else {
      localStorage.setItem('defLang', 'en');
      this.translateService.setDefaultLang('en');
      this.translateService.use('en');
    }

    this.translateService.setDefaultLang(localStorage.getItem('defLang'));
    this.translateService.use(localStorage.getItem('defLang'));
  }

  isBrowserLanguageValid(browserLang: string, langs: string[]) {
    for (let i = 0; i < langs.length; i++) {
      if (langs[i] === browserLang) {
        return true;
      }
    }

    return false;
  }

  ngOnInit(): void {
    document.getElementById('alertDiv').style.visibility = 'hidden';
  }

  registerReq(
    email: string,
    password: string,
    passwordAgain: string,
    ico: string
  ) {
    document.getElementById('alertDiv').className =
      'alert alert-danger alert-dismissable';

    if (email === '' || password === '' || passwordAgain === '' || ico === '') {
      document.getElementById('alertDiv').style.visibility = 'visible';
      this.codeMessage = 'INTERNAL_CODE200';
      return;
    }
    if (password.length < 6) {
      document.getElementById('alertDiv').style.visibility = 'visible';
      this.codeMessage = 'INTERNAL_CODE201';
      return;
    }
    if (password != passwordAgain) {
      document.getElementById('alertDiv').style.visibility = 'visible';
      this.codeMessage = 'INTERNAL_CODE202';
      return;
    }
    if (ico.length < 6 || !+ico) {
      document.getElementById('alertDiv').style.visibility = 'visible';
      this.codeMessage = 'INTERNAL_CODE203';
      return;
    }

    this.registrationService.register(email, password, ico).subscribe(
      (extractData) => (this.registrationData = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => this.handleRegistration()
    );
  }

  handleRegistration() {
    document.getElementById('alertDiv').style.visibility = 'visible';
    if (this.registrationData.code === 100) {
      this.alertStrongMessage = 'REGISTRATION.SUCCESSMESSAGE';
      document.getElementById('alertDiv').className =
        'alert alert-success alert-dismissable';
      this.codeMessage = 'CODE100';
    } else if (this.registrationData.code === 101) {
      this.codeMessage = 'CODE101';
    } else if (this.registrationData.code === 102) {
      this.codeMessage = 'CODE102';
    } else if (this.registrationData.code === 103) {
      this.codeMessage = 'CODE103';
    } else if (this.registrationData.code === 104) {
      this.codeMessage = 'CODE104';
    } else if (this.registrationData.code === 105) {
      this.codeMessage = 'CODE105';
    } else if (this.registrationData.code === 106) {
      this.codeMessage = 'CODE106';
    } else if (this.registrationData.code === -1) {
      this.codeMessage = 'CODE-1';
    }
  }

  back() {
    this.location.back();
  }
}
