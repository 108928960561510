import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../shared/http-handler.service';
import { ServerResponse } from '../../interfaces/shared/server-response.model';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RegistrationService {
  private activationUrl: string = Centrala.URL + '/api/activation';
  private registrationUrl: string = Centrala.URL + '/api/register';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  /**
   * Authenticate user
   * @param token {string} token
   */
  activate(token: string): Observable<ServerResponse> {
    const params = new HttpParams()
      .set('activation_token', token);

    return this.httpClient
      .get<ServerResponse>(
        this.activationUrl, {params}
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  /**
   * Register user
   * @param email {string}
   * @param password {string}
   * @param bin {string} Business Identification Number
   */
  register(
    email: string,
    password: string,
    bin: string
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.registrationUrl,
        {
          token: localStorage.getItem(LocalStorageTags.Token),
          username: email,
          password: password,
          ico: bin,
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
