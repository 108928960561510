<authentication-test></authentication-test>
<navigation selectedPage="contio-store">
  <div class="row no-gutters">
    <div class="col-8">
      <div class="row no-gutters">
        <div class="col-12 page-header">
          {{ 'SHOP.SCHEDULE' | translate }}
        </div>
      </div>
    </div>

    <div class="col-4">
      <button
        class="normal-btn float-right"
        mat-button
        (click)="onGoToContioStoreDetailClick()"
      >
        {{ 'REGISTRATION.BACK' | translate }}
      </button>
    </div>
  </div>

  <hr class="line" />

  <div *ngIf="scheduleList">
    <div *ngFor="let schedule of scheduleList; let index = index">
      <div
        fxLayout="row wrap"
        fxLayoutGap="16px grid"
        fxLayoutAlign="start center"
        fxFlexFill
        class="mb-0"
      >
        <div fxFlex="100%" fxFlex.gt-md="15%">
          <app-checkbox
            *ngIf="schedule.Counter === 0"
            class="ml-3"
            label="{{ schedule.DayOfWeek | day }}"
            labelPosition="after"
            [checked]="schedule.IsOpen"
            (checkedChange)="onCheckChange($event, schedule.DayOfWeek)"
          ></app-checkbox>
        </div>

        <div fxFlex="100%" fxFlex.gt-md="25%">
          <app-input-text
            *ngIf="schedule.IsOpen"
            [(input)]="schedule.StartTime"
            type="time"
            label="Od"
            class="mt-0"
          ></app-input-text>
        </div>

        <div fxFlex="100%" fxFlex.gt-md="25%">
          <app-input-text
            *ngIf="schedule.IsOpen"
            [(input)]="schedule.EndTime"
            type="time"
            label="Do"
            class="mt-0"
          ></app-input-text>
        </div>

        <div fxFlex="100%" fxFlex.gt-md="10%">
          <button
            *ngIf="schedule.IsOpen && schedule.Counter === 0"
            class="normal-btn"
            mat-button
            (click)="onAddTimeClick(index, schedule.DayOfWeek)"
          >
            <i class="fas fa-plus mr-1"></i>
          </button>

          <button
            *ngIf="schedule.IsOpen && schedule.Counter > 0"
            class="delete-btn"
            mat-button
            (click)="onDeleteTimeClick(index)"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <button
    *ngIf="isNewscheduleList && scheduleList.length > 0"
    class="save-btn float-right mr-2"
    mat-button
    (click)="onAddNewSchedule()"
  >
    <i class="fas fa-save"></i>
    <span class="icon-name">{{ 'PRODUCTS.SAVE' | translate }}</span>
  </button>

  <button
    *ngIf="!isNewscheduleList && scheduleList.length > 0"
    class="save-btn float-right mr-2"
    mat-button
    (click)="onEditScheduleClick()"
  >
    <i class="fas fa-save"></i>
    <span class="icon-name">{{ 'PRODUCTS.SAVE' | translate }}</span>
  </button>
</navigation>
