<authentication-test></authentication-test>
<navigation selectedPage="administrator-detail">
  <div class="row no-gutters">
    <div class="col-10 page-header">
      {{ 'ADMINISTRATOR.ADMINISTRATORDETAIL' | translate }}
    </div>
    <div class="col-2">
      <button
        class="normal-btn ml-1 float-right"
        mat-button
        (click)="onGoToUserListClick()"
      >
        {{ 'REGISTRATION.BACK' | translate }}
      </button>
    </div>
  </div>

  <hr class="custom-hr" />

  <div *ngIf="subaccount" class="row mt-3 mb-3">
    <div class="col-xl-6 col-sm-12">
      <div class="card">
        <div class="card-content">
          <div class="row no-gutters">
            <div class="col-12 col-lg-6 d-flex">
              <h5 class="card-title mt-3 ml-3 text-left detail-text">
                {{ 'USERS.NAME' | translate }}:
                <span class="font-weight-bold detail-text">
                  {{ subaccount.Name }} {{ subaccount.Surname }}
                </span>
              </h5>
            </div>
            <div class="col-12 col-lg-6 d-flex">
              <h5 class="card-title mt-3 ml-3 text-right detail-text">
                {{ 'USERS.EMAIL' | translate }}:
                <span class="font-weight-bold detail-text">
                  {{ subaccount.Email }}
                </span>
              </h5>
            </div>
            <hr class="mx-2 custom-hr w-100 mb-2 mt-2" />

            <div class="ml-2 mb-2 w-100">
              <div class="row">
                <div class="col-md-12">
                  <app-dropdown
                    *ngIf="companyPrivilege.Value"
                    [(selected)]="companyPrivilege"
                    label="{{ 'NAVIGATION.STORES' | translate }}"
                    itemKey="Value"
                    [items]="priviligeType"
                    [type]="dropdownType.Subaccount"
                  ></app-dropdown>
                </div>
                <div class="col-md-12 mt-2">
                  <app-dropdown
                    *ngIf="contioPrivilege.Value"
                    [(selected)]="contioPrivilege"
                    label="{{ 'NAVIGATION.SHOP' | translate }}"
                    itemKey="Value"
                    [items]="priviligeType"
                    [type]="dropdownType.Subaccount"
                  ></app-dropdown>
                </div>
                <div class="col-md-12 mt-2">
                  <app-dropdown
                    *ngIf="loyaltyCardPrivilege.Value"
                    [(selected)]="loyaltyCardPrivilege"
                    label="{{ 'NAVIGATION.LOYALTYCARDS' | translate }}"
                    itemKey="Value"
                    [items]="priviligeType"
                    [type]="dropdownType.Subaccount"
                  ></app-dropdown>
                </div>
                <div class="col-md-12 mt-2">
                  <app-dropdown
                    *ngIf="reportPrivilege.Value"
                    [(selected)]="reportPrivilege"
                    label="{{ 'SHOP.REPORTS' | translate }}"
                    itemKey="Value"
                    [items]="priviligeType"
                    [type]="dropdownType.Subaccount"
                  ></app-dropdown>
                </div>
                <div class="col-md-12 mt-2">
                  <app-dropdown
                    *ngIf="userPrivilege.Value"
                    [(selected)]="userPrivilege"
                    label="{{ 'NAVIGATION.USERS' | translate }}"
                    itemKey="Value"
                    [items]="priviligeType"
                    [type]="dropdownType.Subaccount"
                  ></app-dropdown>
                </div>
                <div class="col-md-12 mt-2">
                  <button class="save-btn float-right mr-2" mat-button (click)="onPrivilegeSaveClick()">
                    <i class="fas fa-save"></i>
                    <span class="icon-name">{{ 'PRODUCTS.SAVE' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</navigation>
