export enum CsvImportColumn {
    Name = 0,
    Abbreviation = 1,
    GroupName = 2,
    VatPercentage = 3,
    Price = 4,
    Quantity = 5,
    Unit = 6,
    Barcode = 7,
    SkuCode = 8,
    SaleQuantity = 9,
    IsKitchen = 10,
    Plu = 11,
    UnitId = 12,
    BatchId = 13,
    TaxId = 14,
}
