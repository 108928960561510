import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { DevicePageCount } from './models/device-page-count.model';
import { catchError } from 'rxjs/operators';
import { Device } from './models/device.model';
import { ServerResponse } from '../../shared/models/server-response.model';
import { EditRecordResponse } from '../../shared/models/edit-record-response.model';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private devicesUrl: string = Centrala.URL + '/api/devices';
  private devicesCountUrl: string = Centrala.URL + '/api/devices/count';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  post(
    storeId: number,
    name: string,
    ip: string,
    running: boolean,
    shelly: string
  ): Observable<ServerResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<ServerResponse>(
        this.devicesUrl,
        {
          storeId: storeId,
          name: name,
          ip: ip,
          running: running,
          shelly: shelly,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  get(
    storeId: number,
    currentPage: number,
    pageCount: number
  ): Observable<Device[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('StoreId', storeId.toString())
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

    return this.httpClient
      .get<Device[]>(
        this.devicesUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getPageCount(storeId: number): Observable<DevicePageCount> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('StoreId', storeId.toString());

    return this.httpClient
      .get<DevicePageCount>(
        this.devicesCountUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  put(
    storeId: number,
    id: number,
    name: string,
    ip: string,
    running: boolean,
    shelly: string,
    date: Date
  ): Observable<EditRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<EditRecordResponse>(
        this.devicesUrl,
        {
          storeId: storeId,
          id: id,
          name: name,
          ip: ip,
          running: running,
          shelly: shelly,
          date: date,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
