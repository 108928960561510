import { Directive, OnInit } from '@angular/core';
import { StockGetNoteRecord } from '../../core/interfaces/stock/stock-get-note-record.model';
import { ListBaseDirective } from '../../shared/components/authentication-test/list-base.component';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';
import { StoreService } from '../company-settings/store.service';
import { ContactService } from '../contacts/contact.service';
import { Contact } from '../contacts/models/contact.model';
import { TableColumn } from '../../core/interfaces/shared/table-columns.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StockService } from './stock.service';
import { GridSetting } from '../../shared/models/grid-setting.model';
import { ActivatedRoute, Router } from '@angular/router';
import { StockRoute } from './stock-list/stock-route.enum';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateService } from '../../core/services/formatter/ngb-date.service';

@Directive()
export abstract class StockBaseDirective
  extends ListBaseDirective<StockGetNoteRecord>
  implements OnInit {
  companyId: number;
  columns: TableColumn[];

  getList$: Observable<StockGetNoteRecord[]>;
  entityList: StockGetNoteRecord[] = [];
  currentPage: number = 0;
  stockCount: number;

  abstract maxDate: NgbDate;

  abstract recordsCount: number;
  abstract isStoreDataLoaded: boolean;
  abstract isDataTableLoaded: boolean;
  abstract document: any;
  abstract noteId: number;

  abstract contactList: Contact[];
  abstract selectedContact: Contact;

  error: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public storeService: StoreService,
    public localStorageService: LocalStorageService,
    public contactService: ContactService,
    public translateService: TranslateService,
    public stockService: StockService,
    public dateService: NgbDateService,
  ) {
    super();

    this.columns = this.getNoteRecordListColums();
    this.companyId = this.localStorageService.getCompanyId();
  }

  ngOnInit(): void {
    this.noteId = Number(this.route.snapshot.paramMap.get('value'));
  }

  fetchNote(): void {
    this.stockService
      .getNote(this.noteId)
      .subscribe({
        next: (stockDetail) => {
          this.document = stockDetail[0];
          this.document.Date = new Date(this.document.Date);
          this.document.Date.year = this.document.Date.getFullYear();
          this.document.Date.month = this.document.Date.getMonth() + 1;
          this.document.Date.day = this.document.Date.getDate();

          this.fetchNoteRecords(this.noteId);
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  getCompanyContacts(): void {
    this.contactService
      .getContactsForCompany(
        this.companyId,
        GridSetting.firstPage,
        GridSetting.listLimit
      )
      .subscribe({
        next: (contactList) => {
          this.contactList = contactList;

          this.setContact();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  setContact(): void {
    if (!this.contactList) {
      return;
    }

    if (this.noteId && !this.selectedContact) {
      this.loadDocumentContact(this.document);
    }
  }

  loadDocumentContact(document: any): void {
    this.selectedContact = this.contactList.find(
      (contact) => contact.Id === document.ContactId
    );
  }

  onPageChange(pageIndex?: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.stockService.getNoteRecords(
      this.noteId
    );
    this.setList();
  }

  redirectToList(): void {
    this.router.navigate(['/' + StockRoute.Stock]);
  }

  setMaxDate(): void {
    this.maxDate = this.dateService.ngbDateMax();
  }

  private fetchNoteRecords(noteId: number): void {
    this.stockService
      .getNoteRecords(noteId)
      .subscribe({
        next: (entityList) => {
          this.entityList = entityList;
          this.recordsCount = this.entityList.length;
          this.isDataTableLoaded = true;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private getNoteRecordListColums(): TableColumn[] {
    return [
      {
        columnDef: 'Name',
        header: this.translateService.instant('STOCK.NAME'),
        cell: (element: StockGetNoteRecord) => `${element.Name}`,
      },
      {
        columnDef: 'Quantity',
        header: this.translateService.instant('STOCK.QUANTITY'),
        cell: (element: StockGetNoteRecord) => `${element.Quantity}`,
      },
      {
        columnDef: 'UnitPrice',
        header: this.translateService.instant('STOCK.UNITPRICE'),
        cell: (element: StockGetNoteRecord) => `${element.UnitPrice}`,
      },
      {
        columnDef: 'Total',
        header: this.translateService.instant('STOCK.TOTAL'),
        cell: (element: StockGetNoteRecord) => `${element.Total}`,
      },
    ];
  }
}
