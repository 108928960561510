import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  readonly unixMiliseconds: number = 1000;
  readonly daySeconds: number = 86400;

  constructor() { }

  convertNgbDateToUnixTimestamp(date: NgbDate, isEndDate: boolean): number {
    const dateForConvert = new Date();
    dateForConvert.setDate(date.day + 1);
    dateForConvert.setMonth(date.month - 1);
    dateForConvert.setFullYear(date.year);
    if (isEndDate) {
      dateForConvert.setHours(23, 59, 59);
    }
    return dateForConvert.getTime() / this.unixMiliseconds - this.daySeconds;
  }

  getStartOfDayWithSubtractedDayInUnixTimestamp(dateFrom: NgbDate): number {
    const convertedDate = new Date(dateFrom.year, dateFrom.month - 1, dateFrom.day);
    return convertedDate.getTime() / this.unixMiliseconds;
  }

  getEndOfDayWithSubtractedDayInUnixTimestamp(dateTo: NgbDate): number {
    const convertedDate = new Date(dateTo.year, dateTo.month - 1, dateTo.day);
    convertedDate.setHours(23, 59, 59);
    return convertedDate.getTime() / this.unixMiliseconds ;
  }

  getStartOfDayInUnixTimestamp(date: Date): number {
    const fromDate = new Date(date);
    fromDate.setHours(0, 0, 0);
    let unixFrom = fromDate.getTime() / this.unixMiliseconds;
    unixFrom = Math.floor(unixFrom);
    return unixFrom;
  }

  getEndOfTheDayUnixTimestamp(date: Date): number {
    const toDate = new Date(date);
    toDate.setHours(23, 59, 59);
    let unixTo = toDate.getTime() / this.unixMiliseconds;
    unixTo = Math.floor(unixTo);
    return unixTo;
  }

  convertDateToUnixTimestamp(dateToConvert: Date): number {
    const date = new Date(dateToConvert);
    const unixDate = date.getTime() / this.unixMiliseconds;
    const roundedUnixDate = Math.floor(unixDate);
    return roundedUnixDate;
  }
}
