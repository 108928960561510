import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StockGetNoteRecord } from '../../core/interfaces/stock/stock-get-note-record.model';
import { catchError } from 'rxjs/operators';
import { StockPageCount } from './models/stock-page-count.model';
import { StockNote } from './models/stock.model';

@Injectable({
  providedIn: 'root',
})
export class StockService {
  private stocksUrl: string = Centrala.URL + '/api/stock';
  private stockRecordsUrl: string = Centrala.URL + '/api/stock-record';
  private paginationUrl: string = Centrala.URL + '/api/stock/pagination';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService,
  ) { }

  /**
   * Gets stock notes for specific ID and pagination
   * @param companyId {string} ID of company
   * @param currentPage {number} Current page
   * @param pageCount {number} Total page count
   */
  getCompanyNotes(
    companyId: number,
    currentPage: number,
    pageCount: number
  ): Observable<StockNote[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('companyId', companyId.toString())
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());
    return this.httpClient
      .get<StockNote[]>(
        this.stocksUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  /**
   * Gets stock note data
   * @param stockId {number} note ID
   */
  getNote(stockId: number): Observable<StockNote[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('id', stockId.toString());

    return this.httpClient
      .get<StockNote[]>(
        this.stocksUrl, {params}
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  /**
   * Get stock note records
   * @param noteId {number} note ID
   */
  getNoteRecords(noteId: number): Observable<StockGetNoteRecord[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('noteId', noteId.toString());
    return this.httpClient
      .get<StockGetNoteRecord[]>(
        this.stockRecordsUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  /**
   * Gets pagination information for stocks
   * @param companyId {string} ID of company
   */
  getPageCount(companyId: number): Observable<StockPageCount> {
    const params = new HttpParams()
      .set('companyId', companyId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));
    return this.httpClient
      .get<StockPageCount>(
        this.paginationUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  /**
   * Create new stock note
   * @param note {any}
   * @param records {any}
   */
  post(
    note: any,
    records: any
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.stocksUrl,
        {
          note: JSON.stringify(note),
          records: JSON.stringify(records),
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Update stock note
   * @param note {any}
   * @param records {any}
   */
  put(
    note: any,
    records: any
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put(
        this.stocksUrl,
        {
          note: JSON.stringify(note),
          records: JSON.stringify(records),
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
