import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { UserPageCount } from './models/user-page-count.model';
import { catchError } from 'rxjs/operators';
import { User } from './models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private usersListUrl: string = Centrala.URL + '/api/clientList';
  private usersCountUrl: string = Centrala.URL + '/api/clientList/count';
  private usersImportUrl: string = Centrala.URL + '/api/client/import';
  private deactivateUserUrl: string = Centrala.URL + '/api/clientUser/deactivate';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  getList(
    filter: string,
    currentPage: number,
    pageCount: number,
    clientUserId?: number,
    isEmptyPersonIncluded?: boolean,
  ): Observable<User[]> {
    let params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('filter', filter)
      .set('current_page', currentPage.toString())
      .set('page_count', pageCount.toString());

    if (clientUserId != null) {
      params = params.set('clientUserId', clientUserId.toString());
    }

    if (isEmptyPersonIncluded != null) {
      params = params.set('isEmptyPersonIncluded', isEmptyPersonIncluded.toString());
    }

    return this.httpClient
      .get<User[]>(
        this.usersListUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getPageCount(
    filter: string,
    isEmptyPersonIncluded?: boolean
  ): Observable<UserPageCount> {
    let params = new HttpParams()
      .set('filter', filter)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    if (isEmptyPersonIncluded != null) {
      params = params.set('isEmptyPersonIncluded', isEmptyPersonIncluded.toString());
    }

    return this.httpClient
      .get<UserPageCount>(
        this.usersCountUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  uploadFile(csv: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.usersImportUrl,
        {
          file: csv,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  deactivateUser(clientUserId: Number, deactivate: boolean): Observable<any> {
    return this.httpClient
      .post(
        this.deactivateUserUrl,
        {
          ClientUserId: clientUserId,
          Deactivate: deactivate,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
      )
      .catch(this.httpHandlerService.handleError);
  }
}
