import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../../core/services/product/product.service';
import { LocalStorageTags } from '../../../core/enums/local-storage-tags.enum';
import { StorageCardUnit } from '../../../core/interfaces/storage-card-unit/storage-card-unit.model';
import { StoreCardCategory } from '../../../core/interfaces/store-card-category/store-card-category.model';
import { Warehouse } from '../../../modules/company-settings/models/warehouse.model';
import { SkuService } from '../../../core/services/sku/sku.service';
import { StorageCard } from '../../../core/interfaces/stock/storage-card.model';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { SkuPostResponse } from '../models/sku-post-response.model';
import { ImportProduct } from '../models/import-product.model';
import { SkuIngredient } from '../models/sku-ingredient.model';

@Component({
  selector: 'app-sku-import',
  templateUrl: './sku-import.component.html',
})
export class SkuImportComponent implements OnInit {
  @Input() warehouseList: Warehouse[];
  @Input() categoryList: StoreCardCategory[];
  @Input() unitList: StorageCardUnit[];
  @Input() selectedWarehouse: Warehouse;
  @Output() selectWarehouse: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();
  @Output() isImportSuccessChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedUnitList: StorageCardUnit[] = [];
  selectedCategoryList: StoreCardCategory[] = [];
  isAllCheck: boolean;

  isWarehouseLoading: boolean = false;
  isProductLoading: boolean = false;
  areProductsLoaded: boolean = false;
  isImportValid: boolean = false;
  productSearchPhrase: string;
  productFilter: string = '';
  products: ImportProduct[];
  checkedProducts: ImportProduct[] = [];
  isValue: number = 0;

  isDropdownValidate: boolean = false;

  sku: StorageCard = new StorageCard();
  isCategoryLoaded: boolean = false;
  isWarehouseLoaded: boolean = false;

  importPostResponse: SkuPostResponse;

  error: string;

  @ViewChild('importModal') import: any;

  constructor(
    private modalService: NgbModal,
    private productService: ProductService,
    private skuService: SkuService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    if (this.warehouseList) {
      this.isWarehouseLoaded = true;
    }
  }

  getSelectedWarehouse(warehouse: Warehouse): void {
    this.selectedWarehouse = warehouse;
  }

  openModalImport(): void {
    if (!this.categoryList || !this.unitList) {
      this.isDropdownValidate = true;
      return;
    }

    this.modalService.open(this.import, {
      windowClass: 'sku-import-modal',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
  }

  onImportCategoryChange(
    selectedCategoryId: number,
    selectedProduct: ImportProduct
  ): void {
    const category = this.categoryList.find(category => category.Id === selectedCategoryId);
    const productIndex = this.products.findIndex(product => product.Text === selectedProduct.Text);

    this.products[productIndex].BatchId = category.Id;
    this.products[productIndex].BatchName = category.Name;
  }

  onImportUnitChange(
    selectedUnitId: number,
    selectedProduct: ImportProduct
  ): void {
    const unit = this.unitList.find(unit => unit.Id === selectedUnitId);
    const productIndex = this.products.findIndex(product => product.Text === selectedProduct.Text);

    this.products[productIndex].UnitId = unit.Id;
    this.products[productIndex].Unit = unit.Name;
  }

  onPrepareImportProductCLick(): void {
    this.onClearImportModal();
    this.setProducts();
    this.openModalImport();
  }

  onImportProductsClick(): void {
    this.checkedProducts = [];

    this.products.forEach((product) => {
      if (product.checked) {
        if (
          !this.checkedProducts.some(
            (item) => item.Text === product.Text
          )
        ) {
          this.checkedProducts.push(product);
        }
      }
    });

    this.checkedProducts.forEach((product) => {
      if (this.checkedProducts.some((item) => !item.Text || !item.Plu || !item.Unit || !item.BatchName)) {
        this.isImportValid = true;
        return;
      } else {
        this.isImportValid = false;
      }

      const sku: StorageCard = {
        Id: -1,
        CardNumber: product.Plu,
        Name: product.Text,
        IsIngredient: product.IsIngredient,
        Barcode: this.sku.Barcode,
        StorageCardUnitsId: product.UnitId,
        MinState: this.sku.MinState,
        Packaging: this.sku.Packaging,
        MinDurability: this.sku.MinDurability,
        StorageCardCategoryId: product.BatchId,
        WarehouseId: this.selectedWarehouse.Id,
        Note: this.sku.Note,
      };

      if (product.IsIngredient) {
        const skuIngredient: SkuIngredient = {
          StorageCard: sku,
          ProductId: product.ProductId,
          Coefficient: 1,
        }

        this.skuService
        .postWithIngredient(skuIngredient)
        .subscribe({
          next: (successResponse) => {
            this.importPostResponse = successResponse;
            if (successResponse.success) {
              this.isImportSuccessChange.emit(successResponse.success);
              this.selectWarehouse.emit(this.selectedWarehouse);
            }

            this.snackBarService.show('PRODUCTS.IMPORTPRODUCTSSUCCESS', successResponse.success);
          },
          error: (error) => {
            this.error = error;

            this.snackBarService.show('PRODUCTS.IMPORTPRODUCTSFAIL', false);
          },
        });
      } else {
        this.skuService
        .post(sku)
        .subscribe({
          next: (successResponse) => {
            this.importPostResponse = successResponse;
            if (successResponse.success) {
              this.isImportSuccessChange.emit(successResponse.success);
              this.selectWarehouse.emit(this.selectedWarehouse);
            }

            this.snackBarService.show('PRODUCTS.IMPORTPRODUCTSSUCCESS', successResponse.success);
          },
          error: (error) => {
            this.error = error;

            this.snackBarService.show('PRODUCTS.IMPORTPRODUCTSFAIL', false);
          },
        });
      }
    });
  }

  updateProductFilter(productFilter: string): void {
    this.productSearchPhrase = productFilter;

    this.selectedCategoryList = [];
    this.selectedUnitList = [];

    const filteredProducts = this.products.filter(product => product.Text.toLowerCase().includes(productFilter.toLowerCase()) || String(product.Plu).includes(productFilter));

    this.setSelectedUnitList(filteredProducts);
    this.setSelectedCategoryList(filteredProducts);
  }

  onClearImportModal(): void {
    this.selectedCategoryList = [];
    this.selectedUnitList = [];
    this.checkedProducts = [];
    this.productSearchPhrase = '';
    this.productFilter = '';
    this.isValue = 0;

    if (this.products) {
      this.setSelectedUnitList(this.products);
      this.setSelectedCategoryList(this.products);
    }
  }

  onCheckAllChange(checkbox: any): void {
    this.products.forEach((product) => (product.checked = checkbox.target.checked));

    this.isAllChecked();
  }

  setActive(btn: number): void {
    this.isValue = btn;
  }

  isButtonActive(btn: number): boolean {
    return this.isValue === btn;
  }

  private isAllChecked(): void {
    if (this.products !== undefined) {
      this.isAllCheck = this.products.every((product) => product.checked);
    }
  }

  private setProducts(): void {
    if (!this.categoryList || !this.unitList) {
      this.isDropdownValidate = true;
      return;
    }

    this.productService
      .getImport(localStorage.getItem(LocalStorageTags.CompanyId))
      .subscribe({
        next: (productList) => {
          this.products = productList;

          this.setSelectedUnitList(this.products);
          this.setSelectedCategoryList(this.products);

          if (this.products.length === 0) {
            this.areProductsLoaded = true;
          }

          this.isProductLoading = true;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setSelectedUnitList(productList: ImportProduct[]): void {
    productList.forEach(product => {
      const selectedUnit = this.unitList.find(unit => unit.Name === product.Unit);

      if (selectedUnit) {
        this.selectedUnitList.push(selectedUnit);
      } else {
        const unselectUnit: StorageCardUnit = {
          Id: null,
          Name: null,
        };

        this.selectedUnitList.push(unselectUnit);
      }
    });
  }

  private setSelectedCategoryList(productList: ImportProduct[]): void {
    productList.forEach(product => {
      const selectedCategory = this.categoryList.find(category => category.Name === product.BatchName);

      if (selectedCategory) {
        this.selectedCategoryList.push(selectedCategory);
      } else {
        product.BatchId = null;
        product.BatchName = null;

        const unselectCategory: StoreCardCategory = {
          Id: null,
          Name: null,
        }

        this.selectedCategoryList.push(unselectCategory);
      }
    });
  }
}
