<div class="pt-5 container container-logo text-center">
  <img
    src="../img/centrala.png"
    alt="logo"
    class="w-75 h-75 pt-3 pb-4"
  />
</div>

<div
  class="container pt-5 input-div"
>
  <h1>{{ 'REGISTRATION.TITLE' | translate }}</h1>

  <div>
    <label for="email">Email</label>
    <input #email type="text" class="form-control input-sm" id="email" />
  </div>

  <div>
    <label for="password">{{ 'LOGIN.PASSWORD' | translate }}</label>
    <input
      #password
      type="password"
      class="form-control input-sm"
      id="password"
    />
  </div>

  <div>
    <label for="passwordAgain">{{ 'REGISTRATION.PWDAGAIN' | translate }}</label>
    <input
      #passwordAgain
      type="password"
      class="form-control input-sm"
      id="passwordAgain"
    />
  </div>

  <div>
    <label for="ico">{{ 'REGISTRATION.ICO' | translate }}</label>
    <input #ico type="text" class="form-control input-sm" id="ico" />
  </div>

  <div class="mt-3">
    <button
      [disabled]="registrationData.success"
      id="btnRegistration"
      type="button"
      class="btn btn-success"
      (click)="
        registerReq(email.value, password.value, passwordAgain.value, ico.value)
      "
    >
      {{ 'REGISTRATION.REGISTRATE' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-primary ml-2"
      (click)="back()"
    >
      {{ 'REGISTRATION.BACK' | translate }}
    </button>
  </div>

  <div id="alertDiv" class="mt-4">
    <strong>{{ alertStrongMessage | translate }}</strong>
    {{ codeMessage | translate }}
  </div>
</div>

<div class="registration">
  <p class="pt-2">
    © {{ copyrightYear }}
    <a class="footer-a" href="https://www.arrowsys.cz"
      >ArrowSys s.r.o.</a
    >
  </p>
</div>
