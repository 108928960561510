import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../http/centrala';
import { HttpHandlerService } from '../services/shared/http-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AuthenticationTest } from '../../modules/admin-panel/models/authentication-test.model';

@Injectable()
export class AuthenticationTestService {
  private dashboardUrl: string = Centrala.URL + '/api/dashboard';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  authenticationTest(token: string): Observable<AuthenticationTest> {
    const params = new HttpParams()
      .set('token', token);

    return this.httpClient
      .get<AuthenticationTest>(
        this.dashboardUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
