<div fxLayout="100%" class="dialog-title">
  <h1 mat-dialog-title>{{ title }}</h1>
  <button class="delete-btn float-right" mat-button (click)="onOpenDeleteDialogClick()" *ngIf="isDelete">
    <i class="fas fa-trash-alt mr-1"></i>
    <span class="icon-name">{{ 'PRODUCTS.DELETEGROUP' | translate }}</span>
  </button>
</div>
<mat-divider></mat-divider>
<div mat-dialog-content>
  <div class="input" fxFlex="100%">
    <app-input-text
      [(input)]="value"
      [submit]="submit"
      type="text"
      label="{{ 'PRODUCTS.NAME' | translate }}"
      errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
      [required]="true"
    ></app-input-text>
  </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
  <div fxFlex="100%" fxLayoutAlign="end">
    <button class="close-btn" mat-button mat-dialog-close>
      {{ "DIALOG.CLOSE" | translate }}
    </button>
    <button class="save-btn" mat-button (click)="onSubmitClick(value)">
      <i class="fas fa-save"></i>
      <span *ngIf="!isEdit" class="icon-name">{{ "DIALOG.ADD" | translate }}</span>
      <span *ngIf="isEdit" class="icon-name">{{ "DIALOG.SAVE" | translate }}</span>
    </button>
  </div>
</div>
