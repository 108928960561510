<div class="row no-gutters">
  <div class="col-12 float-left">
    <div class="input-group-prepend">
      <span class="input-group-text">{{ autocompleteName | translate }}</span>

      <div
        class="ng-autocomplete w-100"
        [ngClass]="{
          'autocomplete-invalid': validate
        }"
      >
        <ng-autocomplete
          [data]="items"
          [searchKeyword]="keyword"
          [(ngModel)]="input"
          (selected)="selectEvent($event)"
          (inputChanged)="onChangeSearch($event)"
          (inputCleared)="onInputCleared()"
          [itemTemplate]="itemTemplate"
          [notFoundTemplate]="notFoundTemplate"
        >
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item[keyword]"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
          <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
    <span class="help-block invalid-name" *ngIf="validate">{{
      'VALIDATOR.VALIDATION' | translate
    }}</span>
  </div>
</div>
