import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export function CustomPaginator(translateService: TranslateService): MatPaginatorIntl {
    const customPaginatorIntl = new MatPaginatorIntl();

    customPaginatorIntl.itemsPerPageLabel = translateService.instant('TABLECOMPONENT.ITEMSPERPAGE');
    customPaginatorIntl.nextPageLabel = translateService.instant('TABLECOMPONENT.NEXTPAGE');
    customPaginatorIntl.previousPageLabel = translateService.instant('TABLECOMPONENT.PREVIOUSPAGE');
    customPaginatorIntl.firstPageLabel = translateService.instant('TABLECOMPONENT.FIRSTPAGE');
    customPaginatorIntl.lastPageLabel = translateService.instant('TABLECOMPONENT.LASTPAGE');

    customPaginatorIntl.getRangeLabel = (pageIndex: number, pageSize: number, totalPageCount: number): string => {
        const translateOf = translateService.instant('TABLECOMPONENT.OF');

        if (totalPageCount === 0 || pageSize === 0) {
            return `0 ${translateOf} ${totalPageCount}`;
        }

        const lastPage = Math.max(totalPageCount, 0);
        const startIndex = pageIndex * pageSize;
        const currentPage = Math.min(startIndex + pageSize, lastPage);

        const endIndex = startIndex < lastPage ? currentPage : startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} ${translateOf} ${lastPage}`;
    };

    return customPaginatorIntl;
}
