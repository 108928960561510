<div class="checkbox">
  <mat-checkbox
    [(ngModel)]="checked"
    [(indeterminate)]="indeterminate"
    [labelPosition]="labelPosition"
    [disabled]="disabled"
    (ngModelChange)="onCheckChange($event)"
  >
    <span>{{ label }}</span>
  </mat-checkbox>
</div>
