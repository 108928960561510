import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../http/centrala';
import { HttpHandlerService } from '../shared/http-handler.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LoginService {
  private loginUrl: string = Centrala.URL + '/api/authenticate';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  /**
   * Sign in user
   * @param username {string}
   * @param password {string}
   */
  singIn(
    username: string,
    password: string
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post(
        this.loginUrl,
        {
          username: username,
          password: password,
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
