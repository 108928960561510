import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { IntervalType } from '../../core/enums/interval-type.enum';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Store } from './models/store.model';
import { catchError } from 'rxjs/operators';
import { ContioIntervalResponse } from '../contio-store/models/contio-interval-response.model';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';

@Injectable()
export class StoreService {
  private storeUrl: string = Centrala.URL + '/api/store';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService) {
  }

  get(storeId: number): Observable<Store> {
    const params = new HttpParams()
      .set('id', storeId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));
    return this.httpClient
      .get<Store>(
        this.storeUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getList(companyId: number): Observable<Store[]> {
    const params = new HttpParams()
      .set('companyId', companyId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<Store[]>(
        this.storeUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  post(
    name: string,
    companyId: string
  ): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .post<CreateRecordResponse>(
        this.storeUrl,
        {
          CompanyId: companyId,
          Name: name,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  put(
    id: number,
    companyId: number,
    name: string,
    interval?: number,
    type?: IntervalType
  ): Observable<ContioIntervalResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<ContioIntervalResponse>(
        this.storeUrl,
        {
          Id: id,
          CompanyId: companyId,
          Name: name,
          DoorInterval: interval,
          Type: type,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }

  delete(
    companyId: number,
    id: number
  ): Observable<CreateRecordResponse> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        'CompanyId': companyId,
        'Id': id,
        'token': localStorage.getItem(LocalStorageTags.Token),
      },
    };

    return this.httpClient
      .delete<CreateRecordResponse>(
        this.storeUrl,
        options
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
