export class StorageCard {
    Id?: number;
    CardNumber: number;
    Name: string = null;
    Barcode: string = null;
    StorageCardUnitsId: number;
    StorageCardUnitName?: string;
    MinState: number;
    Packaging: string = null;
    MinDurability: string = null;
    StorageCardCategoryId: number;
    StorageCardCategoryName?: string;
    WarehouseId: number;
    Note: string = null;
    LastUpdate?: Date;
    IsIngredient?: boolean;
}
