import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    transform(value: any): string {
        if (value == null || value == undefined) {
            return;
        }

        if (value.day && value.month && value.year) {
            return value.day + '. ' + value.month + '. ' + value.year;
        }

        var date = value;
        return date;
    }
}
