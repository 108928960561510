import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SkuService } from '../../../core/services/sku/sku.service';
import { ValidatorService } from '../../../core/services/validator/validator.service';
import { IsdocService } from '../../../core/services/isdoc/isdoc.service';
import { StorageCard } from '../../../core/interfaces/stock/storage-card.model';
import { IsdocStorageCardDetail } from '../../../core/interfaces/stock/isdoc-storage-card-detail.model';
import { StorageCardUnitService } from '../../../core/services/storage-card-unit/storage-card-unit.service';
import { StorageCardCategoryService } from '../../../core/services/storage-card-category/storage-card-category.service';
import { Warehouse } from '../../company-settings/models/warehouse.model';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { StoreCardCategory } from '../../../core/interfaces/store-card-category/store-card-category.model';
import { StockGetNoteRecord } from '../../../core/interfaces/stock/stock-get-note-record.model';
import { StorageCardUnit } from '../../../core/interfaces/storage-card-unit/storage-card-unit.model';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { SkuPostResponse } from '../../sku/models/sku-post-response.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-stock-note-base',
  templateUrl: './stock-note-base.component.html',
  styleUrls: ['./stock-note-base.component.scss'],
})
export class StockNoteBaseComponent implements OnInit {
  isCardDetailSelect: boolean = false;
  isExistingCardSelected: boolean;
  createNewStorageCard: boolean = false;
  isFormSubmited: boolean = false;

  readonly keyword: string = 'Name';

  sku: StorageCard = new StorageCard();
  affectedIdDetail: number;

  isDataTableLoaded: boolean = false;

  recordPostResponse: SkuPostResponse;

  error: string;

  isMissingCardsMessage: boolean = false;
  isMissingCategory: boolean = false;
  isMissingUnit: boolean = false;
  isCardSelected: boolean = false;

  selectedUnit: StorageCardUnit;
  unitList: StorageCardUnit[];

  selectedCategory: StoreCardCategory;
  categoryList: StoreCardCategory[];

  selectedWarehouse: Warehouse;
  warehouseList: Warehouse[];

  cardNumber: number;
  cardName: string;

  entityList: StockGetNoteRecord[] = [];
  entityListForImport: StockGetNoteRecord[] = [];
  invoiceDate: Date;
  invoiceNumber: number;

  selectedStorageCard: StorageCard;
  selectedRecordForImport: StorageCard;
  storageCardList: StorageCard[];

  missingCards: number[] = [];
  checkedCards: number[] = [];

  file: any;
  isDocRows: any;
  invoiceDetailData: any;
  detailDataChange: IsdocStorageCardDetail = new IsdocStorageCardDetail();
  invoiceDateToString: string;
  invoiceSupplier: string;
  invoiceNo: string;
  invoiceItemCatalogueId: number;
  invoiceItemName: string;
  affectedRows: number[] = [];
  checkedRows: number[] = [];

  storageCardForm: FormGroup;

  @ViewChild('invoice') content: any;
  @ViewChild('invoiceRowDetail') detail: any;

  constructor(
    private modalService: NgbModal,
    private skuService: SkuService,
    public validatorService: ValidatorService,
    private isdocService: IsdocService,
    private storageCardUnitService: StorageCardUnitService,
    private storageCardCategoryService: StorageCardCategoryService,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.storageCardForm = this.formBuilder.group({
      storageCardNumber: ['', Validators.required],
      storageCardName: ['', Validators.required],
    });
  }

  get validateStorageCardForm() {
    return this.storageCardForm.controls;
  }

  onSelectedWarehouseChange(warehouse: Warehouse): void {
    this.selectedWarehouse = warehouse;

    this.setUnits();
    this.setCategories();
  }

  onStorageCardListChange(storageCardList: StorageCard[]): void {
    this.storageCardList = storageCardList;
  }

  openDetail(id: number): void {
    this.invoiceDetailData = this.isDocRows[id];
    this.modalService.open(this.detail, {
      windowClass: 'invoice-detail-modal',
      backdrop: 'static',
      keyboard: false,
      size: 'sm',
    });
  }

  onChangeImportStorageCardSearch(storageCard: StorageCard): void {
    this.selectedRecordForImport = storageCard;
  }

  onInputCleared(): void {
    this.isExistingCardSelected = false;
  }

  onChangeFileInput(file: Event): void {
    const input = file.target as HTMLInputElement;

    if (!input.files?.length) {
      return;
    }

    this.file = input.files[0];
    this.readImportedFile();
  }

  onPostStorageCardClick(
    cardNumber: number,
    cardName: string
  ): void {
    this.isFormSubmited = true;

    if (this.storageCardForm.invalid) {
      return;
    }

    if (!this.selectedCategory) {
      this.isMissingCategory = true;

      return;
    }

    if (!this.selectedUnit) {
      this.isMissingUnit = true;

      return;
    }

    const sku: StorageCard = {
      Id: -1,
      CardNumber: cardNumber,
      Name: cardName,
      Barcode: this.sku.Barcode,
      StorageCardUnitsId: this.selectedUnit.Id,
      MinState: this.sku.MinState,
      Packaging: this.sku.Packaging,
      MinDurability: this.sku.MinDurability,
      StorageCardCategoryId: this.selectedCategory.Id,
      WarehouseId: this.selectedWarehouse.Id,
      Note: this.sku.Note,
    };

    this.skuService
      .post(sku)
      .subscribe({
        next: (successResponse) => {
          this.recordPostResponse = successResponse;

          if (successResponse.success) {
            this.isDocRows[this.affectedIdDetail].name = cardName;
            this.isDocRows[this.affectedIdDetail].catalogueId = cardNumber;
            this.isDocRows[this.affectedIdDetail].unit = this.selectedUnit.Name;

            this.prepareToImportRows();
            this.onClearStorageCardDataClick();
            this.snackBarService.show('SKU.ADDINCOMECARDSUCESS', successResponse.success);

            this.clear();
          }
        },
        error: (error) => {
          this.snackBarService.show('SKU.ADDINCOMECARDFAIL', false);
        },
      });
  }

  onClearStorageCardDataClick(): void {
    this.selectedStorageCard = null;
    this.invoiceItemCatalogueId = null;
    this.invoiceItemName = '';
    this.checkedRows = [];
    this.affectedRows = [];
    this.checkedCards = [];
    this.missingCards = [];
    this.detailDataChange = new IsdocStorageCardDetail();
    this.isExistingCardSelected = false;
    this.isCardDetailSelect = false;
  }

  onImportInvoiceClick(): void {
    if (this.missingCards.length > 0) {
      this.isMissingCardsMessage = true;
      return;
    }

    this.isDocRows.forEach(isdocRow => {
      this.storageCardList.forEach(storageCard => {
        if (isdocRow.catalogueId === storageCard.CardNumber) {
          const record = {
            StorageCardId: storageCard.Id,
            Name: isdocRow.name,
            Quantity: isdocRow.quantity,
            UnitPrice: isdocRow.unitPrice,
            Total:
              parseFloat(isdocRow.quantity) *
              parseFloat(isdocRow.unitPrice),
          };

          this.entityList.splice(0, 0, record);
          this.entityList = [...this.entityList];
        }
      });
    });

    this.invoiceNumber = this.isDocRows[0].invoiceNo;
    this.invoiceDate = this.isDocRows[0].date;
  }

  onTableRowDetailClick(id: number): void {
    this.affectedRows.forEach(affectedRow => {
      if (id !== affectedRow) {
        this.isCardDetailSelect = false;
        this.createNewStorageCard = false;
        this.affectedIdDetail = id;

        this.openDetail(id);
      }
    });
  }

  onCreateOrAddToExistingClick(isCreateStorageCard: boolean): void {
    if (isCreateStorageCard) {
      this.isCardDetailSelect = false;
      this.createNewStorageCard = true;

      this.onCloseClick();

      this.cardName = this.isDocRows[this.affectedIdDetail].name;
      this.cardNumber = this.isDocRows[this.affectedIdDetail].catalogueId;

      this.validatorReset();

      return;
    }

    this.createNewStorageCard = false;
    this.isCardDetailSelect = true;

    this.onCloseClick();
    this.validatorReset();
  }

  onCloseClick(): void {
    this.selectedRecordForImport = null;
    this.invoiceItemCatalogueId = null;
    this.invoiceItemName = '';
    this.cardName = '';
    this.cardNumber = null;
    this.isExistingCardSelected = false;
    this.detailDataChange = new IsdocStorageCardDetail();
  }

  onStorageCardSelected(storageCard: StorageCard): void {
    this.isCardSelected = false;
    this.isExistingCardSelected = true;
    this.detailDataChange.name = storageCard.Name;
    this.detailDataChange.catalogueId = storageCard.CardNumber;
    this.detailDataChange.unit = storageCard.StorageCardUnitName;
    this.detailDataChange.quantity = this.invoiceDetailData.quantity;
    this.detailDataChange.unitPrice = this.invoiceDetailData.unitPrice;
  }

  onApllyDetailChangeClick(): void {
    if (!this.selectedRecordForImport) {
      this.isCardSelected = true;
      return;
    } else {
      this.isCardSelected = false;
    }

    this.isDocRows[this.affectedIdDetail].name = this.detailDataChange.name;
    this.isDocRows[
      this.affectedIdDetail
    ].catalogueId = this.detailDataChange.catalogueId;
    this.isDocRows[this.affectedIdDetail].unit = this.detailDataChange.unit;

    this.onClearStorageCardDataClick();
    this.prepareToImportRows();
  }

  private openDocImportModal(): void {
    this.modalService.open(this.content, {
      windowClass: 'invoice-modal',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });
  }

  private readImportedFile(): void {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      this.isDocRows = this.isdocService.parseXML(fileReader.result);
      this.isdocReader(this.isDocRows.__zone_symbol__value);
    };

    fileReader.readAsText(this.file);
  }

  private isdocReader(data: any): void {
    this.isDocRows = data;
    this.invoiceDateToString = new Date(this.isDocRows[0].date).toLocaleDateString();
    this.invoiceSupplier = this.isDocRows[0].supplier;
    this.invoiceNo = this.isDocRows[0].invoiceNo;

    this.openDocImportModal();
    this.prepareToImportRows();
  }

  private prepareToImportRows(): void {
    this.isDocRows.forEach(isdocRow => {
      this.invoiceItemCatalogueId = Number(isdocRow.catalogueId);
      this.invoiceItemName = isdocRow.name;

      this.checkIfCardExist(this.invoiceItemName, this.invoiceItemCatalogueId);
    });
  }

  private checkIfCardExist(cardName: string, cardId: number): void {
    let skuMatch: StorageCard[];

    this.skuService
      .get(
        cardName,
        GridSetting.firstPage,
        GridSetting.listLimit,
        this.selectedWarehouse.Id
      )
      .subscribe({
        next: (skuMatchResponse) => {
          skuMatch = skuMatchResponse;

          if (skuMatch.length === 1) {
            this.checkedCards.push(Number(skuMatch[0].CardNumber));
          } else if (skuMatch.length === 0 || skuMatch.length > 1) {
            this.missingCards.push(cardId);
          }

          if (
            this.checkedCards.length + this.missingCards.length ===
            this.isDocRows.length
          ) {
            this.checkAffected();
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private checkAffected(): void {
    if (this.missingCards.length > 0) {
      this.isMissingCardsMessage = true;
    } else if (this.missingCards.length === 0) {
      this.isMissingCardsMessage = false;
    }

    this.isDocRows.forEach((docRows, index: number) => {
      this.missingCards.forEach(missingCard => {
        if (missingCard === Number(docRows.catalogueId)) {
          if (!this.affectedRows.includes(index)) {
            this.affectedRows.push(index);
          }
        }
      });

      this.checkedCards.forEach(checkedCard => {
        if (checkedCard === Number(docRows.catalogueId)) {
          if (!this.checkedRows.includes(index)) {
            this.checkedRows.push(index);
          }
        }
      });
    });
  }

  private validatorReset(): void {
    this.isCardSelected = false;
    this.isMissingUnit = false;
    this.isMissingCategory = false;
  }

  private setUnit(): void {
    if (this.unitList.length === 0) {
      return;
    }

    if (!this.selectedUnit) {
      this.selectedUnit = this.unitList[0];
    }
  }

  private setUnits(): void {
    this.storageCardUnitService
      .get(
        this.selectedWarehouse.Id,
      )
      .subscribe({
        next: (unitList) => {
          this.unitList = unitList;

          this.setUnit();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setCategories(): void {
    this.storageCardCategoryService
      .get(this.selectedWarehouse.Id)
      .subscribe({
        next: (categoryList) => {
          this.categoryList = categoryList;

          this.setCategory();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setCategory(): void {
    if (this.categoryList.length === 0) {
      return;
    }

    if (!this.selectedCategory) {
      this.selectedCategory = this.categoryList[0];
    }
  }

  private clear(): void {
    this.selectedRecordForImport = null;
    this.isFormSubmited = false;
  }
}
