import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CheckboxLabelPosition } from '../../../../core/enums/checkbox-label-position.enum';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputCheckboxComponent {
  @Input() label!: string;
  @Input() disabled!: boolean;
  @Input() indeterminate: boolean = false;
  @Input() checked!: boolean;
  @Input() labelPosition: CheckboxLabelPosition = CheckboxLabelPosition.AFTER;

  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onCheckChange(event: boolean): void {
    this.checkedChange.emit(event);
  }
}
