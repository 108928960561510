import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Headers, RequestOptions } from '@angular/http';
import { Centrala } from '../../http/centrala';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { HttpHandlerService } from '../shared/http-handler.service';
import { SalePageCount } from '../../interfaces/sale/sale-page-count.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ReceiptItem } from '../../interfaces/sale/sale-receipt-item.model';
import { Receipt } from '../../interfaces/sale/sale-receipt.model';
import { PostResponse } from '../../interfaces/post-response.model';

@Injectable()
export class SaleService {
  private salesUrl: string = Centrala.URL + '/api/receipt';
  private salesCashregUrl: string = Centrala.URL + '/api/receipts';
  private salesItemsUrl: string = Centrala.URL + '/api/receiptitems';
  private salesPaginationUrl: string = Centrala.URL + '/api/receipt/pagination';
  private salesFiltersUrl: string = Centrala.URL + '/api/receiptfilter';
  private productReceiptsUrl: string = Centrala.URL + '/api/productReceipts';

  constructor(
    private http: Http,
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  /**
   * Get receipts for current company
   * @param currentPage {number}
   * @param pageCount {number}
   */
  getReceipts(
    currencyId: number,
    currentPage: number,
    pageCount: number
  ): Observable<any[]> {
    return this.http
      .get(
        this.salesUrl +
        '?token=' +
        localStorage.getItem(LocalStorageTags.Token) +
        '&companyId=' +
        localStorage.getItem(LocalStorageTags.CompanyId) +
        '&currencyId=' +
        currencyId +
        '&current_page=' +
        currentPage +
        '&page_count=' +
        pageCount
      )
      .map(this.httpHandlerService.extractData)
      .catch(this.httpHandlerService.handleError);
  }

  /**
   *
   * @param currentPage {number}
   * @param pageCount {number}
   * @param filters {any}
   */
  filterReceipts(
    currencyId: number,
    currentPage: number,
    pageCount: number,
    filters: any
  ): Observable<any[]> {
    const headers = new Headers({ 'Content-Type': 'application/json' });
    const options = new RequestOptions({ headers: headers });

    return this.http
      .post(
        this.salesFiltersUrl +
        '?token=' +
        localStorage.getItem(LocalStorageTags.Token) +
        '&companyId=' +
        localStorage.getItem(LocalStorageTags.CompanyId) +
        '&currencyId=' +
        currencyId +
        '&current_page=' +
        currentPage +
        '&page_count=' +
        pageCount,
        {
          filters: filters,
        },
        options
      )
      .map(this.httpHandlerService.extractData)
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Get receipt pagination for current company
   */
  getPageCount(
    currencyId: number,
    dateFrom: number,
    dateTo: number
  ): Observable<SalePageCount> {
    const params = new HttpParams()
      .set('currencyId', currencyId.toString())
      .set('dateFrom', dateFrom.toString())
      .set('dateTo', dateTo.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('companyId', localStorage.getItem(LocalStorageTags.CompanyId));

    return this.httpClient
      .get<SalePageCount>(
        this.salesPaginationUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  /**
   * Get receipt information
   * @param receiptId {number}
   */
  getReceipt(receiptId: number): Observable<Receipt[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('receiptId', receiptId.toString())
      .set('companyId', localStorage.getItem(LocalStorageTags.CompanyId));

    return this.httpClient
      .get<Receipt[]>(
        this.salesUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  deleteReceipts(companyId: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        CompanyId: companyId,
        token: localStorage.getItem(LocalStorageTags.Token),
      },
    };

    return this.httpClient
      .delete(this.salesUrl, options)
      .catch(this.httpHandlerService.handleError);
  }

  /**
   * Get receipt items
   * @param receiptId {number}
   */
  getReceiptItems(receiptId: number): Observable<ReceiptItem[]> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('receiptId', receiptId.toString())
      .set('companyId', localStorage.getItem(LocalStorageTags.CompanyId));

    return this.httpClient
      .get<ReceiptItem[]>(
        this.salesItemsUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getReceiptsByCashRegisterId(cashRegisterId: number): Observable<PostResponse> {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token))
      .set('cashregisterId', cashRegisterId.toString());

    return this.httpClient
      .get<PostResponse>(
        this.salesCashregUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getProductReceipts(productId: number): Observable<any[]> {
    return this.http
      .get(
        this.productReceiptsUrl +
        '?token=' +
        localStorage.getItem(LocalStorageTags.Token) +
        '&productId=' +
        productId
      )
      .map(this.httpHandlerService.extractData)
      .catch(this.httpHandlerService.handleError);
  }
}
