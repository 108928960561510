<authentication-test></authentication-test>

<div (window:resize)="onResize($event)">
  <navigation selectedPage="dashboard">
    <div
      class="container-fluid mb-2 h-auto justify-content-center data-container"
    >
      <div class="row h-auto w-100">
        <div class="col-xl-4 col-lg-12 col-md-12 pl-3">
          <div
            class="btn-group mb-2 w-25 float-left"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              class="btn btn-secondary date-btn ml-2"
              [class.active]="isButtonActive(customChartType.Montly)"
              (click)="onSetActiveClick(customChartType.Montly); onSetDataClick(customChartType.Montly)"
            >
              {{ 'DASHBOARD.YEAR' | translate }}
            </button>
            <button
              type="button"
              class="btn btn-secondary date-btn"
              [class.active]="isButtonActive(customChartType.Weekly)"
              (click)="onSetActiveClick(customChartType.Weekly); onSetDataClick(customChartType.Daily)"
            >
              {{ 'DASHBOARD.MONTH' | translate }}
            </button>
            <button
              type="button"
              class="btn btn-secondary date-btn"
              [class.active]="isButtonActive(customChartType.Daily)"
              (click)="onSetActiveClick(customChartType.Daily); onSetDataClick(customChartType.Daily)"
            >
              {{ 'DASHBOARD.WEEK' | translate }}
            </button>
            <button
              type="button"
              class="btn btn-secondary date-btn"
              [class.active]="isButtonActive(customChartType.Hourly)"
              (click)="onSetActiveClick(customChartType.Hourly); onSetDataClick(customChartType.Hourly)"
            >
              {{ 'DASHBOARD.DAY' | translate }}
            </button>
          </div>
        </div>

        <div class="col-xl-8 col-lg-12 col-md-12 pl-sm-3 pl-3">
          <div class="input-group-prepend float-xl-right ml-2 float-left">
            <div class="col-md-2 mr-5">
              <app-dropdown [(selected)]="selectedCurrency" (selectedChange)="onCurrencyChange(selectedCurrency)"
              *ngIf="selectedCurrency" label="{{ 'CASHRECORD.CURRENCY' | translate }}" [items]="currencies" itemKey="Name">
            </app-dropdown>
            </div>
            <div class="row m-0">
              <div class="col-md-5 col-12 p-0 ml-4">
                <div class="input-group date-input">
                  <div class="input-group-prepend">
                    <span class="input-group-text" for="from">{{
                      'SALES.DATEFROM' | translate
                    }}</span>
                  </div>
                  <input
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [placement]="placement"
                    readonly="false"
                    id="from"
                    class="form-control"
                    name="dp"
                    value="{{ dateFrom | dateFormat }}"
                    [(ngModel)]="dateFrom"
                    ngbDatepicker
                    #d1="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d1.toggle()"
                      type="button"
                    >
                      <i class="far fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-auto col-md-5 mt-md-0 mt-2 p-0">
                <div class="input-group ml-md-2 ml-0 date-input">
                  <div class="input-group-prepend">
                    <span class="input-group-text" for="to">{{
                      'SALES.DATETO' | translate
                    }}</span>
                  </div>
                  <input
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [placement]="placement"
                    readonly="true"
                    id="to"
                    class="form-control"
                    name="dp"
                    value="{{ dateTo | dateFormat }}"
                    [(ngModel)]="dateTo"
                    ngbDatepicker
                    #d2="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="d2.toggle()"
                      type="button"
                    >
                      <i class="far fa-calendar-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            <div class="col-auto col-md-1 mt-md-0 mt-2">
            <button
              type="button"
              class="btn btn-secondary search-btn"
              [class.active]="isButtonActive(customChartType.Search)"
              (click)="getDateFilter(dateFrom, dateTo); onSetActiveClick(customChartType.Search)"
            >
              <i class="fas fa-search"></i>
            </button>
          </div>
          </div>
        </div>
      </div>
      </div>
    </div>

    <div class="container-fluid mb-2 w-75 h-50">
      <ngx-charts-line-chart
        [scheme]="lineScheme"
        [results]="graphData"
        [gradient]="isGradient"
        [xAxis]="isXAxisVisible"
        [yAxis]="isYAxisVisible"
        [legend]="isLegendVisible"
        [autoScale]="isAutoscale"
        [showXAxisLabel]="isXAxisLabelVisible"
        [showYAxisLabel]="isYAxisLabelVisible"
      >
      </ngx-charts-line-chart>
    </div>

    <div class="row">
      <div class="col-1"></div>
      <div class="col-10 mt-4">
        <h4 class="mr-2 text-right summary">
          {{ 'DASHBOARD.SUMMARY' | translate }}
          {{ graphSum | currency: 'CZK':''}}
        </h4>
      </div>
      <div class="col-1"></div>
    </div>
  </navigation>
</div>
