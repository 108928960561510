import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { DashboardService } from './modules/dashboard/dashboard.service';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LoginService } from './core/services/login/login.service';
import { LoginComponent } from './modules/login/login.component';
import { AppRoutingModule } from './modules/app-routing.module';
import { CompanySettingsCashRegisterComponent } from './modules/company-settings/company-settings-cash-register/company-settings-cash-register.component';
import { CashRegisterService } from './modules/company-settings/cash-register.service';
import { ProductsComponent } from './modules/products/products.component';
import { SalesComponent } from './modules/sales/sales.component';
import { SaleDetailComponent } from './modules/sales-detail/sale-detail.component';
import { SaleService } from './core/services/sale/sale.service';
import { NavigationComponent } from './modules/navigation/navigation.component';
import { UserSettingsComponent } from './modules/user-settings/user-settings.component';
import { PasswordService } from './core/services/password/password.service';
import { RegistrationService } from './core/services/registration/registration.service';
import { RegistrationComponent } from './modules/registration/registration.component';
import { ActivationComponent } from './modules/activation/activation.component';
import { CompanySettingsComponent } from './modules/company-settings/company-settings/company-settings.component';
import { StoreService } from './modules/company-settings/store.service';
import { ProductService } from './core/services/product/product.service';
import { AuthenticationTestComponent } from './shared/components/authentication-test/authentication-test.component';
import { AuthenticationTestService } from './core/authentication-test/authentication-test.service';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MomentModule } from 'angular2-moment';
import { CurrencyFormatPipe } from './shared/pipes/currency.pipe';
import { NumberFormatPipe } from './shared/pipes/number-formater.pipe';
import { DateFormatPipe } from './shared/pipes/date-formater.pipe';
import { ToMatrixPipe } from './shared/pipes/matrix.pipe';
import { TablesComponent } from './modules/tables/tables.component';
import { StockListComponent } from './modules/stock/stock-list/stock-list.component';
import { ContactListComponent } from './modules/contacts/contact-list.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SkuService } from './core/services/sku/sku.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CustomDateParserFormatter } from './core/services/formatter/ngb-datepicker-formatter.service';
import { AdminComponent } from './modules/admin-panel/admin.component';
import { QRCodeModule } from 'angularx-qrcode';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { UserListComponent } from './modules/users/user-list/user-list.component';
import { UserDetailComponent } from './modules/users/user-detail/user-detail-tabs/user-detail/user-detail.component';
import { ContioStoreListComponent } from './modules/contio-store/contio-store-list/contio-store-list.component';
import { DevicesComponent } from './modules/devices/devices.component';
import { CameraListComponent } from './modules/cameras/camera-list.component';
import { TelegramComponent } from './modules/telegram/telegram.component';
import { LoyaltyCardListComponent } from './modules/loyalty-cards/loyalty-card-list/loyalty-card-list.component';
import { LoyaltyCardDetailComponent } from './modules/loyalty-cards/loyalty-card-detail/loyalty-card-detail.component';
import { ReturnListComponent } from './modules/returns/return-list/return-list.component';
import { ReturnDetailComponent } from './modules/returns/return-detail/return-detail.component';
import { IngredientService } from './core/services/ingredient/ingredient.service';
import { HttpModule } from '@angular/http';
import { OrdersComponent } from './modules/orders/orders.component';
import { AutocompleteComponent } from './shared/core-components/autocomplete/autocomplete.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { SunmiShopComponent } from './modules/settings/settings-tabs/sunmi-shop/sunmi-shop.component';
import { ImageUploadComponent } from './modules/image-upload/image-upload.component';
import { DragAndDropDirective } from './modules/image-upload/directive/drag-and-drop.directive';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MaterialModule } from './core/material/material.module';
import { CategoriesComponent } from './modules/categories/categories-tabs/categories/categories.component';
import { CategoriesTabsComponent } from './modules/categories/categories-tabs.component';
import { TableComponent } from './shared/core-components/table/table.component';
import { DropdownComponent } from './shared/core-components/dropdown/dropdown.component';
import { FileSizePipe } from './shared/pipes/file-size.pipe';
import { InputDialogComponent } from './shared/dialogs/input-dialog/input-dialog.component';
import { GenderPipe } from './shared/pipes/gender.pipe';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ContioStoreDetailComponent } from './modules/contio-store/contio-store-detail/contio-store-detail.component';
import { ContioStoreSettingsComponent } from './modules/contio-store/contio-store-settings/contio-store-settings.component';
import { CompanySettingsStoreComponent } from './modules/company-settings/company-settings-store/company-settings-store.component';
import { CompanySettingsWarehouseComponent } from './modules/company-settings/company-settings-warehouse/company-settings-warehouse.component';
import { SkuCategoryDropdownComponent } from './modules/sku/sku-category-dropdown/sku-category-dropdown.component';
import { SkuListComponent } from './modules/sku/sku-list/sku-list.component';
import { SkuUnitDropdownComponent } from './modules/sku/sku-unit-dropdown/sku-unit-dropdown.component';
import { SkuImportComponent } from './modules/sku/sku-import/sku-import.component';
import { SkuRecordComponent } from './modules/sku/sku-record/sku-record.component';
import { DeleteDialogComponent } from './shared/dialogs/delete-dialog/delete-dialog/delete-dialog.component';
import { YesNoPipe } from './shared/pipes/yes-no-pipe/yes-no.pipe';
import { InputTextComponent } from './shared/core-components/input/input-text/input-text.component';
import { InputColorPickerComponent } from './shared/core-components/input/input-color-picker/input-color-picker.component';
import { InputDateComponent } from './shared/core-components/input/input-date/input-date.component';
import { InputCheckboxComponent } from './shared/core-components/input/checkbox/checkbox.component';
import { DatePipe } from '@angular/common';
import { StockNoteInternalComponent } from './modules/stock/stock-note-internal/stock-note-internal.component';
import { StockNoteComponent } from './modules/stock/stock-note/stock-note.component';
import { StockNoteBaseComponent } from './modules/stock/stock-note-base/stock-note-base.component';
import { TwilioListComponent } from './modules/twilio/twilio-list.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { SubaccountListComponent } from './modules/subaccounts/subaccount-list/subaccount-list.component';
import { SubaccountDetailComponent } from './modules/subaccounts/subaccount-detail/subaccount-detail.component';
import { SubaccountAccessPipe } from './modules/subaccounts/subaccount-access.pipe';
import { DATE_FORMATS } from './shared/core-components/input/input-date/date-input-formats.const';
import { ContioStoreActivityListComponent } from './modules/contio-store/contio-store-activity-list/contio-store-activity-list.component';
import { InputSelectComponent } from './shared/core-components/input/input-select/input-select.component';
import { ActivityTypePipe } from './shared/pipes/activity-type.pipe';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { InputDatetimeComponent } from './shared/core-components/input/input-datetime/input-datetime.component';
import { CashRecordComponent } from './modules/cash-record/cash-record.component';
import { DayPipe } from './shared/pipes/day.pipe';
import { ContioStoreScheduleComponent } from './modules/contio-store/contio-store-schedule/contio-store-schedule.component';
import { ContioStoreAnalyticsComponent } from './modules/contio-store/contio-store-analytics/contio-store-analytics.component';
import { UserDetailTabsComponent } from './modules/users/user-detail/user-detail-tabs.component';
import { UserActivitiesComponent } from './modules/users/user-detail/user-detail-tabs/user-activities/user-activities.component';
import { AnalyticsTypePipe } from './shared/pipes/analytics-type.pipe';

const httpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, './lang/', '.json');

@NgModule({
  imports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxDatatableModule,
    MomentModule,
    AutocompleteLibModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    NgxDatatableModule,
    QRCodeModule,
    Ng2SearchPipeModule,
    NgxCsvParserModule,
    ImageCropperModule,
    MaterialModule,
    MatSnackBarModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
  ],
  declarations: [
    // Pipes
    YesNoPipe,
    ActivityTypePipe,
    AnalyticsTypePipe,
    CurrencyFormatPipe,
    NumberFormatPipe,
    DateFormatPipe,
    ToMatrixPipe,
    FileSizePipe,
    GenderPipe,
    DayPipe,
    SubaccountAccessPipe,

    AppComponent,
    DashboardComponent,
    LoginComponent,
    CompanySettingsCashRegisterComponent,
    ProductsComponent,
    SalesComponent,
    NavigationComponent,
    UserSettingsComponent,
    RegistrationComponent,
    ActivationComponent,
    CompanySettingsComponent,
    AuthenticationTestComponent,
    SaleDetailComponent,
    TablesComponent,
    StockListComponent,
    StockNoteBaseComponent,
    ContactListComponent,
    StockNoteInternalComponent,
    StockNoteComponent,
    SkuListComponent,
    AdminComponent,
    UserListComponent,
    ContioStoreDetailComponent,
    UserDetailComponent,
    UserActivitiesComponent,
    UserDetailTabsComponent,
    ContioStoreListComponent,
    ContioStoreActivityListComponent,
    ContioStoreScheduleComponent,
    ContioStoreAnalyticsComponent,
    DevicesComponent,
    CameraListComponent,
    TelegramComponent,
    TwilioListComponent,
    LoyaltyCardListComponent,
    LoyaltyCardDetailComponent,
    ReturnListComponent,
    ReturnDetailComponent,
    OrdersComponent,
    AutocompleteComponent,
    SettingsComponent,
    ContioStoreSettingsComponent,
    SunmiShopComponent,
    ImageUploadComponent,
    DragAndDropDirective,
    InputTextComponent,
    CategoriesComponent,
    CategoriesTabsComponent,
    TableComponent,
    DropdownComponent,
    InputCheckboxComponent,
    InputDialogComponent,
    CompanySettingsStoreComponent,
    SkuCategoryDropdownComponent,
    CompanySettingsWarehouseComponent,
    SkuUnitDropdownComponent,
    SkuImportComponent,
    SkuRecordComponent,
    DeleteDialogComponent,
    InputColorPickerComponent,
    InputDateComponent,
    InputSelectComponent,
    InputDatetimeComponent,
    SubaccountListComponent,
    SubaccountDetailComponent,
    CashRecordComponent
  ],
  providers: [
    // pipes
    YesNoPipe,
    DatePipe,
    ActivityTypePipe,
    AnalyticsTypePipe,
    DayPipe,

    // services
    DashboardService,
    LoginService,
    PasswordService,
    RegistrationService,
    StoreService,
    CashRegisterService,
    AuthenticationTestService,
    ProductService,
    SaleService,
    SkuService,
    IngredientService,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: MAT_DATE_LOCALE, useValue: DATE_FORMATS },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
