<authentication-test></authentication-test>
  <navigation selectedPage="tables">
    <div class="row no-gutters w-100">
      <div class="col-4">
        <button
          type="text"
          class="btn btn-primary new-btn"
          id="addTableInput"
          (click)="onPrepareNewTableClick()"
        >
          <i class="fas fa-plus mr-1"></i>
          {{ 'TABLE.NEWTABLE' | translate }}
        </button>
      </div>
      <div class="col-8">
        <div class="row no-gutters justify-content-end">
          <div
            class="input-group-prepend float-right"
            *ngIf="isInputGroupHidden"
          >
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary dropdown-tag bigger-btn"
                [ngClass]="{
                  'dropdown-invalid': isSelectedStoreValidate && !selectedStore
                }"
              >
                <span class="font-weight-normal">
                  {{ 'STORES.STORE' | translate }}
                </span>
              </button>
              <div
                class="btn-group"
                *ngIf="isStoreLoaded && selectedStore"
                ngbDropdown
                role="group"
              >
                <button
                  class="btn btn-primary dropdown-toggle-primary dropdown-box"
                  id="dropdownBasic1"
                  data-toggle="dropdown"
                  ngbDropdownToggle
                >
                  <span class="font-weight-normal">
                    {{ selectedStore.Name }}
                  </span>
                </button>
                <div
                  ngbDropdownMenu
                  class="dropdown-menu"
                  aria-labelledby="dropdownBasic1"
                >
                  <button
                    *ngFor="let store of storeList"
                    class="dropdown-item"
                    (click)="onChangeStoreClick(store.Id)"
                  >
                    {{ store.Name }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row no-gutters justify-content-end"
          *ngIf="isSelectedStoreValidate && !selectedStore"
        >
          <span
            class="help-block invalid-group w-auto text-right"
            >{{ 'VALIDATOR.STORESVALID' | translate }}
            <span class="text-undeline">
              <a routerLink="/company-settings">{{
                'VALIDATOR.ROUTER' | translate
              }}</a> </span
            >.
          </span>
        </div>
      </div>
    </div>

    <hr
      class="hr-line"
      *ngIf="isInputGroupHidden"
    />

    <div
      class="row no-gutters mt-2 w-100"
      *ngIf="isStoreLoaded && !isInputGroupHidden"
    >
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 my-col">
        <div class="input-group-prepend">
          <div class="btn-group mb-2">
            <button
              type="button"
              class="btn btn-primary dropdown-tag float-left line-height"
            >
              <span class="font-weight-normal">
                {{ 'STORES.STORE' | translate }}
              </span>
            </button>
            <div
              class="btn-group"
              *ngIf="isStoreLoaded"
              ngbDropdown
              role="group"
            >
              <button
                class="btn btn-primary dropdown-toggle-primary dropdown-box"
                id="dropdownBasic1"
                data-toggle="dropdown"
                ngbDropdownToggle
              >
                <span class="font-weight-normal">
                  {{ selectedStore.Name }}
                </span>
              </button>
              <div
                ngbDropdownMenu
                class="dropdown-menu"
                aria-labelledby="dropdownBasic1"
              >
                <button
                  *ngFor="let store of storeList"
                  class="dropdown-item"
                  (click)="onChangeStoreClick(store.Id)"
                >
                  {{ store.Name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-7 my-col mb-2">
        <div class="row no-gutters">
          <div class="float-left">
            <button
              #addZoneInput
              type="text"
              class="btn btn-primary normal-btn zone-block"
              id="addZoneInput"
              (click)="onOpenZoneAddModalClick(content)"
              [ngClass]="{
                'dropdown-invalid': isFormSubmited && !table
              }"
            >
              <i class="fas fa-plus mr-1"></i>
              {{ 'TABLE.ADDCATEGORY' | translate }}
            </button>
          </div>

          <div
            *ngIf="isZoneLoaded"
            ngbDropdown
            class="d-inline-block pl-2"
          >
            <button
              #zonesInput
              class="btn btn-outline-primary dropdown-box"
              id="groups"
              ngbDropdownToggle
            >
              {{ selectedZone.Name }}
            </button>
            <div ngbDropdownMenu class="dropdown-menu">
              <button
                *ngFor="let zone of zoneList"
                class="dropdown-item"
                (click)="onChangeZoneClick(zone)"
              >
                {{ zone.Name }}
              </button>
            </div>
            <div class="pl-2 float-right">
              <button
                #addZoneEdit
                type="text"
                class="btn btn-primary normal-btn"
                id="addZoneEdit"
                (click)="onOpenZoneEditModalClick(zoneEdit)"
              >
                {{ 'STORES.EDIT' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="row no-gutters" *ngIf="isFormSubmited && !this.table">
          <span
            class="help-block invalid-group text-right zone-block"
            >{{ 'VALIDATOR.ZONEVALID' | translate }}</span
          >
        </div>
      </div>

      <div
        class="col-xl-4 col-lg-4 col-md-4 col-sm-5 form-group mb-3"
        [formGroup]="validatorService.tableForm"
      >
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="nameInput">{{
              'TABLE.NUMBER' | translate
            }}</span>
          </div>
          <input
            [(ngModel)]="table.tableCategory.number"
            type="text"
            class="form-control normal-input"
            id="inpTableNumber"
            placeholder="0"
            required
            formControlName="tableNumber"
            class="form-control"
            [ngClass]="{
              'is-invalid': isFormSubmited && validatorService.validate.tableNumber.errors
            }"
          />
          <div
            *ngIf="isFormSubmited && validatorService.validate.tableNumber.errors"
            class="invalid-feedback text-right div-right-margin"
          >
            <div *ngIf="validatorService.validate.tableNumber.errors.required">
              {{ 'VALIDATOR.VALIDATION' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row no-gutters w-100"
      *ngIf="isStoreLoaded && !isInputGroupHidden"
    >
      <div class="col-sm-12">
        <button
          [hidden]="isEdit"
          [disabled]="isEdit"
          (click)="onTableAddClick()"
          class="btn btn-primary save-btn float-right mt-1"
          id="btnAddTable"
        >
          <i class="fas fa-save mr-1"></i>
          {{ 'PRODUCTS.SAVENEWPRODUCT' | translate }}
        </button>
        <button
          [hidden]="!isEdit"
          [disabled]="!isEdit"
          (click)="onTableEditClick()"
          class="btn btn-primary save-btn float-right mt-1"
          id="btnEditTable"
        >
          <i class="fas fa-save mr-1"></i>
          {{ 'PRODUCTS.SAVECHANGES' | translate }}
        </button>
      </div>
    </div>

    <hr
      class="hr-line"
      *ngIf="!isInputGroupHidden"
    />

    <div class="row no-gutters my-row w-100">
      <div class="col-sm my-col">
        <ngx-datatable
          #ngx1
          class="material ngx-datatable-cursor"
          [rows]="tableList"
          [headerHeight]="50"
          [footerHeight]="50"
          [columnMode]="'force'"
          [rowHeight]="'auto'"
          [selectionType]="'single'"
          [selected]="selectedRow"
          [limit]="pageLimit"
          [externalPaging]="true"
          [count]="tableCount"
          [offset]="currentPage"
          [messages]="{
            emptyMessage: 'PRODUCTS.TABLENODATA' | translate,
            totalMessage: 'PRODUCTS.TABLETOTAL' | translate
          }"
          (select)="onZoneTableSelect($event.selected[0])"
          (page)="getStoreTables($event)"
        >
        <ngx-datatable-column prop="tableCategory.name" name="{{'TABLE.CATNAME' | translate}}"></ngx-datatable-column>
        <ngx-datatable-column prop="tableCategory.number" name="{{'TABLE.CATNO' | translate}}"></ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </navigation>

  <!-- Modal -->
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'TABLE.ADDCATEGORY' | translate }}</h4>
    </div>
    <div class="modal-body">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="nameInput">{{
            'MODAL.NAME' | translate
          }}</span>
        </div>
        <input
          #zoneName
          [(ngModel)]="zoneInput"
          type="text"
          class="form-control"
          placeholder="{{ 'PRODUCTS.MODALWRITENAME' | translate }}"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="onZoneInputClearClick(); c('Close click')"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>
      <button
        type="button"
        class="btn normal-btn"
        [disabled]="!zoneInput"
        (click)="onZonePostClick(zoneInput); c('Close click')"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVE' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- Zone Edit -->

  <ng-template #zoneEdit let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ selectedZone.Name }}</h4>
    </div>
    <div class="modal-body">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="nameInput">{{
            'MODAL.NAME' | translate
          }}</span>
        </div>
        <input
          #zoneNameEdit
          [(ngModel)]="selectedZone.Name"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="c('Close click')"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>

      <button
        type="button"
        class="btn normal-btn"
        (click)="onZoneEditClick(zoneNameEdit.value); c('Close click')"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVE' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- MODAL FOR DELETE WINDOW -->
  <ng-template #modalDelete let-c="close" let-d="dismiss">
    <div class="ng-template-padding">
      <div class="modal-header text-center">
        <span class="large-font"
          >{{ 'TABLE.DIALOG' | translate }} '{{ table.tableCategory.name }}'?</span
        >
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="onOpenZoneEditModalClick(zoneEdit); c('Close click')"
        >
          {{ 'PRODUCTS.CLOSE' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="onZoneDelete(); c('Close click')"
        >
          <i class="fas fa-trash-alt mr-1"></i>
          {{ 'PRODUCTS.DELETEGROUP' | translate }}
        </button>
      </div>
    </div>
  </ng-template>