import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateFormatterService {
  constructor() {}

  setPeriod() {
    var date = new Date();
    var period: any;

    period = this.formatDate(
      new Date(date.getFullYear(), date.getMonth(), date.getDate())
    );

    return period;
  }

  formatDate(date: Date) {
    var parsedDate = date.toLocaleDateString().split('. ');

    return { day: +parsedDate[0], month: +parsedDate[1], year: +parsedDate[2] };
  }
}
