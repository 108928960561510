<authentication-test></authentication-test>
<navigation selectedPage="loyalty-cards">
  <div class="row no-gutters mt-3 w-100">
    <div class="col-6">
      <button
        type="text"
        class="btn btn-primary new-btn"
        (click)="onPrepareNewCardClick(false)"
        [hidden]="loyaltyCardPrivilege && loyaltyCardPrivilege === privilegeValue.Read"
      >
        <i class="fas fa-plus mr-1"></i>
        {{ 'PRODUCTS.ADDPRODUCT' | translate }}
      </button>
    </div>

    <div class="col-6 my-col">
      <div class="row no-gutters justify-content-end">
        <div class="input-group-prepend float-right">
          <div
            class="input-group-prepend div-right-margin"
            *ngIf="!isUserInputVisible"
          >
            <button
              type="button"
              class="btn btn-secondary ml-1 line-height"
              (click)="onFormHideClick()"
            >
              <i class="fas fa-chevron-up"></i>
            </button>
          </div>

          <div
            class="input-group-prepend div-right-margin"
            *ngIf="isUserInputVisible"
          >
            <button
              type="button"
              class="btn btn-secondary ml-1 line-height"
              [hidden]="isCardDetailDisabled"
              (click)="onFormShowClick()"
            >
              <i class="fas fa-chevron-down"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row no-gutters mt-3" *ngIf="!isUserInputVisible">
    <div class="col-12 col-md-4 w-auto">
      <div class="row no-gutt">
        <div class="col-12">
          <div class="input-group-prepend">
            <span class="input-group-text" id="spInvoiceNo">{{ 'USERS.SURNAME' | translate }}</span>

            <div class="ng-autocomplete w-100">
              <ng-autocomplete
                [data]="userList"
                [searchKeyword]="keyword"
                (selected)="onUserSelect($event)"
                (inputChanged)="onSearchChange($event)"
                (inputCleared)="onInputClear()"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
              >
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.Surname"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-12">
          <span
            class="help-block hyper-link-btn w-auto text-right"
          >
            <span class="text-underline">
              <a (click)="onOpenAddNewUserModalClick(userAdd)">{{ 'USERS.NEWUSER' | translate }}</a></span
            >
          </span>
        </div>
      </div>
    </div>

    <div class="col-4 px-3 w-auto" *ngIf="selectedUser">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> {{ 'USERS.NAME' | translate }} </span>
        </div>
        <input
          [(ngModel)]="selectedUser.Name"
          [disabled]="true"
          type="text"
          class="form-control normal-input"
          placeholder="{{ 'PRODUCTS.NAME' | translate }}"
          class="form-control"
        />
      </div>
    </div>

    <div class="col-4 w-auto" *ngIf="selectedUser">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"> {{ 'CARDS.SERIALNUMBER' | translate }} </span>
        </div>
        <input
          [(ngModel)]="selectedUser.SerialNumber"
          type="text"
          class="form-control normal-input"
          class="form-control"
        />
      </div>
    </div>
  </div>

  <div
    class="row no-gutters w-100 mt-2"
    *ngIf="!isUserInputVisible"
  >
    <div class="col-sm-12">
      <button
        [hidden]="isCardDetailDisabled"
        [disabled]="isCardDetailDisabled"
        (click)="editCard()"
        class="btn btn-primary save-btn float-right"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVECHANGES' | translate }}
      </button>
      <div class="float-right">
        <button
          [hidden]="!isCardDetailDisabled"
          [disabled]="!isCardDetailDisabled"
          (click)="postCard()"
          class="btn btn-primary save-btn float-right"
        >
          <i class="fas fa-save mr-1"></i>
          {{ 'PRODUCTS.SAVENEWPRODUCT' | translate }}
        </button>
      </div>
    </div>
  </div>

  <hr class="line">

  <div class="row no-gutters w-100">
    <div class="col-sm-12">
      <div class="input-group-prepend float-right">
        <div class="input-group mt-1">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1">
              {{ 'PRODUCTS.SEARCH' | translate }}</span
            >
          </div>
          <input
            id="search"
            type="search"
            class="form-control search-input"
            aria-label="Search"
            aria-describedby="basic-addon1"
            [(ngModel)]="loyaltyCardFilter"
          />

          <button
            type="button"
            class="btn btn-secondary search-btn ml-1"
            [class.active]="isButtonActive(1)"
            (click)="onUpdateFilterClick(loyaltyCardFilter); onSetButtonActiveClick(1)"
          >
            <i class="fas fa-search"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary search-btn ml-1"
            [class.active]="isButtonActive(2)"
            (click)="onUpdateFilterClick(''); onSetButtonActiveClick(2)"
          >
            {{ 'PRODUCTS.ALL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row no-gutters w-100">
    <div class="col-sm my-col data-box">
      <ngx-datatable
        #datatable
        class="material ngx-datatable-cursor "
        [rows]="cardList"
        [limit]="10"
        [columns]="[
          { prop: 'Name', name: 'USERS.NAME' | translate },
          { prop: 'Surname', name: 'USERS.SURNAME' | translate },
          {
            prop: 'SerialNumber',
            name: 'CARDS.SERIALNUMBER' | translate
          },
          {
            prop: 'Balance',
            name: 'CARDS.BALANCE' | translate
          }
        ]"
        [headerHeight]="50"
        [footerHeight]="50"
        [columnMode]="'force'"
        [rowHeight]="'auto'"
        [selectionType]="'single'"
        [limit]="pageLimit"
        [externalPaging]="true"
        [count]="cardCount"
        [offset]="currentPage"
        [messages]="{
          emptyMessage: 'PRODUCTS.TABLENODATA' | translate,
          totalMessage: 'PRODUCTS.TABLETOTAL' | translate
        }"
        (select)="onGoToCardDetailClick($event.selected)"
        (page)="setCardList($event)"
      >
      </ngx-datatable>
    </div>
  </div>
</navigation>

<ng-template #userAdd let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title"> {{ 'USERS.USERWITHOUTAPP' | translate }} </h4>
    <button
      type="button"
      class="close"
      (click)="c('Close click'); onSetDefaultFormClick()"
    >
      <span aria-hidden="false">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row no-gutters">
      <div class="col-6 form-group" [formGroup]="validatorService.loyaltyCardForm">
        <div class="input-group mb-2 pr-2 mt-1">
          <div class="input-group-prepend">
            <span class="input-group-text"> {{ 'USERS.NAME' | translate }}* </span>
          </div>
          <input
            type="text"
            class="form-control normal-input"
            formControlName="name"
            [(ngModel)]="newUser.name"
            [ngClass]="{
              'is-invalid': submitted && validatorService.validate.name.errors
            }"
          />
          <div
            *ngIf="isSubmitted && validatorService.validate.name.errors"
            class="invalid-feedback text-right div-right-margin"
          >
            <div *ngIf="validatorService.validate.name.errors.required">
              {{ 'VALIDATOR.VALIDATION' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-6 form-group" [formGroup]="validatorService.loyaltyCardForm">
        <div class="input-group pl-2 mt-1">
          <div class="input-group-prepend">
            <span class="input-group-text"> {{ 'USERS.SURNAME' | translate }}* </span>
          </div>
          <input
            type="text"
            class="form-control normal-input"
            formControlName="surname"
            [(ngModel)]="newUser.surname"
            [ngClass]="{
              'is-invalid': submitted && validatorService.validate.surname.errors
            }"
          />
          <div
            *ngIf="isSubmitted && validatorService.validate.surname.errors"
            class="invalid-feedback div-right-margin text-right"
          >
            <div *ngIf="validatorService.validate.surname.errors.required">
              {{ 'VALIDATOR.VALIDATION' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="input-group pr-2">
          <div class="input-group-prepend">
            <span class="input-group-text"> {{ 'USERS.PHONE' | translate }} </span>
          </div>
          <input
            type="text"
            class="form-control normal-input"
            [(ngModel)]="newUser.phone"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="input-group mb-3 pl-2">
          <div class="input-group-prepend">
            <span class="input-group-text"> {{ 'USERS.EMAIL' | translate }} </span>
          </div>
          <input
            type="text"
            class="form-control normal-input"
            [(ngModel)]="newUser.email"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="input-group mb-3 pr-2 mt-1">
          <div class="input-group-prepend">
            <span class="input-group-text"> {{ 'USERS.STREET' | translate }} </span>
          </div>
          <input
            type="text"
            class="form-control normal-input"
            [(ngModel)]="newUser.street"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="input-group mb-3 pl-2 mt-1">
          <div class="input-group-prepend">
            <span class="input-group-text"> {{ 'USERS.CITY' | translate }} </span>
          </div>
          <input
            type="text"
            class="form-control normal-input"
            [(ngModel)]="newUser.city"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="input-group mb-1 pr-2 mt-1">
          <div class="input-group-prepend">
            <span class="input-group-text"> {{ 'USERS.ZIPCODE' | translate }} </span>
          </div>
          <input
            type="text"
            class="form-control normal-input"
            [(ngModel)]="newUser.zip"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="input-group mb-1 pl-2 mt-1">
          <div class="input-group-prepend">
            <span class="input-group-text"> {{ 'USERS.COUNTRY' | translate }} </span>
          </div>
          <input
            type="text"
            class="form-control normal-input"
            [(ngModel)]="newUser.country"
          />
        </div>
      </div>

      <div class="col-6 mt-3 mb-1">
        <app-input-date label="{{ 'USERS.BIRTHDATE' | translate }}" [(input)]="newUser.birthDate"></app-input-date>
      </div>

      <div class="col-12 mt-3 mb-1">
        <app-dropdown
          [(selected)]="selectedContioStore"
          (selectedChange)="onContioStoreChange(selectedContioStore)"
          label="{{ 'TWILIO.CONTIOSHOP' | translate }}"
          itemKey="Name"
          [items]="contioStoreList"
        ></app-dropdown>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      (click)="
        postUserWithoutApp();
        newUser.name && newUser.surname && c('Close click') && prepareNewPost()
      "
      class="btn btn-primary save-btn"
    >
      <i class="fas fa-save mr-1"></i>
      {{ 'PRODUCTS.SAVENEWPRODUCT' | translate }}
    </button>
  </div>
</ng-template>
