import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CashRegisterService } from '../cash-register.service';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { Store } from '../models/store.model';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { DeleteStoreResponse } from '../models/delele-store-response.model';
import { ContioStore } from '../../contio-store/models/contio-store.model';
import { ServerResponse } from '../../../shared/models/server-response.model';
import { ValidatorService } from '../../../core/services/validator/validator.service';
import { ContioService } from '../../contio-store/contio.service';
import { StoreService } from '../store.service';
import { finalize } from 'rxjs/operators';
import { LocalStorageTags } from '../../../core/enums/local-storage-tags.enum';
import { CashRegister } from '../models/cashregister.model';
import { InputDialogComponent } from '../../../shared/dialogs/input-dialog/input-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { SubaccountValue } from '../../subaccounts/subaccount-value.enum';
import { GridSetting } from '../../../shared/models/grid-setting.model';

@Component({
  selector: 'app-company-settings-store',
  templateUrl: './company-settings-store.component.html',
  styleUrls: ['../../../../assets/scss/company-settings.scss', './company-settings-store.component.scss'],
})
export class CompanySettingsStoreComponent implements OnInit {
  @Input() companyPrivilege: string;

  privilegeValue: any = SubaccountValue;
  companyId: number;

  storeList: Store[];
  selectedStore: Store;
  isStoreLoaded: boolean;
  selectedStoreName: string;

  storeModalEdit: string;
  canShowStoreValidation: boolean = false;

  readonly defaultInput: string = '';
  updateDialog: MatDialogRef<InputDialogComponent>;

  contioStoreList: ContioStore[];
  selectedContio: ContioStore;

  cashregisterList: CashRegister[];

  storeResponse: CreateRecordResponse;
  storeDeleteResponse: DeleteStoreResponse;
  contioStorePostResponse: ServerResponse;

  error: string;

  constructor(
    private modalService: NgbModal,
    private dialog: MatDialog,
    private storeService: StoreService,
    private cashRegisterService: CashRegisterService,
    private validatorService: ValidatorService,
    private contioService: ContioService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.companyId = this.localStorageService.getCompanyId();

    this.setStores();
    this.validatorService.storeValidator();
  }

  onChangeStoreClick(storeName?: string): void {
    if (storeName) {
      this.selectedStore = this.storeList.find(store => store.Name === storeName);
    }

    this.canShowStoreValidation = false;
    this.setContioStoreList();
  }

  onStoreEditClick(storeName: string): void {
    if (!this.storeModalEdit) {
      this.canShowStoreValidation = true;
      return;
    }

    this.storeService
      .put(
        this.selectedStore.Id,
        this.selectedStore.CompanyId,
        storeName
      )
      .pipe(
        finalize(() => {
          this.storeResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.storeResponse = successResponse;

          if (successResponse.success) {
            this.selectedStoreName = storeName;
            this.setStores();

            this.snackBarService.show('STORES.STOREEDITSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('STORES.STOREEDITFAIL', false);
        },
      });
  }

  onStoreDeleteClick(): void {
    this.checkCashRegister()
      .then(
        (areCashRegistersAssignedToStore) => {
          this.deleteStore(areCashRegistersAssignedToStore);
        });
  }

  deleteStore(areCashRegistersAssignedToStore: boolean): void {
    if (areCashRegistersAssignedToStore) {
      this.snackBarService.show('VALIDATOR.ASSIGNEDCASHREG', false);
      return;
    }

    this.storeService
      .delete(
        this.selectedStore.CompanyId,
        this.selectedStore.Id
      )
      .subscribe({
        next: (successResponse) => {
          this.storeDeleteResponse = successResponse;
          if (successResponse.success) {
            this.selectedStore = null;
            this.setStores();

            this.snackBarService.show('STORES.STOREDELETESUCCESS', successResponse.success);
          } else {
            if (this.storeDeleteResponse.attachedProduct) {
              this.snackBarService.show('VALIDATOR.ATTACHEDPRODUCT', false);
            }
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('STORES.STOREDELETEFAIL', false);
        },
      });
  }

  onContioStorePostClick(contioStoreName: string): void {
    if (!this.selectedContio.Name) {
      this.canShowStoreValidation = true;
      return;
    }

    this.contioService
      .post(
        this.selectedStore.Id,
        contioStoreName,
        this.selectedContio.IsAvailable
      )
      .subscribe({
        next: (successResponse) => {
          this.contioStorePostResponse = successResponse;
          if (successResponse.success) {
            this.setContioStoreList();

            this.snackBarService.show('STORES.CONTIOSTOREPOSTSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('STORES.CONTIOSTOREPOSTFAIL', false);
        },
      });
  }

  onCloseClick(): void {
    this.canShowStoreValidation = false;
  }

  onStorePostDialogClick(): void {
    this.updateDialog = this.dialog.open(InputDialogComponent, {
      data: {
        title: this.translateService.instant('STORES.ADDSTOREMODAL'),
        value: this.defaultInput,
        isEdit: false,
        isDelete: false,
      },
    });
    this.updateDialog.afterClosed().subscribe((input) => {
      if (!input) {
        return;
      }

      this.postStore(input);
    });
  }

  onStorePostModalOpenClick(storeAdd: string): void {
    this.modalService.open(storeAdd, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }


  onStoreEditModalOpenClick(storeName: string): void {
    this.storeModalEdit = this.selectedStore.Name;
    this.modalService.open(storeName, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  onStoreDeleteModalOpenClick(storeName: string): void {
    this.modalService.open(storeName, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  onContioStoreEditOpenModalClick(contio: string): void {
    this.modalService.open(contio, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
      size: 'lg',
    });
  }

  private postStore(storeName: string): void {
    this.canShowStoreValidation = true;

    const companyId = localStorage.getItem(LocalStorageTags.CompanyId);

    this.storeService
      .post(
        storeName,
        companyId
      )
      .pipe(
        finalize(() => {
          this.storeResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.storeResponse = successResponse;
          if (successResponse.success) {
            this.canShowStoreValidation = false;
            this.selectedStoreName = storeName;

            this.setStores();
            this.snackBarService.show('STORES.STOREPOSTSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('STORES.STOREPOSTFAIL', false);
        },
      });
  }

  private setStores(canDeleteAfterLoad?: boolean): void {
    this.storeService
      .getList(this.companyId)
      .subscribe({
        next: (storeList) => {
          this.storeList = storeList;
          if (canDeleteAfterLoad) {
            this.selectedStore = null;
          }
          this.setSelectedStore();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setSelectedStore(): void {
    if (this.storeList.length === 0) {
      this.isStoreLoaded = false;
      return;
    }

    if (this.selectedStore) {
      this.onChangeStoreClick(this.selectedStoreName);
    } else {
      this.selectedStore = this.storeList[0];
    }

    this.isStoreLoaded = true;
    this.setContioStoreList();
  }

  private checkCashRegister(): Promise<boolean> {
    let areCashRegistersAssignedToStore = false;

    return new Promise((resolve) => {
      this.cashRegisterService
        .get(this.selectedStore.Id.toString())
        .subscribe({
          next: (cashregisterList) => {
            this.cashregisterList = cashregisterList;
            if (this.cashregisterList.length > 0) {
              areCashRegistersAssignedToStore = true;
            }
            resolve(areCashRegistersAssignedToStore);
          },
          error: (error) => {
            this.error = error;
          },
        });
    });
  }

  private setContioStoreList(): void {
    this.contioService
      .getList(
        GridSetting.firstPage,
        GridSetting.listLimit
      )
      .subscribe({
        next: (contioStoreList) => {
          this.contioStoreList = contioStoreList;
          this.setContioStore();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setContioStore(): void {
    this.selectedContio = this.contioStoreList.find(contioStore => contioStore.StoreId === this.selectedStore.Id);
    if (!this.selectedContio) {
      this.selectedContio = {
        Id: null,
        UserId: null,
        StoreId: null,
        TwilioApiId: null,
        VerificationLevelId: null,
        Name: '',
        IsAvailable: false,
        MinimumAge: null,
        IsManualRegistration: false,
        Logo: '',
        ConditionsUrl: '',
        EshopUrl: '',
        IsScanAndGoAllowed: false,
        Latitude: null,
        Longitude: null,
        GoPayId: null,
        GoPayClientId: null,
        GoPayClientSecret: ''
      };
    }
  }
}
