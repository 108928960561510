import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Centrala } from '../../core/http/centrala';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { catchError } from 'rxjs/operators';
import { Company } from './models/company.model';
import { CreateRecordResponse } from '../../shared/models/create-record-response.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private companyUrl: string = Centrala.URL + '/api/company';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  getByUserId(
    userId: string,
  ): Observable<Company[]> {
    const params = new HttpParams()
      .set('userId', userId)
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<Company[]>(
        this.companyUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  getById(
    userId: number,
  ): Observable<Company[]> {
    const params = new HttpParams()
      .set('id', userId.toString())
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<Company[]>(
        this.companyUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }

  edit(company: Company): Observable<CreateRecordResponse> {
    const headers = { 'Content-Type': 'application/json' };

    return this.httpClient
      .put<CreateRecordResponse>(
        this.companyUrl,
        {
          company,
          token: localStorage.getItem(LocalStorageTags.Token),
        },
        { headers }
      )
      .catch(this.httpHandlerService.handleError);
  }
}
