import { Injectable } from '@angular/core';
import { Adapter } from '../../../core/services/shared/adapter/adapter.model';
import { PageableResponseAdapter } from '../../../core/services/shared/adapter/pageable-response.adapter';
import { TableCategory } from './table-category.model';
import { Table } from './table.model';

@Injectable({
    providedIn: 'root',
})
export class TableAdapter extends Adapter<Table> {
    constructor(pageableResponseAdapter: PageableResponseAdapter) {
        super(pageableResponseAdapter);
    }

    get(json: any): Table {
        const tableCategory = new TableCategory({
            id: json.catId,
            name: json.catName,
            number: json.catNo,
        });

        return new Table({
            id: json.id,
            tableCategory: tableCategory,
        });
    }
}
