<div class="container logo-container-width">
  <img
    src="../img/centrala.png"
    alt="logo"
    class="logo-size"
  />
</div>

<div
  class="container position-relative container-width"
>
  <h1>{{ 'REGISTRATION.ACTIVATION' | translate }}</h1>

  <h4 class="pt-4" *ngIf="!registrationData.code">
    {{ 'REGISTRATION.ACTIVATIONLOAD' | translate }}
  </h4>

  <div id="alertDiv" class="mt-4">
    <p *ngIf="registrationData.success">
      <strong>{{ 'REGISTRATION.SUCCESSMESSAGE' | translate }}</strong>
    </p>
    <p *ngIf="!registrationData.success">
      <strong>{{ 'REGISTRATION.DANGERMESSAGE' | translate }}</strong>
    </p>
    <p>{{ codeMessage | translate }}</p>
    <p *ngIf="!registrationData.success">
      {{ 'SUPPORT' | translate }}
      <a href="mailto:obchod@arrowsys.cz">obchod@arrowsys.cz</a>
    </p>
  </div>

  <div>
    <button
      id="button"
      type="button"
      class="btn btn-primary"
      (click)="goToLogin()"
    >
      {{ 'REGISTRATION.CONTINUE' | translate }}
    </button>
  </div>
</div>

<div
  class="w-100 text-center position-fixed footer-div"
>
  <p>
    © {{ copyrightYear }}
    <a href="https://www.arrowsys.cz"
      >ArrowSys s.r.o.</a
    >
  </p>
</div>
