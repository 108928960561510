<authentication-test></authentication-test>
<navigation selectedPage="users">
  <div class="row no-gutters w-100">
    <div class="col-sm-12">
      <div class="upload float-left">
        <input
          type="file"
          [accept]="allowedFormats"
          (change)="onFileUpload($event)"
          onclick="value = null"
        />
        <label
          ><i class="fas fa-upload mr-2"></i
          >{{ 'PICTURE.BROWSE' | translate }}</label
        >
      </div>
      <div
        class="input-group-prepend float-right d-flex flex-column flex-md-row"
      >
        <app-checkbox
          class="pr-3"
          label="{{ 'USERS.INCOMPLETEREG' | translate }}"
          labelPosition="before"
          [checked]="isIncompletedRegistration"
          (checkedChange)="onCheckChange($event)"
        ></app-checkbox>
        <div class="row">
          <div class="col-7">
            <app-input-text
              [(input)]="userInputFilter"
              type="text"
              label="{{ 'PRODUCTS.SEARCH' | translate }}"
              class="mt-1"
            ></app-input-text>
          </div>
          <div class="col-5 p-0">
            <div class="input-group">
              <div class="btn-group">
                <button
                  class="search-btn ml-1"
                  mat-button
                  [class.active]="isButtonActive(1)"
                  (click)="
                    onUpdateFilterClick(userInputFilter);
                    onSetButtonActiveClick(1)
                  "
                >
                  <i class="fas fa-search"></i>
                </button>

                <button
                  class="search-btn ml-1"
                  mat-button
                  [class.active]="isButtonActive(2)"
                  (click)="
                    onUpdateFilterClick(userInputFilter);
                    onSetButtonActiveClick(2)
                  "
                >
                  {{ 'PRODUCTS.ALL' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row no-gutters w-100">
    <app-table
      *ngIf="isDataTableLoaded"
      [columns]="columns"
      [data]="entityList"
      [totalCount]="userCount"
      [currentPage]="currentPage"
      [selectable]="true"
      (selectedRow)="onGoToUserDetailCLick($event)"
      (pageChange)="onPageChange($event)"
    ></app-table>
  </div>
</navigation>
