import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ScheduleService } from '../schedule.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { Schedule } from './models/schedule.model';
import { TwilioServerResponse } from '../../twilio/models/twilio-server-response.model';

@Component({
  selector: 'app-contio-store-schedule',
  templateUrl: './contio-store-schedule.component.html',
})
export class ContioStoreScheduleComponent implements OnInit {
  contioStoreId: number;
  userId: number;
  readonly defaultTime: string = '00:00';

  scheduleList: Schedule[] = [];
  isNewscheduleList: boolean = false;

  serverReponse: TwilioServerResponse;
  error: string;

  constructor(
    private location: Location,
    private scheduleService: ScheduleService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.contioStoreId = this.activatedRoute.snapshot.params.value;
    this.userId = this.localStorageService.getUserId();

    this.setSchedule();
  }

  onGoToContioStoreDetailClick(): void {
    this.location.back();
  }

  onCheckChange(
    isOpen: boolean,
    dayOfWeek: number
  ): void {
    this.scheduleList.forEach(day => {
      if (day.DayOfWeek === dayOfWeek) {
        day.IsOpen = isOpen;
      }
    });
  }

  onAddTimeClick(
    index: number,
    dayOfWeek: number
  ): void {
    const lastIndex = this.scheduleList.map(element => element.DayOfWeek).lastIndexOf(dayOfWeek);
    const timeCounter = this.scheduleList[lastIndex].Counter + 1;

    const newTime = {
      ContioStoreId: this.contioStoreId,
      IsOpen: true,
      DayOfWeek: dayOfWeek,
      StartTime: this.defaultTime,
      EndTime: this.defaultTime,
      Counter: timeCounter,
    };
    const insertIndex = index + 1;

    this.scheduleList.splice(insertIndex, 0, newTime);
  }

  onDeleteTimeClick(index: number): void {
    this.scheduleList.splice(index, 1);
  }

  onAddNewSchedule(): void {
    this.scheduleService
      .post(
        this.userId,
        this.scheduleList
      )
      .subscribe({
        next: (successResponse) => {
          this.serverReponse = successResponse;

          if (successResponse.success) {
            this.snackBarService.show(
              this.translateService.instant('SHOP.SCHEDULEEDITSUCCESS'),
              true
            );
            this.setSchedule();
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show(
            this.translateService.instant('SHOP.SCHEDULEEDITFAIL'),
            false
          );
        },
      });
  }

  onEditScheduleClick(): void {
    this.scheduleService
      .update(
        this.userId,
        this.scheduleList
      )
      .subscribe({
        next: (successResponse) => {
          this.serverReponse = successResponse;

          if (successResponse.success) {
            this.snackBarService.show(
              this.translateService.instant('SHOP.SCHEDULEEDITSUCCESS'),
              true
            );
            this.setSchedule();
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show(
            this.translateService.instant('SHOP.SCHEDULEEDITFAIL'),
            false
          );
        },
      });
  }

  private setSchedule(): void {
    this.scheduleService
      .getScheduleByContioStoreId(
        this.contioStoreId,
        this.userId,
      )
      .subscribe({
        next: (scheduleList: Schedule[]) => {
          if (scheduleList && scheduleList.length > 0) {
            this.prepareOpenHourArray(scheduleList);
          } else {
            this.prepareNewTimetable();
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private prepareOpenHourArray(scheduleList: Schedule[]): void {
    let startTime;
    let endTime;

    scheduleList.forEach(openHour => {
      startTime = new Date(openHour.StartTime);
      endTime = new Date(openHour.EndTime);
      openHour.StartTime = startTime.toLocaleTimeString('it-IT', { timeZone: "utc", hour: '2-digit', minute: '2-digit', hour12: false });
      openHour.EndTime = endTime.toLocaleTimeString('it-IT', { timeZone: "utc", hour: '2-digit', minute: '2-digit', hour12: false});
    });

    scheduleList.sort((a, b) => {
      return a.DayOfWeek - b.DayOfWeek || a.Counter - b.Counter;
    });

    this.scheduleList = scheduleList;
    this.isNewscheduleList = false;
  }

  private prepareNewTimetable(): void {
    const daysOfTheWeek = [0, 1, 2, 3, 4, 5, 6];

    daysOfTheWeek.forEach(day => {
      const newDay: Schedule = {
        ContioStoreId: this.contioStoreId,
        IsOpen: true,
        DayOfWeek: day,
        StartTime: this.defaultTime,
        EndTime: this.defaultTime,
        Counter: 0,
      };

      this.scheduleList.push(newDay);
    });

    this.isNewscheduleList = true;
  }
}
