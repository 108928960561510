<div class="row">
    <div class="col-12 ml-3 mb-1" *ngIf="isWarehouseEdit">
      <h4 class="header-color text-left">
        {{ 'STORES.WAREHOUSETITLE' | translate }}
      </h4>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="row no-gutters">
        <app-dropdown
            *ngIf="isWarehouseLoaded"
            [(selected)]="selectedWarehouse"
            (selectedChange)="onChangeWarehouseClick()"
            label="{{ 'STORES.WAREHOUSE' | translate }}"
            itemKey="Name"
            [items]="warehouseList"
        ></app-dropdown>
        <div class="ml-0 ml-md-2 mt-2 mt-md-0 float-left" *ngIf="isWarehouseEdit">
          <mat-button-toggle-group appearance="legacy" aria-label="Choose Group">
              <mat-button-toggle
              #addWarehouse
              class="normal-btn"
              (click)="onAddWarehouseModalOpenClick(warehouseAddModal)"
              [hidden]="companyPrivilege && companyPrivilege === privilegeValue.Read"
              ><i class="fas fa-plus mr-1"></i>{{ 'STORES.WAREHOUSETITLE' | translate }}
            </mat-button-toggle>
            <mat-button-toggle
              #addWarehouseEdit
              class="normal-btn mat-btn-group"
              [hidden]="!selectedWarehouse || (companyPrivilege && companyPrivilege === privilegeValue.Read)"
              (click)="onOpenWarehouseEditDialogClick()"
              >{{ 'STORES.EDIT' | translate }}
            </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
      </div>

      <div class="row no-gutters" *ngIf="isWarehouseEdit">
        <div class="col-12 mt-3">
          <div *ngIf="isWarehouseLoaded && selectedWarehouse.Id">
            <div>Warehouse ID: {{ selectedWarehouse.Id }}</div>
          </div>
        </div>

        <div class="col-12 mt-2">
          <div *ngIf="isWarehouseLoaded && selectedWarehouse.Id">
            <div>
              {{ 'STORES.WAREHOUSEDATE' | translate }}:
              {{ warehouseConvertedStartDate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- MODAL FOR ADDING WAREHOUSE -->
  <ng-template #warehouseAddModal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'STORES.ADDWAREHOUSEMODAL' | translate }}</h4>
    </div>
    <div class="modal-body">
      <div class="row no-gutters">
        <div class="col-12">
          <div class="input-group mb-3 line-height">
            <div class="input-group-prepend">
              <span class="input-group-text" for="from">{{
                'STORES.WAREHOUSEDATE' | translate
              }}</span>
            </div>
            <input
              [minDate]="minDate"
              [maxDate]="maxDate"
              [placement]="ngbDatepickerPlacement"
              readonly="false"
              id="from"
              class="form-control"
              name="dp"
              value="{{ inceptionDate | dateFormat }}"
              [(ngModel)]="inceptionDate"
              ngbDatepicker
              #d1="ngbDatepicker"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d1.toggle()"
                type="button"
              >
                <i class="far fa-calendar-alt"></i>
              </button>
            </div>
            <div class="input-group-append">
              <button
                class="btn btn-danger"
                (click)="onCalendarClearClick()"
                type="button"
              >
                <i class="far fa-calendar-times"></i>
              </button>
            </div>
          </div>

          <div
            *ngIf="inceptionDate"
            class="alert alert-danger alert-dismissable mt-3 text-center"
          >
            <p>
              <strong>{{ 'VALIDATOR.WAREHOUSEDATE' | translate }}</strong>
            </p>
            <p>{{ 'VALIDATOR.WAREHOUSEDATEANNOTATION' | translate }}</p>
          </div>
        </div>

        <div class="col-12 form-group" [formGroup]="validatorService.warehouseForm">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" for="warehouseInput">{{
                'STORES.NEWWAREHOUSE' | translate
              }}</span>
            </div>
            <input
              #newWarehouse
              [(ngModel)]="warehouseInput"
              type="text"
              id="warehouseInput"
              class="form-control"
              placeholder="{{ 'STORES.NAME' | translate }}"
              formControlName="warehouseName"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  isWarehouseValidationVisible &&
                  validatorService.validateForm.warehouseInput.errors
              }"
            />
            <div
              *ngIf="
                isWarehouseValidationVisible &&
                validatorService.validateForm.warehouseInput.errors
              "
              class="invalid-feedback text-right mr-1"
            >
              <div class="input-group-append float-right">
                <div
                  *ngIf="validatorService.validateForm.warehouseInput.errors.required"
                >
                  {{ 'VALIDATOR.VALIDATION' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="c('Close click'); resetAddValidator(); onCalendarClearClick()"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>

      <button
        class="btn btn-primary save-btn"
        type="button"
        (click)="
          onWarehousePostClick(newWarehouse.value, inceptionDate);
          newWarehouse.value && c('Close click')
        "
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'STORES.NEW' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- MODAL FOR EDITING WAREHOUSE -->
  <ng-template #warehouseEditModal let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ selectedWarehouse.Name }}</h4>
      <button
        class="btn btn-danger float-right"
        type="button"
        (click)="onWarehouseDeleteOpenModalClick(warehouseDeleteModal); c('Close click')"
      >
        <i class="fas fa-trash-alt mr-1"></i>
        {{ 'PRODUCTS.DELETEGROUP' | translate }}
      </button>
    </div>
    <div class="modal-body">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="nameInput">{{
            'MODAL.NAME' | translate
          }}</span>
        </div>
        <input
          #warehouseNameEdit
          [(ngModel)]="selectedWarehouseName"
          type="text"
          class="form-control"
          [ngClass]="{
            'is-invalid': isWarehouseValidationVisible
          }"
        />

        <div
          *ngIf="isWarehouseValidationVisible"
          class="invalid-feedback text-right div-right-margin"
        >
          <div class="input-group-append float-right">
            <div *ngIf="!selectedWarehouse.Name">
              {{ 'VALIDATOR.VALIDATION' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="c('Close click'); resetAddValidator()"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary save-btn"
        (click)="
          onWarehouseEditClick(warehouseNameEdit.value); c('Close click')"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'STOCK.UPDATE' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- MODAL WINDOW FOR DELETE WAREHOUSE -->
  <ng-template #warehouseDeleteModal let-c="close" let-d="dismiss">
    <div class="ng-template-padding">
      <div class="modal-header text-center">
        <span class="large-font"
          >{{ 'STORES.WDIALOG' | translate }} '{{
            selectedWarehouse.Name
          }}'?</span
        >
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="openModalEdit(warehouseEditModal); c('Close click')"
        >
          {{ 'PRODUCTS.CLOSE' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="onWarehouseDeleteClick(); c('Close click')"
        >
          <i class="fas fa-trash-alt mr-1"></i>
          {{ 'PRODUCTS.DELETEGROUP' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
