import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebsocketService } from '../../../core/services/websocket/websocket.service';
import { CameraService } from '../../cameras/camera.service';
import { DeviceService } from '../../devices/device.service';
import { Camera } from '../../cameras/models/camera.model';
import { Device } from '../../devices/models/device.model';
import { DeviceState } from '../../devices/models/device-state.model';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { LocalStorageTags } from '../../../core/enums/local-storage-tags.enum';
import { PrivilegeType } from '../../subaccounts/privilege-type.enum';
import { SubaccountValue } from '../../subaccounts/subaccount-value.enum';

@Component({
  selector: 'app-contio-store-detail',
  templateUrl: './contio-store-detail.component.html',
  styleUrls: [
    './contio-store-detail.component.scss',
  ],
})
export class ContioStoreDetailComponent implements OnInit, OnDestroy {
  isVideoLive: boolean = false;
  videoUrl: string = '';

  contioShopPrivilege: string;
  privilegeValue: any = SubaccountValue;

  storeId: number;
  deviceList: Device[];
  deviceStateList: DeviceState[];

  currentPage: number = 0;
  listLimit: number = GridSetting.listLimit;

  cameraList: Camera[];
  selectedCameraId: number = 0;

  error: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public webSocketService: WebsocketService,
    private cameraService: CameraService,
    private deviceService: DeviceService,
    private localStorageService: LocalStorageService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.localStorageService.autenticateContioUser();

    this.playVideo(this.isVideoLive);

    this.webSocketService.openWebSocket();
    this.storeId = this.activatedRoute.snapshot.params.value;
    this.deviceStateList = <DeviceState[]>[{
      active: 0,
      inactive: 0,
    }];

    this.setDevices();
    this.setCameras();
    this.getContioShopPrivilege();
  }

  ngOnDestroy(): void {
    this.webSocketService.closeWebSocket();
  }

  onPlayVideoClick(isVideoLive: boolean): void {
    this.playVideo(isVideoLive);
  }

  onSelectCameraClick(
    rowIndex: number,
    columnIndex: number
  ): void {
    columnIndex = columnIndex * 3;
    rowIndex += (columnIndex + 1);
    this.selectedCameraId = rowIndex;
  }

  onGoToActivitiesList(): void {
    this.router.navigate(['/contio-store/' + this.storeId + '/activities']);
  }

  onGoToShopDevicesClick(): void {
    this.router.navigate(['/devices/' + this.storeId]);
  }

  onGoToShopCamerasClick(): void {
    this.router.navigate(['/cameras/' + this.storeId]);
  }

  onGoToShopSettingClick(): void {
    this.router.navigate([`/contio-store/detail/${this.storeId}/settings`]);
  }

  onGoToContioShopListClick(): void {
    this.router.navigate(['/contio-store']);
  }

  onGoToShopScheduleClick(): void {
    this.router.navigate([`/contio-store/${this.storeId}/schedule`]);
  }

  private getContioShopPrivilege(): void {
    const privilegeList = JSON.parse(localStorage.getItem(LocalStorageTags.Privilege));

    if (privilegeList) {
      this.contioShopPrivilege = privilegeList[PrivilegeType.ContioShop].Value;
    }
  }

  private playVideo(isVideoLive: boolean): void {
    if (isVideoLive) {
      const selectedCameraIndex = this.selectedCameraId - 1;
      this.videoUrl = this.cameraList[selectedCameraIndex].Url;
      this.isVideoLive = true;
    } else {
      this.videoUrl = '../img/pause-video.png';
      this.isVideoLive = false;
    }
  }

  private setCameras(): void {
    this.cameraService
      .get(
        this.storeId,
        1,
        10
      )
      .subscribe({
        next: cameraList => {
          this.cameraList = cameraList;
          this.setSelectedCamera();
        },
        error: error => {
          this.error = error;
        },
      });
  }

  private setSelectedCamera(): void {
    this.selectedCameraId = this.cameraList.findIndex(
      camera => camera.IsFavourite
    );
    this.selectedCameraId += 1;
  }

  private setActiveDevicesCount(deviceList: Device[]): void {
    deviceList.forEach( (device, index) => {
      if (deviceList[index].IsRunning) {
        this.deviceStateList[0].active += 1;
      } else {
        this.deviceStateList[0].inactive += 1;
      }
    });
  }

  private setDevices(): void {
    this.deviceService
      .get(
        this.storeId,
        GridSetting.firstPage,
        this.listLimit
      )
      .subscribe({
        next: deviceList => {
          this.deviceList = deviceList;
          this.setActiveDevicesCount(this.deviceList);
        },
        error: error => {
          this.error = error;
        },
      });
  }
}
