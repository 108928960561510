import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from '../../modules/contio-store/contio-store-activity-list/activity-type.enum';

@Pipe({
  name: 'activityType'
})
export class ActivityTypePipe implements PipeTransform {
  activityType: any = ActivityType;
  constructor(
    private translateService: TranslateService,
  ) { }

  transform(type: string): string {
    switch (type) {
      case this.activityType.All:
        return this.translateService.instant('PRODUCTS.ALL');
      case this.activityType.Entrance:
        return this.translateService.instant('STORES.ENTRANCE');
      case this.activityType.Kiosk:
        return this.translateService.instant('STORES.KIOSK');
      case this.activityType.Receipt:
        return this.translateService.instant('STORES.RECEIPT');
      default:
        return '';
    }
  }
}
