import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../users/user.service';
import { LoyaltyCardService } from '../loyalty-card.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorService } from '../../../core/services/validator/validator.service';
import { LoyaltyCard } from '../models/loyalty-card.model';
import { LoyaltyCardPageable } from '../models/loyalty-card-pageable.model';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { finalize } from 'rxjs/operators';
import { User } from '../../users/models/user.model';
import { ServerResponse } from '../../../shared/models/server-response.model';
import { LoyaltyCardUser } from '../models/loyalty-card-user.model';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { ContioService } from '../../contio-store/contio.service';
import { ContioStore } from '../../contio-store/models/contio-store.model';
import { LocalStorageTags } from '../../../core/enums/local-storage-tags.enum';
import { PrivilegeType } from '../../subaccounts/privilege-type.enum';
import { SubaccountValue } from '../../subaccounts/subaccount-value.enum';
import { DateTimeService } from '../../../core/services/formatter/date-time.service';

@Component({
  selector: 'app-loyalty-card-list',
  templateUrl: './loyalty-card-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LoyaltyCardListComponent implements OnInit {
  isUserInputVisible: boolean = true;
  isCardDetailDisabled: boolean = true;

  readonly keyword: string = 'Surname';
  selectedUser: User;
  userList: User[];

  loyaltyCardPrivilege: string;
  privilegeValue: any = SubaccountValue;

  readonly defaultFilterForm: string = '';
  currentPage: number = 0;
  pageLimit: number = GridSetting.pageLimit;
  cardCount: number = 0;
  cardList: LoyaltyCard[];
  contioStoreList: ContioStore[];
  selectedContioStore: ContioStore;

  activeButton: number = 0;
  loyaltyCardFilter: string = '';

  newUser: LoyaltyCardUser;
  postResponse: ServerResponse;

  error: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private modalService: NgbModal,
    private validatorService: ValidatorService,
    private loyaltyCardService: LoyaltyCardService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
    private contioService: ContioService,
    private dateService: DateTimeService
  ) { }

  async ngOnInit(): Promise<void> {
    this.localStorageService.autenticateContioUser();

    this.setUserList();
    this.setCardList();
    this.validatorService.loyaltyCardValidator();
    this.onSetDefaultFormClick();
    this.getContioStores();
    this.getLoayltyCardPageCount();
    this.getLoyaltyCardPrivilege();
  }

  onOpenAddNewUserModalClick(userName: string): void {
    this.modalService
      .open(userName, {
        windowClass: 'dark-modal',
        keyboard: false,
        backdrop: 'static',
        size: 'lg',
      });
  }

  onUserSelect(user: User): void {
    this.selectedUser = user;
  }

  onInputClear(): void {
    this.selectedUser = null;
  }

  onSearchChange(search: string): void {
    if (!search) {
      return;
    }
    this.selectedUser = null;
  }

  setUserList(): void {
    this.userService
      .getList(
        this.defaultFilterForm,
        1,
        GridSetting.listLimit,
        null,
        false
      )
      .subscribe({
        next: (userList) => {
          this.userList = userList;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onPrepareNewCardClick(isUserInputVisible: boolean): void {
    this.isUserInputVisible = isUserInputVisible;
    this.isCardDetailDisabled = true;
  }

  postCard(): void {
    this.loyaltyCardService
      .post(
        this.selectedUser.ClientUserId,
        this.selectedUser.SerialNumber
      )
      .pipe(
        finalize(() => {
          this.postResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.postResponse = successResponse;

          if (successResponse.success) {
            this.setCardList();
            this.onInputClear();
          }

          this.snackBarService.show('CARDS.ADDUSERSUCCESS', successResponse.success);
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('CARDS.ADDUSERFAIL', false);
        },
      });
  }

  setCardList(loyaltyCard?: LoyaltyCardPageable): void {
    if (loyaltyCard != null) {
      this.currentPage = loyaltyCard.offset;
    }

    const nextPage = this.currentPage + 1;
    this.loyaltyCardService
      .getCardList(
        this.loyaltyCardFilter,
        nextPage,
        GridSetting.pageLimit,
      )
      .subscribe({
        next: (cardList) => {
          this.cardList = cardList;

          this.getLoayltyCardPageCount();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onUpdateFilterClick(filter: string): void {
    const filteredLoyaltyCards = filter.toString().toLowerCase().trim();
    this.loyaltyCardFilter = filteredLoyaltyCards;

    this.setCardList();
  }

  postUserWithoutApp(): void {
    let birthDate;

    if (this.validatorService.loyaltyCardForm.invalid) {
      return;
    }

    if (this.newUser.birthDate) {
      birthDate = this.dateService.convertDateToUnixTimestamp(this.newUser.birthDate);
    }

    this.loyaltyCardService
      .postWithoutApp(
        this.newUser.name,
        this.newUser.surname,
        this.newUser.phone,
        this.newUser.email,
        this.newUser.street,
        this.newUser.city,
        this.newUser.zip,
        this.newUser.country,
        birthDate,
        this.selectedContioStore.Id
      )
      .pipe(
        finalize(() => {
          this.postResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.postResponse = successResponse;
          if (successResponse.success) {
            this.setUserList();
            this.onSetDefaultFormClick();
          }
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  getContioStores(): void {
    this.contioService
      .getList(
        GridSetting.firstPage,
        GridSetting.listLimit
      )
      .subscribe({
        next: (contioStores) => {
          this.contioStoreList = contioStores;
          this.selectedContioStore = contioStores[0];
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onContioStoreChange(contioStore: ContioStore): void {
    this.selectedContioStore = contioStore;
  }

  onSetDefaultFormClick(): void {
    this.newUser = <LoyaltyCardUser>{
      name: '',
      surname: '',
      phone: '',
      email: '',
      street: '',
      city: '',
      zip: '',
      country: '',
      birthDate: null,
    };
  }

  onGoToCardDetailClick(loyaltyCard: LoyaltyCard): void {
    this.router.navigate([
      '/loyalty-card/detail/' + loyaltyCard[0].Id,
    ]);
  }

  onSetButtonActiveClick(buttonNumber: number): void {
    this.activeButton = buttonNumber;
  }

  isButtonActive(buttonNumber: number): boolean {
    return this.activeButton === buttonNumber;
  }

  onFormHideClick(): void {
    this.isUserInputVisible = true;
  }

  onFormShowClick(): void {
    this.isUserInputVisible = false;
  }

  private getLoyaltyCardPrivilege(): void {
    const privilegeList = JSON.parse(localStorage.getItem(LocalStorageTags.Privilege));

    if (privilegeList) {
      this.loyaltyCardPrivilege = privilegeList[PrivilegeType.LoyaltyCard].Value;
    }
  }

  private getLoayltyCardPageCount(): void {
    this.loyaltyCardService
      .getPageCount(this.loyaltyCardFilter)
      .subscribe({
        next: (cardCount) => {
          this.cardCount = cardCount[0].cardCount;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
