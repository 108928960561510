import { Component, OnInit } from '@angular/core';
import { Twilio } from './models/twilio.model';
import { TwilioService } from './twilio.service';
import { ListBaseDirective } from '../../shared/components/authentication-test/list-base.component';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';
import { TableColumn } from '../../core/interfaces/shared/table-columns.model';
import { TranslateService } from '@ngx-translate/core';
import { YesNoPipe } from '../../shared/pipes/yes-no-pipe/yes-no.pipe';
import { DatePipe } from '@angular/common';
import { ContioStore } from '../contio-store/models/contio-store.model';
import { ContioService } from '../contio-store/contio.service';
import { SnackBarService } from '../../core/services/shared/snackbar.service';
import { TwilioServerResponse } from './models/twilio-server-response.model';
import { GridSetting } from '../../shared/models/grid-setting.model';

@Component({
  selector: 'app-twilio-list',
  templateUrl: './twilio-list.component.html',
  styleUrls: ['./twilio-list.component.scss'],
})
export class TwilioListComponent
  extends ListBaseDirective<Twilio>
  implements OnInit {
  userId: number;
  entityList: Twilio[] = [];
  selectedTwilio: Twilio;
  currentPage: number = 0;
  twilioCount: number = 0;
  isDataTableLoaded: boolean = false;
  getList$: Observable<Twilio[]>;
  columns: TableColumn[];

  twilioFilter: number = 0;

  isSubmit: boolean = false;
  areInputElementsVisible: boolean = true;
  isEdit: boolean = false;

  contioStoreList: ContioStore[];
  selectedContioStore: ContioStore;

  serverResponse: TwilioServerResponse;

  error: string;

  constructor(
    private twilioService: TwilioService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private yesNoPipe: YesNoPipe,
    private datePipe: DatePipe,
    private contioService: ContioService,
    private snackBarService: SnackBarService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.userId = this.localStorageService.getUserId();
    this.columns = this.setTwilioListColums();
    this.localStorageService.autenticateContioUser();
    this.setContioStoreList();

    this.onPageChange(0);
  }

  onPageChange(pageIndex?: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.twilioService.getList(this.userId);
    this.setList();

    this.twilioCount = this.entityList.length;
    this.isDataTableLoaded = true;
  }

  onRowSelect(twilio: Twilio): void {
    if (!twilio) {
      this.onSetNewTwilioClick(true);
      return;
    }

    this.selectedTwilio = twilio;
    this.onSetContioStoreChange(twilio.ContioStoreId);
    this.isEdit = true;
    this.areInputElementsVisible = false;
  }

  onTwilioSaveClick(isEdit: boolean): void {
    this.isSubmit = true;

    if (isEdit) {
      this.editTwilio();

      return;
    }

    this.postTwilio();
  }

  onSetNewTwilioClick(areInputElementsVisible: boolean): void {
    this.areInputElementsVisible = areInputElementsVisible;
    this.isSubmit = false;
    this.isEdit = false;
    this.selectedTwilio = <Twilio>{
      Name: '',
      CreateDateTime: new Date(),
      TwilioAccountSid: '',
      TwilioAuthToken: '',
      TwilioAppService: '',
      IsActive: true,
    };

    this.setContioStore();
  }

  onCheckChange(isChecked: boolean): void {
    this.selectedTwilio.IsActive = isChecked;
  }

  onCheckSmsChange(isChecked: boolean): void {
    this.selectedTwilio.IsSmsService = isChecked;
  }

  onSetContioStoreChange(contioStoreId: number): void {
    this.selectedContioStore = this.contioStoreList.find(contioStore => contioStore.Id === contioStoreId);
    this.selectedTwilio.ContioStoreId = this.selectedContioStore.Id;
  }

  private setContioStoreList(): void {
    this.contioService
      .getList(
        GridSetting.firstPage,
        GridSetting.listLimit
      )
      .subscribe({
        next: (contioStoreList: ContioStore[]) => {
          this.contioStoreList = contioStoreList;

          this.setContioStore();
        },
      });
  }

  private setContioStore(): void {
    if (this.contioStoreList.length < 1) {
      return;
    }

    this.selectedContioStore = this.contioStoreList[0];
  }

  private postTwilio(): void {
    this.twilioService
      .post(this.selectedTwilio)
      .subscribe({
        next: (successResponse) => {
          this.serverResponse = successResponse;

          if (successResponse.success) {
            this.snackBarService.show('TWILIO.TWILIOPOSTSUCCESS', successResponse.success);
            this.onSetNewTwilioClick(true);
            this.onPageChange(0);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('TWILIO.TWILIOPOSTFAIL', false);
        },
      });
  }

  private editTwilio(): void {
    this.twilioService
      .put(this.selectedTwilio)
      .subscribe({
        next: (successResponse) => {
          this.serverResponse = successResponse;

          if (successResponse.success) {
            this.snackBarService.show('TWILIO.TWILIOEDITSUCCESS', successResponse.success);
            this.onSetNewTwilioClick(true);
            this.onPageChange(0);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('TWILIO.TWILIOEDITFAIL', false);
        },
      });
  }

  private setTwilioListColums(): TableColumn[] {
    return [
      {
        columnDef: 'Name',
        header: this.translateService.instant('USERS.NAME'),
        cell: (element: Twilio) => `${element.Name}`,
      },
      {
        columnDef: 'CreateDateTime',
        header: this.translateService.instant('TWILIO.CREATEDAT'),
        cell: (element: Twilio) =>
          `${this.datePipe.transform(element.CreateDateTime, 'd. M. y h:mm')}`,
      },
      {
        columnDef: 'IsActive',
        header: this.translateService.instant('STORES.ISAVAILABLE'),
        cell: (element: Twilio) =>
          `${this.yesNoPipe.transform(element.IsActive)}`,
      },
    ];
  }
}
