import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-datetime',
  templateUrl: './input-datetime.component.html',
  styleUrls: ['../../../../../assets/scss/input.scss'],
})
export class InputDatetimeComponent<T extends string | number> implements OnInit {
  formControlName: string = 'name';
  floatLabelControl: FormControl = new FormControl('never');
  hideRequiredControl: FormControl = new FormControl(true);
  minDate: Date;
  maxDate: Date;

  @Input() input?: T;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() errorMessage: string;
  @Input() required: boolean = false;
  @Input() submit: boolean = false;
  @Input() useMaterial: boolean = false;

  @Output() inputChange: EventEmitter<string | number> = new EventEmitter<string | number>();

  form: FormControl = new FormControl('', [Validators.required]);

  ngOnInit(): void {
    this.minDate = new Date(2000, 1, 1);
    this.maxDate = new Date();

    this.hideRequiredControl = new FormControl(!this.required);
  }

  onChange(input?: T): void {
      this.inputChange.emit(input);
  }
}
