import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { StockService } from '../stock.service';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { ListBaseDirective } from '../../../shared/components/authentication-test/list-base.component';
import { TableColumn } from '../../../core/interfaces/shared/table-columns.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { StockRoute } from './stock-route.enum';
import { StockNoteType } from '../stock-note-type.enum';
import { StockNote } from '../models/stock.model';

@Component({
  selector: 'app-stock',
  templateUrl: './stock-list.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class StockListComponent
  extends ListBaseDirective<StockNote>
  implements OnInit {
  public stockRoute = StockRoute;
  companyId: number;

  entityList: StockNote[] = [];
  currentPage: number = 0;
  stockCount: number;

  columns: TableColumn[];
  isDataTableLoaded: boolean = false;
  getList$: Observable<StockNote[]>;

  error: string;

  constructor(
    private stockService: StockService,
    private router: Router,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private localStorageService: LocalStorageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.columns = this.getStockListColums();
    this.companyId = this.localStorageService.getCompanyId();

    this.setNotePageCount();
  }

  onPageChange(pageIndex?: number): void {
    this.currentPage = pageIndex + 1;
    this.getList$ = this.stockService.getCompanyNotes(
      this.companyId,
      this.currentPage,
      GridSetting.pageLimit,
    );
    this.setList();
  }

  onGoToStockDetailClick(note: StockNote): void {
    switch (note.Type) {
      case StockNoteType.Receive: {
        this.router.navigate(['/' + StockRoute.ReceiveNote, note.Id]);
        break;
      }
      case StockNoteType.Withdrawal: {
        this.router.navigate(['/' + StockRoute.WithdrawalNote, note.Id]);
        break;
      }
      default: {
        break;
      }
    }
  }

  onGoToNewStockReceiveClick(): void {
    this.router.navigate(['/' + StockRoute.NewReceiveNote]);
  }

  onGoToNewStockWithdrawalClick(): void {
    this.router.navigate(['/' + StockRoute.NewWithdrawalNote]);
  }

  onGoToNewStockInternalClick(): void {
    this.router.navigate(['/' + StockRoute.NewInternalNote]);
  }

  private setNotePageCount(): void {
    this.stockService
      .getPageCount(this.companyId)
      .subscribe({
        next: (noteCount) => {
          this.stockCount = noteCount[0].stock_count;
          this.isDataTableLoaded = true;

          this.onPageChange(0);
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private getStockListColums(): TableColumn[] {
    return [
      {
        columnDef: 'Date',
        header: this.translateService.instant('SALES.TABLEDATE'),
        cell: (element: StockNote) => `${this.datePipe.transform(element.Date, 'd. M. y')}`,
      },
      {
        columnDef: 'StoreName',
        header: this.translateService.instant('STORES.WAREHOUSETITLE'),
        cell: (element: StockNote) => `${element.WarehouseName}`,
      },
      {
        columnDef: 'CompanyName',
        header: this.translateService.instant('STOCK.SUPPLIER'),
        cell: (element: StockNote) => `${element.CompanyName}`,
      },
      {
        columnDef: 'Type',
        header: this.translateService.instant('STOCK.TYPE'),
        cell: (element: StockNote) => `${element.Type}`,
        isResponsive: true,
      },
    ];
  }
}
