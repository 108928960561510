<div class="custom-input">
  <mat-button-toggle-group appearance="legacy">
    <mat-button-toggle
      [ngClass]="{ 'input-error': form.invalid && submit }"
      disabled
    >
      {{ label }}{{ required ? '*' : '' }}
    </mat-button-toggle>

    <mat-form-field
      [ngClass]="{ invalid: submit && !input }"
      [floatLabel]="floatLabelControl.value"
      [hideRequiredMarker]="hideRequiredControl.value"
      novalidate
    >
      <input
        matInput
        [ngxMatDatetimePicker]="picker"
        [placeholder]="placeholder"
        [formControl]="form"
        [min]="minDate"
        [max]="maxDate"
        [value]="input"
        [(ngModel)]="input"
        (ngModelChange)="onChange(input)"
      />
      <ngx-mat-datetime-picker #picker [showSeconds]="false">
        <ng-template>
          <mat-icon>check</mat-icon>
        </ng-template>
      </ngx-mat-datetime-picker>
    </mat-form-field>
    <mat-button-toggle
      [disabled]="useMaterial"
      mat-raised-button
      (click)="picker.open()"
    >
      <i class="far fa-calendar-alt text-dark"></i>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-error *ngIf="form.invalid && submit">{{ errorMessage }}</mat-error>
</div>
