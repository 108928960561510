import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-detail-tabs',
  templateUrl: './user-detail-tabs.component.html',
})
export class UserDetailTabsComponent {
  constructor(
    private location: Location,
  ) { }

  onGoToUserListClick(): void {
    this.location.back();
  }
}
