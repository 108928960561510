import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ReturnService } from '../return.service';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { finalize } from 'rxjs/operators';
import { Return } from '../models/return.model';
import { ReturnType } from '../models/return-type.model';
import { ServerResponse } from '../../../shared/models/server-response.model';
import { GridSetting } from '../../../shared/models/grid-setting.model';

@Component({
  selector: 'app-return-detail',
  templateUrl: './return-detail.component.html',
  styleUrls: ['./return-detail.component.scss'],
})
export class ReturnDetailComponent implements OnInit {
  returnTypeList: ReturnType[];
  returnId: number;
  isReturnTypesSet: boolean = false;
  return: Return;
  isReturnLoaded: boolean = false;

  pageLimit: number = GridSetting.pageLimit;

  readonly defaultMinDateForm: number = 0;
  readonly dafaultMaxDateForm: number = 0;
  readonly currentPage: number = 0;

  returnServerResponse: ServerResponse;
  error: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private returnService: ReturnService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.returnId = this.activatedRoute.snapshot.params.value;

    this.setReturnDetail();
    this.getReturnTypes();
  }

  onReturnTypeSelect(returnTypeId: number): void {
    this.returnTypeList.forEach(value => {
      if (value.Id === returnTypeId) {
        this.return.ReturnType = value.Id;
        this.return.Description = value.Description;
      }
    });
  }

  onEditClick(): void {
    this.returnService
      .edit(this.returnId, this.return.ReturnType)
      .pipe(
        finalize(() => {
          this.returnServerResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.returnServerResponse = successResponse;
          if (successResponse.success) {
            this.setReturnDetail();

            this.snackBarService.show('TELEGRAM.BLOCKSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('TELEGRAM.BLOCKFAILED', false);
        },
      });
  }

  onGoToReturnListClick(): void {
    this.location.back();
  }

  private setReturnDetail(): void {
    this.returnService
      .get(
        this.defaultMinDateForm,
        this.dafaultMaxDateForm,
        this.currentPage,
        GridSetting.pageLimit,
        this.returnId,
      )
      .subscribe({
        next: (returnDetail) => {
          this.return = returnDetail[0];
          this.isReturnLoaded = true;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private getReturnTypes(): void {
    this.returnService
      .getTypes()
      .subscribe({
        next: (returnTypeList) => {
          this.returnTypeList = returnTypeList;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
