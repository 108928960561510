<authentication-test></authentication-test>
<navigation selectedPage="contio-store-activities">
  <div class="row no-gutters">
    <div class="col-8">
      <div class="row no-gutters">
        <div class="col-12 page-header">
          {{ 'SHOP.ACTIVITIES' | translate }}
        </div>
      </div>
    </div>

    <div class="col-4">
      <button
        class="normal-btn float-right"
        mat-button
        (click)="onGoToContioShopDetailClick()"
      >
        {{ 'REGISTRATION.BACK' | translate }}
      </button>
    </div>
  </div>

  <hr class="line" />

  <div fxLayout="row wrap" fxLayoutGap="8px grid" fxLayoutAlign="end">
    <div fxFlex="100%" fxFlex.gt-md="20%">
      <app-input-datetime
        [(input)]="startDate"
        label="{{ 'SALES.DATEFROM' | translate }}"
        errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
        [useMaterial]="false"
      ></app-input-datetime>
    </div>
    <div fxFlex="100%" fxFlex.gt-md="20%">
      <app-input-datetime
        [(input)]="endDate"
        label="{{ 'SALES.DATETO' | translate }}"
        errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
        [useMaterial]="false"
      ></app-input-datetime>
    </div>
    <div fxFlex="100%" fxFlex.gt-md="20%">
      <app-input-select
      [items]="activityTypeList"
      [label]="'BITKASA.TXTYPE' | translate"
      [isPipeForActivityType]="true"
      [(input)]="activityType"
      >
      </app-input-select>
    </div>
    <div class="btn-group">
      <button
        class="search-btn ml-1"
        mat-button
        [class.active]="isButtonActive(buttonStateType.Search)"
        (click)="
          onUpdateFilterClick(startDate, endDate, activityType);
          onSetButtonActiveClick(buttonStateType.Search)
        "
      >
        <i class="fas fa-search"></i>
      </button>

      <button
        class="search-btn ml-1"
        mat-button
        [class.active]="isButtonActive(buttonStateType.ShowAll)"
        (click)="
          onShowAllItemsClick();
          onSetButtonActiveClick(buttonStateType.ShowAll)
        "
      >
        {{ 'PRODUCTS.ALL' | translate }}
      </button>
    </div>
  </div>
  <div class="row no-gutters w-100 mt-2">
    <app-table
      *ngIf="isDataTableLoaded"
      [columns]="columns"
      [data]="entityList"
      [totalCount]="activityCount"
      [selectable]="false"
      (pageChange)="onPageChange($event)"
    ></app-table>
  </div>
</navigation>
