import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from './device.service';
import { finalize } from 'rxjs/operators';
import { Device } from './models/device.model';
import { DevicePageable } from './models/device-pageable.model';
import { SnackBarService } from '../../core/services/shared/snackbar.service';
import { ServerResponse } from '../../shared/models/server-response.model';
import { EditRecordResponse } from '../../shared/models/edit-record-response.model';
import { GridSetting } from '../../shared/models/grid-setting.model';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class DevicesComponent implements OnInit {
  storeId: number;
  selectedStoreId: number;
  device: Device;

  currentPage: number = 0;
  pageLimit: number = GridSetting.pageLimit;
  deviceCount: number = 0;
  deviceList: Device[];
  selectedDevice: Device[] = [];

  isDeviceInputGroupVisible: boolean = true;
  isDeviceDetailDisabled: boolean = true;
  devicePostResponse: ServerResponse;
  deviceEditData: EditRecordResponse;
  error: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.router.routeReuseStrategy.shouldReuseRoute = function (): boolean {
      return false;
    };

    this.selectedStoreId = this.activatedRoute.snapshot.params.value;
    this.storeId = this.selectedStoreId;

    this.localStorageService.autenticateContioUser();

    this.getDevices();
  }

  onDeviceTableSelect(device: Device): void {
    this.isDeviceInputGroupVisible = false;
    this.isDeviceDetailDisabled = false;

    this.device = device;
  }

  onNewDeviceClick(isDeviceDetailVisible: boolean): void {
    this.isDeviceInputGroupVisible = isDeviceDetailVisible;
    this.isDeviceDetailDisabled = true;
    this.device = <Device>{
      Name: '',
      Ip: '',
      IsRunning: false,
      ShellyMqttPath: '',
    };
    this.unselectRow();
  }

  onPostDeviceClick(): void {
    this.deviceService
      .post(
        this.storeId,
        this.device.Name,
        this.device.Ip,
        this.device.IsRunning,
        this.device.ShellyMqttPath
      )
      .pipe(
        finalize(() => {
          this.devicePostResponse = null;
        }
        )
      )
      .subscribe({
        next: (successResponse) => {
          this.devicePostResponse = successResponse;
          if (successResponse.success) {
            this.getDevices();
            this.onNewDeviceClick(false);

            this.snackBarService.show('SHOP.ADDDEVSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('SHOP.ADDDEVFAILED', false);
        },
      });
  }

  getDevices(device?: DevicePageable): void {
    if (device != null) {
      this.currentPage = device.offset;
    }

    const nextPage = this.currentPage + 1;
    this.deviceService
      .get(
        this.storeId,
        nextPage,
        GridSetting.pageLimit)
      .subscribe({
        next: (deviceList) => {
          this.deviceList = deviceList;
          this.setDevicePageCount();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onEditDeviceClick(): void {
    this.deviceService
      .put(
        this.storeId,
        this.device.Id,
        this.device.Name,
        this.device.Ip,
        this.device.IsRunning,
        this.device.ShellyMqttPath,
        new Date()
      )
      .pipe(
        finalize(() => {
          this.deviceEditData = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.deviceEditData = successResponse;
          if (successResponse.success) {
            this.getDevices();
            this.onNewDeviceClick(false);

            this.snackBarService.show('SHOP.EDITDEVSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('SHOP.EDITDEVFAILED', false);
        },
      }
      );
  }

  onIsRunningCheckboxChange(): void {
    this.device.IsRunning = !this.device.IsRunning;
  }

  onGoToShopDetailClick(): void {
    this.router.navigate(['/contio-store/detail/' + this.storeId]);
  }

  onFormShowClick(): void {
    this.isDeviceInputGroupVisible = true;
  }

  onFormHideClick(): void {
    this.isDeviceInputGroupVisible = false;
  }

  private setDevicePageCount(): void {
    this.deviceService
      .getPageCount(this.storeId)
      .subscribe({
        next: deviceCount => {
          this.deviceCount = deviceCount[0].deviceCount;

          this.onNewDeviceClick(false);
        },
        error: error => {
          this.error = error;
        },
      });
  }

  private unselectRow(): void {
    this.selectedDevice = [];
  }
}
