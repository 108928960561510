<div class="custom-input">
  <mat-button-toggle-group appearance="legacy">
    <mat-button-toggle
      [ngClass]="{ 'input-error': form.invalid && submit }"
      disabled
    >
      {{ label }}{{ required ? '*' : '' }}
    </mat-button-toggle>

    <mat-form-field
      [ngClass]="{ invalid: submit && !input }"
      [floatLabel]="floatLabelControl.value"
      [hideRequiredMarker]="hideRequiredControl.value"
      novalidate
    >
      <input
        matInput
        [type]="type"
        [min]="min"
        [step]="step"
        [formControl]="form"
        [placeholder]="placeholder"
        [(ngModel)]="input"
        (ngModelChange)="onChange(input)"
      />
      <mat-icon matSuffix class="error" *ngIf="form.invalid && submit"
        >error_outline</mat-icon
      >
    </mat-form-field>
  </mat-button-toggle-group>
  <mat-error *ngIf="form.invalid && submit">{{ errorMessage }}</mat-error>
</div>