<authentication-test
  (success)="authenticationResult($event)"
></authentication-test>
<div (window:resize)="onResize($event)" *ngIf="isAuthentication">
  <navigation selectedPage="products">
    <mat-tab-group
      mat-stretch-tabs
      animationDuration="0ms"
      (selectedTabChange)="onTabChange($event)"
    >
      <!-- Tab 1 -->

      <mat-tab label="{{ 'PRODUCTS.INFORMATION' | translate }}">
        <div class="row no-gutters tab-content">
          <div class="row no-gutters w-100 mt-3">
            <div class="col-sm">
              <button
                type="text"
                class="btn btn-primary new-btn"
                id="addProductInput"
                (click)="onPrepareNewProductClick()"
              >
                <i class="fas fa-plus mr-1"></i>
                {{ 'PRODUCTS.ADDPRODUCT' | translate }}
              </button>

              <div
                class="input-group-prepend float-right div-right-margin"
                *ngIf="!isHidden"
              >
                <div class="btn-group">
                  <button
                    type="button"
                    class="btn btn-secondary bigger-btn"
                    (click)="collapseAll()"
                  >
                    <i class="fas fa-chevron-up"></i>
                  </button>
                </div>
              </div>

              <div
                class="input-group-prepend float-right div-right-margin"
                *ngIf="isHidden"
              >
                <button
                  type="button"
                  class="btn btn-secondary bigger-btn"
                  [hidden]="isChangeDisabled"
                  (click)="expandAll()"
                >
                  <i class="fas fa-chevron-down"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="row no-gutters mt-3 w-100" *ngIf="!isHidden">
            <div
              class="col-lg-4 col-md-12 col-sm-12 pr-xl-3 pr-lg-2 my-col form-group"
              [formGroup]="validatorService.productForm"
            >
              <div class="input-group mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="nameInput">{{
                    'PRODUCTS.PRODUCTNAME' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="product.Text"
                  type="text"
                  class="form-control normal-input"
                  id="nameInput"
                  placeholder="{{ 'PRODUCTS.NAME' | translate }}"
                  aria-describedby="nameInput"
                  formControlName="productName"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted &&
                      validatorService.validate.productName.errors
                  }"
                />
                <div
                  *ngIf="
                    isSubmitted && validatorService.validate.productName.errors
                  "
                  class="invalid-feedback text-right div-right-margin"
                >
                  <div
                    *ngIf="
                      validatorService.validate.productName.errors.required
                    "
                  >
                    {{ 'VALIDATOR.VALIDATION' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-12 col-sm-12 pr-xl-3 px-lg-2 my-col form-group"
              [formGroup]="validatorService.productForm"
            >
              <div class="input-group mb-1" *ngIf="!product.IsDiscount">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="nameInput">{{
                    'PRODUCTS.PRODUCTPRICE' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="product.Price"
                  type="number"
                  class="form-control normal-input"
                  id="priceInput"
                  step="0.01"
                  placeholder="0.00"
                  formControlName="productPrice"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted &&
                      validatorService.validate.productPrice.errors
                  }"
                />
                <div
                  *ngIf="
                    isSubmitted && validatorService.validate.productPrice.errors
                  "
                  class="invalid-feedback text-right div-right-margin"
                >
                  <div
                    *ngIf="
                      validatorService.validate.productPrice.errors.required
                    "
                  >
                    {{ 'VALIDATOR.VALIDATION' | translate }}
                  </div>
                </div>
              </div>

              <div class="input-group mb-1" *ngIf="product.IsDiscount">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="nameInput">{{
                    'PRODUCTS.DISCOUNT' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="product.Discount"
                  type="number"
                  class="form-control normal-input"
                  id="discountInput"
                  step="0.01"
                  placeholder="0.00"
                  formControlName="productDiscount"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      isSubmitted &&
                      validatorService.validate.productDiscount.errors
                  }"
                />
                <div
                  *ngIf="
                    isSubmitted &&
                    validatorService.validate.productDiscount.errors
                  "
                  class="invalid-feedback text-right div-right-margin"
                >
                  <div
                    *ngIf="
                      validatorService.validate.productDiscount.errors.required
                    "
                  >
                    {{ 'VALIDATOR.VALIDATION' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12 pl-xl-3 pl-lg-2 my-col">
              <div class="input-group mb-3 mb-1">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="nameInput">{{
                    'PRODUCTS.SHORTCUT' | translate
                  }}</span>
                </div>
                <input
                  [(ngModel)]="product.Abbreviation"
                  type="text"
                  class="form-control normal-input"
                  id="productShortcut"
                />
              </div>
            </div>

            <div class="row no-gutters w-100 mt-1">
              <div class="col-lg-4 col-md-12 col-sm-12 pr-xl-3 pr-lg-2 my-col">
                <div class="input-group mb-3 mt-1">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="nameInput">{{
                      'PRODUCTS.PRODUCTBARCODE' | translate
                    }}</span>
                  </div>
                  <input
                    [(ngModel)]="product.Barcode"
                    type="text"
                    class="form-control normal-input"
                    id="barcodeInput"
                  />
                </div>
              </div>

              <div
                class="col-lg-4 col-md-12 col-sm-12 pr-xl-3 px-lg-2 my-col overflow-auto"
                [formGroup]="validatorService.productForm"
              >
                <div class="input-group mb-1">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="nameInput">{{
                      'PRODUCTS.PRODUCTPLU' | translate
                    }}</span>
                  </div>
                  <input
                    [(ngModel)]="product.plu"
                    type="number"
                    class="form-control normal-input"
                    id="inpPlu"
                    aria-describedby="inpPlu"
                    formControlName="productPlu"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid':
                        isSubmitted &&
                        validatorService.validate.productPlu.errors
                    }"
                  />
                  <div
                    *ngIf="
                      isSubmitted && validatorService.validate.productPlu.errors
                    "
                    class="invalid-feedback text-right div-right-margin"
                  >
                    <div
                      *ngIf="
                        validatorService.validate.productPlu.errors.required
                      "
                    >
                      {{ 'VALIDATOR.VALIDATION' | translate }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-lg-4 col-md-12 col-sm-12 pl-xl-3 pl-lg-2 my-col mt-1"
              >
                <div
                  class="input-group-prepend input-group justify-content-start justify-content-md-end mb-3"
                >
                  <div class="btn-group">
                    <button type="button" class="btn btn-primary dropdown-tag">
                      <span class="font-weight-normal">
                        {{ 'PRODUCTS.VATRATE' | translate }}
                      </span>
                    </button>
                    <div
                      class="btn-group"
                      *ngIf="isTaxesLoaded"
                      ngbDropdown
                      role="group"
                      placement="bottom-right"
                    >
                      <button
                        class="btn btn-primary dropdown-toggle-primary dropdown-box"
                        id="taxesInput"
                        data-toggle="dropdown"
                        ngbDropdownToggle
                      >
                        <span class="font-weight-normal">{{
                          selectedTax.Name
                        }}</span>
                      </button>
                      <div
                        ngbDropdownMenu
                        class="dropdown-menu"
                        aria-labelledby="dropdownBasic1"
                      >
                        <button
                          *ngFor="let tax of taxes"
                          class="dropdown-item"
                          (click)="changeTax(tax.Id)"
                        >
                          {{ tax.Name }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row no-gutters w-100">
              <div class="col-md-7 col-12 mb-3 mb-md-0">
                <div class="label input-group-prepend float-left">
                  <span class="input-group-text">{{
                    'PRODUCTS.CHOOSEGROUP' | translate
                  }}</span>
                </div>
                <div class="input-group-append ng-autocomplete">
                  <ng-autocomplete
                    [data]="groups"
                    [searchKeyword]="keyword"
                    [(ngModel)]="selectedGroup"
                    (selected)="selectGroup($event)"
                    (inputChanged)="onChangeGroup($event)"
                    (inputCleared)="onGroupCleared()"
                    [itemTemplate]="itemTemplate"
                    [notFoundTemplate]="notFoundTemplate"
                  >
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.Name"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>
                <div class="btn-group bigger-btn ml-0 ml-md-3 mt-3 mt-md-0">
                  <button
                    #addGroupInput
                    type="text"
                    class="btn btn-primary normal-btn add-product-btn"
                    id="addGroupInput"
                    (click)="openModal(content)"
                  >
                    <i class="fas fa-plus mr-1 float-left mt-1"></i>
                    {{ 'PRODUCTS.ADDGROUP' | translate }}
                  </button>
                  <button
                    #addGroupEdit
                    type="text"
                    class="btn btn-primary normal-btn"
                    id="addGroupEdit"
                    (click)="openModalEdit(groupEdit)"
                    [hidden]="!selectedGroup"
                  >
                    {{ 'STORES.EDIT' | translate }}
                  </button>
                </div>
              </div>

              <div class="col-md-5 col-12">
                <div class="row no-gutters justify-content-md-end">
                  <div
                    class="btn-group bigger-btn float-md-right float-left mr-1"
                  >
                    <button type="button" class="btn btn-primary dropdown-tag">
                      <span class="font-weight-normal">
                        {{ 'PRODUCTS.CHOOSEGROUP' | translate }}
                      </span>
                    </button>
                    <div
                      class="btn-group"
                      *ngIf="isUnitLoaded"
                      ngbDropdown
                      role="unit"
                      placement="bottom-right"
                    >
                      <button
                        class="btn btn-primary dropdown-toggle-primary dropdown-box"
                        id="units"
                        data-toggle="dropdown"
                        ngbDropdownToggle
                      >
                        <span class="font-weight-normal">{{
                          selectedUnit.Name
                        }}</span>
                      </button>
                      <div
                        ngbDropdownMenu
                        class="dropdown-menu"
                        aria-labelledby="units"
                      >
                        <button
                          *ngFor="let unit of units"
                          class="dropdown-item"
                          (click)="changeUnit(unit.Id)"
                        >
                          {{ unit.Name }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="btn-group float-md-right">
                    <button
                      #addUnitInput
                      type="text"
                      class="btn btn-primary normal-btn unit-btn"
                      id="addUnitInput"
                      [ngClass]="{
                        'dropdown-invalid':
                          isSubmitted && dropdownValidate && !selectedUnit
                      }"
                      (click)="openModal(contentUnit)"
                    >
                      <i class="fas fa-plus mt-1"></i>
                      {{ 'SKU.SKUUNIT' | translate }}
                    </button>
                    <button
                      #addUnitEdit
                      type="text"
                      class="btn btn-primary normal-btn"
                      id="addUnitEdit"
                      [hidden]="!selectedUnit"
                      (click)="openModalUnitEdit(unitEdit)"
                    >
                      {{ 'STORES.EDIT' | translate }}
                    </button>
                  </div>
                </div>
                <div
                  class="row no-gutters justify-content-md-end"
                  *ngIf="isSubmitted && dropdownValidate && !selectedUnit"
                >
                  <div class="btn-group float-md-right float-left">
                    <button
                      type="button"
                      class="btn btn-primary hidden-btn mr-1"
                      [disabled]="true"
                      style="height: 30px"
                    >
                      <span class="font-weight-normal">
                        {{ 'PRODUCTS.CHOOSEGROUP' | translate }}
                      </span>
                    </button>

                    <span
                      class="help-block invalid-group text-right"
                      style="width: 114px"
                      >{{ 'VALIDATOR.DROPDOWNUNIT' | translate }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <hr class="hr-line" />

            <div class="row no-gutters w-100">
              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 pr-xl-3 pr-lg-2 pr-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.mobileWaiter"
                    class="custom-control-input"
                    id="checkbox-3"
                    checked="product.mobileWaiter"
                  />
                  <label class="custom-control-label" for="checkbox-3">{{
                    'PRODUCTS.MOBILEWAITER' | translate
                  }}</label>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 pl-xl-3 pl-lg-2 pl-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.IsHidden"
                    class="custom-control-input"
                    id="checkbox-4"
                    checked="product.IsHidden"
                  />
                  <label class="custom-control-label" for="checkbox-4">{{
                    'PRODUCTS.ISHIDDEN' | translate
                  }}</label>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 px-xl-3 px-lg-2 pl-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.Kitchen"
                    class="custom-control-input"
                    id="checkbox-10"
                    checked="product.Kitchen"
                  />
                  <label class="custom-control-label" for="checkbox-10">{{
                    'PRODUCTS.ONORDER' | translate
                  }}</label>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 px-xl-3 px-lg-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.IsPackaging"
                    class="custom-control-input"
                    id="checkbox-9"
                    checked="product.IsPackaging"
                  />
                  <label class="custom-control-label" for="checkbox-9">{{
                    'PRODUCTS.RETURNABLE' | translate
                  }}</label>
                </div>
              </div>
            </div>

            <div class="row no-gutters w-100 mt-3">
              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 pr-xl-3 pr-lg-2 pr-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.VariableQuantity"
                    [disabled]="product.VariablePrice || product.IsDiscount"
                    class="custom-control-input"
                    id="checkbox-6"
                    checked="product.VariableQuantity"
                  />
                  <label class="custom-control-label" for="checkbox-6">{{
                    'PRODUCTS.VARIABLEAMOUNT' | translate
                  }}</label>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 pl-xl-3 pl-lg-2 pl-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.VariablePrice"
                    [disabled]="product.VariableQuantity || product.IsDiscount"
                    class="custom-control-input"
                    id="checkbox-7"
                    checked="product.VariablePrice"
                  />
                  <label class="custom-control-label" for="checkbox-7">{{
                    'PRODUCTS.VARIABLEPRICE' | translate
                  }}</label>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 px-xl-3 px-lg-2 pl-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.VariableName"
                    class="custom-control-input"
                    id="checkbox-8"
                    checked="product.VariableName"
                  />
                  <label class="custom-control-label" for="checkbox-8">{{
                    'PRODUCTS.VARIABLENAME' | translate
                  }}</label>
                </div>
              </div>

              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 pl-xl-3 pl-lg-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.IsDiscount"
                    (ngModelChange)="setValidators(product)"
                    class="custom-control-input"
                    id="checkbox-12"
                    checked="product.IsDiscount"
                  />
                  <label class="custom-control-label" for="checkbox-12">{{
                    'PRODUCTS.DISCOUNTITEM' | translate
                  }}</label>
                </div>
              </div>
            </div>

            <div class="row no-gutters w-100 mt-3">
              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 pr-xl-3 pr-lg-2 pr-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.IsRedeemable"
                    class="custom-control-input"
                    id="checkbox-11"
                    checked="product.IsRedeemable"
                  />
                  <label class="custom-control-label" for="checkbox-11">{{
                    'PRODUCTS.VOUCHER' | translate
                  }}</label>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 pl-xl-3 pl-lg-2 pl-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    type="checkbox"
                    [(ngModel)]="product.IsWeighted"
                    class="custom-control-input"
                    id="checkbox-13"
                    checked="product.IsWeighted"
                  />
                  <label class="custom-control-label" for="checkbox-13">{{
                    'PRODUCTS.WEIGHTED' | translate
                  }}</label>
                </div>
              </div>
              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 pl-xl-3 pl-lg-2 pl-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    id="cbProductAdulthood"
                    type="checkbox"
                    [(ngModel)]="product.IsAdulthood"
                    class="custom-control-input"
                    checked="product.IsAdulthood"
                  />
                  <label
                    class="custom-control-label"
                    for="cbProductAdulthood"
                    >{{ 'PRODUCTS.ADULTHOOD' | translate }}</label
                  >
                </div>
              </div>
              <div
                *ngIf="product.IsWeighted"
                class="col-lg-3 col-md-4 col-sm-4 col-xs-6 pl-xl-3 pl-lg-2 pl-sm-2 my-col"
              >
                <div class="custom-control custom-checkbox checkbox-lg">
                  <input
                    id="cbProductSelfcheckoutWeighted"
                    type="checkbox"
                    [(ngModel)]="product.IsSelfcheckoutWeighted"
                    class="custom-control-input"
                    checked="product.IsSelfcheckoutWeighted"
                  />
                  <label
                    class="custom-control-label"
                    for="cbProductSelfcheckoutWeighted"
                    >{{ 'PRODUCTS.SELFCHECKOUTWEIGHT' | translate }}</label
                  >
                </div>
              </div>
            </div>

            <div class="row no-gutters w-100 mt-2">
              <div class="col-sm-12">
                <div class="float-left">
                  <input
                    #uploader
                    type="file"
                    name="file"
                    id="file"
                    class="form-control inputfile"
                    (change)="changeListener($event)"
                    accept=".csv"
                    onclick="value = null"
                  />
                  <button
                    class="btn btn-primary save-btn"
                    (click)="uploader.click()"
                  >
                    <i class="fas fa-upload mr-1"></i>
                    {{ 'PRODUCTS.IMPORTPRODUCT' | translate }}
                  </button>
                </div>

                <button
                  [hidden]="isChangeDisabled"
                  [disabled]="isChangeDisabled"
                  (click)="changeProduct(); onPostChoice()"
                  class="btn btn-primary save-btn float-right"
                  id="saveChanButton"
                >
                  <i class="fas fa-save mr-1"></i>
                  {{ 'PRODUCTS.SAVECHANGES' | translate }}
                </button>
                <div class="float-right">
                  <button
                    [hidden]="isAddProductDisabled"
                    [disabled]="isAddProductDisabled"
                    (click)="postProduct()"
                    class="btn btn-primary save-btn float-right"
                    id="saveProdButton"
                  >
                    <i class="fas fa-save mr-1"></i>
                    {{ 'PRODUCTS.SAVENEWPRODUCT' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="changedProductData" class="w-100">
            <div class="row no-gutters">
              <div class="col-sm-12 pt-2">
                <div
                  *ngIf="changedProductData.success"
                  class="alert alert-success alert-dismissable"
                >
                  <strong>{{ 'PRODUCTS.EDITPRODSUCCESS' | translate }}</strong>
                </div>

                <div
                  *ngIf="!changedProductData.success"
                  class="alert alert-danger alert-dismissable"
                >
                  <strong>{{ 'PRODUCTS.EDITPRODFAILED' | translate }}</strong>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="productPostedData" class="w-100">
            <div class="row no-gutters">
              <div class="col-sm-12 pt-2">
                <div
                  *ngIf="productPostedData.success"
                  class="alert alert-success alert-dismissable"
                >
                  <strong>{{ 'PRODUCTS.ADDPRODSUCCESS' | translate }}</strong>
                </div>

                <div
                  *ngIf="!productPostedData.success"
                  class="alert alert-danger alert-dismissable"
                >
                  <strong>{{ 'PRODUCTS.ADDPRODFAILED' | translate }}</strong>
                </div>
              </div>
            </div>
          </div>

          <hr class="hr-line" />

          <div class="row no-gutters w-100">
            <div class="input-group-prepend ml-md-auto">
              <div class="input-group mt-1">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">
                    {{ 'PRODUCTS.SEARCH' | translate }}</span
                  >
                </div>
                <input
                  id="search"
                  type="search"
                  class="form-control mr-1"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  [(ngModel)]="skuFilter"
                />

                <button
                  type="button"
                  class="btn btn-secondary search-btn mr-1"
                  [class.active]="isButtonActive(1)"
                  (click)="updateFilter(skuFilter); setActive(1)"
                >
                  <i class="fas fa-search"></i>
                </button>

                <button
                  type="button"
                  class="btn btn-secondary search-btn mr-1"
                  [class.active]="isButtonActive(2)"
                  (click)="updateFilter(''); setActive(2)"
                >
                  {{ 'PRODUCTS.ALL' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row no-gutters w-100">
          <div class="col-12" style="height: 700px">
            <ngx-datatable
              #ngx1
              style="cursor: pointer !important"
              class="material"
              [rows]="products"
              [columns]="[
                {
                  prop: 'Text',
                  name: 'PRODUCTS.TABLEPRODUCTNAME' | translate
                },
                { prop: 'Price', name: 'PRODUCTS.PRODUCTPRICE' | translate },
                { prop: 'Unit', name: 'PRODUCTS.PRODUCTUNIT' | translate },
                {
                  prop: 'TaxName',
                  name: 'PRODUCTS.TABLEPRODUCTTAX' | translate
                },
                {
                  prop: 'BatchName',
                  name: 'PRODUCTS.TABLEPRODUCTGROUP' | translate
                }
              ]"
              [headerHeight]="50"
              [footerHeight]="50"
              [columnMode]="'force'"
              [rowHeight]="'auto'"
              [selectionType]="'single'"
              [selected]="selected"
              [selectCheck]="singleSelectCheck"
              [limit]="pageLimit"
              [externalPaging]="true"
              [count]="totalProducts"
              [offset]="currentPage"
              [messages]="{
                emptyMessage: 'PRODUCTS.TABLENODATA' | translate,
                totalMessage: 'PRODUCTS.TABLETOTAL' | translate
              }"
              (activate)="setProductUpdate($event)"
              (page)="getCompanyProducts($event)"
            >
            </ngx-datatable>
          </div>
        </div>
      </mat-tab>

      <!-- MODAL FOR CSV PREVIEW -->
      <ng-template #csv let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title">
            {{ 'PRODUCTS.IMPORTMODALTITLE' | translate }}
          </h4>
          <button type="button" class="close" (click)="c('Close click')">
            <span aria-hidden="false">&times;</span>
          </button>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn normal-btn float-right"
            (click)="
              importProducts();
              !invalidImport && clearImportModal();
              !invalidImport && c('Close click')
            "
          >
            <i class="fas fa-save mr-1"></i>
            {{ 'PRODUCTS.SAVE' | translate }}
          </button>
        </div>

        <div class="modal-footer justify-content-start">
          <div class="mb-3 d-flex flex-column flex-md-row w-100">
            <div class="p-0 w-100 pr-2">
              <app-input-text
                [(input)]="importFilter"
                type="text"
                label="{{ 'PRODUCTS.SEARCH' | translate }}"
                placeholder="{{ 'PRODUCTS.SEARCH' | translate }}"
              ></app-input-text>
            </div>
            <div class="ml-md-auto p-0 mt-2 mt-md-0">
              <div class="btn-group">
                <button
                  class="search-btn"
                  mat-button
                  [class.active]="isFilterActive(1)"
                  (click)="updateImportFilter(importFilter); setActiveFilter(1)"
                >
                  <i class="fas fa-search"></i>
                </button>

                <button
                  class="search-btn ml-1"
                  mat-button
                  [class.active]="isFilterActive(2)"
                  (click)="
                    updateImportFilter('');
                    clearImportModal();
                    setActiveFilter(2)
                  "
                >
                  {{ 'PRODUCTS.ALL' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div
            *ngIf="invalidImport"
            class="alert alert-danger alert-dismissable mb-3 text-center w-100"
          >
            <strong>{{ 'VALIDATOR.FIELDISEMPTY' | translate }}</strong>
          </div>

          <div class="table-responsive-md w-100 d-block d-md-table">
            <table class="table table-bordered table-hover">
              <tr>
                <th>
                  <input
                    type="checkbox"
                    class="checkboxTableTitle"
                    [checked]="isAllChecked()"
                    (change)="checkAll($event)"
                  />
                </th>
                <th class="th-plu">{{ 'PRODUCTS.PRODUCTPLU' | translate }}</th>
                <th class="th-name">{{ 'STOCK.NAME' | translate }}</th>
                <th>{{ 'STOCK.UNIT' | translate }}</th>
                <th>{{ 'PRODUCTS.TABLEPRODUCTGROUP' | translate }}</th>
              </tr>

              <ng-container
                *ngFor="
                  let record of csvRecords | filter: searchedProduct;
                  let i = index
                "
              >
                <tbody>
                  <tr id="row{{ i + 1 }}">
                    <td>
                      <input
                        type="checkbox"
                        class="checkboxTable"
                        [(ngModel)]="record.checked"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': !record[11]
                        }"
                        [(ngModel)]="record[11]"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        [ngClass]="{
                          'is-invalid': !record[0]
                        }"
                        [(ngModel)]="record[0]"
                      />
                    </td>
                    <td>
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-primary dropdown-tag"
                        >
                          <span class="font-weight-normal">
                            {{ 'PRODUCTS.CHOOSEGROUP' | translate }}
                          </span>
                        </button>
                        <div
                          class="btn-group"
                          *ngIf="isUnitLoaded"
                          ngbDropdown
                          role="unit"
                          placement="bottom-right"
                        >
                          <button
                            class="btn btn-primary dropdown-toggle-primary dropdown-box"
                            [ngClass]="{
                              'dropdown-invalid': record[6] == null
                            }"
                            id="units"
                            data-toggle="dropdown"
                            ngbDropdownToggle
                          >
                            <span class="font-weight-normal">{{
                              record[6]
                            }}</span>
                          </button>
                          <div
                            ngbDropdownMenu
                            class="dropdown-menu"
                            aria-labelledby="units"
                          >
                            <button
                              *ngFor="let unit of units"
                              class="dropdown-item"
                              (click)="changeUnitImport(unit.Id, i)"
                            >
                              {{ unit.Name }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-primary dropdown-tag"
                        >
                          <span class="font-weight-normal">
                            {{ 'PRODUCTS.CHOOSEGROUP' | translate }}
                          </span>
                        </button>
                        <div
                          class="btn-group"
                          *ngIf="isGroupsLoaded"
                          ngbDropdown
                          role="group"
                          placement="bottom-left"
                        >
                          <button
                            class="btn btn-primary dropdown-toggle-primary dropdown-box"
                            [ngClass]="{
                              'dropdown-invalid': record[2] == null
                            }"
                            id="groups"
                            data-toggle="dropdown"
                            ngbDropdownToggle
                          >
                            <span class="font-weight-normal">{{
                              record[2]
                            }}</span>
                          </button>
                          <div
                            ngbDropdownMenu
                            class="dropdown-menu"
                            aria-labelledby="groups"
                          >
                            <button
                              *ngFor="let group of groups"
                              class="dropdown-item"
                              (click)="changeGroupImport(group.Id, i)"
                            >
                              {{ group.Name }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </ng-container>
            </table>
          </div>

          <div class="col-12 text-center">
            <div
              *ngIf="!csvLoading"
              class="spinner-border mt-3 mb-2"
              role="status"
              style="width: 3rem; height: 3rem"
            ></div>
          </div>
        </div>
      </ng-template>

      <mat-tab
        class="tab-content"
        label="{{ 'PRODUCTS.INGREDIENTS' | translate }}"
        [disabled]="isChangeDisabled"
      >
        <div
          *ngIf="itemPosted"
          class="alert alert-danger alert-dismissable mt-3 text-center"
        >
          <p>
            <strong>{{ 'VALIDATOR.ITEMPOSTED' | translate }}</strong>
          </p>
          <p>{{ 'VALIDATOR.ITEMPOSTEDANNOTATION' | translate }}</p>
        </div>

        <hr *ngIf="itemPosted" class="hr-line" />

        <div class="row no-gutters my-row mt-3 w-100">
          <div class="col-sm">
            <div class="input-group-prepend float-left">
              <h4 class="mt-2" style="color: #5b6c5d">
                {{ 'PRODUCTS.INGREDIENTNAME' | translate }}
                {{ ingredientTitle }}
              </h4>
            </div>
          </div>
          <div class="col-sm">
            <div class="input-group-prepend float-right">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-tag w-auto"
                >
                  <span class="font-weight-normal">
                    {{ 'STORES.WAREHOUSE' | translate }}
                  </span>
                </button>
                <div
                  class="btn-group"
                  *ngIf="isWarehouseLoaded"
                  placement="bottom-right"
                  ngbDropdown
                >
                  <button
                    class="btn btn-primary dropdown-toggle-primary dropdown-box"
                    id="warehouses"
                    data-toggle="dropdown"
                    ngbDropdownToggle
                  >
                    <span class="font-weight-normal">{{
                      selectedWarehouse.Name
                    }}</span>
                  </button>
                  <div
                    ngbDropdownMenu
                    class="dropdown-menu"
                    aria-labelledby="warehouses"
                  >
                    <button
                      *ngFor="let warehouse of warehouses"
                      class="dropdown-item"
                      (click)="changeWarehouse(warehouse.Id)"
                    >
                      {{ warehouse.Name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="hr-line" />

        <div class="row no-gutters my-row w-100 mt-1" *ngIf="!isHidden">
          <div
            class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pr-xl-3 pr-lg-2 mb-2 my-col"
          >
            <div class="input-group-prepend">
              <span class="input-group-text" id="spInvoiceNo">{{
                'SKU.SKU' | translate
              }}</span>

              <div
                class="ng-autocomplete w-100"
                [ngClass]="{
                  'autocomplete-invalid':
                    isIngredientSubmitted && autocompleteValidate
                }"
              >
                <ng-autocomplete
                  [data]="records"
                  [searchKeyword]="keyword"
                  [placeHolder]="itemPlaceholder"
                  [(ngModel)]="selectedRecord"
                  (selected)="selectEvent($event)"
                  (inputChanged)="onChangeSearch($event)"
                  (inputFocused)="onFocused($event)"
                  (inputCleared)="onInputCleared()"
                  [itemTemplate]="itemTemplate"
                  [notFoundTemplate]="notFoundTemplate"
                >
                </ng-autocomplete>

                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.Name"></a>
                </ng-template>

                <ng-template #notFoundTemplate let-notFound>
                  <div [innerHTML]="notFound"></div>
                </ng-template>
              </div>
            </div>
            <span
              class="help-block invalid-name"
              *ngIf="isIngredientSubmitted && autocompleteValidate"
              >{{ 'VALIDATOR.VALIDATION' | translate }}</span
            >
          </div>

          <div
            class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pl-xl-3 pl-lg-2 mt-lg-0 mt-md-2 mt-sm-2 my-col form-group mb-3"
            [formGroup]="validatorService.ingredientForm"
          >
            <div class="input-group">
              <div class="input-group-prepend my-form">
                <h2 class="input-group-text" id="nameInput">
                  {{ 'PRODUCTS.COEFFICIENT' | translate }}
                </h2>
                <h2 class="input-group-text" id="nameInput" *ngIf="hasUnit">
                  [ {{ ingredientUnit }} ]
                </h2>
              </div>
              <input
                [(ngModel)]="coefficient"
                type="text"
                class="form-control normal-input"
                id="ingredientCoefficient"
                placeholder="0.00"
                formControlName="ingredientCoefficient"
                class="form-control my-form"
                [ngClass]="{
                  'is-invalid':
                    isIngredientSubmitted &&
                    validatorService.validateForm.ingredientCoefficient.errors
                }"
              />
              <div
                *ngIf="
                  isIngredientSubmitted &&
                  validatorService.validateForm.ingredientCoefficient.errors
                "
                class="invalid-feedback float-right div-right-margin"
              >
                <div
                  *ngIf="
                    validatorService.validateForm.ingredientCoefficient.errors
                      .required
                  "
                >
                  {{ 'VALIDATOR.VALIDATION' | translate }}
                </div>
              </div>
            </div>
          </div>

          <hr class="hr-line" />

          <div class="row no-gutters my-row w-100">
            <div class="col-sm-12 text-center my-col">
              <button
                (click)="deleteRecord()"
                class="btn btn-danger float-right"
                id="btnDeleteRecord"
                [disabled]="itemPosted"
              >
                <i class="fas fa-trash-alt mr-1"></i>
                {{ 'STOCK.DELETERECORD' | translate }}
              </button>
              <button
                (click)="addRecord()"
                class="btn btn-primary new-btn float-right mr-1"
                id="btnAddRecord"
                [hidden]="ingredientEdit"
                [disabled]="itemPosted"
              >
                <i class="fas fa-plus mr-1"></i>
                {{ 'STOCK.ADDRECORD' | translate }}
              </button>
              <button
                (click)="saveEdit()"
                class="btn btn-primary save-btn float-right mr-1"
                id="btnSaveDocument"
                [hidden]="!ingredientEdit"
                [disabled]="itemPosted"
              >
                <i class="fas fa-save mr-1"></i>
                Update
              </button>
            </div>
          </div>
        </div>

        <div class="row no-gutters my-row w-100">
          <div class="col-sm my-col" style="height: 700px">
            <ngx-datatable
              #datatable
              style="cursor: pointer !important"
              class="material"
              [rows]="ingredients"
              [count]="ingredientsCount"
              [limit]="10"
              [columns]="[
                { prop: 'Name', name: 'STOCK.NAME' | translate },
                {
                  prop: 'Coefficient',
                  name: 'PRODUCTS.COEFFICIENT' | translate
                },
                {
                  prop: 'Unit',
                  name: 'PRODUCTS.PRODUCTUNIT' | translate
                }
              ]"
              [headerHeight]="50"
              [footerHeight]="50"
              [columnMode]="'force'"
              [rowHeight]="'auto'"
              [selectionType]="'single'"
              [selected]="selected"
              [selectCheck]="singleSelectCheck"
              [messages]="{
                emptyMessage: 'PRODUCTS.TABLENODATA' | translate,
                totalMessage: 'PRODUCTS.TABLETOTAL' | translate
              }"
              (activate)="prepareUpdateRecord($event)"
              (select)="onSelectRecord($event)"
            >
            </ngx-datatable>
          </div>
        </div>
      </mat-tab>
      <mat-tab
        class="tab-content"
        label="{{ 'PICTURE.PICTURE' | translate }}"
        [disabled]="isChangeDisabled"
      >
        <app-image-upload
          #imageUploadComponent
          [image]="productImage"
          (imageBase64)="onImageSelect($event)"
        ></app-image-upload>
      </mat-tab>
    </mat-tab-group>
  </navigation>

  <!-- MODAL FOR ADDING GROUP -->
  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'PRODUCTS.ADDGROUP' | translate }}</h4>
    </div>
    <div class="modal-body">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="nameInput">{{
            'MODAL.NAME' | translate
          }}</span>
        </div>
        <input
          #groupName
          [(ngModel)]="grName"
          type="text"
          class="form-control"
          placeholder="{{ 'PRODUCTS.MODALWRITENAME' | translate }}"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="groupClear(); c('Close click')"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>
      <button
        type="button"
        class="btn save-btn"
        [disabled]="!grName"
        (click)="postGroup(groupName.value); c('Close click')"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVE' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- MODAL FOR EDITING  GROUP -->
  <ng-template #groupEdit let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">
        {{ selectedGroup.Name }}
      </h4>
      <button
        class="btn btn-danger float-right"
        type="button"
        [hidden]="true"
        (click)="openModalDelete(modalDelete); c('Close click')"
      >
        <i class="fas fa-trash-alt mr-1"></i>
        {{ 'PRODUCTS.DELETEGROUP' | translate }}
      </button>
    </div>

    <div class="modal-body">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="nameInput">{{
            'MODAL.NAME' | translate
          }}</span>
        </div>
        <input
          #groupNameEdit
          [(ngModel)]="grNameEdit"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="c('Close click')"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>

      <button
        type="button"
        class="btn save-btn"
        (click)="putGroupEdit(groupNameEdit.value); c('Close click')"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVE' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- MODAL FOR DELETE WINDOW -->
  <ng-template #modalDelete let-c="close" let-d="dismiss">
    <div class="ng-template-padding">
      <div class="modal-header text-center">
        <span style="font-size: large"
          >{{ 'PRODUCTS.DIALOG' | translate }} '{{ selectedGroup.Name }}'?</span
        >
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="openModalEdit(groupEdit); c('Close click')"
        >
          {{ 'PRODUCTS.CLOSE' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="onGroupDelete(); c('Close click')"
        >
          {{ 'PRODUCTS.DELETEGROUP' | translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <!-- ADD UNIT -->
  <ng-template #contentUnit let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ 'SKU.SKUUNIT' | translate }}</h4>
    </div>
    <div class="modal-body">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="nameInput">{{
            'MODAL.NAME' | translate
          }}</span>
        </div>
        <input
          #unitName
          [(ngModel)]="unName"
          type="text"
          class="form-control"
          placeholder="{{ 'SKU.MODALWRITEUNIT' | translate }}"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="groupClear(); c('Close click')"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>
      <button
        type="button"
        class="btn normal-btn"
        [disabled]="!unName"
        (click)="postUnit(unitName.value); c('Close click')"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVE' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- MODAL FOR EDITING  UNIT -->
  <ng-template #unitEdit let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title">{{ selectedUnit.Name }}</h4>
      <button
        class="btn btn-danger text-right"
        type="button"
        [hidden]="true"
        (click)="openModalUnitDelete(modalUnitDelete); c('Close click')"
      >
        <i class="fas fa-trash-alt mr-1"></i>
        {{ 'PRODUCTS.DELETEGROUP' | translate }}
      </button>
    </div>
    <div class="modal-body">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="nameInput">{{
            'MODAL.NAME' | translate
          }}</span>
        </div>
        <input
          #unitNameEdit
          [(ngModel)]="unNameEdit"
          type="text"
          class="form-control"
        />
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="c('Close click')"
      >
        {{ 'PRODUCTS.CLOSE' | translate }}
      </button>

      <button
        type="button"
        class="btn save-btn"
        (click)="putUnitEdit(unitNameEdit.value); getRecord(); c('Close click')"
      >
        <i class="fas fa-save mr-1"></i>
        {{ 'PRODUCTS.SAVE' | translate }}
      </button>
    </div>
  </ng-template>

  <!-- MODAL FOR UNIT DELETE WINDOW -->
  <ng-template #modalUnitDelete let-c="close" let-d="dismiss">
    <div style="padding-top: 3%">
      <div class="modal-header text-center">
        <span style="font-size: large"
          >{{ 'SKU.UNITDIALOG' | translate }} '{{ selectedUnit.Name }}'?</span
        >
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          (click)="openModalEdit(unitEdit); c('Close click')"
        >
          {{ 'REGISTRATION.BACK' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="onUnitDelete(); c('Close click')"
        >
          <i class="fas fa-trash-alt mr-1"></i>
          {{ 'PRODUCTS.DELETEGROUP' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
