import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateService } from '../../../core/services/formatter/ngb-date.service';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { SkuService } from '../../../core/services/sku/sku.service';
import { ValidatorService } from '../../../core/services/validator/validator.service';
import { Warehouse } from '../models/warehouse.model';
import { CreateRecordResponse } from '../../../shared/models/create-record-response.model';
import { EditRecordResponse } from '../../../shared/models/edit-record-response.model';
import { LocalStorageTags } from '../../../core/enums/local-storage-tags.enum';
import { finalize } from 'rxjs/operators';
import { WarehouseService } from '../warehouse.service';
import { DateTimeService } from '../../../core/services/formatter/date-time.service';
import { InputDialogComponent } from '../../../shared/dialogs/input-dialog/input-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubaccountValue } from '../../subaccounts/subaccount-value.enum';

@Component({
  selector: 'app-company-settings-warehouse',
  templateUrl: './company-settings-warehouse.component.html',
  styleUrls: ['../../../../assets/scss/company-settings.scss', './company-settings-warehouse.component.scss'],
})
export class CompanySettingsWarehouseComponent implements OnInit {
  @Input() companyPrivilege: string;
  @Input() isWarehouseEdit: boolean;
  @Input() selectedWarehouse: Warehouse;
  @Output() setSelectedWarehouse: EventEmitter<Warehouse> = new EventEmitter<Warehouse>();

  readonly ngbDatepickerPlacement: string = 'bottom-right';
  readonly minDate: Partial<NgbDate> = {
    year: 2000,
    month: 1,
    day: 1,
  };
  maxDate: NgbDate;
  inceptionDate: NgbDate;

  privilegeValue: any = SubaccountValue;

  warehouseList: Warehouse[];
  selectedWarehouseName: string;
  productCount: number;
  warehouseConvertedStartDate: string;
  isWarehouseValidationVisible: boolean = false;
  isWarehouseLoaded: boolean;

  readonly warehouseInput: string = '';
  updateDialog: MatDialogRef<InputDialogComponent>;

  warehouseServerResponse: CreateRecordResponse;
  warehouseEditResponse: EditRecordResponse;

  error: string;

  constructor(
    private warehouseService: WarehouseService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private validatorService: ValidatorService,
    private dateTimeService: DateTimeService,
    private skuService: SkuService,
    private unixBoundsService: NgbDateService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit(): void {
    this.setWarehouseList();
    this.validatorService.warehouseValidator();
    this.setMaxDate();
  }

  onChangeWarehouseClick(warehouseName?: string): void {
    if (warehouseName) {
      this.selectedWarehouse = this.warehouseList.find(warehouse => warehouse.Name = warehouseName);
    }

    this.isWarehouseValidationVisible = false;

    this.formatWarehouseInitDate();
    this.setSelectedWarehouse.emit(this.selectedWarehouse);
  }

  onWarehousePostClick(
    warehouseName: string,
    startDate: NgbDate
  ): void {
    this.isWarehouseValidationVisible = true;

    if (this.validatorService.warehouseForm.invalid) {
      return;
    }

    let startDateEdited;
    if (startDate) {
      startDateEdited = this.dateTimeService.convertNgbDateToUnixTimestamp(startDate, false);
    } else {
      startDateEdited = null;
    }

    const companyId = localStorage.getItem(LocalStorageTags.CompanyId);

    this.warehouseService
      .post(
        warehouseName,
        startDateEdited,
        companyId
      )
      .pipe(
        finalize(() => {
          this.warehouseServerResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.warehouseServerResponse = successResponse;
          if (successResponse.success) {
            this.setWarehouseList();
            this.onChangeWarehouseClick(warehouseName);

            this.isWarehouseValidationVisible = false;
            this.inceptionDate = null;

            this.snackBarService.show('WAREHOUSE.WAREHOUSEPOSTSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('WAREHOUSE.WAREHOUSEPOSTFAIL', false);
        },
      });
  }

  onWarehouseEditClick(warehouseName: string): void {
    this.isWarehouseValidationVisible = true;

    this.warehouseService
      .put(
        this.selectedWarehouse.CompanyId,
        this.selectedWarehouse.Id,
        warehouseName
      )
      .pipe(
        finalize(() => {
          this.warehouseEditResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.warehouseEditResponse = successResponse;
          if (successResponse.success) {
            this.setWarehouseList();
            this.onChangeWarehouseClick(warehouseName);

            this.snackBarService.show('WAREHOUSE.WAREHOUSEEDITSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('WAREHOUSE.WAREHOUSEEDITFAIL', false);
        },
      });
  }

  onWarehouseDeleteClick(): void {
    this.hasWarehouseAssignedStorageCards()
      .then(
        (isSkuForWarehouseExist) => {
          this.deleteWarehouse(isSkuForWarehouseExist);
        });
  }

  onCalendarClearClick(): void {
    this.inceptionDate = null;
  }

  resetAddValidator(): void {
    this.isWarehouseValidationVisible = false;
  }


  onAddWarehouseModalOpenClick(warehouseAdd: string): void {
    this.modalService.open(warehouseAdd, {
      windowClass: 'dark-modal',
      keyboard: false,
      backdrop: 'static',
    });
  }

  onOpenWarehouseEditDialogClick(): void {
    this.updateDialog = this.dialog.open(InputDialogComponent, {
      data: {
        title: this.selectedWarehouse.Name,
        value: this.selectedWarehouse.Name,
        isEdit: true,
        isDelete: true,
      },
    });
    this.updateDialog.afterClosed().subscribe((input) => {
      if (!input) {
        return;
      }

      if (input === true) {
        this.onWarehouseDeleteClick();
      } else {
        this.onWarehouseEditClick(input);
      }
    });
  }

  private setWarehouse(): void {
    if (this.warehouseList.length === 0) {
      this.isWarehouseLoaded = false;
      return;
    }

    if (!this.selectedWarehouse) {
      this.selectedWarehouse = this.warehouseList[0];
      this.formatWarehouseInitDate();
      this.setSelectedWarehouse.emit(this.selectedWarehouse);
    }

    this.isWarehouseLoaded = true;
  }

  private hasWarehouseAssignedStorageCards(): Promise<boolean> {
    let hasWarehouseAssignedStorageCards = false;

    return new Promise((resolve) => {
      this.skuService
        .getPageCount(
          this.selectedWarehouse.Id,
          ''
        )
        .subscribe({
          next: (skuCount) => {
            this.productCount = skuCount[0].product_count;
            if (this.productCount > 0) {
              hasWarehouseAssignedStorageCards = true;
            }
            resolve(hasWarehouseAssignedStorageCards);
          },
          error: (error) => {
            this.error = error;
          },
        });
    });
  }

  private deleteWarehouse(hasWarehouseAssignedStorageCards: boolean): void {
    if (hasWarehouseAssignedStorageCards) {
      this.snackBarService.show('VALIDATOR.ASSIGNEDSKU', false);
      return;
    }

    this.warehouseService
      .delete(
        this.selectedWarehouse.CompanyId,
        this.selectedWarehouse.Id
      )
      .pipe(
        finalize(() => {
          this.warehouseServerResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.warehouseServerResponse = successResponse;
          if (successResponse.success) {
            this.setWarehouseList(true);

            this.snackBarService.show('WAREHOUSE.WAREHOUSEDELETESUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('WAREHOUSE.WAREHOUSEDELETEFAIL', false);
        },
      });
  }

  private setWarehouseList(canDeleteAfterLoad?: boolean): void {
    this.warehouseService
      .getList()
      .subscribe({
        next: (warehouseList) => {
          this.warehouseList = warehouseList;
          if (canDeleteAfterLoad) {
            this.selectedWarehouse = null;
          }
          this.setWarehouse();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  private setMaxDate(): void {
    this.maxDate = this.unixBoundsService.ngbDateMax();
  }

  private formatWarehouseInitDate(): void {
    if (this.selectedWarehouse.InitDate) {
      this.warehouseConvertedStartDate = new Date(
        this.selectedWarehouse.InitDate
      ).toLocaleDateString();
    } else {
      this.warehouseConvertedStartDate = null;
    }
  }
}
