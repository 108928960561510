import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { SubaccountValue } from '../subaccount-value.enum';
import { SubaccountRoute } from '../subaccount-route.enum';
import { SubaccountService } from '../subaccount.service';
import { Subaccount } from '../models/subaccount.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../../../core/services/shared/snackbar.service';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { Privilege } from '../models/privilege.model';
import { TwilioServerResponse } from '../../twilio/models/twilio-server-response.model';
import { SubaccountIndex } from '../subaccount-index.enum';
import { DropdownType } from '../../../shared/core-components/dropdown/dropdown-type.enum';
import { LocalStorageTags } from '../../../core/enums/local-storage-tags.enum';

@Component({
  selector: 'app-subaccount-detail',
  templateUrl: './subaccount-detail.component.html',
  styleUrls: ['./subaccount-detail.component.scss'],
})
export class SubaccountDetailComponent implements OnInit {
  dropdownType: any = DropdownType;
  userId: number;
  subaccountId: number;

  subaccount: Subaccount;
  privilegeList: Privilege[];
  priviligeType: Privilege[];

  companyPrivilege: Privilege;
  contioPrivilege: Privilege;
  loyaltyCardPrivilege: Privilege;
  reportPrivilege: Privilege;
  userPrivilege: Privilege;

  putResponse: TwilioServerResponse;
  error: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private subaccountService: SubaccountService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.userId = this.localStorageService.getUserId();
    this.subaccountId = this.activatedRoute.snapshot.params.value;

    this.priviligeType = <Privilege[]>[
      {
        Value: SubaccountValue.NoAccess,
      },
      {
        Value: SubaccountValue.Read,
      },
      {
        Value: SubaccountValue.ReadWrite,
      },
    ];

    this.setSubaccountDetail();
  }

  onGoToUserListClick(): void {
    this.router.navigate(['/' + SubaccountRoute.SubaccountList]);
  }

  onPrivilegeSaveClick(): void {
    this.privilegeList[SubaccountIndex.Company].Value = this.companyPrivilege.Value;
    this.privilegeList[SubaccountIndex.Contio].Value = this.contioPrivilege.Value;
    this.privilegeList[SubaccountIndex.LoyaltyCard].Value = this.loyaltyCardPrivilege.Value;
    this.privilegeList[SubaccountIndex.Reports].Value = this.reportPrivilege.Value;
    this.privilegeList[SubaccountIndex.Users].Value = this.userPrivilege.Value;

    this.subaccountService
    .putPrivilige(
      this.privilegeList
    )
    .subscribe({
      next: (successResponse) => {
        this.putResponse = successResponse;

        localStorage.setItem(LocalStorageTags.Privilege, JSON.stringify(this.privilegeList));

        this.showSnackBar(true);
      },
      error: (error) => {
        this.error = error;

        this.showSnackBar(false);
      },
    });
  }

  private setSubaccountDetail(): void {
    this.subaccountService
    .get(
      this.subaccountId
    )
    .subscribe({
      next: (subaccount) => {
        this.subaccount = subaccount;
        this.privilegeList = subaccount.PrivilegeList;

        this.setPrivilege();
      },
      error: (error) => {
        this.error = error;
      },
    });
  }

  private setPrivilege(): void {
    this.companyPrivilege = <Privilege>{
      Value: this.privilegeList[SubaccountIndex.Company].Value,
    };
    this.contioPrivilege = <Privilege>{
      Value: this.privilegeList[SubaccountIndex.Contio].Value,
    };
    this.loyaltyCardPrivilege = <Privilege>{
      Value: this.privilegeList[SubaccountIndex.LoyaltyCard].Value,
    };
    this.reportPrivilege = <Privilege>{
      Value: this.privilegeList[SubaccountIndex.Reports].Value,
    };
    this.userPrivilege = <Privilege>{
      Value: this.privilegeList[SubaccountIndex.Users].Value,
    };
  }

  private showSnackBar(success: boolean): void {
    if (success) {
      this.snackBarService.show(this.translateService.instant('SHOP.PRIVILEGEEDITSUCCESS'), true);
      return;
    }

    this.snackBarService.show(this.translateService.instant('SHOP.PRIVILEGEEDITFAIL'), false);
  }
}
