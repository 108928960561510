<authentication-test></authentication-test>
<navigation selectedPage="twilio">
  <div class="initial-row w-100">
    <button
      class="normal-btn"
      mat-button
      (click)="onSetNewTwilioClick(!areInputElementsVisible)"
    >
      <i *ngIf="areInputElementsVisible" class="fas fa-plus"></i>
      <i *ngIf="!areInputElementsVisible" class="fas fa-minus"></i>
      <span class="icon-name">{{ 'PRODUCTS.ADDPRODUCT' | translate }}</span>
    </button>
  </div>

  <div class="content" *ngIf="!areInputElementsVisible">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <div fxFlex="100%" fxFlex.gt-md="50%">
        <app-input-text
          [(input)]="selectedTwilio.Name"
          [submit]="isSubmit"
          type="text"
          label="{{ 'MODAL.NAME' | translate }}"  
          placeholder="{{ 'PRODUCTS.NAME' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
        ></app-input-text>
      </div>
      <div fxFlex="100%" fxFlex.gt-md="50%">
        <app-input-date
          [(input)]="selectedTwilio.CreateDateTime"
          [submit]="isSubmit"
          label="{{ 'SALES.TABLEDATE' | translate }}"  
          placeholder="{{ 'SALES.TABLEDATE' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
          [useMaterial]="false"
        ></app-input-date>
      </div>
      <div fxFlex="100%" fxFlex.gt-md="33.333333%">
        <app-input-text
          [(input)]="selectedTwilio.TwilioAccountSid"
          [submit]="isSubmit"
          type="text"
          label="{{ 'TWILIO.ACCOUNTID' | translate }}"  
          placeholder="{{ 'TWILIO.ACCOUNTID' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
        ></app-input-text>
      </div>
      <div fxFlex="100%" fxFlex.gt-md="33.333333%">
        <app-input-text
          [(input)]="selectedTwilio.TwilioAuthToken"
          [submit]="isSubmit"
          type="text"
          label="{{ 'TWILIO.AUTHTOKEN' | translate }}"  
          placeholder="{{ 'TWILIO.AUTHTOKEN' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
        ></app-input-text>
      </div>
      <div fxFlex="100%" fxFlex.gt-md="33.333333%">
        <app-input-text
          *ngIf="!selectedTwilio.IsSmsService"
          [(input)]="selectedTwilio.TwilioAppService"
          [submit]="isSubmit"
          type="text"
          label="{{ 'TWILIO.APPSERVICE' | translate }}"  
          placeholder="{{ 'TWILIO.APPSERVICE' | translate }}"
          errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
          [required]="true"
        ></app-input-text>
        <app-input-text
        *ngIf="selectedTwilio.IsSmsService"
        [(input)]="selectedTwilio.Phone"
        [submit]="isSubmit"
        type="text"
        label="{{ 'STORES.COMPANYPHONE' | translate }}"  
        placeholder="+420xxxxxxxxx"
        errorMessage="{{ 'VALIDATOR.VALIDATION' | translate }}"
        [required]="true"
      ></app-input-text>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="33.333333%">
        <app-dropdown
          *ngIf="selectedContioStore"
          [(selected)]="selectedContioStore"
          label="{{ 'TWILIO.CONTIOSHOP' | translate }}:"
          itemKey="Name"
          [items]="contioStoreList"
          (selectedChange)="onSetContioStoreChange($event.Id)"
        ></app-dropdown>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="33.333333%">
        <app-checkbox
          label="{{ 'STORES.ISAVAILABLE' | translate }}"
          labelPosition="after"
          [checked]="selectedTwilio.IsActive"
          (checkedChange)="onCheckChange($event)"
        ></app-checkbox>
      </div>
      <div fxFlex="100%" fxFlex.gt-lg="33.333333%">
        <app-checkbox
          label="{{ 'TWILIO.ISSMSSERVICE' | translate }}"
          labelPosition="after"
          [checked]="selectedTwilio.IsSmsService"
          (checkedChange)="onCheckSmsChange($event)"
        ></app-checkbox>
      </div>
      <div fxFlex="100%" fxLayoutAlign="end">
        <button class="save-btn" mat-button (click)="onTwilioSaveClick(isEdit)">
          <i class="fas fa-save"></i>
          <span *ngIf="!isEdit" class="icon-name">{{ 'STORES.NEW' | translate }}</span>
          <span *ngIf="isEdit" class="icon-name">{{ 'PRODUCTS.SAVE' | translate }}</span>
        </button>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>
  <mat-progress-bar
    *ngIf="!isDataTableLoaded"
    mode="indeterminate"
  ></mat-progress-bar>
  <app-table
    *ngIf="isDataTableLoaded"
    [activeRow]="selectedTwilio"
    [columns]="columns"
    [data]="entityList"
    [totalCount]="twilioCount"
    [currentPage]="currentPage"
    [selectable]="true"
    (selectedRow)="onRowSelect($event)"
    (pageChange)="onPageChange($event)"
  ></app-table>
</navigation>
