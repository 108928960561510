<authentication-test
  (success)="authenticationResult($event)"
></authentication-test>
<div *ngIf="isAuthentication">
  <navigation selectedPage="sales">
    <div *ngIf="detailViewData">
      <div class="row no-gutters">
        <div class="col-10 page-header">
          {{ 'SALES.DETAIL' | translate }}
          <span class="font-weight-bold">
            {{ detailViewData[0].InternalNo }}
          </span>
        </div>

        <div class="col-2">
          <button
            type="button"
            class="btn btn-primary normal-btn float-right"
            (click)="back()"
          >
            {{ 'REGISTRATION.BACK' | translate }}
          </button>
        </div>
      </div>
    </div>

    <hr class="line">

    <div
      class="sales-detail pl-3 pr-3"
    >
      <div class="text-center" *ngIf="detailLoaded">
        <!-- eKasa header -->
        <div
          class="header pt-4"
          *ngIf="headerDataLoaded && detailViewData[0].EK_SecureCode"
        >
          {{ detailViewData[0].EK_Company }}<br />
          {{ 'SALES.TIN' | translate }}: {{ detailViewData[0].Tin }}<br />
          {{ 'SALES.BIN' | translate }}: {{ detailViewData[0].Bin }}<br />
          {{ 'SALES.SKVATID' | translate }}: {{ detailViewData[0].EK_SkVatId }}
          <br />
          {{ 'SALES.DKP' | translate }}: {{ detailViewData[0].EK_DKP }} <br />
          {{ 'SALES.COMP_ADDRESS' | translate }}:
          {{ detailViewData[0].EK_CompanyAddress }}<br />
          {{ 'SALES.STORE_ADDRESS' | translate }}:
          {{ detailViewData[0].EK_StoreAddress }}<br />

          {{ 'SALES.TABLEINTERNALNO' | translate }}:
          {{ detailViewData[0].InternalNo }}<br />
          <hr class="sales-detail-hr" />
        </div>

        <!-- EET  header -->
        <div
          class="header pt-4"
          *ngIf="headerDataLoaded && !detailViewData[0].EK_SecureCode"
        >
          {{ headerData[0].Name }}<br />
          {{ headerData[0].Street }} {{ headerData[0].City }}<br />
          {{ 'SALES.BIN' | translate }}: {{ headerData[0].Bin }}
          <div class="d-inline" *ngIf="headerData[0].Tin">
            {{ 'SALES.TIN' | translate }}: {{ headerData[0].Tin }}
          </div>
          <br />
          {{ 'SALES.TABLEINTERNALNO' | translate }}:
          {{ detailViewData[0].InternalNo }}
          <hr class="sales-detail-hr" />
        </div>

        <div class="row font-weight-bold">
          <div class="col-sm-2">
            {{ 'SALES.PRODUCT' | translate }}
          </div>
          <div class="col-sm-2">
            {{ 'SALES.AMOUNT' | translate }}
          </div>
          <div class="col-sm-2">
            {{ 'SALES.UNITAMOUNT' | translate }}
          </div>
          <div class="col-sm-2">%</div>
          <div class="col-sm-2">
            {{ 'SALES.TAX' | translate }}
          </div>
          <div class="col-sm-2">
            {{ 'SALES.PRICE' | translate }}
          </div>
        </div>

        <hr
        class="mt-1 mb-2 invisible-hr-small" 
        />

        <div *ngFor="let item of detailViewDataItems">
          <div class="row">
            <div class="col-sm-2">
              {{ item.Text }}
            </div>
            <div class="col-sm-2">
              {{ item.QuantityAmount }} {{ item.Unit }} x
            </div>
            <div class="col-sm-2">
              {{ item.UnitPrice | currencyFormat }}
            </div>
            <div class="col-sm-2">
              {{ item.TaxPercentage }}
            </div>
            <div class="col-sm-2">
              {{ item.Tax }}
            </div>
            <div class="col-sm-2">
              {{ item.Brutto | currencyFormat }}
            </div>
          </div>
        </div>

        <hr class="sales-detail-section-hr" />

        <div class="row font-weight-bold">
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            {{ 'SALES.VAT' | translate }}
          </div>
          <div class="col-sm-4">
            {{ 'SALES.NETTO' | translate }}
          </div>
        </div>

        <hr
          class="invisible-hr"
        />

        <div *ngFor="let taxRecap of taxRecapitulation">
          <div class="row">
            <div class="col-sm-4">
              {{ taxRecap.taxPrefix }} DPH {{ taxRecap.taxName }}
            </div>
            <div class="col-sm-4">
              {{ taxRecap.taxSum | currencyFormat }}
            </div>
            <div class="col-sm-4">
              {{ taxRecap.taxNettoSum | currencyFormat }}
            </div>
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-sm-4">{{ 'SALES.VATSUM' | translate }}</div>
            <div class="col-sm-4">
              {{ taxSumData.vat | currencyFormat }}
            </div>
            <div class="col-sm-4">
              {{ taxSumData.netto | currencyFormat }}
            </div>
          </div>
        </div>

        <hr class="sales-detail-section-hr" />

        <div class="row pl-1">
          <div class="col-sm-12 text-left">
            {{ 'SALES.METHOD' | translate }}:
            {{ detailViewData[0].TransactionType | translate }}
          </div>
        </div>

        <hr class="sales-detail-section-hr" />

        <div class="row font-weight-bold total-price">
          <div class="col-sm-12">
            {{ 'SALES.TOTAL' | translate }}:
            {{ detailViewData[0].ReceiptTotal }} {{ currency }}
          </div>
        </div>

        <hr class="sales-detail-hr" />

        <div class="footer">
          {{ 'SALES.DATEANDTIME' | translate }}:
          {{ detailViewData[0].Date | amDateFormat: 'DD.MM.YYYY HH:mm:ss' }}
          <br />
          {{ 'STORES.STORE' | translate }} {{ detailViewData[0].EET_StoreId }}
          {{ 'CASHREGISTERS.CASHREGISTER' | translate }}:
          {{ detailViewData[0].EET_PosId }} <br />

          <div *ngIf="detailViewData[0].EET_FID">
            {{ 'SALES.TABLEFIK' | translate }}: {{ detailViewData[0].EET_FID
            }}<br />
            {{ 'SALES.SECCODE' | translate }}:
            {{ detailViewData[0].EET_SecureCode }}
          </div>
          <div *ngIf="!detailViewData[0].EET_FID">
            <div *ngIf="detailViewData[0].EET_SecureCode">
              BKP: {{ detailViewData[0].EET_SecureCode }}
            </div>
          </div>

          <!-- eKasa Data -->
          <div *ngIf="detailViewData[0].EK_FID">
            {{ 'SALES.EK_FID' | translate }}: {{ detailViewData[0].EK_FID
            }}<br />
            {{ 'SALES.EK_OKP' | translate }}:
            {{ detailViewData[0].EK_SecureCode }}
          </div>
          <div *ngIf="!detailViewData[0].EK_FID">
            <div *ngIf="detailViewData[0].EK_SecureCode">
              {{ 'SALES.EK_OKP' | translate }}:
              {{ detailViewData[0].EK_SecureCode }}
            </div>
          </div>
        </div>

        <div>
          <qrcode
            [qrdata]="'Your data string'"
            [width]="256"
            [errorCorrectionLevel]="'M'"
          ></qrcode>
        </div>

        <div *ngIf="!detailViewData[0].EK_FID">
          {{ 'SALES.EK_OFFLINE_REC' | translate }}
        </div>
        <div *ngIf="detailViewData[0].EK_FID">
          {{ 'SALES.EK_ONLINE_REC' | translate }}
        </div>

        <hr
          class="invisible-hr"
        />
      </div>
    </div>

    <div class="col-sm-12 mt-3 mb-3 text-center">
      <div *ngIf="detailViewData[0].EET_FID">
        <span
          class="badge success-badge"
          >{{ 'SALES.INEET' | translate }}</span
        >
      </div>
      <div *ngIf="!detailViewData[0].EET_FID">
        <div *ngIf="detailViewData[0].EET_SecureCode">
          <span
            class="badge danger-badge"
            >{{ 'SALES.UNSENT' | translate }}</span
          >
        </div>
        <div *ngIf="!detailViewData[0].EET_SecureCode">
          <span
            class="badge warning-badge"
            >{{ 'SALES.NOTEET' | translate }}</span
          >
        </div>
      </div>
    </div>
  </navigation>
</div>
