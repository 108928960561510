import { Component, OnInit } from '@angular/core';
import { SaleService } from '../../core/services/sale/sale.service';
import { StoreService } from '../company-settings/store.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CompanyService } from '../company-settings/company.service';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';

@Component({
  selector: 'sales-detail',
  templateUrl: './sale-detail.component.html',
  styleUrls: [
    './sale-detail.component.scss',
  ],
})
export class SaleDetailComponent implements OnInit {
  isAuthentication: any;

  errorMessage: string;

  detailLoaded = false;
  itemDetailLoaded = false;

  detailViewData: any;
  detailViewDataItems: any;

  companyName: string;
  currency: string = 'Kč';

  headerData: any;

  taxRecapData: any;
  taxRecapitulation: any;

  taxSumData: any;

  headerDataLoaded = false;

  constructor(
    private salesService: SaleService,
    private route: ActivatedRoute,
    private location: Location,
    private storeService: StoreService,
    private companyService: CompanyService
  ) {}

  authenticationResult(event: object) {
    this.isAuthentication = event;
  }

  ngOnInit(): void {
    this.getHeaderData();

    let receiptId;

    this.route.params.subscribe((params) => {
      receiptId = params['value'];
    });

    this.getItemDetails(receiptId);
    this.getDetails(receiptId);
  }

  getDetails(receiptId: number) {
    this.salesService.getReceipt(receiptId).subscribe(
      (extractData) => (this.detailViewData = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => {
        this.detailLoaded = true;
        this.setReceiptCurrency();
      }
    );
  }

  getItemDetails(receiptId: number) {
    this.salesService.getReceiptItems(receiptId).subscribe(
      (extractData) => (this.detailViewDataItems = extractData),
      (handleError) => (this.errorMessage = <any>handleError),
      () => {
        this.itemDetailLoaded = true;
        this.taxRecapitulation = this.getTaxRecap();
      }
    );
  }

  setReceiptCurrency(): void {
    let receiptCurrency = this.detailViewData[0].CurrencyId;
    if (receiptCurrency == 1) {
      this.currency = 'Kč';
      return;
    } else if (receiptCurrency == 2) {
      this.currency = '€';
      return;
    }
    return;
  }

  getHeaderData() {
    this.companyService
      .getById(Number(localStorage.getItem(LocalStorageTags.CompanyId)))
      .subscribe(
        (extractData) => (this.headerData = extractData),
        (handleError) => (this.errorMessage = <any>handleError),
        () => {
          this.headerDataLoaded = true;
        }
      );
  }

  /**
   * Returns array of number taxes
   */
  getTaxRecapNames() {
    const taxValues = new Array();

    taxValues.push(this.detailViewDataItems[0].TaxPercentage);

    for (let i = 1; i < Object.keys(this.detailViewDataItems).length; i++) {
      if (
        !this.isTaxPresent(this.detailViewDataItems[i].TaxPercentage, taxValues)
      ) {
        taxValues.push(this.detailViewDataItems[i].TaxPercentage);
      }
    }

    return taxValues;
  }

  /**
   * Is VAT in VAT array
   * @param tax {number} percentage
   * @param taxValues {number array}
   */
  isTaxPresent(tax: number, taxValues: number[]) {
    for (let j = 0; j < taxValues.length; j++) {
      if (tax === taxValues[j]) {
        return true;
      }
    }

    return false;
  }

  /**
   * Computes tax recapitulation
   */
  getTaxRecap() {
    const taxRecapNames = this.getTaxRecapNames();
    const taxRecapSum = new Array();
    const taxRecapNettoSum = new Array();

    for (let i = 0; i < taxRecapNames.length; i++) {
      for (let j = 0; j < Object.keys(this.detailViewDataItems).length; j++) {
        if (taxRecapNames[i] === this.detailViewDataItems[j].TaxPercentage) {
          if (taxRecapSum.length - 1 !== i) {
            taxRecapSum.push(this.detailViewDataItems[j].Tax);
          } else {
            taxRecapSum[i] = taxRecapSum[i] + this.detailViewDataItems[j].Tax;
          }

          if (taxRecapNettoSum.length - 1 !== i) {
            taxRecapNettoSum.push(this.detailViewDataItems[j].Netto);
          } else {
            taxRecapNettoSum[i] =
              taxRecapNettoSum[i] + this.detailViewDataItems[j].Netto;
          }
        }
      }
    }

    const template = new Array();

    this.taxSumData = {
      netto: 0,
      vat: 0,
    };

    for (let i = 0; i < taxRecapNames.length; i++) {
      this.taxSumData.netto += taxRecapNettoSum[i];
      this.taxSumData.vat += taxRecapSum[i];

      const txMark = this.getTaxMark(taxRecapNames[i]);

      template.push({
        taxPrefix: txMark,
        taxName: taxRecapNames[i],
        taxSum: taxRecapSum[i],
        taxNettoSum: taxRecapNettoSum[i],
      });
    }

    return template;
  }

  getTaxMark(percentage: number) {
    switch (percentage) {
      case 20:
        return 'A';
      case 10:
        return 'B';
      case 0:
        return 'C';
      default:
        return null;
    }
  }

  back() {
    this.location.back();
  }
}
