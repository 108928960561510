import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { LocalStorageTags } from '../../enums/local-storage-tags.enum';
import { Centrala } from '../../http/centrala';
import { Currency } from '../../interfaces/currency/currency.interface';
import { HttpHandlerService } from '../shared/http-handler.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private currencyUrl: string = Centrala.URL + '/api/currencies';

  constructor(
    private httpClient: HttpClient,
    private httpHandlerService: HttpHandlerService
  ) { }

  getCurrencies() {
    const params = new HttpParams()
      .set('token', localStorage.getItem(LocalStorageTags.Token));

    return this.httpClient
      .get<Currency[]>(
        this.currencyUrl, { params }
      )
      .pipe(
        catchError(this.httpHandlerService.handleError)
      );
  }
}
