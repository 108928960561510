import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent<T> {
  @Input() autocompleteName: string = null;
  @Input() keyword: string = null;
  @Input() input: string = null;
  @Input() items: T;
  @Input() validate: boolean;

  @Output() selectedItem = new EventEmitter<T>();

  selectEvent(item: T): void {
    this.selectedItem.emit(item);
    this.validate = false;
  }

  onInputCleared(): void {
    this.selectedItem.emit(null);
    this.validate = true;
  }

  onChangeSearch(val: string): void {
    this.input = val;
  }
}
