import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { ContioService } from '../contio.service';
import { ContioStore } from '../models/contio-store.model';
import { LineChart } from '../../dashboard/models/line-chart.model';
import { AnalyticsService } from '../analytics.service';
import { Analytics } from './models/analytics.model';
import { ChartType } from './chart-type.emum';
import { AnalyticsChart } from './models/analytics-chart.model';
import { DropdownType } from '../../../../app/shared/core-components/dropdown/dropdown-type.enum';
import { GridSetting } from '../../../shared/models/grid-setting.model';
import { CsvService } from '../../../core/services/csv/csv.service';
import { LocalStorageService } from '../../../core/services/shared/local-storage.service';
import { DateTimeService } from '../../../core/services/formatter/date-time.service';

@Component({
  selector: 'app-contio-store-analytics',
  templateUrl: './contio-store-analytics.component.html',
})
export class ContioStoreAnalyticsComponent implements OnInit {
  dropdownType: any = DropdownType;
  chartType: any = ChartType;
  userId: number;

  contioStoreList: ContioStore[];
  selectedContioStore: ContioStore;

  contioChartTypeList: AnalyticsChart[] = [];
  selectedContioChartType: AnalyticsChart;

  startDate: Date = new Date();
  endDate: Date = new Date();

  isSubmit: boolean = false;

  lineScheme: object = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  readonly isXAxisVisible: boolean = true;
  readonly isYAxisVisible: boolean = true;
  readonly isXAxisLabelVisible: boolean = false;
  readonly isYAxisLabelVisible: boolean = false;
  readonly isLegendVisible: boolean = false;
  readonly isGradient: boolean = true;
  readonly isAutoscale: boolean = false;
  schemeType: string = "ordinal";

  graphData: LineChart[] = [];
  dataForChart: any[] = [];
  graphSummary: number = 0;
  isGraphLoaded: boolean = false;

  screenWidth: number = 0;

  error: string;

  constructor(
    private location: Location,
    private contioService: ContioService,
    private analyticsService: AnalyticsService,
    private datePipe: DatePipe,
    private csvService: CsvService,
    private localStorageService: LocalStorageService,
    private dateService: DateTimeService,
  ) { }

  onResize(event: any): void {
    this.screenWidth = event.target.innerWidth;
  }

  ngOnInit() {
    this.userId = this.localStorageService.getUserId();

    Object.keys(ChartType)
      .map(key => {
        const chartType: AnalyticsChart = { chartType: ChartType[key] };
        this.contioChartTypeList.push(chartType);
      });

    this.selectedContioChartType = this.contioChartTypeList[0];

    this.setContioStoreList();
  }

  onGoToContioStoreDetailClick(): void {
    this.location.back();
  }

  onSetAnalyticsClick(): void {
    if (this.selectedContioChartType.chartType === ChartType.Activity) {
      this.setActivityChart();
    } else if (this.selectedContioChartType.chartType === ChartType.BankId) {
      this.setBankIdChart();
    } else if (this.selectedContioChartType.chartType === ChartType.Receipt) {
      this.setReceiptChart();
    }
  }

  onCreateCSVClick(): void {
    const startDateTimestamp = this.dateService.convertDateToUnixTimestamp(this.startDate);
    const endDateTimestamp = this.dateService.convertDateToUnixTimestamp(this.endDate);

    if (this.selectedContioChartType.chartType === ChartType.Activity) {
      this.csvService.postEntranceCSV(
        this.userId,
        this.selectedContioStore.StoreId,
        startDateTimestamp,
        endDateTimestamp,
      );
    } else if (this.selectedContioChartType.chartType === ChartType.BankId) {
      this.csvService.postBankIdCSV(
        this.userId,
        this.selectedContioStore.Id,
        startDateTimestamp,
        endDateTimestamp,
      );
    }
  }

  private setActivityChart(): void {
    this.analyticsService
      .getActivity(
        this.selectedContioStore.UserId,
        this.selectedContioStore.StoreId,
        this.startDate,
        this.endDate,
      )
      .subscribe({
        next: (analytics) => {
          this.setGraph(analytics.items);
        },
        error: (error) => {
          this.error = error;
        }
      });
  }


  private setBankIdChart(): void {
    this.analyticsService
      .getBankId(
        this.selectedContioStore.Id,
        this.startDate,
        this.endDate,
      )
      .subscribe({
        next: (analytics) => {
          this.setGraph(analytics.items);
        },
        error: (error) => {
          this.error = error;
        }
      });
  }

  private setReceiptChart(): void {
    this.analyticsService
      .getReceipt(
        this.selectedContioStore.UserId,
        this.selectedContioStore.StoreId,
        this.startDate,
        this.endDate,
      )
      .subscribe({
        next: (analytics) => {
          this.setGraph(analytics.items);
        },
        error: (error) => {
          this.error = error;
        }
      });
  }

  private setGraph(graphData: Analytics[]): void {
    this.graphData = [];

    for (let index = 0; index < graphData.length; index++) {
      const graphRow: LineChart = {
        value: graphData[index][0].activity_count,
        name: this.startDate.getTime() === this.endDate.getTime() ? new Date(graphData[index][0].activity_date).getHours() + ':00' : this.datePipe.transform(graphData[index][0].activity_date, 'd. M. y'),
      }

      this.graphData.push(graphRow);
    }

    this.sumGraphData();

    this.dataForChart = [
      {
        name: '',
        series: this.graphData,
      },
    ];

    this.isGraphLoaded = true;
  }

  private sumGraphData(): void {
    this.graphSummary = 0;

    this.graphData.forEach(graphRow => {
      this.graphSummary = this.graphSummary + graphRow.value;
    });
  }

  private setContioStoreList(): void {
    this.contioService
      .getList(
        GridSetting.firstPage,
        GridSetting.listLimit
      )
      .subscribe({
        next: (contioStoreList) => {
          this.contioStoreList = contioStoreList;

          if (contioStoreList.length > 0) {
            this.selectedContioStore = contioStoreList[0];
          }
        },
        error: (error) => {
          this.error = error;
        }
      })
  }
}
