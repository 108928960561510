import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { TelegramPageable } from './models/telegram-pageable.model';
import { TelegramUser } from './models/telegram-user.model';
import { TelegramService } from './telegram.service';
import { SnackBarService } from '../../core/services/shared/snackbar.service';
import { ServerResponse } from '../../shared/models/server-response.model';
import { GridSetting } from '../../shared/models/grid-setting.model';
import { LocalStorageService } from '../../core/services/shared/local-storage.service';

@Component({
  selector: 'app-telegram',
  templateUrl: './telegram.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class TelegramComponent implements OnInit {
  telegramUser: TelegramUser;
  generatedTelegramKey: string;

  currentPage: number = 0;
  pageLimit: number = GridSetting.pageLimit;
  userCount: number = 0;
  telegramUserList: TelegramUser[];

  isUserDetailVisible: boolean = true;
  readonly isUserValid: boolean = true;
  telegramPostResponse: ServerResponse;
  telegramBlockResponse: ServerResponse;
  error: string;

  constructor(
    private telegramService: TelegramService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.localStorageService.autenticateContioUser();

    this.getUsers();
  }

  onGenerateTelegramKeyClick(): void {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    this.generatedTelegramKey = result;
  }

  postTelegramKey(): void {
    this.telegramService
      .post(
        this.generatedTelegramKey,
        this.isUserValid
      )
      .pipe(
        finalize(() => {
          this.telegramPostResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.telegramPostResponse = successResponse;
          if (successResponse.success) {
            this.getUsers();
            this.generatedTelegramKey = '';

            this.snackBarService.show('TELEGRAM.KEYPOSTSUCCESS', successResponse.success);
          }
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('TELEGRAM.KEYPOSTFAILED', false);
        },
      });
  }

  getUsers(user?: TelegramPageable): void {
    if (user != null) {
      this.currentPage = user.offset;
    }

    const nextPage = this.currentPage + 1;
    this.telegramService
      .getUsersByUserId(
        nextPage,
        GridSetting.pageLimit
      )
      .subscribe({
        next: (telegramUsers) => {
          this.telegramUserList = telegramUsers;
          this.getUserPageCount();
        },
        error: (error) => {
          this.error = error;
        },
      });
  }

  onViewUserDetailSelect(user: TelegramUser): void {
    this.isUserDetailVisible = false;

    this.telegramUser = user;
  }

  onBlockUserCLick(): void {
    this.telegramUser.IsValid = !this.telegramUser.IsValid;
    this.telegramService
      .blockUser(
        this.telegramUser.IsValid,
        this.telegramUser.Id
      )
      .pipe(
        finalize(() => {
          this.telegramBlockResponse = null;
        })
      )
      .subscribe({
        next: (successResponse) => {
          this.telegramBlockResponse = successResponse;

          this.snackBarService.show('TELEGRAM.BLOCKSUCCESS', successResponse.success);
        },
        error: (error) => {
          this.error = error;

          this.snackBarService.show('TELEGRAM.BLOCKFAILED', false);
        },
      });
  }

  private getUserPageCount(): void {
    this.telegramService
      .getPageCount()
      .subscribe({
        next: (usersCount) => {
          this.userCount = usersCount[0].userCount;
        },
        error: (error) => {
          this.error = error;
        },
      });
  }
}
