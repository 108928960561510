import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Centrala } from '../../core/http/centrala';
import { HttpHandlerService } from '../../core/services/shared/http-handler.service';
import { LocalStorageTags } from '../../core/enums/local-storage-tags.enum';
import { Schedule } from './contio-store-schedule/models/schedule.model';
import { centralaRequestHeaders } from '../../core/http/http-request';
import { TwilioServerResponse } from '../twilio/models/twilio-server-response.model';

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {
    private scheduleUrl: string = Centrala.URL + '/api/schedule';

    constructor(
        private httpClient: HttpClient,
        private httpHandlerService: HttpHandlerService
    ) { }

    getScheduleByContioStoreId(
        contioStoreId: number,
        userId: number,
    ): Observable<Schedule[]> {
        const params = new HttpParams()
            .set('token', localStorage.getItem(LocalStorageTags.Token))
            .set('contioStoreId', contioStoreId.toString())
            .set('userId', userId.toString());

        return this.httpClient
            .get<Schedule[]>(
                this.scheduleUrl, { params }
            )
            .pipe(
                catchError(this.httpHandlerService.handleError)
            );
    }

    post(
        userId: number,
        scheduleList: Schedule[],
    ): Observable<TwilioServerResponse> {
        const body = {
            UserId: userId,
            ScheduleList: scheduleList,
            token: localStorage.getItem(LocalStorageTags.Token),
        };

        return this.httpClient
            .post<TwilioServerResponse>(
                this.scheduleUrl,
                body,
                { 'headers': centralaRequestHeaders },
            )
            .catch(this.httpHandlerService.handleError);
    }

    update(
        userId: number,
        scheduleList: Schedule[]
    ): Observable<TwilioServerResponse> {
        const body = {
            UserId: userId,
            ScheduleList: scheduleList,
            token: localStorage.getItem(LocalStorageTags.Token),
        };

        return this.httpClient
            .put<TwilioServerResponse>(
                this.scheduleUrl,
                body,
                { 'headers': centralaRequestHeaders },
            )
            .catch(this.httpHandlerService.handleError);
    }
}